/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.jsf
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './Home.css';
import { Fragment, SVGProps, useCallback, useEffect, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import ConnectButton from './web3/ConnectButton';
import ConnectModal from './web3/ConnectModal';
import { SunIcon, MoonIcon } from '@heroicons/react/24/outline';

// Footer nav
const navigation = {
  solutions: [
    { name: 'Marketing', href: '#' },
    { name: 'Analytics', href: '#' },
    { name: 'Commerce', href: '#' },
    { name: 'Insights', href: '#' },
  ],
  support: [
    { name: 'Pricing', href: '#' },
    { name: 'Documentation', href: '#' },
    { name: 'Guides', href: '#' },
    { name: 'API Status', href: '#' },
  ],
  company: [
    { name: 'About', href: '#' },
    { name: 'Blog', href: '#' },
    { name: 'Jobs', href: '#' },
    { name: 'Press', href: '#' },
    { name: 'Partners', href: '#' },
  ],
  legal: [
    { name: 'Claim', href: '#' },
    { name: 'Privacy', href: '#' },
    { name: 'Terms', href: '#' },
  ],
  social: [
    {
      name: 'Twitter',
      href: 'https://twitter.com/timemachinenfts',
      icon: (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
  ],
};

export default function Home() {
  const [openConnectModal, setOpenConnectModal] = useState(false);
  const [themeChange, setThemeChange] = useState(
    localStorage.getItem('theme') === 'dark',
  );
  SwiperCore.use([Autoplay]);

  const [isDark, setIsDark] = useState(
    localStorage.getItem('theme') === 'dark',
  );

  if (window.location.host.startsWith('www.')) {
    window.location.href =
      window.location.protocol +
      '//' +
      window.location.host.replace('www.', '') +
      window.location.pathname;
  }

  useEffect(() => {
    const theme = themeChange ? 'dark' : 'light';
    if (theme === 'dark') {
      setIsDark(false);
      localStorage.setItem('theme', 'dark');
      document.getElementById('html')?.classList.add('dark');
      //@ts-ignore
      window.changeBodyColor('#121212');
    } else {
      setIsDark(true);
      localStorage.setItem('theme', 'light');
      document.getElementById('html')?.classList.remove('dark');
      //@ts-ignore
      window.changeBodyColor('#fff');
    }
  }, [themeChange]);

  return (
    <div className="bg-white dark:bg-zinc-900">
      <ConnectModal
        open={openConnectModal}
        setOpen={setOpenConnectModal}
      ></ConnectModal>
      <header>
        <Popover className="relative bg-white dark:bg-zinc-900 mx-auto max-w-7xl py-5 ">
          <div className="flex justify-between items-center mx-auto px-4 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <a href="#">
                <span className="sr-only">Time Sniper</span>
                {themeChange ? (
                  <img
                    style={{ width: '75px' }}
                    src="/time-transparent.png"
                    alt="TimeSniper"
                  />
                ) : (
                  <img
                    style={{ width: '75px' }}
                    src="/time.png"
                    alt="TimeSniper"
                  />
                )}
              </a>
            </div>
            <div className="md:flex items-center justify-end md:flex-1 lg:w-0">
              <button
                onClick={() => {
                  setThemeChange(!themeChange);
                }}
                type="button"
                className="relative mr-4 bg-white dark:bg-zinc-900 dark:text-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none"
              >
                <span className="sr-only">
                  {themeChange ? 'Light mode' : 'Dark mode'}
                </span>
                {themeChange ? (
                  <SunIcon
                    className="h-5 w-5 dark:hover:text-yellow-300"
                    aria-hidden="true"
                  />
                ) : (
                  <MoonIcon className="h-5 w-5" aria-hidden="true" />
                )}
              </button>

              <button
                onClick={() => {
                  window.location.href = '/app/drops';
                }}
                className="wallet-button"
              >
                {/* Open Time Sniper */}
                View App
              </button>
            </div>
          </div>
        </Popover>
      </header>

      <main className="dark:bg-zinc-900">
        <div className="relative pt-16 pb-32 overflow-hidden toprightimg">
          <div className="relative ">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24 ">
              <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0 ">
                <div className="">
                  <div className="mt-6 ">
                    <div className="animated fadeInLeft">
                      <h1 className="dark:text-gray-100">
                        Snipe your <br />
                        dream
                        <br /> investments
                      </h1>
                    </div>
                    <p className="text-sub animated fadeInLeft dark:text-gray-300">
                      Why buy a lottery ticket when you can invest in the
                      future? Create watchlists for the NFTs you want and set
                      alerts to tell you when they hit your desired price. Basic
                      version of Time Sniper is free just click on the open app
                      button or the get started button below.
                    </p>

                    <div className="mt-12 animated fadeInDown">
                      <a
                        href="#"
                        onClick={() => {
                          setOpenConnectModal(true);
                        }}
                        target="_blank"
                        className="button-get-started dark:shadow-none"
                        rel="noreferrer"
                      >
                        Get started now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0 animated fadeInLeft">
                <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                  <img
                    className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                    src="app.png"
                    alt="Inbox user interface"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="relative overflow-hidden bg-white dark:bg-zinc-900 py-16 wrap">
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="mx-auto text-lg text-center">
              <div style={{ width: 'auto' }}>
                <span className=" text-center text-sm font-semibold text-indigo-600 pill">
                  Why Us
                </span>
              </div>
              <h1>
                <span className="mt-2 block text-center dark:text-gray-100 font-bold leading-8 tracking-tight text-lead">
                  Do it all with the Time Sniper
                  <br />
                  NFT portfolio tracker.
                </span>
              </h1>
              <p className="mt-8 leading-8 text-center mx-auto max-w-6xl dark:text-gray-300">
                Time Sniper is the ultimate tool for managing your Non-Fungible
                Token (NFT) investments in a single dashboard. You can track any
                NFT you own through our dashboard and we will automatically
                notify you when the price changes on that specific NFT. You can
                also create watchlists of any NFTs to track them all at once,
                without having to switch between wallets or apps.
              </p>
            </div>

            <div className="lg:mx-auto">
              <div className="grid grid-cols-1 lg:grid-cols-3 grid-rows-3 lg:grid-rows-1 grid-flow-col mt-6 ">
                <div className="box text-center dark:shadow-none dark:bg-zinc-850">
                  <div className="flex justify-center">
                    {isDark ? (
                      <img src="/icons/d-298-coins-outline.gif" width={75} />
                    ) : (
                      <img src="/icons/298-coins-outline.gif" width={75} />
                    )}
                  </div>
                  <h2 className="mt-2 title dark:text-gray-200">
                    Wallet Integration
                  </h2>
                  <p className="cardtext dark:text-gray-300">
                    Connect all Cronos NFT supported wallets and your crypto.org
                    wallet to view all your NFT portfolio on the dashboard in
                    one place
                  </p>
                </div>

                <div className="box text-center dark:shadow-none dark:bg-zinc-850">
                  <div className="flex justify-center">
                    {isDark ? (
                      <img src="/icons/d-36-bulb-outline.gif" width={75} />
                    ) : (
                      <img src="/icons/36-bulb-outline.gif" width={75} />
                    )}
                  </div>
                  <h2 className="mt-2 title dark:text-gray-200">
                    Floor Price Alert
                  </h2>
                  <p className="cardtext dark:text-gray-300">
                    User can set alerts to specific NFT projects and receive an
                    alert when the floor price changes by either a % or a
                    monetary value
                  </p>
                </div>
                <div className="box text-center dark:shadow-none dark:bg-zinc-850">
                  <div className="flex justify-center">
                    {isDark ? (
                      <img
                        src="/icons/d-152-bar-chart-arrow-growth-outline.gif"
                        width={75}
                      />
                    ) : (
                      <img
                        src="/icons/152-bar-chart-arrow-growth-outline.gif"
                        width={75}
                      />
                    )}
                  </div>
                  <h2 className="mt-2 title dark:text-gray-200">
                    Alerts on Individual NFTs
                  </h2>
                  <p className="cardtext dark:text-gray-300">
                    User can set alerts to specific NFTs and receive an alert
                    when the price changes by either a % or a monetary value.
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-1 lg:grid-cols-3 grid-rows-3 lg:grid-rows-1 grid-flow-col mt-5 gap-4">
                <div className="box text-center dark:shadow-none dark:bg-zinc-850">
                  <div className="flex justify-center">
                    {isDark ? (
                      <img src="/icons/d-69-eye-outline.gif" width={75} />
                    ) : (
                      <img src="/icons/69-eye-outline.gif" width={75} />
                    )}
                  </div>
                  <h2 className="mt-2 title dark:text-gray-200">Watch Lists</h2>
                  <p className="cardtext dark:text-gray-300">
                    Create and monitor all your targeted NFT Projects with
                    personal watchlists
                  </p>
                </div>

                <div className="box text-center dark:shadow-none dark:bg-zinc-850">
                  <div className="flex justify-center">
                    {isDark ? (
                      <img src="/icons/d-12-layes-outline.gif" width={75} />
                    ) : (
                      <img src="/icons/12-layes-outline.gif" width={75} />
                    )}
                  </div>
                  <h2 className="mt-2 title dark:text-gray-200">
                    Exclusive Access
                  </h2>
                  <p className="cardtext dark:text-gray-300">
                    Access Time Sniper by owning one of our specified NFTs or by
                    paying a small fee per month, per wallet. Please Contact
                    Time Team for inquires
                  </p>
                </div>
                <div className="box text-center dark:shadow-none dark:bg-zinc-850">
                  <div className="flex justify-center">
                    {isDark ? (
                      <img
                        src="/icons/d-1827-growing-plant-outline.gif"
                        width={75}
                      />
                    ) : (
                      <img
                        src="/icons/1827-growing-plant-outline.gif"
                        width={75}
                      />
                    )}
                  </div>
                  <h2 className="mt-2 title dark:text-gray-200">
                    More to Come
                  </h2>
                  <p className="cardtext dark:text-gray-300">
                    We are constantly working on new Features and tools!
                    <br />
                    Check our{' '}
                    <a href="#" className="sh-link">
                      Roadmap
                    </a>{' '}
                    or
                    <br />
                    <a href="#" className="sh-link">
                      submit a suggestion 💖
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white dark:bg-zinc-900 mt-4">
          <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
            <div className="lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
              <div className="sm:p-0 lg:pt-1 lg:pr-24 lg:pb-20">
                <div style={{ width: 'auto' }}>
                  <span className=" text-center text-sm font-semibold text-indigo-600 pill">
                    NFT Tracking
                  </span>
                </div>
                <h1>
                  <span className="mt-6 block dark:text-gray-100 font-bold leading-8 tracking-tight text-lead">
                    Keep tabs on your
                    <br />
                    investments.
                  </span>
                </h1>
                <p className="mt-3 text-sub dark:text-gray-300">
                  Keeping tabs on all of your investments can be a daunting
                  task. With Task Sniper, you can create alerts to specific NFTs
                  and receive an alert when their price changes in value so you
                  never miss out on a great opportunity again.
                </p>
                <div className="mt-8 sm:flex">
                  <a
                    href="#"
                    onClick={() => setOpenConnectModal(true)}
                    className="button-get-started dark:shadow-none"
                  >
                    Get started now
                  </a>
                </div>
              </div>
              <div className="mt-12  lg:mt-0">
                <img src="/Individual_NFT_alerts.gif" alt="NFT Alerts" />
              </div>
            </div>
          </div>
        </div>

        {/* Logo Cloud */}

        <div className="mx-auto max-w-7xl py-4 lg:py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="sm:p-0 lg:pt-1 lg:pr-24 lg:pb-20">
            <Swiper slidesPerView={2}>
              <SwiperSlide>
                <img
                  contextMenu=""
                  src="/cronos-logo.png"
                  width={300}
                  alt="Cronos"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img src="/polygon.png" alt="Crypto.com" width={300} />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>

        <div className="bg-white dark:bg-zinc-900 mt-14">
          <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
            <div className="lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
              <div className="mt-8 lg:mt-0">
                <img src="/analytics.webp" alt="Analytics" />
              </div>
              <div className="sm:p-0 lg:pt-1 lg:pr-24 lg:pb-20">
                <div style={{ width: 'auto' }}>
                  <span className=" text-center text-sm font-semibold text-indigo-600 pill">
                    Staking, Trading, Comparing
                  </span>
                </div>
                <h1>
                  <span className="mt-6 block font-bold leading-8 dark:text-gray-100 tracking-tight text-lead">
                    Invest in the best
                    <br />
                    projects with just
                    <br /> a glance.
                  </span>
                </h1>
                <p className="mt-3 text-sub-sm dark:text-gray-300">
                  Task Sniper is a comprehensive and impartial engagement
                  platform for all NFT staking enthusiasts, traders and
                  investors to connect, communicate and share information about
                  NFT projects on all the marketplaces. You will be able to see
                  and compare at a glance the best projects to invest in.
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>

      <footer
        className="bg-footer dark:bg-zinc-800"
        aria-labelledby="footer-heading"
      >
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="space-y-8 xl:col-span-1">
              <img className="h-24" src="/time.png" alt="Time Sniper" />
              <p className="text-base text-gray-500">
                Copyright © 2024{' '}
                <a href="https://discord.gg/gDYDAQA78K" className="sh-link">
                  Time Machines
                </a>
              </p>
              <div className="flex space-x-6">
                {navigation.social.map(item => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="text-gray-400 hover:text-gray-500 sh-link"
                  >
                    <span className="sr-only">{item.name}</span>
                    <item.icon className="h-6 w-6" aria-hidden="true" />
                  </a>
                ))}
              </div>
            </div>
            <div className="mt-12 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-base font-bold text-white">Need help?</h3>
                  <ul role="list" className="mt-4 space-y-4">
                    <li>
                      <a
                        href={'https://timesniper.helpsite.com'}
                        className="text-base text-gray-500 hover:text-gray-200 duration-200 "
                      >
                        FAQ
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

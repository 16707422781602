import { Trait } from './Trait';

interface Props {
  traits: { type: string; value: string; rarity: number }[];
}

export default function TraitList({ traits }: Props) {
  const list = traits.map((trait: any, index: number) => (
    <Trait key={index} trait={trait}></Trait>
  ));
  return (
    <div className="grid md:grid-cols-4 lg:grid-cols-4 grid-cols-1 gap-6">
      {list}
    </div>
  );
}

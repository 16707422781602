interface Props {
  trait: { type: string; value: string; rarity: number };
}

export function Trait({ trait }: Props) {
  return (
    <div className="border border-blue-600 bg-blue-100 dark:bg-zinc-800 rounded-lg px-2 py-3 text-center">
      <p className="font-medium text-blue-600">{trait.type}</p>
      <p className="mt-2 text-base font-medium text-gray-700 dark:text-gray-200">
        {trait.value}
      </p>
      {trait.rarity && trait.rarity !== -1 ? (
        <p className="mt-2 text-gray-500 dark:text-gray-400">
          {trait.rarity}% have this trait
        </p>
      ) : null}
    </div>
  );
}

// helper/utils.ts
import { ethers } from 'ethers'; // npm install ethers
import { IWallet } from '../contexts/AuthContext';
import {
  CRC20ContractKey,
  CRC20Tokens,
  ERC20ContractKey,
  ERC20Tokens,
  configVars,
} from './config';

// import * as config from '../config';
import ERC20CROABI from './ABI/erc20.cro.abi.json';

export const defaultWallet: IWallet = {
  walletProviderName: '',
  address: '',
  browserWeb3Provider: null,
  serverWeb3Provider: null,
  wcConnector: null,
  wcProvider: null,
  connected: false,
  chainId: 0,
};

export const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

export const hexToInt = (s: string) => {
  const bn = ethers.BigNumber.from(s);
  return parseInt(bn.toString());
};

export const reloadApp = () => {
  window.location.reload();
};

// Get the last block number
export const getLastBlockNumber = async (ethersProvider: any): Promise<any> => {
  return ethersProvider.getBlockNumber();
};

export async function getERC20Balance(walletAddress: string, contract: string) {
  // Connect to the Cronos network
  const provider = new ethers.providers.JsonRpcProvider(
    configVars.polygon.rpcUrl,
  );

  // CRC20 token ABI elements (Only the balanceOf function)
  const abi = ['function balanceOf(address owner) view returns (uint256)'];

  // Connect to the token contract
  const tokenContract = new ethers.Contract(contract, abi, provider);

  // Query the balance
  const balance = await tokenContract.balanceOf(walletAddress);

  // Format the balance (assuming 18 decimal places, adjust if different)
  const formattedBalance = ethers.utils.formatUnits(balance, 18);

  return formattedBalance;
}

export async function getCRC20Balance(
  walletAddress: string,
  network: CRC20ContractKey,
) {
  // Connect to the Cronos network
  const provider = new ethers.providers.JsonRpcProvider(
    'https://evm.cronos.org',
  );

  // CRC20 token ABI elements (Only the balanceOf function)
  const abi = ['function balanceOf(address owner) view returns (uint256)'];

  // Connect to the token contract
  const tokenContract = new ethers.Contract(
    CRC20Tokens[network]?.contract || '',
    abi,
    provider,
  );

  // Query the balance
  const balance = await tokenContract.balanceOf(walletAddress);

  // Format the balance (assuming 18 decimal places, adjust if different)
  const formattedBalance = ethers.utils.formatUnits(balance, 18);

  return formattedBalance;
}

export const getBalance = async (
  address: string,
  network: ERC20ContractKey,
): Promise<string> => {
  const provider = new ethers.providers.JsonRpcProvider(
    process.env.REACT_APP_INFURA,
  );

  // Create ethers.Contract object using the smart contract's ABI
  const readContractInstance = new ethers.Contract(
    ERC20Tokens[network]?.contract || '',
    ERC20CROABI,
    provider,
  );
  const contractResponse = await readContractInstance.balanceOf(address);

  return ethers.utils.formatUnits(contractResponse, 8);
};

export const txWait = async (resp: Promise<any>) => {
  const tx = await resp;

  // https://docs.ethers.org/v6/api/contract/#ContractTransactionResponse-wait
  const receipt = await tx.wait();
  if (receipt!.status === 0) {
    throw new Error('Transaction reverted');
  }

  // Receipt is always present since we wait with confirmations: 1
  return receipt!;
};

export const ensureBase = async () => {
  if (window.ethereum) {
    try {
      // Request the user to connect their account
      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts',
      });

      // Check if the user has any accounts connected
      if (accounts.length === 0) {
        alert('Please connect to MetaMask.');
        return;
      }

      // Check if they are already connected to the Cronos network
      const currentChainId = await window.ethereum.request({
        method: 'eth_chainId',
      });
      if (currentChainId !== configVars.base.chainIdHex) {
        // Request to switch to the Cronos network
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: configVars.base.chainIdHex }],
        });

        const currentChainId = await window.ethereum.request({
          method: 'eth_chainId',
        });

        if (currentChainId !== configVars.base.chainIdHex) {
          throw new Error('Failed to change chain.');
        }
      }
    } catch (error: any) {
      if (error.code === 4902) {
        try {
          // The chain has not been added to MetaMask, request to add it
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: configVars.base.chainIdHex,
                rpcUrl: configVars.base.rpcUrl, // Replace with the RPC URL of Cronos
                // ... other chain parameters if necessary
              },
            ],
          });
        } catch (addError) {
          throw new Error('Failed to add the Base chain');
        }
      } else {
        console.log(error);
        throw new Error('Failed to switch to the Base chain');
      }
    }
  } else {
    throw new Error('Ethereum wallet not detected');
  }
};

export const ensureCronos = async () => {
  if (window.ethereum) {
    try {
      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts',
      });

      if (accounts.length === 0) {
        alert('Please connect to a wallet.');
        return;
      }

      const currentChainId = await window.ethereum.request({
        method: 'eth_chainId',
      });
      if (currentChainId !== configVars.rpcNetwork_mainnet.chainIdHex) {
        // Request to switch to the Cronos network
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: configVars.rpcNetwork_mainnet.chainIdHex }],
        });

        const currentChainId = await window.ethereum.request({
          method: 'eth_chainId',
        });

        if (currentChainId !== configVars.rpcNetwork_mainnet.chainIdHex) {
          throw new Error('Failed to change chain.');
        }
      }
    } catch (error: any) {
      if (error.code === 4902) {
        try {
          // The chain has not been added to MetaMask, request to add it
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: configVars.rpcNetwork_mainnet.chainIdHex,
                rpcUrl: configVars.rpcNetwork_mainnet.rpcUrl,
              },
            ],
          });
        } catch (addError) {
          throw new Error('Failed to add the Cronos chain');
        }
      } else {
        console.log(error);
        throw new Error('Failed to switch to the Cronos chain');
      }
    }
  } else {
    throw new Error('Ethereum wallet not detected');
  }
};

export const ensurePolygon = async () => {
  if (window.ethereum) {
    try {
      // Request the user to connect their account
      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts',
      });

      // Check if the user has any accounts connected
      if (accounts.length === 0) {
        alert('Please connect to MetaMask.');
        return;
      }

      // Check if they are already connected to the Cronos network
      const currentChainId = await window.ethereum.request({
        method: 'eth_chainId',
      });
      if (currentChainId !== configVars.polygon.chainIdHex) {
        // Request to switch to the Cronos network
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: configVars.polygon.chainIdHex }],
        });

        const currentChainId = await window.ethereum.request({
          method: 'eth_chainId',
        });
        if (currentChainId !== configVars.polygon.chainIdHex) {
          throw new Error('Failed to change chain.');
        }
      }
    } catch (error: any) {
      if (error.code === 4902) {
        try {
          // The chain has not been added to MetaMask, request to add it
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: configVars.polygon.chainIdHex,
                rpcUrl: configVars.polygon.rpcUrl, // Replace with the RPC URL of Cronos
                // ... other chain parameters if necessary
              },
            ],
          });
        } catch (addError) {
          throw new Error('Failed to add the Cronos chain');
        }
      } else {
        throw new Error('Failed to switch to the Cronos chain');
      }
    }
  } else {
    throw new Error('Ethereum wallet not detected');
  }
};
// Get the CTOK token balance of address
// The CTOK is a ERC20 smart contract, its address is retrieved from
// the config/config.ts file
// and the ABI from config/contracts/MyERC20MintableByAnyone.json
// export const getBalance = async (
//   serverWeb3Provider: ethers.providers.JsonRpcProvider,
//   address: string,
// ): Promise<number> => {
//   // Create ethers.Contract object using the smart contract's ABI
//   const readContractInstance = new ethers.Contract(
//     config.configVars.erc20.address,
//     ERC20ABI,
//     serverWeb3Provider,
//   );
//   const contractResponse = await readContractInstance['balanceOf'](address);
//   // Balance is rounded at 2 decimals instead of 18, to simplify UI
//   return (
//     ethers.BigNumber.from(contractResponse)
//       .div(ethers.BigNumber.from('10000000000000000'))
//       .toNumber() / 100
//   );
// };

// Generate a ethers.Contract instance of the contract object
// together with a signer that will trigger a transaction
// approval in the wallet whenever it is called by the Dapp
// export const getWriteContractInstance = async (
//   browserWeb3Provider: any,
// ): Promise<ethers.Contract> => {
//   const ethersProvider = browserWeb3Provider;
//   // Create ethers.Contract object using the smart contract's ABI
//   const readContractInstance = new ethers.Contract(
//     config.configVars.erc20.address,
//     ERC20ABI,
//     ethersProvider,
//   );
//   // Add a signer to make the ethers.Contract object able
//   // to craft transactions
//   const fromSigner = ethersProvider.getSigner();
//   return readContractInstance.connect(fromSigner);
// };

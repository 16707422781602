import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import TimeABI from '../../../web3/ABI/time.abi.json';
import { BigNumber, ethers } from 'ethers';
import {
  PoolStakingContractKeys,
  SoftStakingContractKeys,
  poolStakingContracts,
  softStakingContracts,
} from '../../../web3/config';
import { formatEther, parseEther } from 'ethers/lib/utils';
import { MyStakingTokenList } from '../components/token/MyStakingTokenList';
import {
  POOLS_ADDRESS,
  POOLS_ABI,
} from '../../../web3/ABI/contracts/time-pools';
import { UserReward, CollectionInfoData } from './SoftStaking';
import ERC20ABI from '../../../web3/ABI/erc20.abi.json';
import { secondsToDhms } from '../../../utils/parser';
import TTTInput from '../components/inputs/TTTInput';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PlaceholderLoading from 'react-placeholder-loading';
import { useNetwork } from '../../../hooks/useNetwork';
import { ensureCronos, txWait } from '../../../web3/utils';
import WalletRequired from '../components/WalletRequired';
export type PoolInfo = {
  id: bigint;
  stakeToken: `0x${string}`;
  earnToken: `0x${string}`;
  enabled: boolean;
  totalClaimed: bigint;
  totalStaked: bigint;
  apr: bigint;
  lockTime: bigint;
};

const contractConfig = {
  address: POOLS_ADDRESS,
  abi: POOLS_ABI,
};

type PoolType = {
  id: bigint;
  stakeToken: string;
  apr: number;
  lockTime: any;
  totalStaked: any;
};

const testWallet: string | null = null;

function Pool({ tokenName, pool }: { tokenName: string; pool: PoolType }) {
  const { user, activeWallet } = useAuth();
  const { tttUSDPrice } = useNetwork();
  const [balance, setBalance] = useState('0');
  const [stakeAmount, setStakeAmount] = useState('0.00');
  const [userData, setUserData] = useState<any>();
  const [unclaimedRewards, setUnclaimedRewards] = useState<any>();
  const [tokenAllowance, setTokenAllowance] = useState<any>();

  const tttChange = (val: any) => {
    setStakeAmount(val);
  };

  useEffect(() => {
    ensureCronos();
  }, []);
  async function getUserData() {
    if (!user.isAuthenticated) return;

    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const poolsContract = new ethers.Contract(
      contractConfig.address,
      contractConfig.abi,
      provider,
    );
    const gotUserData = await poolsContract.userData(
      pool.id,
      testWallet ? testWallet! : activeWallet!,
    );

    if (gotUserData) {
      setUserData(gotUserData);
    }
  }

  async function getUnclaimedRewards() {
    if (!user.isAuthenticated) return;

    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const poolsContract = new ethers.Contract(
      contractConfig.address,
      contractConfig.abi,
      provider,
    );
    const gotUnclaimedRewards =
      await poolsContract.getUnclaimedRewardsForAddress(
        pool.id,
        testWallet ? testWallet! : activeWallet!,
      );

    if (gotUnclaimedRewards) {
      setUnclaimedRewards(gotUnclaimedRewards);
    }
  }

  async function getTokenAllowanceAndBalance() {
    if (!user.isAuthenticated) return;

    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const poolContract = new ethers.Contract(
      pool.stakeToken,
      ERC20ABI,
      provider,
    );
    const gotTokenAllowance = await poolContract.allowance(
      testWallet ? testWallet! : activeWallet!,
      POOLS_ADDRESS,
    );

    if (gotTokenAllowance) {
      setTokenAllowance(gotTokenAllowance);
    }

    const gotBalance = await poolContract.balanceOf(
      testWallet ? testWallet! : activeWallet!,
    );

    if (gotBalance) {
      setBalance(gotBalance);
    }
  }

  useEffect(() => {
    if (!activeWallet && !testWallet) return;

    async function run() {
      await ensureCronos();
    }
    run().then(() => {
      getUserData();
      getUnclaimedRewards();
      getTokenAllowanceAndBalance();
    });
  }, [user.isAuthenticated, activeWallet, user.wallet.chainId]);

  const seconds = new Date().getTime() / 1000;
  const isLocked = userData && Number(userData.unlockTimestamp) > seconds;
  const remainingLockInSeconds =
    userData && isLocked ? Number(userData.unlockTimestamp) - seconds : 0;

  // Begin Stake
  const isEnoughAllowed =
    tokenAllowance !== undefined &&
    tokenAllowance >= parseEther(stakeAmount || '0');

  async function onStake() {
    if (
      !user ||
      !user.wallet ||
      !user.isConnected ||
      !user.isAuthenticated ||
      !activeWallet
    ) {
      console.log('User or wallet not fully initialized or not connected');
      alert('User or wallet not fully initialized or not connected');
      return;
    }

    if (!isEnoughAllowed) {
      alert('You do not have enough.');
      return;
    }

    try {
      await ensureCronos();
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const stakingContract = new ethers.Contract(
        contractConfig.address,
        contractConfig.abi,
        signer,
      );

      const gasEstimate = await provider.estimateGas({
        to: contractConfig.address,
        from: await signer.getAddress(),
        data: stakingContract.interface.encodeFunctionData('stake', [
          pool.id,
          parseEther(stakeAmount || '0'),
        ]),
        value: parseEther('1'),
      });

      const gasLimit = gasEstimate.mul(12).div(10);

      await txWait(
        stakingContract.stake(pool.id, parseEther(stakeAmount || '0'), {
          value: parseEther('1'),
          gasLimit,
        }),
      );
      await getUserData();
      await getUnclaimedRewards();
      await getTokenAllowanceAndBalance();
    } catch (error: any) {
      console.log(error.code);
      switch (error.code) {
        case -32603:
          alert('You do not have enough funds to perform this transaction.');
          break;
        default:
          //alert('Something went wrong, please view the console logs.');
          break;
      }
      console.error('Failed:', error);
    }
  }

  async function onUnstake() {
    if (
      !user ||
      !user.wallet ||
      !user.isConnected ||
      !user.isAuthenticated ||
      !activeWallet
    ) {
      alert('User or wallet not fully initialized or not connected');
      return;
    }

    if (isLocked || userData.userStakedTokens <= 0) {
      alert('Failed to unstake, do you meet the requirements?');
      return;
    }

    try {
      await ensureCronos();
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const stakingContract = new ethers.Contract(
        contractConfig.address,
        contractConfig.abi,
        signer,
      );

      const fee = await stakingContract.fee();

      const gasEstimate = await provider.estimateGas({
        to: contractConfig.address,
        from: await signer.getAddress(),
        data: stakingContract.interface.encodeFunctionData('unstake', [
          pool.id,
        ]),
        value: fee ?? 0,
      });

      const gasLimit = gasEstimate.mul(12).div(10);

      await txWait(
        stakingContract.unstake(pool.id, {
          value: fee ?? 0,
          gasLimit: gasLimit,
        }),
      );

      getUserData();
      getUnclaimedRewards();
      getTokenAllowanceAndBalance();
    } catch (error: any) {
      console.log(error.code);
      switch (error.code) {
        case -32603:
          alert('You do not have enough funds to perform this transaction.');
          break;
        default:
          //alert('Something went wrong, please view the console logs.');
          break;
      }
      console.error('Failed:', error);
    }
  }

  // Claim
  async function onClaim() {
    if (
      !user ||
      !user.wallet ||
      !user.isConnected ||
      !user.isAuthenticated ||
      !activeWallet
    ) {
      alert('User or wallet not fully initialized or not connected');
      return;
    }

    if (!userData || userData.userStakedTokens === 0) {
      alert('Not enough tokens.');
      return;
    }

    try {
      await ensureCronos();
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const stakingContract = new ethers.Contract(
        contractConfig.address,
        contractConfig.abi,
        signer,
      );

      const gasEstimate = await provider.estimateGas({
        to: contractConfig.address,
        from: await signer.getAddress(),
        data: stakingContract.interface.encodeFunctionData(
          'claimRewardsForAddress',
          [pool.id, testWallet ? testWallet! : activeWallet!],
        ),
        value: parseEther('1'),
      });

      const gasLimit = gasEstimate.mul(12).div(10);

      await txWait(
        stakingContract.claimRewardsForAddress(
          pool.id,
          testWallet ? testWallet! : activeWallet!,
          {
            value: parseEther('1'),
            gasLimit,
          },
        ),
      );
      alert('Claimed');
      getUserData();
      getUnclaimedRewards();
      getTokenAllowanceAndBalance();
    } catch (error: any) {
      console.log(error.code);
      switch (error.code) {
        case -32603:
          alert('You do not have enough funds to perform this transaction.');
          break;
        default:
          //alert('Something went wrong, please view the console logs.');
          break;
      }
      console.error('Failed:', error);
    }
  }

  // Approve
  const tokenValue = parseEther(`${Number(stakeAmount || '0')}`);
  async function onApprove() {
    if (
      !user ||
      !user.wallet ||
      !user.isConnected ||
      !user.isAuthenticated ||
      !activeWallet
    ) {
      console.log('User or wallet not fully initialized or not connected');
      alert('User or wallet not fully initialized or not connected');
      return;
    }

    if (tokenValue.lte(0)) {
      alert('Invalid amount.');
      return;
    }

    try {
      await ensureCronos();
      const provider = new ethers.providers.Web3Provider(window.ethereum);

      const signer = provider.getSigner();
      const stakingContract = new ethers.Contract(
        pool.stakeToken,
        ERC20ABI,
        signer,
      );

      await txWait(stakingContract.approve(POOLS_ADDRESS, tokenValue));
      getTokenAllowanceAndBalance();
    } catch (error: any) {
      console.log(error.code);
      switch (error.code) {
        case -32603:
          alert('You do not have enough funds to perform this transaction.');
          break;
        default:
          //alert('Something went wrong, please view the console logs.');
          break;
      }
      console.error('Failed:', error);
    }
  }

  return (
    <div
      className={`bg-white dark:bg-zinc-900 dark:text-white border border-gray-100 dark:border-zinc-700 bg-white p-6 rounded-lg`}
    >
      <div className="flex justify-between mt-0 dark:text-white">
        <div>
          <div className="flex flex-row">
            <div>
              <LazyLoadImage
                className="w-14 rounded-lg "
                src={'/tokens/ttt.webp'}
                width={200}
                height={200}
                placeholder={
                  <PlaceholderLoading
                    shape="rect"
                    width={200}
                    height={200}
                    colorStart="transparent"
                    colorEnd="#9CA3AF"
                  />
                }
              />
            </div>
            <div className="ml-4">
              <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
                Earn {tokenName}
              </h1>
              <p className="text-sm dark:text-gray-300">Stake {tokenName}</p>
            </div>
          </div>
        </div>
        <div className="text-end">
          <p className="text-sm dark:text-white">
            <b>APR</b> {Number(pool.apr)}%
          </p>
          {pool.lockTime > 0 ? (
            <p className="text-sm dark:text-white">
              <b>Lock Time</b> {secondsToDhms(Number(pool.lockTime))}
            </p>
          ) : null}
          {isLocked && (
            <div className="text-sm text-[red]">
              Unlocks in {secondsToDhms(remainingLockInSeconds)}
            </div>
          )}
        </div>
      </div>
      <div className="flex space-x-16 mt-6 dark:text-white">
        <div>
          <p className="text-sm text-gray-900 dark:text-white">Earned</p>
          <p className="text-lg font-semibold dark:text-white">
            {Number(
              formatEther(unclaimedRewards ? unclaimedRewards : 0),
            ).toLocaleString('en-us', { maximumFractionDigits: 4 })}{' '}
            {tokenName}
          </p>
          <p className="text-[12px] text-gray-500 leading-3 dark:text-gray-300">
            {tttUSDPrice
              ? '($' +
                (
                  Number(formatEther(unclaimedRewards ? unclaimedRewards : 0)) *
                  tttUSDPrice
                ).toFixed(2) +
                ' USDC)'
              : null}
          </p>
          <p className="text-sm text-gray-500 mt-[2px] dark:text-gray-300">
            Claimed:{' '}
            {Number(
              formatEther(userData ? userData.userTotalRewards : 0),
            ).toLocaleString('en-us', { maximumFractionDigits: 4 })}{' '}
            {tokenName}
          </p>
        </div>

        <div>
          <p className="text-sm text-gray-900 dark:text-white">Staked</p>
          <p className="text-lg font-semibold dark:text-white">
            {Number(
              formatEther(userData ? userData.userStakedTokens : 0),
            ).toLocaleString('en-us', { maximumFractionDigits: 4 })}{' '}
            {tokenName}
          </p>
          <p className="text-[12px] text-gray-500 leading-3 dark:text-gray-300">
            {tttUSDPrice
              ? '($' +
                (
                  Number(
                    formatEther(userData ? userData.userStakedTokens : 0),
                  ) * tttUSDPrice
                ).toFixed(2) +
                ' USDC)'
              : null}
          </p>
          <p className="text-sm text-gray-500 mt-[1px] dark:text-gray-300">
            Total:{' '}
            {Number(formatEther(pool.totalStaked)).toLocaleString('en-us', {
              maximumFractionDigits: 0,
            })}{' '}
            {tokenName}
          </p>
        </div>
      </div>

      <div className="flex space-x-16 mt-6 dark:text-white">
        <p className="text-sm dark:text-white">
          Balance:{' '}
          {Number(formatEther(balance)).toLocaleString('en-us', {
            maximumFractionDigits: 4,
          })}{' '}
          {tokenName}
        </p>
      </div>

      <div className="w-full lg:w-72">
        <TTTInput
          value={stakeAmount}
          onChange={tttChange}
          max={Number(formatEther(pool.totalStaked))}
        />
      </div>

      <div className="flex items-center justify-between">
        <div className="mt-2 flex gap-x-2">
          <button
            onClick={() => {
              if (isEnoughAllowed) {
                onStake();
              } else {
                onApprove();
              }
            }}
            disabled={
              !stakeAmount || stakeAmount === '0' || stakeAmount === '0.00'
            }
            className="cursor-pointer disabled:cursor-default bg-white dark:bg-zinc-800 dark:text-white dark:border-zinc-700 dark:disabled:opacity-50 dark:hover:bg-zinc-700 dark:disabled:hover:bg-zinc-800 disabled:bg-gray-200 py-2 px-6 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 disabled:text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {isEnoughAllowed ? 'Stake' : 'Approve'}
          </button>
          <button
            onClick={() => onUnstake()}
            disabled={!userData || userData.userStakedTokens.eq(0) || isLocked}
            className="cursor-pointer disabled:cursor-default bg-white dark:bg-zinc-800 dark:text-white dark:border-zinc-700 dark:disabled:opacity-50  dark:hover:bg-zinc-700 dark:disabled:hover:bg-zinc-800 disabled:bg-gray-200 py-2 px-6 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 disabled:text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Unstake All
          </button>
        </div>
        <div>
          <button
            onClick={() => onClaim()}
            disabled={
              Number(formatEther(unclaimedRewards ? unclaimedRewards : 0)) <= 0
            }
            className="cursor-pointer disabled:cursor-default bg-white dark:bg-zinc-800 dark:text-white dark:border-zinc-700 dark:disabled:opacity-50 dark:hover:bg-zinc-700 dark:disabled:hover:bg-zinc-800 disabled:bg-gray-200 py-2 px-6 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 disabled:text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Claim
          </button>
        </div>
      </div>
    </div>
  );
}

export default function PoolStaking() {
  const { user, updateUser, activeWallet, loadingActiveWallet } = useAuth();
  let { name } = useParams<{ name?: PoolStakingContractKeys }>(); // Ensure name is a string or undefined

  const tokenName = poolStakingContracts[name as PoolStakingContractKeys]?.name;

  const [pools, setPools] = useState<any[]>();

  useEffect(() => {
    if (!user.isConnected) return;

    async function fetchPools() {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const poolsContract = new ethers.Contract(
        contractConfig.address,
        contractConfig.abi,
        provider,
      );
      const getPools = await poolsContract.getAllPools();

      setPools(getPools);
    }
    fetchPools();
  }, [activeWallet, user.isConnected]);

  if (loadingActiveWallet) return <></>;
  if (!activeWallet) {
    return <WalletRequired />;
  }
  // name: 101s, etc

  return (
    <>
      <h1 className="text-2xl font-semibold text-gray-900 dark:text-white">
        {tokenName} Pools
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 justify-center gap-6 w-full pt-4">
        {pools && pools.length > 0
          ? pools.map(pool => (
              <Pool
                key={tokenName + '-' + pool.id.toString()}
                tokenName={tokenName || ''}
                pool={pool}
              />
            ))
          : 'No pools available.'}
      </div>
    </>
  );
}

import { Dialog, Transition } from '@headlessui/react';
import { XCircleIcon } from '@heroicons/react/24/solid';
import { Fragment } from 'react';

type DialogType = {
  open: any;
  setOpen: any;
  children: any;
  closeable?: boolean;
};

function DialogScreen({
  open,
  setOpen,
  closeable = true,
  children,
}: DialogType) {
  const handleClose = () => {
    if (closeable) {
      setOpen(false);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-1 md:p-4 text-center sm:items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all mb-32 md:my-0 sm:max-w-lg w-full">
                {/* Close Icon */}
                {closeable && (
                  <div className="absolute top-0 right-0 p-4">
                    <button onClick={handleClose}>
                      <XCircleIcon className="h-6 w-6 text-gray-400 hover:text-gray-600" />
                    </button>
                  </div>
                )}
                {/* Dialog Content */}
                <div className="px-4 pb-4 pt-6">{children}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default DialogScreen;

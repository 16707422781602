import PlaceholderLoading from 'react-placeholder-loading';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useState } from 'react';
import './DropCard.css';

export function DropCard({ name, gifImage, bannerImage, slug }: any) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <a
      target="_blank"
      href={`https://time-launcher.com/drops/${slug}`}
      rel="noreferrer"
    >
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="flex flex-col w-full relative cursor-pointer rounded-t-lg rounded-b-lg dark:border-zinc-600 bg-white dark:bg-zinc-800 shadow-sm hover:border-gray-400"
      >
        <div
          className="bg-image-container bg-zinc-300 w-full h-64 overflow-hidden rounded-t-md flex justify-center items-center"
          onClick={e => {}}
        >
          <div
            className="bg-blur-container"
            style={{ backgroundImage: `url(${bannerImage})` }}
          />
          <LazyLoadImage
            className={`z-[2] max-w-full max-h-full object-cover w-auto h-auto transition-transform duration-300 ${
              isHovered ? 'scale-105' : 'scale-100'
            }`}
            src={bannerImage}
            placeholder={
              <PlaceholderLoading
                shape="rect"
                width={300}
                height={300}
                colorStart="transparent"
                colorEnd="#9CA3AF"
              />
            }
            alt={name}
          />
        </div>
        <div className="pt-4 bg-black dark:bg-zinc-800 rounded-b-lg p-5 text-center">
          <h2 className="text-xl inline-block font-semibold text-white items-center flex justify-center">
            <span className="inline-block mr-1">{name}</span>
            <div className="blink inline-block" />
          </h2>
          {/* Other content */}
        </div>
      </div>
    </a>
  );
}

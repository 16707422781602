import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { BigNumber, ethers } from 'ethers';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../hooks/useAuth';
import ApiClient from '../../../../utils/ApiClient';
import { configVars } from '../../../../web3/config';
import { ensureCronos, ensurePolygon, txWait } from '../../../../web3/utils';
import ERC20ABI from '../../../../web3/ABI/erc20.abi.json';
import CRC721ABI from '../../../../web3/ABI/crc721.abi.json';
import ERC721ABI from '../../../../web3/ABI/erc721.abi.json';
import APRVAULTABI from '../../../../web3/ABI/aprvaults.abi.json';
import {
  ArrowLeftIcon,
  ArrowUpIcon,
  PlusIcon,
  RocketLaunchIcon,
} from '@heroicons/react/24/solid';
import { numberWithCommas } from '../../../../utils/parser';
import { formatEther, formatUnits, parseEther } from 'ethers/lib/utils';
import { useToast } from '../../../../contexts/ToastContext';
import PLANETSTAKINGABI from '../../../../web3/ABI/planetsstaking.abi.json';
import MoonflowApiClient from '../../../../utils/MoonflowApiClient';
import DialogScreen from '../DialogScreen';
import { TokenSelectNative } from '../../components/token/TokenSelect';

export const TIME_TOKEN =
  process.env.ENV === 'production' || process.env.REACT_APP_ENV === 'production'
    ? '0xA206BE8b5820D78711d4eD9BfC55AC2425F36F31'
    : '0xf0d6E6F139a0Ce56802fa3e9AfB7D9D9D594E248';

export const APR_VAULT =
  process.env.REACT_APP_ENV === 'production' || process.env.ENV === 'production'
    ? '0x548C0dA0dDF0fCA7094d3A07Da729F0950D9De6C'
    : '0xbcBa9adEB4CC9A668c85e16Aacdac6bB56a81bDc';

const PLANETS_STAKING =
  process.env.ENV === 'production' || process.env.REACT_APP_ENV === 'production'
    ? '0x8d36B7571FdbbF2092De7d41d302Ff7E5e3b8574'
    : '0x6c2E37a68CFe7ae78eeCD5638cE5C4D99B5C8E70';

const PLANETS =
  process.env.ENV === 'production' || process.env.REACT_APP_ENV === 'production'
    ? '0xc6CebAc4E04e363Fe26987231255652C43398566'
    : '0xdBD315bB00Bd9f55Fb6f52616d2A18F62D6292B1';

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

type Tabs =
  | 'vaults'
  | 'new_vault'
  | 'selected_vault'
  | 'add_time_to_selected_vault'
  | 'nft_boosts';
const vaultDays = [
  { title: '1 Season', days: 90, apr: 20, warp: 0.8 },
  { title: '2 Seasons', days: 180, apr: 40, warp: 1 },
  { title: '3 Seasons', days: 270, apr: 60, warp: 1.1 },
  { title: '4 Seasons', days: 360, apr: 80, warp: 1.2 },
  { title: '5 Seasons', days: 450, apr: 100, warp: 1.4 },
  { title: '6 Seasons', days: 540, apr: 120, warp: 1.6 },
  { title: '7 Seasons', days: 630, apr: 140, warp: 1.8 },
  { title: '8 Seasons', days: 720, apr: 160, warp: 2 },
];
const calculateWarp = (amount: number, lengthDays: number) => {
  // Find the warp value for the given lengthDays
  const selectedVaultDay = vaultDays.find(
    vaultDay => vaultDay.days === lengthDays,
  );

  if (!selectedVaultDay) {
    console.error('Invalid vault lengthDays provided');
    return 0.0; // Or handle this case as you see fit
  }

  // Calculate the $WARP based on the amount and the warp factor
  return numberWithCommas((amount * selectedVaultDay.warp).toFixed(0));
};
export function StakeTime() {
  const navigate = useNavigate();
  const { activeWallet, user } = useAuth();
  const [timeBalance, setTimeBalance] = useState<BigNumber>(
    BigNumber.from('0'),
  );
  const totalTimeBalance = Number(
    ethers.utils.formatUnits(timeBalance.toString(), 18),
  );

  const [approvedTime, setApprovedTime] = useState<number>(0);
  const [approvedTimeForPlanetsStaking, setApprovedTimeForPlanetsStaking] =
    useState<number>(0);

  const [loaded, setLoaded] = useState(false);

  async function approveAllowance(
    value: BigNumber,
    customAddress?: string,
  ): Promise<boolean> {
    if (
      process.env.ENV === 'production' ||
      process.env.REACT_APP_ENV === 'production'
    ) {
      await ensurePolygon();
    } else {
      await ensureCronos();
    }

    try {
      setLoadingApprove(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = await provider.getSigner();
      const contract = new ethers.Contract(TIME_TOKEN, ERC20ABI, signer);
      triggerToast('Info', 'Please wait for approval confirmation...', 'info');
      const approve = await txWait(
        contract.approve(customAddress || APR_VAULT, value),
      );

      updateTimeBalance();
      setLoadingApprove(false);
      if (approve) {
        triggerToast(
          'Success',
          `You have approved ${formatUnits(value, 18)} $TIME!`,
          'success',
        );
      }
      return approve ? true : false;
    } catch (e: any) {
      triggerToast(
        'Error',
        e.response?.data?.message || e.data?.message || 'Something went wrong.',
        'error',
      );
      setLoadingApprove(false);
      return false;
    }
  }

  async function updateTimeBalance() {
    if (
      process.env.ENV === 'production' ||
      process.env.REACT_APP_ENV === 'production'
    ) {
      await ensurePolygon();
    } else {
      await ensureCronos();
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const contract = new ethers.Contract(TIME_TOKEN, ERC20ABI, provider);
    const balance = await contract.balanceOf(user.data?.address);

    const gotTokenAllowance = await contract.allowance(
      user.data?.address,
      APR_VAULT,
    );

    if (gotTokenAllowance) {
      setApprovedTime(Number(formatEther(gotTokenAllowance)));
    }

    const gotTokenAllowanceForPlanets = await contract.allowance(
      user.data?.address,
      PLANETS_STAKING,
    );
    if (gotTokenAllowanceForPlanets) {
      setApprovedTimeForPlanetsStaking(
        Number(formatEther(gotTokenAllowanceForPlanets)),
      );
    }
    setTimeBalance(balance);
  }

  const [vaults, setVaults] = useState<any>([]);
  async function retrieveVaultsInfo() {
    try {
      if (!user.data) return;
      if (
        process.env.ENV === 'production' ||
        process.env.REACT_APP_ENV === 'production'
      ) {
        await ensurePolygon();
      } else {
        await ensureCronos();
      }

      const provider = new ethers.providers.Web3Provider(window.ethereum);

      const contract = new ethers.Contract(APR_VAULT, APRVAULTABI, provider);

      let vaults = await contract.allAprVaults(user.data?.address);

      const aprVaults = await ApiClient.getAPRVaults();

      const newVaults = vaults.map((v: any, i: number) => {
        const newVault: any = {};
        newVault.api = aprVaults.find((a: any) => a.vaultIndex === i);
        newVault.amount = Number(formatUnits(v.amount));
        newVault.duration = v.duration.toNumber();
        newVault.startTimestamp = new Date(v.startTimestamp.toNumber() * 1000);
        newVault.index = i;

        return newVault;
      });

      setVaults(newVaults);

      if (
        selectedVault &&
        newVaults.find(
          (v: any) =>
            v.index === selectedVault.index && v.api && !v.api?.closed,
        )
      ) {
        setSelectedVault(
          newVaults.find((v: any) => v.index === selectedVault.index),
        );
      }
      // update the selected vault
      setLoaded(true);
    } catch (err: any) {
      console.log(err);
      alert(err.message);
    }
  }

  useEffect(() => {
    async function run() {
      if (
        process.env.ENV === 'production' ||
        process.env.REACT_APP_ENV === 'production'
      ) {
        await ensurePolygon();
      } else {
        await ensureCronos();
      }
      updateTimeBalance().catch(e => {
        alert('updateTimeBalance error');
        alert(e.message || e);
      });
      retrieveVaultsInfo().catch(e => {
        alert('retrieveVaultsInfo err');
        alert(e.message || e);
      });
    }

    run();
  }, []);

  const [tabId, setTabId] = useState<Tabs>('vaults');
  const [loadingApprove, setLoadingApprove] = useState(false);

  const [newVaultAmount, setNewVaultAmount] = useState(1000);
  const [newVaultDays, setNewVaultDays] = useState<{
    days: number;
    title: string;
    apr: number;
  }>(vaultDays[0]);

  const [onLoadingNewVault, setOnLoadingNewVault] = useState(false);

  async function onCreateNewVault() {
    if (
      process.env.ENV === 'production' ||
      process.env.REACT_APP_ENV === 'production'
    ) {
      await ensurePolygon();
    } else {
      await ensureCronos();
    }

    if (newVaultAmount < 1000) {
      alert('Invalid amount, must be more than 1000');
      return;
    }

    setOnLoadingNewVault(true);
    if (approvedTime < newVaultAmount) {
      try {
        await approveAllowance(parseEther(`${newVaultAmount}`));
      } catch {}
      setOnLoadingNewVault(false);
      return;
    }

    ApiClient.createAPRVaultRequest(newVaultAmount, Number(newVaultDays.days))
      .then(async r => {
        if (r.signature) {
          const provider = new ethers.providers.Web3Provider(window.ethereum);

          const signer = provider.getSigner();
          const contract = new ethers.Contract(APR_VAULT, APRVAULTABI, signer);

          const gasEstimate = await signer.estimateGas({
            to: APR_VAULT,
            from: await signer.getAddress(),
            data: contract.interface.encodeFunctionData('createVault', [
              r.values,
              r.signature,
            ]),
          });

          const gasLimit = gasEstimate.mul(12).div(10);

          const response = await txWait(
            contract.createVault(r.values, r.signature, {
              gasLimit,
            }),
          );

          console.log({ values: r.values });
          setOnLoadingNewVault(false);
          if (response) {
            triggerToast(
              'Success',
              'Your new vault has been created.',
              'success',
            );
            setTabId('vaults');

            for (let i = 1; i <= 5; i++) {
              setTimeout(() => {
                retrieveVaultsInfo();
                updateTimeBalance();
              }, 3000 * i);
            }
          }
        }
      })
      .catch(e => {
        setOnLoadingNewVault(false);
        triggerToast(
          'Error',
          e.response?.data?.message ||
            e.data?.message ||
            'Something went wrong.',
          'error',
        );
      });
  }

  const [loadingWithdraw, setLoadingWithdraw] = useState(false);
  async function onWithdrawSelectedVault() {
    if (
      process.env.ENV === 'production' ||
      process.env.REACT_APP_ENV === 'production'
    ) {
      await ensurePolygon();
    } else {
      await ensureCronos();
    }

    if (!selectedVault || selectedVault.api?.closed) {
      alert('Invalid vault selection');
      return;
    }

    setLoadingWithdraw(true);
    ApiClient.createWithdrawAPRVaultRequest(selectedVault?.index)
      .then(async r => {
        if (r.signature) {
          const provider = new ethers.providers.Web3Provider(window.ethereum);

          const signer = provider.getSigner();
          const contract = new ethers.Contract(APR_VAULT, APRVAULTABI, signer);

          console.log(r);
          const gasEstimate = await signer.estimateGas({
            to: APR_VAULT,
            from: await signer.getAddress(),
            data: contract.interface.encodeFunctionData('withdraw', [
              r.values,
              r.signature,
            ]),
          });

          const gasLimit = gasEstimate.mul(12).div(10);

          const response = await txWait(
            contract.withdraw(r.values, r.signature, {
              gasLimit,
            }),
          );

          console.log({ response });
          setLoadingWithdraw(false);
          if (response) {
            triggerToast('Success', 'Withdraw successful.', 'success');
            setTabId('vaults');

            for (let i = 1; i <= 3; i++) {
              setTimeout(() => {
                retrieveVaultsInfo();
                updateTimeBalance();
              }, 3000 * i);
            }
          }
        }
      })
      .catch(e => {
        setLoadingWithdraw(false);
        triggerToast(
          'Error',
          e.response?.data?.message ||
            e.data?.message ||
            'Something went wrong.',
          'error',
        );
      });
  }

  const [depositAmount, setDepositAmount] = useState<number>(1000);

  const [loadingDeposit, setLoadingDeposit] = useState(false);
  async function onDepositSelectedVault() {
    if (
      process.env.ENV === 'production' ||
      process.env.REACT_APP_ENV === 'production'
    ) {
      await ensurePolygon();
    } else {
      await ensureCronos();
    }

    console.log({ depositAmount });
    if (!selectedVault || selectedVault.api?.closed) {
      alert('Invalid vault selection');
      return;
    }

    if (depositAmount < 1000 || approvedTime < depositAmount) {
      alert('Invalid time amount or not approved.');
      return;
    }

    setLoadingDeposit(true);
    ApiClient.createDepositAPRVaultRequest(selectedVault?.index, depositAmount)
      .then(async r => {
        if (r.signature) {
          const provider = new ethers.providers.Web3Provider(window.ethereum);

          const signer = provider.getSigner();
          const contract = new ethers.Contract(APR_VAULT, APRVAULTABI, signer);

          const gasEstimate = await signer.estimateGas({
            to: APR_VAULT,
            from: await signer.getAddress(),
            data: contract.interface.encodeFunctionData('addTokensToVault', [
              r.values,
              r.signature,
            ]),
          });

          const gasLimit = gasEstimate.mul(12).div(10);

          const response = await txWait(
            contract.addTokensToVault(r.values, r.signature, {
              gasLimit,
            }),
          );

          console.log({ response });
          setLoadingDeposit(false);
          if (response) {
            triggerToast('Success', 'Your deposit was successful.', 'success');
            for (let i = 1; i <= 3; i++) {
              setTimeout(() => {
                retrieveVaultsInfo();
                updateTimeBalance();
              }, 3000 * i);
            }
          }
        }
      })
      .catch(e => {
        setLoadingDeposit(false);

        triggerToast(
          'Error',
          e.response?.data?.message ||
            e.data?.message ||
            'Something went wrong.',
          'error',
        );
      });
  }

  const [selectedVault, setSelectedVault] = useState<any>();
  const { triggerToast } = useToast();

  const [totalAPRBoost, setTotalAPRBoost] = useState(0);
  const [slots, setSlots] = useState<any[]>();
  async function retrievePlanetsStakingInfo() {
    if (
      process.env.ENV === 'production' ||
      process.env.REACT_APP_ENV === 'production'
    ) {
      await ensurePolygon();
    } else {
      await ensureCronos();
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const contract = new ethers.Contract(
      PLANETS_STAKING,
      PLANETSTAKINGABI,
      provider,
    );

    const slotsInfo = await contract.slotsDetails(user.data?.address);

    let slotsData: any = [];
    for (let i = 0; i < slotsInfo.length; i += 1) {
      const slotsPricing = await contract.pricing(i);
      slotsData.push({
        ca: slotsInfo[i].ca,
        id: slotsInfo[i].id.toNumber(),
        purchased: slotsInfo[i].purchased,
        slotPrice: slotsPricing,
        slotPriceDecimal: Number(formatUnits(slotsPricing, 18)),
        canPurchase: i === 0 ? true : slotsInfo[i - 1].purchased ? true : false,
        isSet:
          slotsInfo[i].purchased &&
          slotsInfo[i].ca !== '0x0000000000000000000000000000000000000000',
        image: null,
      });
    }

    const tokenIds = slotsData
      .filter((s: any) => s.isSet)
      .map((s: any) => s.id.toString());
    if (tokenIds.length > 0) {
      const images = await ApiClient.getCollectionTokenImages(
        PLANETS,
        tokenIds,
      );

      if (images && images.length > 0) {
        for (let i = 0; i < slotsData.length; i += 1) {
          if (!slotsData[i].isSet) {
            continue;
          }

          const foundImage = images.find(
            (img: any) => img.tokenId.toString() === slotsData[i].id.toString(),
          );
          if (foundImage) {
            slotsData[i].image = foundImage.image;
          }
        }
      }
    }

    const boostViableCount = slotsData.filter((s: any) => s.isSet).length;
    setTotalAPRBoost(boostViableCount * 10);

    setSlots(slotsData);
  }

  const [buyingSlot, setBuyingSlot] = useState(false);
  const [buyingSlotFor, setBuyingSlotFor] = useState(-1);
  async function buySlot(slotIndex: number) {
    try {
      if (!slots) return;

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = await provider.getSigner();
      if (
        approvedTimeForPlanetsStaking <
        Number(slots[slotIndex].slotPriceDecimal)
      ) {
        const approved = await approveAllowance(
          parseEther(`${slots[slotIndex].slotPriceDecimal}`),
          PLANETS_STAKING,
        );

        if (!approved) return;
      }

      const contract = new ethers.Contract(
        PLANETS_STAKING,
        PLANETSTAKINGABI,
        signer,
      );

      setBuyingSlot(true);
      setBuyingSlotFor(slotIndex);
      const gasEstimate = await signer.estimateGas({
        to: PLANETS_STAKING,
        from: await signer.getAddress(),
        data: contract.interface.encodeFunctionData('buySlot', [slotIndex]),
      });

      const gasLimit = gasEstimate.mul(12).div(10);

      const buyTheSlot = await txWait(
        contract.buySlot(slotIndex, { gasLimit }),
      );

      console.log(buyTheSlot);
      setBuyingSlot(false);
      setBuyingSlotFor(-1);
      if (buyTheSlot) {
        triggerToast(
          'Success',
          `You have bought slot ${slotIndex}!`,
          'success',
        );
        setTimeout(() => {
          retrievePlanetsStakingInfo();
          updateTimeBalance();
          retrieveMyPlanets();

          setTimeout(() => {
            retrievePlanetsStakingInfo();
          }, 5000);
        }, 1000);
        return;
      }
    } catch (e: any) {
      setBuyingSlotFor(-1);
      setBuyingSlot(false);
      triggerToast(
        'Error',
        e.response?.data?.message || e.data?.message || 'Something went wrong.',
        'error',
      );
    }
  }

  const [planets, setPlanets] = useState<any[]>([]);
  async function retrieveMyPlanets() {
    if (
      process.env.ENV === 'production' ||
      process.env.REACT_APP_ENV === 'production'
    ) {
      await ensurePolygon();
    } else {
      await ensureCronos();
    }

    const provider = new ethers.providers.JsonRpcProvider(
      process.env.ENV === 'production' ||
      process.env.REACT_APP_ENV === 'production'
        ? configVars.polygon.rpcUrl
        : configVars.rpcNetwork_mainnet.rpcUrl,
    );

    const contract = new ethers.Contract(PLANETS, CRC721ABI, provider);

    const nfts = await contract.walletOfOwner(user.data?.address);

    const nftsData = [];

    for (let i = 0; i < nfts.length; i += 1) {
      nftsData.push({
        id: nfts[i].toNumber(),
        name: 'Time Poly Planet',
        collectionAddress: PLANETS,
        tokenId: nfts[i].toNumber(),
      });
    }
    setPlanets(nftsData);
  }

  useEffect(() => {
    if (tabId === 'nft_boosts') {
      retrievePlanetsStakingInfo();

      retrieveMyPlanets();
    }
  }, [tabId]);

  useEffect(() => {
    retrievePlanetsStakingInfo();
  }, []);

  const [showNFTSelector, setShowNFTSelector] = useState(false);
  const [selectingNFTForSlot, setSelectingNFTForSlot] = useState(-1);
  const [selectedPlanets, setSelectedPlanets] = useState<any>([]);

  const [stakingLoading, setStakingLoading] = useState(false);
  async function stakeSelectedNFT() {
    try {
      if (
        process.env.ENV === 'production' ||
        process.env.REACT_APP_ENV === 'production'
      ) {
        await ensurePolygon();
      } else {
        await ensureCronos();
      }
      setStakingLoading(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = await provider.getSigner();
      const contract = new ethers.Contract(
        PLANETS_STAKING,
        PLANETSTAKINGABI,
        signer,
      );

      const planetsContract = new ethers.Contract(PLANETS, ERC721ABI, signer);

      const isApproved =
        ethers.utils.getAddress(
          await planetsContract.getApproved(selectedPlanets[0].tokenId),
        ) === ethers.utils.getAddress(PLANETS_STAKING);

      if (!isApproved) {
        triggerToast(
          'Info',
          'Please wait for approval confirmation...',
          'info',
        );

        console.log({ PLANETS_STAKING, tokenId: selectedPlanets[0].tokenId });
        const approved = await txWait(
          planetsContract.approve(PLANETS_STAKING, selectedPlanets[0].tokenId),
        );

        if (approved) {
          triggerToast(
            'Success',
            `You have approved NFT ID #${selectedPlanets[0].tokenId}`,
            'success',
          );
          await new Promise(r => setTimeout(r, 1500));
        }
        if (!approved) return;
      }

      const staked = await txWait(
        contract.stake({
          slot: selectingNFTForSlot,
          ca: PLANETS,
          id: selectedPlanets[0].tokenId,
        }),
      );

      setStakingLoading(false);
      if (staked) {
        setTimeout(() => {
          retrievePlanetsStakingInfo();
          updateTimeBalance();
          retrieveMyPlanets();
        }, 1000);
      }
    } catch (e: any) {
      setStakingLoading(false);
      triggerToast(
        'Error',
        e.response?.data?.message || e.data?.message || 'Something went wrong.',
        'error',
      );
    }
  }

  const [showUnstake, setShowUnstake] = useState(false);
  const [selectingSlotForUnstake, setSelectingNFTForUnstake] = useState(-1);
  const [unstakingLoading, setunStakingLoading] = useState(false);
  async function unstakeSlot() {
    if (!slots || selectingSlotForUnstake === -1) return;

    const tokenId = slots[selectingSlotForUnstake];
    try {
      if (
        process.env.ENV === 'production' ||
        process.env.REACT_APP_ENV === 'production'
      ) {
        await ensurePolygon();
      } else {
        await ensureCronos();
      }
      setunStakingLoading(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = await provider.getSigner();
      const contract = new ethers.Contract(
        PLANETS_STAKING,
        PLANETSTAKINGABI,
        signer,
      );

      console.log({
        slot: selectingSlotForUnstake,
        ca: PLANETS,
        id: tokenId.id,
      });
      const unstaked = await txWait(
        contract.unstake({
          slot: selectingSlotForUnstake,
          ca: PLANETS,
          id: tokenId.id,
        }),
      );

      setunStakingLoading(false);

      if (unstaked) {
        setShowUnstake(false);
        retrievePlanetsStakingInfo();
        updateTimeBalance();
        retrieveMyPlanets();
      }
    } catch (e: any) {
      console.log(e);
      setunStakingLoading(false);
      triggerToast(
        'Error',
        e.response?.data?.message || e.data?.message || 'Something went wrong.',
        'error',
      );
    }
  }

  return (
    <>
      <DialogScreen open={showNFTSelector} setOpen={setShowNFTSelector}>
        <div className="pt-6">
          <div className=" text-black text-xl rounded-br-none py-3 pb-6 rounded-md flex flex-col text-start  mb-4 font-semibold items-center ">
            Planets Boost for Slot {selectingNFTForSlot}
          </div>
          <TokenSelectNative
            tokens={planets.filter(
              p =>
                !slots ||
                !slots?.some(s => s.id.toString() === p.tokenId.toString()),
            )}
            selectedTokens={selectedPlanets}
            setSelectedTokens={setSelectedPlanets}
            max={1}
            getImages={5}
          />
        </div>

        <div className="flex justify-center">
          <button
            onClick={() => {
              setShowNFTSelector(false);
              stakeSelectedNFT();
            }}
            disabled={
              selectedPlanets.length === 0 || selectingNFTForSlot === -1
            }
            className="px-6 py-2 mt-1 bg-zinc-800 hover:bg-zinc-700 disabled:opacity-50 text-white rounded-md font-semibold"
          >
            <span className="inline">Stake NFT</span>
          </button>
        </div>
      </DialogScreen>
      <DialogScreen open={showUnstake} setOpen={setShowUnstake}>
        {selectingSlotForUnstake !== -1 && (
          <>
            <div className="pt-6">
              <div className=" text-black text-xl rounded-br-none py-3 pb-6 rounded-md flex flex-col text-start  mb-4 font-semibold items-center ">
                Unstake Slot {selectingSlotForUnstake}
              </div>
              <div className="flex justify-center mb-10">
                <img
                  src={
                    slots?.[selectingSlotForUnstake]?.image || '/timetoken.png'
                  }
                  alt="NFT"
                  className="w-24 h-24 rounded-md"
                />
              </div>
            </div>

            <div className="flex justify-center">
              <button
                onClick={() => {
                  unstakeSlot();
                }}
                disabled={unstakingLoading}
                className="px-6 py-2 mt-1 bg-zinc-800 hover:bg-zinc-700 disabled:opacity-50 text-white rounded-md font-semibold"
              >
                <span className="inline">Unstake</span>
              </button>
            </div>
          </>
        )}
      </DialogScreen>
      <div className="pt-0">
        <div className=" text-black text-xl rounded-br-none py-3 rounded-md flex flex-col text-start  mb-4 font-semibold items-center ">
          {tabId === 'new_vault'
            ? 'Add New Vault'
            : tabId === 'selected_vault'
            ? `Manage Vault ${selectedVault?.index + 1}`
            : tabId === 'add_time_to_selected_vault'
            ? `Update Vault ${selectedVault?.index + 1}`
            : tabId === 'nft_boosts'
            ? '$Time NFT Boost Staking'
            : 'Stake $TIME'}
        </div>
        <div className="px-6 ">
          <div className="px-0 py-1">
            {/* Content based on the selected tab */}
            <div className="mt-0">
              {tabId === 'vaults' ? (
                <div>
                  <div className="text p-4 text-sm bg-yellow-300/30 rounded-lg">
                    <span className="font-semibold">Stake & earn</span> $TIME
                    and receive $WARP for Fuel. <br />
                    The longer you stake, the{' '}
                    <span className="font-semibold">
                      better the APR and $WARP drop
                    </span>
                    .
                  </div>
                  <div
                    className="flex flex-col mt-4 gap-y-2 "
                    style={{ maxHeight: '300px', overflowY: 'auto' }}
                  >
                    {!loaded ? (
                      <>Loading vaults...</>
                    ) : vaults.length === 0 ? (
                      <div className="flex flex-col justify-center items-center">
                        <div>
                          <img
                            src="/timetoken.png"
                            className="h-24 w-24"
                            alt="TimeToken"
                          />
                        </div>
                        <div className="text text-zinc-700 text-sm">
                          You don't have any vaults yet.
                        </div>
                        <div
                          className="mt-2 mb-4 text text-sm font-semibold"
                          style={{ fontWeight: 'bold' }}
                        >
                          Create one to get started!
                        </div>
                      </div>
                    ) : (
                      vaults
                        .sort((vaultA: any, vaultB: any) => {
                          const now = new Date().getTime();

                          const isClosedA = vaultA.api && vaultA.api.closed;
                          const isClosedB = vaultB.api && vaultB.api.closed;

                          // Both are closed
                          if (isClosedA && isClosedB) {
                            return vaultA.index - vaultB.index;
                          }

                          // Only A is closed
                          if (isClosedA) return 1;

                          // Only B is closed
                          if (isClosedB) return -1;

                          // Neither is closed, or both are expired/not expired
                          // Sort by index to combine valid futures and undefined apis
                          return vaultA.index - vaultB.index;
                        })
                        .map((vault: any) => (
                          <div
                            key={vault.index}
                            onClick={() => {
                              if (!vault.api) {
                                alert(
                                  'Please contact staff, failed to check vault info.',
                                );
                                return;
                              }

                              if (vault.api.closed) {
                                return;
                              }

                              setSelectedVault(vault);
                              setTabId('selected_vault');
                            }}
                            className={`pl-4 pr-4 pb-2 pt-2 text-sm border-l-4 border-l-yellow-300/80 hover:bg-gray-100 hover:border-l-yellow-300/60 cursor-pointer rounded-sm ${
                              !vault.api
                                ? 'opacity-50 hover:bg-white cursor-default border-l-yellow-300/30 hover:border-l-yellow-300/30'
                                : vault.api.closed
                                ? 'opacity-50 hover:bg-white cursor-default border-l-red-400 hover:border-l-red-400'
                                : new Date(vault.api.expiresAt).getTime() <=
                                  new Date().getTime()
                                ? ' border-l-green-400 hover:border-l-green-400'
                                : ''
                            }`}
                          >
                            <div className="flex flex-row justify-between">
                              <div className="text-zinc-500">
                                Vault {vault.index + 1}
                              </div>
                              <div className="flex items-center">
                                <img
                                  className="h-6 w-6 ml-1 mr-1"
                                  src="/timetoken.png"
                                  alt="Time Token"
                                />
                                <div className="inline font-semibold">
                                  {numberWithCommas(vault.amount)}
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-row justify-between">
                              <div className="text-black font-semibold mt-1">
                                {vault.api?.lengthDays || '-'} days
                              </div>
                              <div className="flex items-center mt-1 gap-x-1">
                                <div className="border py-0.5 border-zinc-400 text-zinc-600 text-xs rounded-sm px-2">
                                  {vault.api?.apr + totalAPRBoost || '-'}% APR
                                </div>
                                {vault.api && (
                                  <div className="border py-0.5 border-green-400 text-green-600 text-xs rounded-sm px-2">
                                    {calculateWarp(
                                      vault.amount,
                                      vault.api.lengthDays,
                                    ) || '-'}{' '}
                                    $WARP
                                  </div>
                                )}
                                {!vault.api?.closed && (
                                  <div className="border py-0.5 border-yellow-400/60 text-yellow-400/80 text-xs rounded-sm px-2">
                                    🏆 #{vault.api?.ranking || 'TBD'}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ))
                    )}
                  </div>
                  <div className="flex justify-center mt-4">
                    <button
                      onClick={() => {
                        setTabId('nft_boosts');
                      }}
                      className="px-4 py-2 mt-1 mr-4 bg-zinc-800 hover:bg-zinc-700 disabled:bg-violet-500 disabled:opacity-50 text-white rounded-md font-semibold"
                    >
                      <span className="flex justify-center">
                        <RocketLaunchIcon className="inline h-5 w-5 self-center mr-1" />
                        <div>NFT Boosts</div>
                      </span>
                    </button>
                    <button
                      onClick={() => {
                        setTabId('new_vault');
                      }}
                      className="px-4 py-2 mt-1 bg-zinc-800 hover:bg-zinc-700 disabled:bg-violet-500 disabled:opacity-50 text-white rounded-md font-semibold"
                    >
                      <span className="flex justify-center">
                        <PlusIcon className="inline h-6 w-6 self-center mr-1" />
                        <div>New Vault</div>
                      </span>
                    </button>
                  </div>
                </div>
              ) : tabId === 'new_vault' ? (
                <div>
                  <div className="text p-4 mb-4 text-sm bg-yellow-300/30 rounded-lg">
                    Earn staking benefits by opening a staking vault. <br />
                    Earn{' '}
                    <span className="font-semibold">
                      APR, Warp, and Fuel
                    </span>{' '}
                    for battle. <br />
                    <span className="font-semibold">
                      Stake more to earn more.
                    </span>
                  </div>
                  <div className="text flex justify-end text-sm ">
                    <div className="border border-yellow-400 font-semibold bg-yellow-400/10 text-yellow-400 rounded-lg px-2 pr-3 py-1 flex items-center">
                      <img
                        className="h-6 w-6 ml-1"
                        src="/timetoken.png"
                        alt="Time Token"
                      />
                      <div className="ml-1">
                        {numberWithCommas(totalTimeBalance.toFixed(2))}
                      </div>
                    </div>
                  </div>
                  <div className="mt-6">
                    <div className="flex justify-center gap-x-4">
                      <div className="text-zinc-600 text-sm flex flex-col gap-y-1.5">
                        <div className="text-center text-zinc-500 ">
                          Amount to stake
                        </div>
                        <div>
                          <input
                            type="number"
                            value={newVaultAmount}
                            min={1000}
                            max={totalTimeBalance}
                            disabled={
                              totalTimeBalance < 1000 || onLoadingNewVault
                            }
                            onChange={e => {
                              setNewVaultAmount(Number(e.target.value) || 0);
                            }}
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 dark:border-zinc-600 dark:bg-zinc-900 dark:text-gray-300 rounded-md"
                            placeholder="0"
                          />
                          <div
                            onClick={() => {
                              if (totalTimeBalance < 1000) {
                                setNewVaultAmount(0);
                              } else {
                                setNewVaultAmount(totalTimeBalance);
                              }
                            }}
                            className="text-center mt-1 font-semibold cursor-pointer hover:text-zinc-500 hover:underline"
                          >
                            Stake All
                          </div>
                        </div>
                      </div>
                      <div className="text-zinc-600 text-sm flex flex-col gap-y-1.5">
                        <div className="text-center text-zinc-500">
                          Duration (days)
                        </div>
                        <div>
                          <select
                            id="chain"
                            name="chain"
                            value={newVaultDays.days}
                            disabled={onLoadingNewVault}
                            onChange={e => {
                              setNewVaultDays(
                                vaultDays.find(
                                  v => v.days === Number(e.target.value),
                                ) || vaultDays[0],
                              );
                            }}
                            className=" block w-full border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                            <option value="" disabled>
                              Select a duration
                            </option>
                            {vaultDays.map(vaultDays => (
                              <option
                                key={vaultDays.days}
                                value={vaultDays.days}
                              >
                                {vaultDays.days} days
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6">
                    <div className="flex justify-center">
                      <div className="bg-gray-100 rounded-lg border border-gray-300 px-12 py-8">
                        <div className="flex flex-col items-center">
                          <img
                            className="h-10 w-10"
                            src="/timetoken.png"
                            alt="Time Token"
                          />
                          <div className="text-lg text-zinc-600 self-center">
                            APR
                          </div>
                          <div className="text-xl text-zinc-600 font-bold">
                            {newVaultDays.apr}%
                          </div>
                          <div className="text-sm text-zinc-500">
                            {newVaultDays.days} day commitment
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center mt-8">
                    {onLoadingNewVault || loadingApprove ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        style={{
                          background: 'none',
                          display: 'block',
                          shapeRendering: 'auto',
                        }}
                        width="50px"
                        height="50px"
                        viewBox="0 0 100 100"
                        preserveAspectRatio="xMidYMid"
                      >
                        <circle
                          cx="50"
                          cy="50"
                          fill="none"
                          stroke="#cdcdcd"
                          strokeWidth="7"
                          r="18"
                          strokeDasharray="84.82300164692441 30.274333882308138"
                        >
                          <animateTransform
                            attributeName="transform"
                            type="rotate"
                            repeatCount="indefinite"
                            dur="1s"
                            values="0 50 50;360 50 50"
                            keyTimes="0;1"
                          ></animateTransform>
                        </circle>
                      </svg>
                    ) : (
                      <button
                        onClick={() => {
                          onCreateNewVault();
                        }}
                        disabled={onLoadingNewVault}
                        className="px-6 py-2 mt-1 bg-zinc-800 hover:bg-zinc-700 disabled:opacity-50 text-white rounded-md font-semibold"
                      >
                        <span className="inline">
                          {approvedTime < newVaultAmount
                            ? 'Approve'
                            : 'Stake $Time'}
                        </span>
                      </button>
                    )}
                  </div>
                  <div className="flex justify-start mt-4">
                    <button
                      onClick={() => {
                        setTabId('vaults');
                      }}
                      className="flex justify-center px-4 py-2 mt-1 bg-zinc-500 hover:bg-zinc-400 disabled:opacity-50 text-white rounded-md font-semibold"
                    >
                      <ArrowLeftIcon className="self-center mr-2 h-5 w-5 font-bold text-sm" />
                      <div className="self-center">Back</div>
                    </button>
                  </div>
                </div>
              ) : tabId === 'selected_vault' && selectedVault ? (
                <div>
                  <div className="mt-0">
                    <div className="text p-4 mb-4 text-sm bg-yellow-300/30 rounded-lg">
                      Manage your vault &{' '}
                      <span className="font-semibold">
                        add more $Time to increase interest
                      </span>
                      .
                    </div>
                    <div className="flex justify-center flex-col">
                      <div className="bg-gray-100 rounded-lg border border-gray-300 w-full pt-6 pb-1  px-4">
                        <div className="text flex justify-between text-sm ">
                          <div>
                            <div className="text">
                              Vault {selectedVault?.index + 1}
                            </div>
                            <div className="">
                              <span className="font-semibold">
                                {selectedVault.duration} days
                              </span>
                            </div>
                          </div>
                          <div className="text-end">
                            <div className="border border-yellow-400 font-semibold bg-yellow-400/10 text-yellow-400 rounded-lg px-2 pr-3 py-1 flex items-center">
                              <img
                                className="h-6 w-6 ml-1"
                                src="/timetoken.png"
                                alt="Time Token"
                              />
                              <div className="ml-1">
                                {numberWithCommas(
                                  selectedVault?.amount.toFixed(2),
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-col justify-center gap-x-14 mt-6">
                          <div className="flex flex-row justify-between">
                            <div className="text text-zinc-600 self-center">
                              APR
                            </div>
                            <div className="font-semibold text-zinc-600 self-center text-end">
                              {selectedVault?.api?.apr}%{' '}
                              {totalAPRBoost > 0 ? (
                                <>
                                  <br />
                                  <div className="text-xs mb-2 px-2 border rounded-md py-1 border-zinc-300">
                                    +{totalAPRBoost}% Planets Boost
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </div>
                          <div className="flex flex-row justify-between">
                            <div className="text text-zinc-600 text-start">
                              Ranking
                            </div>
                            <div className="font-semibold text-zinc-600 ">
                              #{selectedVault?.api?.ranking || 'TBD'}
                            </div>
                          </div>
                          <div className="flex flex-row justify-between">
                            <div className="text text-zinc-600 text-start">
                              WARP
                            </div>
                            <div className="font-semibold text-zinc-600 ">
                              {calculateWarp(
                                selectedVault.amount,
                                selectedVault.api?.lengthDays,
                              ) || '-'}{' '}
                              $WARP
                            </div>
                          </div>
                          <div className="flex flex-row justify-between">
                            <div className="text text-zinc-600 text-start">
                              End Date
                            </div>
                            <div className="font-semibold text-zinc-600 ">
                              {new Date(
                                selectedVault?.api?.expiresAt,
                              ).getDate()}{' '}
                              {
                                months[
                                  new Date(
                                    selectedVault?.api?.expiresAt,
                                  ).getMonth()
                                ]
                              }{' '}
                              {new Date(
                                selectedVault?.api?.expiresAt,
                              ).getFullYear()}
                            </div>
                          </div>
                        </div>

                        <div className="flex justify-center mt-8">
                          {loadingWithdraw ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              style={{
                                background: 'none',
                                display: 'block',
                                shapeRendering: 'auto',
                              }}
                              width="50px"
                              height="50px"
                              viewBox="0 0 100 100"
                              preserveAspectRatio="xMidYMid"
                            >
                              <circle
                                cx="50"
                                cy="50"
                                fill="none"
                                stroke="#cdcdcd"
                                strokeWidth="7"
                                r="18"
                                strokeDasharray="84.82300164692441 30.274333882308138"
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="rotate"
                                  repeatCount="indefinite"
                                  dur="1s"
                                  values="0 50 50;360 50 50"
                                  keyTimes="0;1"
                                ></animateTransform>
                              </circle>
                            </svg>
                          ) : (
                            <button
                              onClick={() => {
                                if (
                                  new Date(
                                    selectedVault?.api?.expiresAt,
                                  ).getTime() < new Date().getTime()
                                ) {
                                  onWithdrawSelectedVault();
                                } else {
                                  setTabId('add_time_to_selected_vault');
                                }
                              }}
                              className="text-base px-6 py-2 mt-1 bg-zinc-800 hover:bg-zinc-700 disabled:opacity-50 text-white rounded-md font-semibold"
                            >
                              {new Date(
                                selectedVault?.api?.expiresAt,
                              ).getTime() < new Date().getTime() ? (
                                <span className="flex justify-center">
                                  <div>Withdraw</div>
                                </span>
                              ) : (
                                <span className="flex justify-center">
                                  <PlusIcon className="inline h-6 w-6 self-center mr-1" />
                                  <div>Add $Time</div>
                                </span>
                              )}
                            </button>
                          )}
                        </div>

                        {loadingWithdraw ? (
                          <></>
                        ) : new Date(selectedVault?.api?.expiresAt).getTime() >
                          new Date().getTime() ? (
                          <div className="flex justify-center mt-3 ">
                            <button
                              onClick={() => {
                                if (
                                  // eslint-disable-next-line no-restricted-globals
                                  confirm(
                                    'Are you sure you want to emergency withdraw, 50% of the vault will be burned and 50% returned to you, do you want to continue?',
                                  )
                                ) {
                                  onWithdrawSelectedVault();
                                }
                              }}
                              className="text-sm pb-1 text-zinc-800 hover:text-zinc-700 hover:underline"
                            >
                              <span className="inline">Emergency Withdraw</span>
                            </button>
                          </div>
                        ) : (
                          <div className="flex justify-center mt-3 ">
                            <button
                              onClick={() => {
                                onWithdrawSelectedVault();
                              }}
                              className="text-sm pb-1 text-zinc-800 hover:text-zinc-700 hover:underline"
                            >
                              <span className="inline">
                                Safe to withdraw 100% of vault
                              </span>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-start mt-4">
                    <button
                      onClick={() => {
                        setTabId('vaults');
                      }}
                      className="flex justify-center px-4 py-2 mt-1 bg-zinc-500 hover:bg-zinc-400 disabled:opacity-50 text-white rounded-md font-semibold"
                    >
                      <ArrowLeftIcon className="self-center mr-2 h-5 w-5 font-bold text-sm" />
                      <div className="self-center">Back</div>
                    </button>
                  </div>
                </div>
              ) : tabId === 'add_time_to_selected_vault' ? (
                <div>
                  <div className="mt-0">
                    <div className="text p-4 mb-4 text-sm bg-yellow-300/30 rounded-lg">
                      Earn staking benefits by opening a staking vault. <br />
                      Earn{' '}
                      <span className="font-semibold">
                        APR, Warp, and Fuel
                      </span>{' '}
                      for battle. <br />
                      <span className="font-semibold">
                        Stake more to earn more.
                      </span>
                    </div>
                    <div className="flex justify-center flex-col">
                      <div className="bg-gray-100 rounded-lg border border-gray-300 w-full pt-6 pb-1  px-4">
                        <div className="text flex justify-between text-sm ">
                          <div>
                            <div className="text">
                              Vault {selectedVault?.index + 1}
                            </div>
                            <div className="">
                              <span className="font-semibold">
                                {selectedVault.duration} days
                              </span>
                            </div>
                          </div>
                          <div className="text-end">
                            <div className="text mb-1">Vault Balance</div>
                            <div className="border border-yellow-400 font-semibold bg-yellow-400/10 text-yellow-400 rounded-lg px-2 pr-3 py-1 flex items-center">
                              <img
                                className="h-6 w-6 ml-1"
                                src="/timetoken.png"
                                alt="Time Token"
                              />
                              <div className="ml-1">
                                {numberWithCommas(
                                  selectedVault?.amount.toFixed(2),
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-row justify-between mt-4">
                          <div className="text-sm text">
                            <div className="text">End Date</div>
                            <div className="">
                              <span className="font-semibold">
                                {new Date(
                                  selectedVault?.api?.expiresAt,
                                ).getDate()}{' '}
                                {
                                  months[
                                    new Date(
                                      selectedVault?.api?.expiresAt,
                                    ).getMonth()
                                  ]
                                }{' '}
                                {new Date(
                                  selectedVault?.api?.expiresAt,
                                ).getFullYear()}
                              </span>
                            </div>
                          </div>
                          <div>
                            <div className="text text-end text-sm mb-1">
                              Your $Time
                            </div>
                            <div className="flex justify-end">
                              <div className="border border-yellow-400 font-semibold bg-yellow-400/10 text-yellow-400 rounded-lg px-2 pr-3 py-1 flex items-center">
                                <img
                                  className="h-6 w-6 ml-1"
                                  src="/timetoken.png"
                                  alt="Time Token"
                                />
                                <div className="ml-1">
                                  {numberWithCommas(
                                    totalTimeBalance.toFixed(2),
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col justify-center mt-6">
                          <div className="text-center text-zinc-500 ">
                            Deposit amount
                          </div>
                          <div>
                            <input
                              type="number"
                              value={depositAmount}
                              min={1000}
                              max={totalTimeBalance}
                              disabled={
                                totalTimeBalance < 1000 || onLoadingNewVault
                              }
                              onChange={e => {
                                setDepositAmount(Number(e.target.value) || 0);
                              }}
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 dark:border-zinc-600 dark:bg-zinc-900 dark:text-gray-300 rounded-md"
                              placeholder="0"
                            />
                            <div
                              onClick={() => {
                                if (totalTimeBalance < 1000) {
                                  setDepositAmount(0);
                                } else {
                                  setDepositAmount(totalTimeBalance);
                                }
                              }}
                              className="text-center text-sm mt-1 font-semibold cursor-pointer text-zinc-500 hover:text-zinc-500 hover:underline"
                            >
                              Stake All
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-center mt-8 mb-4">
                          {loadingDeposit || loadingApprove ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              style={{
                                background: 'none',
                                display: 'block',
                                shapeRendering: 'auto',
                              }}
                              width="50px"
                              height="50px"
                              viewBox="0 0 100 100"
                              preserveAspectRatio="xMidYMid"
                            >
                              <circle
                                cx="50"
                                cy="50"
                                fill="none"
                                stroke="#cdcdcd"
                                strokeWidth="7"
                                r="18"
                                strokeDasharray="84.82300164692441 30.274333882308138"
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="rotate"
                                  repeatCount="indefinite"
                                  dur="1s"
                                  values="0 50 50;360 50 50"
                                  keyTimes="0;1"
                                ></animateTransform>
                              </circle>
                            </svg>
                          ) : (
                            <button
                              onClick={() => {
                                if (approvedTime < depositAmount) {
                                  approveAllowance(
                                    parseEther(`${depositAmount}`),
                                  );
                                } else {
                                  onDepositSelectedVault();
                                }
                              }}
                              disabled={loadingDeposit}
                              className="text-base px-6 py-2 mt-1 bg-zinc-800 hover:bg-zinc-700 disabled:opacity-50 text-white rounded-md font-semibold"
                            >
                              <span className="flex justify-center">
                                <div>
                                  {approvedTime < depositAmount
                                    ? 'Approve'
                                    : 'Update'}
                                </div>
                              </span>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-start mt-4">
                    <button
                      onClick={() => {
                        setTabId('selected_vault');
                      }}
                      className="flex justify-center px-4 py-2 mt-1 bg-zinc-500 hover:bg-zinc-400 disabled:opacity-50 text-white rounded-md font-semibold"
                    >
                      <ArrowLeftIcon className="self-center mr-2 h-5 w-5 font-bold text-sm" />
                      <div className="self-center">Back</div>
                    </button>
                  </div>
                </div>
              ) : tabId === 'nft_boosts' ? (
                <div>
                  <div className="text p-4 mb-6 text-sm bg-yellow-300/30 rounded-lg">
                    Boost your vaults APR by 10% per NFT by staking Poly Time
                    Planets. <br />
                    (MAX 5 slots)
                  </div>
                  <div className="mt-0">
                    <div
                      className={`grid gap-4 grid-cols-2 md:grid-cols-4 ${
                        stakingLoading ? 'opacity-50' : ''
                      }`}
                    >
                      {slots && slots.length > 0 ? (
                        slots.map((slot: any, index: number) => {
                          return slot.purchased && !slot.isSet ? (
                            <div
                              key={`${index}_${slot.purchased}_${slot.id}`}
                              className="cursor-pointer hover:opacity-80"
                              onClick={() => {
                                if (stakingLoading) return;
                                setSelectingNFTForSlot(index);
                                setShowNFTSelector(true);
                              }}
                            >
                              <img
                                src={'/timetoken.png'}
                                className="w-full rounded-tl-md rounded-tr-md"
                                alt="nft"
                              />
                              <div className="text-xs py-1 text-center px-2 bg-yellow-400 text-white rounded-bl-md rounded-br-md">
                                {stakingLoading && index === selectingNFTForSlot
                                  ? 'Staking...'
                                  : 'Choose NFT'}
                              </div>
                            </div>
                          ) : slot.purchased && slot.isSet ? (
                            <div
                              key={`${index}_${slot.purchased}_${slot.id}`}
                              onClick={() => {
                                setSelectingNFTForUnstake(index);
                                setShowUnstake(true);
                              }}
                              className={`hover:opacity-80 cursor-pointer ${
                                !slot.image
                                  ? 'bg-purple-200/40 rounded-tl-md rounded-tr-md'
                                  : ''
                              }`}
                            >
                              <img
                                src={slot.image || '/timetoken.png'}
                                className="w-full rounded-tl-md rounded-tr-md"
                                alt="nft"
                              />
                              <div className="text-sm py-0.5 text-center px-2 bg-purple-500 text-white rounded-bl-md rounded-br-md">
                                +10% APR
                              </div>
                            </div>
                          ) : (
                            <div
                              key={`${index}_${slot.purchased}_${slot.id}`}
                              className={`${
                                slot.canPurchase ? 'cursor-pointer' : ''
                              } hover:opacity-80`}
                              onClick={() => {
                                if (!slot.canPurchase) {
                                  return;
                                }

                                buySlot(index);
                              }}
                            >
                              <img
                                src={'/timetoken.png'}
                                className="w-full bg-gray-200 grayscale rounded-tl-md rounded-tr-md"
                                alt="Locked"
                              />
                              <div
                                className={`flex justify-center items-center text-sm py-0.5 text-center bg-zinc-400 ${
                                  slot.canPurchase
                                    ? 'text-white'
                                    : 'text-gray-200'
                                } rounded-bl-md rounded-br-md`}
                              >
                                {buyingSlotFor === index && buyingSlot ? (
                                  'Buying...'
                                ) : (
                                  <>
                                    <div className="pl-2">
                                      {slot.canPurchase
                                        ? slot.slotPriceDecimal
                                        : 'Locked'}
                                    </div>
                                    <img
                                      src={'/timetoken.png'}
                                      className="w-5 h-5"
                                      alt="vacant"
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="text-center flex justify-center col-span-full">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            style={{
                              background: 'none',
                              display: 'block',
                              shapeRendering: 'auto',
                            }}
                            width="50px"
                            height="50px"
                            viewBox="0 0 100 100"
                            preserveAspectRatio="xMidYMid"
                          >
                            <circle
                              cx="50"
                              cy="50"
                              fill="none"
                              stroke="#cdcdcd"
                              strokeWidth="7"
                              r="18"
                              strokeDasharray="84.82300164692441 30.274333882308138"
                            >
                              <animateTransform
                                attributeName="transform"
                                type="rotate"
                                repeatCount="indefinite"
                                dur="1s"
                                values="0 50 50;360 50 50"
                                keyTimes="0;1"
                              ></animateTransform>
                            </circle>
                          </svg>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="flex justify-start mt-6">
                    <button
                      onClick={() => {
                        setTabId('vaults');
                      }}
                      className="flex justify-center px-4 py-2 mt-1 bg-zinc-500 hover:bg-zinc-400 disabled:opacity-50 text-white rounded-md font-semibold"
                    >
                      <ArrowLeftIcon className="self-center mr-2 h-5 w-5 font-bold text-sm" />
                      <div className="self-center">Back</div>
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <button
                    onClick={() => {
                      setTabId('vaults');
                    }}
                    className="px-4 py-2 mt-1 bg-gray-400 hover:bg-gray-500 disabled:opacity-50 text-white rounded-md font-semibold"
                  >
                    <span className="inline">
                      <ArrowLeftIcon className="inline h-6 w-6 font-bold" /> Go
                      Back
                    </span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import { useEffect, useState } from 'react';
import ApiClient from '../../../utils/ApiClient';
import {
  numberWithCommas,
  parseNumberToShortForm,
} from '../../../utils/parser';
import { SocialIcon } from 'react-social-icons';
import { Tooltip } from 'flowbite-react';
import classNames from '../../../utils/classNames';
import {
  CalendarDaysIcon,
  CalendarIcon,
  ClipboardIcon,
} from '@heroicons/react/24/outline';
import { useAuth } from '../../../hooks/useAuth';

const Contests = () => {
  const [contests, setContests] = useState([]);

  useEffect(() => {
    ApiClient.getContests().then(res => {
      console.log(res);
      setContests(res.contests);
    });
  }, []);

  const ensureTopThreeEntries = (entries: any) => {
    const placeholders = [
      { empty: true, Account: { address: '??' }, points: 0 },
      { empty: true, points: 0 },
      { empty: true, points: 0 },
    ];
    return [...entries, ...placeholders].slice(0, 3);
  };

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-8 px-0 md:px-2 ">
        {contests.map((contest: any, index) => {
          return (
            <div
              key={index}
              className="flex flex-col bg-white dark:bg-zinc-900 shadow-md border border-gray-100 dark:border-zinc-800 rounded-lg p-4 transform transition-transform duration-300 hover:scale-105"
            >
              <div className="flex justify-between">
                <div className="flex">
                  <img
                    src={contest.TokenTrack.tokenLogo}
                    alt={contest.TokenTrack.tokenName}
                    className="w-12 h-12 rounded-full"
                  />
                  <div className="ml-2">
                    <p className="font-semibold text-lg dark:text-zinc-100">
                      {contest.TokenTrack.tokenSymbol} Contest
                    </p>
                    <p className="text-xs text-gray-500 dark:text-zinc-200">
                      {!contest.hasStarted ? (
                        <>
                          <CalendarDaysIcon className="h-4 w-4 inline mr-1 self-start align-top" />
                          Starts in{' '}
                          {contest.daysUntilStart !== 0
                            ? `${contest.daysUntilStart} days`
                            : contest.hoursUntilStart
                            ? `${contest.hoursUntilStart} hour${
                                contest.hoursUntilStart > 1 ? 's' : ''
                              }`
                            : `${
                                contest.minutesUntilStart > 0
                                  ? `${contest.minutesUntilStart} minutes`
                                  : 'one moment'
                              }`}
                        </>
                      ) : !contest.hasEnded ? (
                        <>
                          <CalendarDaysIcon className="h-4 w-4 inline mr-1 self-start align-top" />
                          Ends in{' '}
                          {contest.daysUntilEnd !== 0
                            ? `${contest.daysUntilEnd} days`
                            : contest.hoursUntilEnd
                            ? `${contest.hoursUntilEnd} hour${
                                contest.hoursUntilEnd > 1 ? 's' : ''
                              }`
                            : `${
                                contest.minutesUntilEnd > 0
                                  ? `${contest.minutesUntilEnd} minutes`
                                  : 'one moment'
                              }`}
                        </>
                      ) : (
                        <>
                          <CalendarDaysIcon className="h-4 w-4 inline mr-1 self-start align-top" />
                          Ended{' '}
                          {contest.daysUntilEnd !== 0
                            ? `${contest.daysUntilEnd} day${
                                contest.daysUntilEnd > 1 ? 's' : ''
                              }`
                            : contest.hoursUntilEnd
                            ? `${contest.hoursUntilEnd} hour${
                                contest.hoursUntilEnd > 1 ? 's' : ''
                              }`
                            : `${
                                contest.minutesUntilEnd > 0
                                  ? `${contest.minutesUntilEnd} minutes`
                                  : 'one moment'
                              }`}{' '}
                          ago
                        </>
                      )}
                    </p>
                  </div>
                </div>
                <div className="flex">
                  <div className="text-xs">
                    {!contest.hasStarted ? (
                      <div className="px-4 py-1 border rounded-sm text-blue-500 font-semibold border-blue-300 bg-blue-600/10 dark:border-blue-500">
                        Upcoming
                      </div>
                    ) : contest.hasEnded ? (
                      <div className="px-4 py-1 border rounded-sm font-semibold text-gray-500 border-gray-300 bg-gray-600/10 dark:border-gray-500">
                        Ended
                      </div>
                    ) : (
                      <div className="px-4 py-1 border rounded-sm text-pink-500 font-semibold border-pink-300 bg-pink-600/10 dark:border-pink-500">
                        Live
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="text-sm">
                <div className="mt-4 dark:text-zinc-100">
                  {ensureTopThreeEntries(contest.Entries).map(
                    (entry: any, index: number) => {
                      return (
                        <div
                          key={index}
                          className={classNames(
                            `flex justify-between items-center border-b py-1 px-1`,
                            index === 0
                              ? 'border-yellow-300/50 bg-yellow-300/20 text-yellow-500/90 dark:border-yellow-300 dark:bg-yellow-200/20 dark:text-yellow-500'
                              : index === 1
                              ? 'border-gray-300 bg-gray-300/40 text-gray-500 dark:bg-gray-300/20 dark:text-gray-400'
                              : 'border-yellow-600/30 bg-yellow-500/20 text-yellow-600/90 dark:bg-yellow-500/20',
                          )}
                        >
                          <div className="flex items-center">
                            <p
                              className={`text-md opacity-75 w-6 text-center ${
                                index === 0
                                  ? 'text-yellow-500/90 dark:text-yellow-400'
                                  : index === 1
                                  ? 'text-gray-500 dark:text-gray-400'
                                  : 'text-yellow-600/90 dark:bg-yellow-500/20 dark:text-yellow-500'
                              }`}
                            >
                              {index + 1}
                            </p>
                            {
                              <img
                                src={entry?.Account?.image || '/time.png'}
                                alt={entry?.Account?.address || 'Empty'}
                                className={`w-5 h-5 rounded-full ml-1 ${
                                  entry.empty ? 'opacity-30 grayscale' : ''
                                }`}
                              />
                            }
                            <a
                              href={
                                entry.empty
                                  ? `#`
                                  : `https://cronoscan.com/address/${entry.Account.address}`
                              }
                              target={entry.empty ? `_self` : `_blank`}
                              rel="noreferrer"
                              className={entry.empty ? 'cursor-default' : ''}
                            >
                              <p
                                className={`ml-2 font-medium ${
                                  entry.empty ? 'opacity-50' : ''
                                } text-xs ${
                                  index === 0
                                    ? 'text-yellow-500/90 dark:text-yellow-400'
                                    : index === 1
                                    ? 'text-gray-500 dark:text-gray-400'
                                    : 'text-yellow-600/90 dark:text-yellow-500'
                                }`}
                              >
                                {entry.empty
                                  ? 'Open slot'
                                  : entry.Account.address.slice(0, 6) +
                                    '...' +
                                    entry.Account.address.slice(-4)}
                              </p>
                            </a>
                          </div>
                          <p
                            className={`text-xs mr-1 ${
                              entry.empty ? 'opacity-50' : ''
                            } font-medium ${
                              index === 0
                                ? 'text-yellow-500/90 dark:text-yellow-400'
                                : index === 1
                                ? 'text-gray-500 dark:text-gray-400'
                                : 'text-yellow-600/90 dark:text-yellow-500'
                            }`}
                          >
                            {parseNumberToShortForm(
                              entry.points > 0 ? entry.points : 0,
                            )}{' '}
                            pts
                          </p>
                        </div>
                      );
                    },
                  )}
                </div>
                <p className="text-gray-400  mt-1.5 text-xs flex justify-end">
                  {contest._count.Entries > 2
                    ? `and ${contest._count.Entries - 3} other participants`
                    : `${contest._count.Entries} participants`}
                </p>
              </div>
              <div className="mt-4">
                <p className="text-sm text-gray-500 flex flex-col items-start align-start">
                  <div className="flex items-center mt-1">
                    {contest.metadata.prizes?.filter((p: string) => p !== '')
                      .length > 0 && (
                      <div className="mr-2 font-medium">Prizes: </div>
                    )}
                    {contest.metadata.prizes
                      ?.slice(0, 3)
                      ?.filter((p: string) => p !== '')
                      .map((prize: string, index: number) => (
                        <div
                          key={index}
                          className={`flex font-medium text-sm items-center border rounded-md px-1 py-0.5 bg-gray-100 text-gray-500 mr-2 ${
                            index === 0
                              ? 'border-yellow-300/50 bg-yellow-300/20 text-yellow-500/90 dark:border-yellow-300/80 dark:text-yellow-400'
                              : index === 1
                              ? 'border-gray-300 bg-gray-300/40 text-gray-500 dark:text-gray-400 dark:bg-gray-300/20 dark:border-gray-400'
                              : 'border-yellow-600/30 bg-yellow-500/20 text-yellow-600/90 dark:border-yellow-600/60 dark:text-yellow-500'
                          }`}
                        >
                          <span className="px-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="1em"
                              height="1em"
                              viewBox="0 0 24 24"
                              className="inline"
                            >
                              <path
                                fill="currentColor"
                                d="M14.94 19.5L12 17.77L9.06 19.5l.78-3.34l-2.59-2.24l3.41-.29L12 10.5l1.34 3.13l3.41.29l-2.59 2.24M20 2H4v2l4.86 3.64a8 8 0 1 0 6.28 0L20 4m-2 11a6 6 0 1 1-7.18-5.88a5.9 5.9 0 0 1 2.36 0A6 6 0 0 1 18 15m-5.37-8h-1.26l-4-3h9.34Z"
                              ></path>
                            </svg>{' '}
                            {prize}
                          </span>
                        </div>
                      ))}

                    {contest.metadata.prizes?.length > 3 && (
                      <div className="text-xs dark:text-zinc-200 self-end">
                        and {contest.metadata.prizes?.length - 3} more...
                      </div>
                    )}
                  </div>
                </p>
              </div>
              <div className="mt-4">
                {contest.hasStarted && !contest.hasEnded ? (
                  <a
                    href={`/app/contest/${contest.id}/${contest.TokenTrack.tokenSymbol}`}
                  >
                    <button className="w-full py-2 rounded-lg font-semibold text-white bg-pink-500 hover:bg-pink-400 dark:bg-pink-600 dark:hover:bg-pink-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                      View Leaderboard
                    </button>
                  </a>
                ) : !contest.hasStarted ? (
                  <button className="w-full py-2 rounded-lg font-semibold opacity-50 cursor-default text-white bg-blue-500 dark:bg-blue-600 focus:outline-none">
                    Starting Soon
                  </button>
                ) : (
                  <a
                    href={`/app/contest/${contest.id}/${contest.TokenTrack.tokenSymbol}`}
                  >
                    <button className="w-full py-2 rounded-lg font-semibold text-white bg-zinc-800 hover:bg-zinc-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                      View Leaderboard
                    </button>
                  </a>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
export default Contests;

/* eslint-disable no-restricted-globals */
import { useEffect, useRef, useState } from 'react';
import ApiClient from '../../../../utils/ApiClient';
import AdminTabs from '../../components/admin/AdminTabs';
import { ethers } from 'ethers';
import _ from 'lodash';
import DialogScreen from '../../components/DialogScreen';
import { Dialog } from '@headlessui/react';

export default function TokenTrackAdmin() {
  const [tokenAddress, setTokenAddress] = useState<string>('');
  const [network, setNetwork] = useState<'CRONOS' | 'POLYGON'>('CRONOS');

  const [validated, setValidated] = useState<boolean>(false);
  const [validatedData, setValidatedData] = useState<any>(null);

  const [tokens, setTokens] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(200);

  const [added, setAdded] = useState('');
  useEffect(() => {
    setValidated(false);
    setValidatedData(null);
  }, [network, tokenAddress]);

  useEffect(() => {
    ApiClient.getTokenTracks(page, pageSize).then(r => {
      setTokens(r.tokens || []);
    });
  }, []);

  function onValidateTokenTrack() {
    ApiClient.admin_validateTokenTrack(tokenAddress, network)
      .then(r => {
        console.log(r);
        if (!r || !r.tokenTrack) {
          return alert('failed, most likely not available on dexscreener');
        }

        setValidatedData(r.tokenTrack);
        setValidated(true);
      })
      .catch(e => {
        console.log(e);
        alert(e.response?.data?.message || 'Failed');
      });
  }

  function onNewTokenTrack() {
    ApiClient.admin_newTokenTrack(tokenAddress, network)
      .then(r => {
        console.log(r);
        if (!r || !r.tokenTrack) {
          return alert('failed, is it already added or supported?');
        }

        setValidatedData(null);
        setValidated(false);
        setTokenAddress('');
        setAdded(r.tokenTrack.tokenName);
        ApiClient.getTokenTracks(page, pageSize).then(r => {
          setTokens(r.tokens || []);
        });
      })
      .catch(e => alert(e.response?.data?.message || 'Failed'));
  }

  const [showManageDialog, setShowManageDialog] = useState(false);
  const [managingToken, setManagingToken] = useState<any>(undefined);

  function handleEditTokenChange(e: React.ChangeEvent<HTMLInputElement>) {
    setManagingToken({
      ...managingToken,
      [e.target.name]: e.target.value,
    });
  }

  function handleLinkChange(
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) {
    const updatedLinks = managingToken.links.map((link: any, i: number) =>
      i === index ? { ...link, url: e.target.value } : link,
    );
    setManagingToken({
      ...managingToken,
      links: updatedLinks,
    });
  }

  function onSubmitEditToken(remove: boolean = false) {
    ApiClient.admin_editTokenTrack(
      managingToken.id,
      managingToken.tokenName,
      managingToken.tokenSymbol,
      managingToken.tokenLogo,
      managingToken.links,
      remove,
    )
      .then(r => {
        console.log(r);
        if (r) {
          ApiClient.getTokenTracks(page, pageSize).then(r => {
            setTokens(r.tokens || []);
          });
          setManagingToken(undefined);
          setShowManageDialog(false);
          alert('Successfully edited token');
        }
      })
      .catch(e => alert(e.response?.data?.message || 'Failed'));
  }
  return (
    <>
      <DialogScreen open={showManageDialog} setOpen={setShowManageDialog}>
        {managingToken && (
          <div>
            <div>
              <div className="mt-3 sm:mt-5">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-semibold leading-6 text-gray-900"
                >
                  Manage {managingToken.tokenName}
                </Dialog.Title>

                <h2>Token Address: {managingToken.tokenAddress}</h2>

                <div className="mt-4">
                  <label
                    htmlFor="editTokenName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Token Name
                  </label>
                  <input
                    type="text"
                    id="editTokenName"
                    name="tokenName"
                    value={managingToken.tokenName}
                    onChange={handleEditTokenChange}
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>

                <div className="mt-4">
                  <label
                    htmlFor="tokenSymbol"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Token Symbol
                  </label>
                  <input
                    type="text"
                    id="tokenSymbol"
                    name="tokenSymbol"
                    value={managingToken.tokenSymbol}
                    onChange={handleEditTokenChange}
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>

                <div className="mt-4 mb-4">
                  <label
                    htmlFor="tokenLogo"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Token Logo
                  </label>
                  <input
                    type="text"
                    id="tokenLogo"
                    name="tokenLogo"
                    value={managingToken.tokenLogo}
                    onChange={handleEditTokenChange}
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>

                {managingToken.links.map((link: any, i: number) => {
                  return (
                    <div className="mb-2">
                      <label
                        htmlFor="linkValue"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Link {i + 1}: {link.type}
                      </label>
                      <input
                        type="text"
                        id="linkValue"
                        value={link.url}
                        onChange={e => handleLinkChange(e, i)}
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  );
                })}
              </div>
              <button
                type="button"
                onClick={() => {
                  if (confirm('Are you sure you want to delete this token?')) {
                    onSubmitEditToken(true);
                  }
                }}
                className="inline-flex justify-center rounded-md bg-red-700 disabled:opacity-50 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-600 disabled:hover:bg-gray-600"
              >
                Delete
              </button>
            </div>

            <div className="mt-5 sm:mt-6 flex flex-row gap-x-4">
              <button
                type="button"
                onClick={() => {
                  setShowManageDialog(false);
                  setManagingToken(undefined);
                }}
                className="inline-flex w-full justify-center rounded-md bg-gray-600 disabled:opacity-50 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 disabled:hover:bg-gray-600"
              >
                Cancel
              </button>

              <button
                type="button"
                onClick={() => {
                  onSubmitEditToken();
                }}
                className="inline-flex w-full justify-center rounded-md bg-purple-600 disabled:opacity-50 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 disabled:hover:bg-purple-600"
              >
                Save
              </button>
            </div>
          </div>
        )}
      </DialogScreen>
      <AdminTabs />
      <h2 className="mt-6 mb-2 text-xl font-semibold text-gray-900 dark:text-white">
        Manage Tokens
      </h2>
      <div>
        <h3 className="font-semibold">Add New Token</h3>
        <div className="mb-3">
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            Token Address
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={tokenAddress}
            onChange={e => setTokenAddress(e.target.value)}
            placeholder="0x"
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            required
          />
        </div>

        <div className="mb-3">
          <select
            id="walletwithdraw"
            name="walletwithdraw"
            value={network}
            onChange={t => {
              setNetwork(t.target.value as any);
            }}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="CRONOS">Cronos</option>
            <option value="POLYGON">Polygon</option>
          </select>
        </div>

        {added && <p>Successfully added {added}</p>}
        <button
          className="cursor-pointer mb-4 disabled:cursor-default bg-white dark:bg-zinc-800 dark:text-white dark:border-zinc-700 dark:disabled:opacity-50 dark:hover:bg-zinc-700 dark:disabled:hover:bg-zinc-800 disabled:bg-gray-200 py-2 px-6 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 disabled:text-gray-400 hover:bg-gray-50 focus:outline-none"
          onClick={() =>
            validated ? onNewTokenTrack() : onValidateTokenTrack()
          }
        >
          {!validated ? 'Validate Token' : 'Submit New Token'}
        </button>

        {validated && validatedData && (
          <div>
            <h2 className="font-bold">Review the information then submit:</h2>
            <p>
              Token Name: {validatedData.tokenName}
              <br />
              Token Symbol: {validatedData.tokenSymbol}
              <br />
              Market Cap: {validatedData.marketCap} <br />
              Price USD: {validatedData.priceUsd} <br />
              Links:{' '}
              {validatedData.links
                ?.map((l: any) => `${l.type}: ${l.url}`)
                .join(', ') || ''}{' '}
              <br />
              Logo:{' '}
              <img
                src={validatedData.tokenLogo}
                className="h-10 w-10 rounded-full"
                alt={'New token logo'}
              />
            </p>
          </div>
        )}
      </div>
      <h2 className="mt-6 text-xl font-semibold text-gray-900 dark:text-white">
        Existing Tokens
      </h2>
      <div className="flow-root overflow-hidden">
        <div className=" max-w-7xl">
          <table className="w-full text-left">
            <thead className="bg-white">
              <tr>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Token Name
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Token Symbol
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                >
                  Links
                </th>
                <th scope="col" className="relative py-3.5 pl-3">
                  <span className="sr-only">Manage</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {tokens?.map((token: any) => (
                <tr key={token.id}>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    <div className="flex items-center flex-row">
                      <img
                        src={token.tokenLogo}
                        className="h-8 w-8 rounded-full mr-2"
                        alt={token.tokenName}
                      />{' '}
                      <div>{token.tokenName}</div>
                    </div>
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    {token.tokenSymbol}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                    {token.links.length} links
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    {/* {wallet.active ? 'Yes' : 'No'} */}
                  </td>
                  <td className="relative py-4 pl-3 text-right text-sm font-medium">
                    <a
                      href="#"
                      onClick={() => {
                        setManagingToken(token);
                        setShowManageDialog(true);
                      }}
                      className="text-purple-600 hover:text-purple-700"
                      rel="noreferrer"
                    >
                      Manage
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

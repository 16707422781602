export default function PercInput({ value, onChange }: any) {
  return (
    <div>
      <label
        htmlFor="price"
        className="block text-sm font-medium text-gray-700 dark:text-gray-300"
      >
        Price
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none ">
          {/* <span className="text-gray-500 sm:text-sm">%</span> */}
        </div>
        <input
          id="percprice"
          name="percprice"
          type="range"
          min="-100"
          max="10000"
          value={value}
          onChange={onChange}
          step="1"
          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
          aria-describedby="price-currency"
        />

        {/* <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm" id="price-currency">
              Percentage
            </span>
          </div> */}
      </div>
    </div>
  );
}

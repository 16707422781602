/* This example requires Tailwind CSS v2.0+ */
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import classNames from '../../utils/classNames';

export default function ConnectAlert({
  title,
  message,
}: {
  title: string;
  message: string;
}) {
  return (
    <div
      className={classNames(
        message === '' ? 'hidden' : '',
        'rounded-md bg-yellow-50 p-4 ml-8',
      )}
    >
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationCircleIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">{title}</h3>
          <div className="mt-2 text-sm text-yellow-700">
            <p>{message}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

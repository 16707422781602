/* eslint-disable react-hooks/exhaustive-deps */
import { Transition, Dialog, Combobox } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import classNames from '../../../utils/classNames';
import ApiClient from '../../../utils/ApiClient';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { CronosSvg, PolygonSvg } from '../../../utils/parser';
import PlaceholderLoading from 'react-placeholder-loading';
import { debounce } from 'lodash';
import {
  SoftStakingContractKeys,
  softStakingContracts,
} from '../../../web3/config';
import useSWR from 'swr';
import { useAuth } from '../../../hooks/useAuth';

type ItemType = {
  id: string;
  name: string;
  category: string;
  url: string;
  image: string;
  network?: string;
};

const manuallyAddedItems: ItemType[] = [
  // Add your manually added items here

  ...Object.keys(softStakingContracts)
    .filter(s => s !== 'unknown')
    .map(k => {
      return {
        id: `softstake-${k}`,
        name: softStakingContracts[k as SoftStakingContractKeys]?.name!,
        category: 'Soft Staking',
        url: `/app/soft-staking/${k}`,
        image: softStakingContracts[k as SoftStakingContractKeys]?.image!,
      };
    }),
  // ... other manually added items
];

const customFetcher: any = (url: string) =>
  ApiClient.authGet(url).then(res => res.data.data);

const Search = () => {
  const { hasFullAccess } = useAuth();
  const [inputValue, setInputValue] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSearch = debounce(value => {
    setSearchTerm(value);
  }, 700); // 500 milliseconds delay

  useEffect(() => {
    // Call the debounced search whenever inputValue changes
    debouncedSearch(inputValue);

    // Cleanup function to cancel the debounced function if the component unmounts
    return () => {
      debouncedSearch.cancel();
    };
  }, [inputValue]); // Only re-run the effect if inputValue changes

  const [isResultsVisible, setIsResultsVisible] = useState(false);
  // Update inputValue immediately on every change
  const handleInputChange = (e: any) => {
    setIsResultsVisible(true);
    setInputValue(e.target.value);
  };

  const [groupedItems, setGroupedItems] = useState<{
    [category: string]: ItemType[];
  }>({});
  const { data, error } = useSWR(null, customFetcher, {
    dedupingInterval: 60000,
  });

  const loading = inputValue !== '' && !data && !error;

  const items = data
    ? [
        ...manuallyAddedItems,
        ...(hasFullAccess
          ? data.map((item: any) => {
              return {
                ...item,
                category: 'Collections',
                url: `/app/collection/${item.address}`,
              };
            })
          : []),
      ].filter(item =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase()),
      )
    : [];

  const groupItemsByCategory = (items: any) => {
    const grouped = items.reduce((acc: any, item: any) => {
      acc[item.category] = acc[item.category] || [];
      acc[item.category].push(item);
      return acc;
    }, {});

    setGroupedItems(grouped);
  };

  useEffect(() => {
    if (items.length > 0) {
      groupItemsByCategory(items);
      setIsResultsVisible(true);
    } else {
      if (!inputValue || inputValue === '') setIsResultsVisible(false);
    }
  }, [items, searchTerm]);

  const handleInputFocus = () => {
    if (Object.keys(groupedItems).length > 0) {
      setIsResultsVisible(true);
    }
  };

  return (
    <Combobox
      as="div"
      className="relative w-full hidden md:block lg:block xl:block"
      value={searchTerm}
      onChange={handleInputChange}
    >
      <Combobox.Input
        onFocus={handleInputFocus}
        placeholder={`Search for staking or events`}
        className="block text-sm w-full  dark:bg-zinc-900 dark:text-white rounded-md border border-gray-100 dark:border-zinc-700 px-4 py-[9px] leading-tight focus:outline-none"
        onChange={handleInputChange}
      />
      {isResultsVisible && (
        <Combobox.Options className="absolute   z-10 w-full mt-1 rounded-lg  bg-white shadow-lg dark:bg-zinc-800">
          {loading ? (
            <div className="px-4 rounded-lg flex justify-center py-2 text-xs font-semibold text-gray-700 bg-gray-100 dark:bg-zinc-800 dark:text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                style={{
                  background: 'none',
                  display: 'block',
                  shapeRendering: 'auto',
                }}
                width="25px"
                height="25px"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
              >
                <circle
                  cx="50"
                  cy="50"
                  fill="none"
                  stroke="#cdcdcd"
                  strokeWidth="7"
                  r="18"
                  strokeDasharray="84.82300164692441 30.274333882308138"
                >
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    repeatCount="indefinite"
                    dur="1s"
                    values="0 50 50;360 50 50"
                    keyTimes="0;1"
                  ></animateTransform>
                </circle>
              </svg>
            </div>
          ) : (
            Object.keys(groupedItems).map(category => (
              <Fragment key={category}>
                <div className="px-4 py-2 rounded-t-sm  text-xs font-semibold text-gray-700 bg-gray-100 dark:bg-zinc-700 dark:text-white">
                  {category}
                </div>
                {groupedItems[category].map((item, index) => (
                  <Combobox.Option
                    key={item.id}
                    value={item.name}
                    as={Fragment}
                  >
                    {({ active }) => (
                      <a
                        href={item.url}
                        className={`block cursor-pointer dark:bg-zinc-800 px-4 py-2 text-sm ${
                          active
                            ? 'bg-gray-100 dark:bg-zinc-700 text-white'
                            : 'text-gray-700'
                        }`}
                      >
                        <div className="flex flex-row align-center items-center py-1">
                          <LazyLoadImage
                            src={item.image || '/nav.png'}
                            className="w-12  rounded-lg"
                            placeholder={
                              <PlaceholderLoading
                                shape="rect"
                                width={100}
                                height={100}
                                colorStart="transparent"
                                colorEnd="#9CA3AF"
                              />
                            }
                          />
                          <p className="text-black dark:text-white ml-4 overflow-hidden whitespace-nowrap overflow-ellipsis">
                            {item.name}
                          </p>
                        </div>
                      </a>
                    )}
                  </Combobox.Option>
                ))}
              </Fragment>
            ))
          )}
        </Combobox.Options>
      )}
    </Combobox>
  );
};

export default Search;

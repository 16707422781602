// import CollectionNav from "../CollectionNav";
import { Key, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ApiClient, {
  PaginatedResponse,
  Token,
} from '../../../../utils/ApiClient';
import Pagination from '../Pagination';
import SearchButtons from '../SearchButtons';
import { MyStakingTokenCard } from './MyStakingTokenCard';
import { ArrowRightIcon } from '@heroicons/react/24/solid';
import { useAuth } from '../../../../hooks/useAuth';

export function MyStakingTokenList({
  collectionName,
  address,
}: {
  collectionName: string;
  address: string;
}) {
  let [searchParams, setSearchParams] = useSearchParams();
  const [pages, setPages] = useState(0);
  const [pageSize, setPageSize] = useState(12);
  const [page, setPage] = useState(
    parseInt(searchParams.get('page') || '1', 10),
  );
  const { isAuthenticated, hasFullAccess } = useAuth();
  const [total, setTotal] = useState(0);
  const [order, setOrder] = useState('floorPrice');
  const [direction, setDirection] = useState('desc');
  const [search, setSearch] = useState('');
  const [listed, setListed] = useState('both');
  const [tokens, setTokens] = useState<Token[]>([]);

  const goto = (page: number) => {
    setSearchParams({ page: page.toString() });
    setPage(page);
  };

  useEffect(() => {
    const p = parseInt(searchParams.get('page') || '1', 10);
    ApiClient.getMyTokens(
      p,
      pageSize,
      order,
      direction,
      search,
      listed,
      address,
    )
      .then((res: PaginatedResponse<Token>) => {
        setTokens(res.data);
        setTotal(res.total);
        setPages(res.totalPages);
        setDirection(res.direction);
        setOrder(res.order);
        setPageSize(res.pageSize);
        executeScroll();
      })
      .catch((e: any) => console.log(e));
  }, [page, search, order, direction, listed]);

  const list = tokens.map((token: any) => (
    <MyStakingTokenCard key={token.id} token={token}></MyStakingTokenCard>
  ));
  const myRef = useRef(null);
  const executeScroll = () => {
    if (myRef.current) {
      (myRef.current as any).scrollIntoView();
    }
  };
  return (
    <div
      ref={myRef}
      className="bg-white dark:bg-zinc-900 dark:text-white my-6 shadow border border-gray-100 dark:border-zinc-600 bg-white p-6 pt-3 rounded-lg"
    >
      <div className="grid md:flex items-end md:justify-end mb-6">
        <div className="flex-1">
          <div className="max-w-7xl">
            <h1 className="text-2xl font-semibold text-gray-900 dark:text-white">
              My {collectionName}
              {collectionName.endsWith('s') ? '' : "'s"}
            </h1>
          </div>
        </div>
        <div className="">
          {isAuthenticated && hasFullAccess && (
            <a href={`/app/collection/${address}`} target="_blank">
              <button
                className={`relative mt-5 bg-purple-600 hover:bg-purple-500 text-[#fff] inline-flex items-center px-4 py-2 rounded-md text-sm font-medium focus:z-10 focus:outline-none`}
              >
                View Full Collection
                <ArrowRightIcon className="w-4 h-4 ml-2" />
              </button>
            </a>
          )}
        </div>
      </div>

      {total && total > 0 ? (
        <>
          <div className="col-span-3">
            <div className="grid grid-cols-2 gap-4 sm:grid-cols-4">{list}</div>
          </div>
          <Pagination
            total={total}
            page={page}
            goto={goto}
            pageSize={pageSize}
            pages={pages}
          ></Pagination>
        </>
      ) : (
        <div className="flex justify-center">
          <p className="text-md dark:text-white">
            {isAuthenticated
              ? 'You have none to stake.'
              : 'You do not have Time Sniper access.'}
          </p>
        </div>
      )}
      {/* </div> */}
    </div>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useAuth } from '../../../hooks/useAuth';
import { ethers } from 'ethers';
import { formatEther, parseEther } from 'ethers/lib/utils';
import ERC20ABI from '../../../web3/ABI/erc20.abi.json';
import ERC721ABI from '../../../web3/ABI/erc721.abi.json';
import { secondsToDhms } from '../../../utils/parser';
import TTTInput from '../components/inputs/TTTInput';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PlaceholderLoading from 'react-placeholder-loading';
import { useNetwork } from '../../../hooks/useNetwork';
import { ensureCronos, txWait } from '../../../web3/utils';
import {
  FARMS_ADDRESS,
  FARMS_ABI,
  FARMS_BOOSTERS_ADDRESS,
} from '../../../web3/ABI/contracts/time-farms';
import DialogScreen from '../components/DialogScreen';
import { Dialog } from '@headlessui/react';
import { TokenSelect } from '../components/token/TokenSelect';
import WalletRequired from '../components/WalletRequired';

export type FarmInfo = {
  id: bigint;
  stakeToken: string;
  earnToken: string;
  enabled: boolean;
  totalClaimed: bigint;
  totalStaked: bigint;
  apr: bigint;
  lockTime: bigint;
};

const contractConfig = {
  address: FARMS_ADDRESS,
  abi: FARMS_ABI,
};

function resolveTokenName(address: string) {
  switch (address) {
    case '0xCF82a83463B12898d9f208b772E82aF304d49FC6':
      return 'TTT-WCRO (CronaSwap)';
  }
}

const testWallet: string | null = null; // 0x3b428943Ef1C49bf81Ddb00f9A11e55811Fc7b3c

function Farm({ farm }: { farm: FarmInfo }) {
  const { user, activeWallet, loadingActiveWallet } = useAuth();
  const { tttUSDPrice } = useNetwork();

  const [stakeAmount, setStakeAmount] = useState('');
  const handleStakeAmount = (val: any) => setStakeAmount(val);

  const [showBoostersDialog, setShowBoostersDialog] = useState(false);
  const [selectedBoosters, setSelectedBoosters] = useState<bigint[]>([]);
  const [selectedStakedBoosters, setSelectedStakedBoosters] = useState<
    bigint[]
  >([]);

  const [boosters, setBoosters] = useState<any>();

  const [userData, setUserData] = useState<any>();
  const [unclaimedRewards, setUnclaimedRewards] = useState<any>();
  const [tokenAllowance, setTokenAllowance] = useState<any>();
  const [balance, setBalance] = useState('0');

  const [isBoosterApproved, setIsBoosterApproved] = useState(false);
  useEffect(() => {
    async function checkBoosterApproval() {
      if (!user.isAuthenticated) return;

      const provider = new ethers.providers.Web3Provider(window.ethereum);

      const boosterContract = new ethers.Contract(
        FARMS_BOOSTERS_ADDRESS,
        ERC721ABI,
        provider,
      );
      const isApprovedForAll = await boosterContract.isApprovedForAll(
        testWallet ? testWallet! : activeWallet!,
        FARMS_ADDRESS,
      );

      setIsBoosterApproved(isApprovedForAll ? true : false);
    }

    checkBoosterApproval();
  }, [activeWallet]);

  async function getRequiredData() {
    if (!user.isAuthenticated) return;

    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const boosterContract = new ethers.Contract(
      contractConfig.address,
      contractConfig.abi,
      provider,
    );

    const gotBoosters = await boosterContract.tokensOfWallet(
      testWallet ? testWallet! : activeWallet!,
      FARMS_BOOSTERS_ADDRESS,
    );
    setBoosters(gotBoosters);

    const gotUserData = await boosterContract.userData(
      farm.id,
      testWallet ? testWallet! : activeWallet!,
    );
    setUserData(gotUserData);

    const gotUnclaimed = await boosterContract.getUnclaimedRewardsForAddress(
      farm.id,
      testWallet ? testWallet! : activeWallet!,
    );
    setUnclaimedRewards(gotUnclaimed);

    const farmStakeContract = new ethers.Contract(
      farm.stakeToken,
      ERC20ABI,
      provider,
    );

    const gotTokenAllowance = await farmStakeContract.allowance(
      testWallet ? testWallet! : activeWallet!,
      FARMS_ADDRESS,
    );
    setTokenAllowance(gotTokenAllowance);

    const gotBalance = await farmStakeContract.balanceOf(
      testWallet ? testWallet! : activeWallet!,
    );
    setBalance(gotBalance);
  }

  useEffect(() => {
    if (!isBoosterApproved) return;
    if (!activeWallet && !testWallet) return;

    async function run() {
      await ensureCronos();
    }

    run().then(() => {
      getRequiredData();
    });

    let interval: any;
    interval = setInterval(() => {
      getRequiredData();
    }, 4000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [
    user.isAuthenticated,
    activeWallet,
    user.wallet.chainId,
    isBoosterApproved,
  ]);

  const seconds = new Date().getTime() / 1000;
  const isLocked = userData && Number(userData.unlockTimestamp) > seconds;
  const remainingLockInSeconds =
    userData && isLocked ? Number(userData.unlockTimestamp) - seconds : 0;

  const tokenValue = parseEther(`${Number(stakeAmount || '0')}`);
  async function onApprove() {
    if (
      !user ||
      !user.wallet ||
      !user.isConnected ||
      !user.isAuthenticated ||
      !activeWallet
    ) {
      console.log('User or wallet not fully initialized or not connected');
      alert('User or wallet not fully initialized or not connected');
      return;
    }

    if (tokenValue.lte(0)) {
      alert('Invalid amount.');
      return;
    }

    try {
      await ensureCronos();
      const provider = new ethers.providers.Web3Provider(window.ethereum);

      const signer = provider.getSigner();
      const stakingContract = new ethers.Contract(
        farm.stakeToken,
        ERC20ABI,
        signer,
      );

      await txWait(stakingContract.approve(FARMS_ADDRESS, tokenValue));

      getRequiredData();
    } catch (error: any) {
      console.log(error.code);
      switch (error.code) {
        case -32603:
          alert('You do not have enough funds to perform this transaction.');
          break;
        default:
          //alert('Something went wrong, please view the console logs.');
          break;
      }
      console.error('Failed:', error);
    }
  }

  const isEnoughAllowed =
    tokenAllowance !== undefined &&
    tokenAllowance >= parseEther(stakeAmount || '0');

  async function onStake() {
    if (
      !user ||
      !user.wallet ||
      !user.isConnected ||
      !user.isAuthenticated ||
      !activeWallet
    ) {
      console.log('User or wallet not fully initialized or not connected');
      alert('User or wallet not fully initialized or not connected');
      return;
    }

    if (!isEnoughAllowed) {
      alert('You do not have enough.');
      return;
    }

    try {
      await ensureCronos();
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const stakingContract = new ethers.Contract(
        contractConfig.address,
        contractConfig.abi,
        signer,
      );

      const gasEstimate = await provider.estimateGas({
        to: contractConfig.address,
        from: await signer.getAddress(),
        data: stakingContract.interface.encodeFunctionData('stake', [
          farm.id,
          parseEther(stakeAmount || '0'),
        ]),
        value: parseEther('1'),
      });

      const gasLimit = gasEstimate.mul(12).div(10);

      await txWait(
        stakingContract.stake(farm.id, parseEther(stakeAmount || '0'), {
          value: parseEther('1'),
          gasLimit: gasLimit,
        }),
      );

      getRequiredData();
    } catch (error: any) {
      console.log(error.code);
      switch (error.code) {
        case -32603:
          alert('You do not have enough funds to perform this transaction.');
          break;
        default:
          //alert('Something went wrong, please view the console logs.');
          break;
      }
      console.error('Failed:', error);
    }
  }

  async function onUnstake() {
    if (
      !user ||
      !user.wallet ||
      !user.isConnected ||
      !user.isAuthenticated ||
      !activeWallet
    ) {
      alert('User or wallet not fully initialized or not connected');
      return;
    }

    if (isLocked || !userData || userData.userStakedTokens <= 0) {
      alert('Failed to unstake, do you meet the requirements?');
      return;
    }

    try {
      await ensureCronos();
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const stakingContract = new ethers.Contract(
        contractConfig.address,
        contractConfig.abi,
        signer,
      );

      const gasEstimate = await provider.estimateGas({
        to: contractConfig.address,
        from: await signer.getAddress(),
        data: stakingContract.interface.encodeFunctionData('unstake', [
          farm.id,
        ]),
        value: parseEther('1'),
      });

      const gasLimit = gasEstimate.mul(12).div(10);

      await txWait(
        stakingContract.unstake(farm.id, {
          value: parseEther('1'),
          gasLimit: gasLimit,
        }),
      );

      getRequiredData();
    } catch (error: any) {
      console.log(error.code);
      switch (error.code) {
        case -32603:
          alert('You do not have enough funds to perform this transaction.');
          break;
        default:
          //alert('Something went wrong, please view the console logs.');
          break;
      }
      console.error('Failed:', error);
    }
  }

  // BOOSTERS
  async function onStakeBoostersApprove() {
    if (
      !user ||
      !user.wallet ||
      !user.isConnected ||
      !user.isAuthenticated ||
      !activeWallet
    ) {
      console.log('User or wallet not fully initialized or not connected');
      alert('User or wallet not fully initialized or not connected');
      return;
    }

    if (!isBoosterApproved) {
      alert('You do not have enough.');
      return;
    }

    try {
      await ensureCronos();
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const stakingContract = new ethers.Contract(
        FARMS_BOOSTERS_ADDRESS,
        ERC721ABI,
        signer,
      );

      await txWait(stakingContract.setApprovalForAll(FARMS_ADDRESS, true));
      setSelectedBoosters([]);
      getRequiredData();
    } catch (error: any) {
      console.log(error.code);
      switch (error.code) {
        case -32603:
          alert('You do not have enough funds to perform this transaction.');
          break;
        default:
          //alert('Something went wrong, please view the console logs.');
          break;
      }
      console.error('Failed:', error);
    }
  }

  async function onStakeBoosters() {
    if (
      !user ||
      !user.wallet ||
      !user.isConnected ||
      !user.isAuthenticated ||
      !activeWallet
    ) {
      console.log('User or wallet not fully initialized or not connected');
      alert('User or wallet not fully initialized or not connected');
      return;
    }

    if (!isBoosterApproved) {
      alert('You do not have enough.');
      return;
    }

    try {
      await ensureCronos();
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const stakingContract = new ethers.Contract(
        contractConfig.address,
        contractConfig.abi,
        signer,
      );

      const gasEstimate = await provider.estimateGas({
        to: contractConfig.address,
        from: await signer.getAddress(),
        data: stakingContract.interface.encodeFunctionData('stakeBoosters', [
          farm.id,
          selectedBoosters,
        ]),
        value: ethers.utils.parseEther('1'),
      });
      const gasLimit = gasEstimate.mul(12).div(10);
      await txWait(
        stakingContract.stakeBoosters(farm.id, selectedBoosters, {
          value: parseEther('1'),
          gasLimit,
        }),
      );
      setSelectedBoosters([]);
      getRequiredData();
    } catch (error: any) {
      console.log(error.code);
      switch (error.code) {
        case -32603:
          alert('You do not have enough funds to perform this transaction.');
          break;
        default:
          //alert('Something went wrong, please view the console logs.');
          break;
      }
      console.error('Failed:', error);
    }
  }

  async function onUnstakeBoosters() {
    if (
      !user ||
      !user.wallet ||
      !user.isConnected ||
      !user.isAuthenticated ||
      !activeWallet
    ) {
      alert('User or wallet not fully initialized or not connected');
      return;
    }

    console.log({ selectedStakedBoosters });
    if (selectedStakedBoosters.length === 0) {
      alert('Failed to unstake, do you meet the requirements?');
      return;
    }

    try {
      await ensureCronos();
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const stakingContract = new ethers.Contract(
        contractConfig.address,
        contractConfig.abi,
        signer,
      );

      const gasEstimate = await provider.estimateGas({
        to: contractConfig.address,
        from: await signer.getAddress(),
        data: stakingContract.interface.encodeFunctionData('unstakeBoosters', [
          farm.id,
          selectedStakedBoosters,
        ]),
        value: parseEther('1'),
      });
      const gasLimit = gasEstimate.mul(12).div(10);

      await txWait(
        stakingContract.unstakeBoosters(farm.id, selectedStakedBoosters, {
          value: parseEther('1'),
          gasLimit,
        }),
      );
      setSelectedStakedBoosters([]);
      getRequiredData();
    } catch (error: any) {
      console.log(error.code);
      switch (error.code) {
        case -32603:
          alert('You do not have enough funds to perform this transaction.');
          break;
        default:
          //alert('Something went wrong, please view the console logs.');
          break;
      }
      console.error('Failed:', error);
    }
  }

  async function onClaim() {
    if (
      !user ||
      !user.wallet ||
      !user.isConnected ||
      !user.isAuthenticated ||
      !activeWallet
    ) {
      console.log('User or wallet not fully initialized or not connected');
      alert('User or wallet not fully initialized or not connected');
      return;
    }

    if (!userData || userData.userStakedTokens <= 0) {
      alert('Failed to claim');
      return;
    }

    try {
      await ensureCronos();
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const stakingContract = new ethers.Contract(
        contractConfig.address,
        contractConfig.abi,
        signer,
      );

      const fee = await stakingContract.fee();

      const gasEstimate = await provider.estimateGas({
        to: contractConfig.address,
        from: await signer.getAddress(),
        data: stakingContract.interface.encodeFunctionData(
          'claimRewardsForAddress',
          [farm.id, testWallet ? testWallet! : activeWallet!],
        ),
        value: fee ?? 0,
      });

      const gasLimit = gasEstimate.mul(12).div(10);

      await txWait(
        stakingContract.claimRewardsForAddress(
          farm.id,
          testWallet ? testWallet! : activeWallet!,
          {
            value: parseEther('1'),
            gasLimit,
          },
        ),
      );
      getRequiredData();
    } catch (error: any) {
      console.log(error.code);
      switch (error.code) {
        case -32603:
          alert('You do not have enough funds to perform this transaction.');
          break;
        default:
          //alert('Something went wrong, please view the console logs.');
          break;
      }
      console.error('Failed:', error);
    }
  }

  return (
    <>
      <DialogScreen open={showBoostersDialog} setOpen={setShowBoostersDialog}>
        <div>
          <div className="mt-3 text-center sm:mt-5">
            <Dialog.Title
              as="h3"
              className="text-lg font-semibold leading-6 text-gray-900"
            >
              NFT Boosting
            </Dialog.Title>
            <h2>
              Current Boosters (
              {userData && userData.boosters.length
                ? userData.boosters.length
                : 0}
              )
            </h2>
            {userData && userData.boosters.length > 0 && (
              <div className="mt-2">
                <TokenSelect
                  collection={FARMS_BOOSTERS_ADDRESS}
                  tokens={userData.boosters}
                  selectedTokens={selectedStakedBoosters}
                  setSelectedTokens={setSelectedStakedBoosters}
                />
              </div>
            )}
            <h2>Available Boosters ({boosters?.length || 0})</h2>
            <div className="mt-2">
              <TokenSelect
                tokens={boosters}
                selectedTokens={selectedBoosters}
                setSelectedTokens={setSelectedBoosters}
                collection={FARMS_BOOSTERS_ADDRESS}
              />
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-6 flex flex-row gap-x-4">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md bg-red-600 disabled:opacity-50 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 disabled:hover:bg-red-600"
            disabled={selectedStakedBoosters.length === 0}
            onClick={() => onUnstakeBoosters?.()}
          >
            {selectedStakedBoosters.length === 0
              ? 'Unstake'
              : `Unstake ${selectedStakedBoosters.length} NFTs`}
          </button>
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md bg-purple-600 disabled:opacity-50 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 disabled:hover:bg-purple-600"
            disabled={selectedBoosters.length === 0}
            onClick={() =>
              isBoosterApproved
                ? onStakeBoosters?.()
                : onStakeBoostersApprove?.()
            }
          >
            {selectedBoosters.length === 0
              ? 'Boost'
              : isBoosterApproved
              ? `Boost with ${selectedBoosters.length} NFTs`
              : 'Approve'}
          </button>
        </div>
      </DialogScreen>
      <div
        className={`bg-white dark:bg-zinc-900 dark:text-white border border-gray-100 dark:border-zinc-700 bg-white p-6 rounded-lg`}
      >
        <div className="flex justify-between mt-0 dark:text-white">
          <div>
            <div className="flex flex-row">
              <div>
                <LazyLoadImage
                  className="w-14 rounded-lg "
                  src={
                    resolveTokenName(farm.stakeToken)?.includes('CronaSwap')
                      ? '/tokens/tttcronaswap.png'
                      : '/tokens/ttt.webp'
                  }
                  width={200}
                  height={200}
                  placeholder={
                    <PlaceholderLoading
                      shape="rect"
                      width={200}
                      height={200}
                      colorStart="transparent"
                      colorEnd="#9CA3AF"
                    />
                  }
                />
              </div>
              <div className="ml-4">
                <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Earn TTT-WCRO
                </h1>
                <p className="text-sm dark:text-gray-300">
                  Stake {resolveTokenName(farm.stakeToken)}
                </p>
              </div>
            </div>
          </div>
          <div className="text-end">
            <p className="text-sm dark:text-white">
              <b>APR</b> {Number(farm.apr)}%
            </p>
            {farm.lockTime > 0 ? (
              <p className="text-sm dark:text-white">
                <b>Lock Time</b> {secondsToDhms(Number(farm.lockTime))}
              </p>
            ) : null}
            {userData && userData.boost > 0 && (
              <p className="text-sm dark:text-white">
                <b>Incl. boost:</b>{' '}
                {(Number(farm.apr) * (100 + Number(userData.boost))) / 100}%
              </p>
            )}
            {isLocked && (
              <div className="text-sm text-[red]">
                Unlocks in {secondsToDhms(remainingLockInSeconds)}
              </div>
            )}
          </div>
        </div>
        <div className="flex space-x-16 mt-6 dark:text-white">
          <div>
            <p className="text-sm text-gray-900 dark:text-white">Earned</p>
            <p className="text-lg font-semibold dark:text-white">
              {Number(
                formatEther(unclaimedRewards ? unclaimedRewards : 0),
              ).toLocaleString('en-us', { maximumFractionDigits: 4 })}{' '}
              $TTT
            </p>
            <p className="text-sm text-gray-500 mt-[2px] dark:text-gray-300">
              Claimed:{' '}
              {Number(
                formatEther(userData ? userData.userTotalRewards : 0),
              ).toLocaleString('en-us', { maximumFractionDigits: 4 })}{' '}
              $TTT
            </p>
          </div>

          <div>
            <p className="text-sm text-gray-900 dark:text-white">Staked</p>
            <p className="text-lg font-semibold dark:text-white">
              {Number(
                formatEther(userData ? userData.userStakedTokens : 0),
              ).toLocaleString('en-us', { maximumFractionDigits: 4 })}{' '}
              TTT-WCRO
            </p>

            <p className="text-sm text-gray-500 mt-[1px] dark:text-gray-300">
              Total:{' '}
              {Number(formatEther(farm.totalStaked)).toLocaleString('en-us', {
                maximumFractionDigits: 0,
              })}{' '}
              TTT-WCRO
            </p>
          </div>
        </div>

        <div className="flex space-x-16 mt-6 dark:text-white">
          <p className="text-sm dark:text-white">
            Balance:{' '}
            {Number(formatEther(balance)).toLocaleString('en-us', {
              maximumFractionDigits: 4,
            })}{' '}
            TTT-WCRO
          </p>
        </div>

        <div className="w-full lg:w-72">
          <TTTInput
            value={stakeAmount}
            onChange={handleStakeAmount}
            max={Number(formatEther(farm.totalStaked))}
          />
        </div>

        <div className="mt-2 flex items-center justify-between">
          <div className="mt-2 flex gap-x-2">
            <button
              onClick={() => {
                if (isEnoughAllowed) {
                  onStake();
                } else {
                  onApprove();
                }
              }}
              disabled={
                !stakeAmount || stakeAmount === '0' || stakeAmount === '0.00'
              }
              className="cursor-pointer disabled:cursor-default bg-white dark:bg-zinc-800 dark:text-white dark:border-zinc-700 dark:disabled:opacity-50 dark:hover:bg-zinc-700 dark:disabled:hover:bg-zinc-800 disabled:bg-gray-200 py-2 px-6 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 disabled:text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {isEnoughAllowed ? 'Stake' : 'Approve'}
            </button>
            <button
              onClick={() => onUnstake()}
              disabled={
                !userData || userData.userStakedTokens.eq(0) || isLocked
              }
              className="cursor-pointer disabled:cursor-default bg-white dark:bg-zinc-800 dark:text-white dark:border-zinc-700 dark:disabled:opacity-50  dark:hover:bg-zinc-700 dark:disabled:hover:bg-zinc-800 disabled:bg-gray-200 py-2 px-6 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 disabled:text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Unstake All
            </button>
          </div>
          <div className="mt-2 flex gap-x-2">
            <button
              onClick={() => setShowBoostersDialog(true)}
              className="cursor-pointer disabled:cursor-default bg-white dark:bg-zinc-800 dark:text-white dark:border-zinc-700 dark:disabled:opacity-50 dark:hover:bg-zinc-700 dark:disabled:hover:bg-zinc-800 disabled:bg-gray-200 py-2 px-6 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 disabled:text-gray-400 hover:bg-gray-50 focus:outline-none"
            >
              Boosters ({userData?.boosters.length ?? 0})
            </button>
            <button
              onClick={() => onClaim()}
              disabled={
                Number(formatEther(unclaimedRewards ? unclaimedRewards : 0)) <=
                0
              }
              className="cursor-pointer disabled:cursor-default bg-white dark:bg-zinc-800 dark:text-white dark:border-zinc-700 dark:disabled:opacity-50 dark:hover:bg-zinc-700 dark:disabled:hover:bg-zinc-800 disabled:bg-gray-200 py-2 px-6 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 disabled:text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Claim
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default function TTTLPStaking() {
  const { user, updateUser, activeWallet, loadingActiveWallet } = useAuth();
  const [farms, setFarms] = useState<any[]>();

  useEffect(() => {
    if (!user.isConnected) return;

    async function fetchFarms() {
      await ensureCronos();

      const provider = new ethers.providers.Web3Provider(window.ethereum);

      const poolsContract = new ethers.Contract(
        contractConfig.address,
        contractConfig.abi,
        provider,
      );
      const getPools = await poolsContract.getAllPools();

      setFarms(
        getPools.filter(
          (p: any) =>
            p.stakeToken !== '0xF803419a262A4AD553b52A8B4ba9Df21c71EbbEE',
        ),
      );
    }

    fetchFarms();
  }, [activeWallet, user.isConnected]);

  if (loadingActiveWallet) {
    return <></>;
  }

  if (!activeWallet) {
    return <WalletRequired />;
  }
  // name: 101s, etc

  return (
    <>
      <h1 className="text-2xl font-semibold text-gray-900 dark:text-white">
        {'TTT-WCRO'} Farms
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 justify-center gap-6 w-full pt-4">
        {farms && farms.length > 0
          ? farms.map(farm => <Farm key={farm.id} farm={farm} />)
          : 'No farms available.'}
      </div>
    </>
  );
}

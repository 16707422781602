import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import Container from './components/Container';

import reportWebVitals from './reportWebVitals';

import './index.css';
import AuthProvider from './providers/AuthProvider';
import Home from './components/Home';
import App from './components/App';
import TagManager from 'react-gtm-module';
import NetworkProvider from './providers/NetworkProvider';

const tagManagerArgs = {
  gtmId: 'G-62X75W09H3',
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <NetworkProvider>
    <AuthProvider>
      <HelmetProvider>
        <title>Time Sniper</title>
        <React.StrictMode>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="app/*" element={<App />} />
            </Routes>
          </BrowserRouter>
          <Container />
        </React.StrictMode>
      </HelmetProvider>
    </AuthProvider>
  </NetworkProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Network } from './ApiClient';
import DOMPurify from 'dompurify';
export function numberWithCommas(x: number | string | undefined | null) {
  if (x === undefined || x === null) return '-';

  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const isMp4 = (url: string) => {
  return url && url.match(/\.(mp4)$/) != null;
};
export function userImage(image?: string): string {
  if (!image) return `https://time-sniper.com/time.png`;

  return image;
}

export function secondsToDhms(seconds: number) {
  seconds = Number(seconds);
  var d = Math.floor(seconds / (3600 * 24));
  var h = Math.floor((seconds % (3600 * 24)) / 3600);
  var m = Math.floor((seconds % 3600) / 60);

  var dDisplay = d > 0 ? d + 'd' : '';
  var hDisplay = h > 0 ? h + 'h' : '';
  var mDisplay = m > 0 ? m + 'm' : '';
  var sDisplay = '';
  return [dDisplay, hDisplay, mDisplay, sDisplay].join(' ');
}

export function dateToDMY(dateString: string): string {
  const date = new Date(dateString);
  const day = date.getDate(); // Get the day as a number (1-31)
  const month = date.toLocaleString('default', { month: 'short' }); // Get the full month name
  const year = date.getFullYear(); // Get the four digit year

  return `${day} ${month} ${year}`;
}

export function collectionImage(network: Network, image: string) {
  if (!image || image.trim() === '') return '/nav.png';

  switch (network) {
    case 'CRONOS':
      return image;
    case 'POLYGON':
      return image;
  }
}

export const convertLinksToHtml = (text: string) => {
  const regex = /\[([^[]+)\]\(([^)]+)\)/g;
  return DOMPurify.sanitize(
    text.replace(regex, '<a href="$2" class="externalDescUrl">$1</a>'),
    {
      ALLOWED_TAGS: ['a'],
    },
  );
};

export const BaseSvg = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 2500 2500"
    style={{ enableBackground: 'new 0 0 2500 2500' }}
    xmlSpace="preserve"
    {...props}
  >
    <g id="Layer_x0020_1">
      <path d="M1247.8,2500c691.6,0,1252.2-559.6,1252.2-1250C2500,559.6,1939.4,0,1247.8,0C591.7,0,53.5,503.8,0,1144.9h1655.1v210.2H0   C53.5,1996.2,591.7,2500,1247.8,2500z"></path>
    </g>
  </svg>
);

export const PolygonSvg = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 38.4 33.5"
    style={{
      enableBackground: 'new 0 0 38.4 33.5',
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path
      d="M29 10.2c-.7-.4-1.6-.4-2.4 0L21 13.5l-3.8 2.1-5.5 3.3c-.7.4-1.6.4-2.4 0L5 16.3c-.7-.4-1.2-1.2-1.2-2.1v-5c0-.8.4-1.6 1.2-2.1l4.3-2.5c.7-.4 1.6-.4 2.4 0L16 7.2c.7.4 1.2 1.2 1.2 2.1v3.3l3.8-2.2V7c0-.8-.4-1.6-1.2-2.1l-8-4.7c-.7-.4-1.6-.4-2.4 0L1.2 5C.4 5.4 0 6.2 0 7v9.4c0 .8.4 1.6 1.2 2.1l8.1 4.7c.7.4 1.6.4 2.4 0l5.5-3.2 3.8-2.2 5.5-3.2c.7-.4 1.6-.4 2.4 0l4.3 2.5c.7.4 1.2 1.2 1.2 2.1v5c0 .8-.4 1.6-1.2 2.1L29 28.8c-.7.4-1.6.4-2.4 0l-4.3-2.5c-.7-.4-1.2-1.2-1.2-2.1V21l-3.8 2.2v3.3c0 .8.4 1.6 1.2 2.1l8.1 4.7c.7.4 1.6.4 2.4 0l8.1-4.7c.7-.4 1.2-1.2 1.2-2.1V17c0-.8-.4-1.6-1.2-2.1L29 10.2z"
      style={{
        fill: '#8247e5',
      }}
    />
  </svg>
);

export const CronosSvg = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    x={0}
    y={0}
    viewBox="0 0 120.1 138.8"
    style={{
      enableBackground: 'new 0 0 120.1 138.8',
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.st0{fill:#002d74}'}</style>
    <path
      className="st0"
      d="M60.1 0 0 34.7v69.4l60.1 34.7 60-34.7V34.7L60.1 0zm42.2 93.8L60 118.2 17.8 93.8V45l42.3-24.4L102.3 45v48.8z"
    />
    <linearGradient
      id="SVGID_1_"
      gradientUnits="userSpaceOnUse"
      x1={90.117}
      y1={1.087}
      x2={90.117}
      y2={70.488}
      gradientTransform="matrix(1 0 0 -1 0 139.89)"
    >
      <stop
        offset={0}
        style={{
          stopColor: '#002d74',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#002d74',
          stopOpacity: 0,
        }}
      />
    </linearGradient>
    <path
      d="m60.1 138.8 60-34.7V34.7L60.1 0v20.6L102.3 45v48.9L60 118.3v20.5z"
      style={{
        fill: 'url(#SVGID_1_)',
      }}
    />
    <linearGradient
      id="SVGID_00000034064843000503835000000005854543727190432132_"
      gradientUnits="userSpaceOnUse"
      x1={30.023}
      y1={139.89}
      x2={30.023}
      y2={70.488}
      gradientTransform="matrix(1 0 0 -1 0 139.89)"
    >
      <stop
        offset={0}
        style={{
          stopColor: '#002d74',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#002d74',
          stopOpacity: 0,
        }}
      />
    </linearGradient>
    <path
      style={{
        fill: 'url(#SVGID_00000034064843000503835000000005854543727190432132_)',
      }}
      d="M60 0 0 34.7v69.4l60 34.7v-20.6L17.8 93.8V44.9L60 20.6V0z"
    />
    <path
      className="st0"
      d="m88.1 85.6-28 16.2L32 85.6V53.2L60.1 37l28 16.2L76.4 60 60 50.5 43.7 60v18.9l16.4 9.5 16.4-9.5 11.6 6.7z"
    />
  </svg>
);

export const getCurrencySymbol = (network: string) => {
  switch (network) {
    case 'POLYGON':
      return 'MATIC';
    case 'CRONOS':
      return 'CRO';
  }
};

// Function to parse a float number to short form with K, M, B, T, etc.
export const parseNumberToShortForm = (num: number, fixed: number = 1) => {
  if (num < 1000) return num;
  const sizes = ['', 'K', 'M', 'B', 'T', 'Q'];
  const i = Math.floor(Math.log(num) / Math.log(1000));
  return parseFloat((num / Math.pow(1000, i)).toFixed(fixed)) + sizes[i];
};

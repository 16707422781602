// wallet-metamask.ts

// Injected wallet
// Works with MetaMask in browser or in in-app browser

import { ethers } from 'ethers'; // npm install ethers

import { IWallet } from '../../contexts/AuthContext';
import { defaultWallet } from '../utils';
import * as utils from '../utils';
import { Connector, ConnectorCallbacks } from './Connector';

export class MetamaskConnector implements Connector {
  isAvailable(): boolean {
    return (
      typeof window !== 'undefined' &&
      window.ethereum &&
      window.ethereum.isMetaMask
    );
  }
  disconnect(callback: () => void = () => {}): void {
    window.ethereum.removeAllListeners();
    window.ethereum.disconnect();
    callback();
  }

  async connect(callbacks?: ConnectorCallbacks): Promise<IWallet> {
    if (!this.isAvailable()) {
      throw new Error('This browser does not support this wallet.');
    }
    try {
      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts',
      });

      if (callbacks) {
        window.ethereum.on('chainChanged', callbacks.chainChanged);
        window.ethereum.on('accountsChanged', callbacks.accountsChanged);
        window.ethereum.on('disconnect', this.disconnect(callbacks.disconnect));
      }

      return {
        ...defaultWallet,
        walletProviderName: 'metamask',
        address: ethers.utils.getAddress(accounts[0]),
        browserWeb3Provider: new ethers.providers.Web3Provider(window.ethereum),
        // serverWeb3Provider: new ethers.providers.JsonRpcProvider(
        //   config.configVars.rpcNetwork.rpcUrl,
        // ),
        connected: true,
        chainId: utils.hexToInt(
          await window.ethereum.request({ method: 'eth_chainId' }),
        ),
      };
    } catch (e: any) {
      if (e.code) {
        switch (e.code) {
          case 4001:
            throw new Error('Connect request canceled.');
          case -32002:
            throw new Error(
              'Connect request pending, open Metamask to connect or cancel',
            );
          default:
            console.log(e);
        }
      }
      console.log(e);
      throw new Error('Unknown error.');
    }
  }

  // One feature of MetaMask is that the Dapp developer
  // can programmatically
  // change the network that the browser
  // extension is connected to.
  // This feature is implemented below,
  // to automatically set - up Cronos
  async switchNetwork() {
    // try {
    //   await window.ethereum.request({
    //     method: 'wallet_switchEthereumChain',
    //     params: [{ chainId: config.configVars.rpcNetwork.chainIdHex }],
    //   });
    //   console.log(
    //     'Switched to network:',
    //     config.configVars.rpcNetwork.chainName,
    //   );
    // } catch (e) {
    //   console.log(e);
    //   await window.ethereum.request({
    //     method: 'wallet_addEthereumChain',
    //     params: [
    //       {
    //         chainId: config.configVars.rpcNetwork.chainIdHex,
    //         chainName: config.configVars.rpcNetwork.chainName,
    //         rpcUrls: [config.configVars.rpcNetwork.rpcUrl],
    //         nativeCurrency: config.configVars.rpcNetwork.nativeCurrency,
    //         blockExplorerUrls: [config.configVars.rpcNetwork.blockExplorerUrl],
    //       },
    //     ],
    //   });
    // }
  }
}

export default new MetamaskConnector();

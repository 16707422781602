import { useState, useEffect } from 'react';
import { Token } from '../../../../utils/ApiClient';
import { useNavigate } from 'react-router-dom';
// import { TraitList } from '../trait/TraitList';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PlaceholderLoading from 'react-placeholder-loading';
import { isMp4 } from '../../../../utils/parser';
import _ from 'lodash';

interface Props {
  token: any;
}

export function MyTokenCard({ token }: Props) {
  const navigate = useNavigate();

  const click = async () => {
    navigate(`/app/collection/${token.collectionAddress}/${token.tokenId}`);
  };

  const [imgSrcUrl, setImgSrcUrl] = useState(
    token.image?.trim() === '' ? '/nav.png' : token.image,
  );

  return (
    <a
      href="#"
      onClick={click}
      key={token.id}
      className="focus:outline-none rounded-lg dark:bg-zinc-800 border border-gray-100 dark:border-zinc-600 bg-white px-6 py-5 shadow-sm hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
    >
      <div className="flex-shrink-0">
        {isMp4(imgSrcUrl) ? (
          // Render video tag for MP4
          <video
            width="250"
            height="250"
            controls
            className="w-auto rounded-lg"
          >
            <source src={imgSrcUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          // Render LazyLoadImage for images
          <LazyLoadImage
            className="text-center rounded-lg"
            src={imgSrcUrl}
            onError={() => {
              setImgSrcUrl('/nav.png');
            }}
            width={250}
            height={250}
            placeholder={
              <PlaceholderLoading
                shape="rect"
                width={250}
                height={250}
                colorStart="transparent"
                colorEnd="#9CA3AF"
              />
            }
            alt={token.name}
          />
        )}
      </div>
      <div className="my-5">
        <h2 className="text-gray-700 dark:text-gray-200 font-bold">
          {token.name}
        </h2>
        {token.ERC1155Balance?.length > 0 && (
          <p className=" text-xs px-2 py-1 bg-purple-700 rounded-lg text-white w-fit">
            {_.sum(token.ERC1155Balance.map((b: any) => b.balance))} Owned
          </p>
        )}
      </div>
      <div className="">
        {Number(token.pListPrice) ? (
          <div className="grid grid-cols-2">
            <p className="text-gray-400 dark:text-gray-300">Best List Price</p>
            <p className="text-gray-400 dark:text-gray-300 text-right">
              {Number(token.pListPrice)
                ? `${Number(token.pListPrice).toFixed(0)} ${
                    token.TokenStats?.find(
                      (t: any) => t.listPrice === token.pListPrice,
                    )?.originalCurrency || ''
                  } ${token.pListPriceCurrency}`
                : '-'}
            </p>
          </div>
        ) : (
          <></>
        )}
        {/* <div className="grid grid-cols-2">
          <p className="text-gray-400 dark:text-gray-300">Best Floor Price</p>
          <p className="text-gray-400 dark:text-gray-300 text-right">
            {Number(token.collection.pFloor).toFixed(2)}{' '}
            {token.collection.network === 'POLYGON' ? 'MATIC' : 'CRO'}
          </p>
        </div> */}
        {Number(token.rank) ? (
          <div className="grid grid-cols-2">
            <p className="text-gray-400 dark:text-gray-300">Rarity Rank</p>
            <p className="text-gray-400 dark:text-gray-300 text-right">
              {Number(token.rank) ? token.rank : '-'}
            </p>
          </div>
        ) : null}
      </div>
    </a>
  );
}

export default function TTTInput({
  value,
  onChange,
  max,
}: {
  value: any;
  onChange: any;
  max?: any;
}) {
  return (
    <div>
      {/* <label htmlFor="price" className="block text-sm font-medium text-gray-700">
          Price
        </label> */}
      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          type="number"
          name="price"
          id="price"
          value={value}
          onBlur={() => {
            if (!value) return;

            onChange(parseFloat(value || 0).toFixed(2));
          }}
          onChange={event => {
            onChange(event.target.value);
          }}
          max={max}
          min={0}
          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full  pr-12 sm:text-sm border-gray-300 dark:border-zinc-600 dark:bg-zinc-900 dark:text-gray-300 rounded-md"
          placeholder="0.00"
          aria-describedby="price-currency"
        />
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <span
            className="text-gray-500 sm:text-sm dark:border-zinc-600 dark:bg-zinc-900 dark:text-gray-300"
            id="price-currency"
          >
            $TTT
          </span>
        </div>
      </div>
    </div>
  );
}

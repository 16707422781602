import React from 'react';
import { useAuth } from '../hooks/useAuth';
import App from './App';
import Home from './Home';

function Container() {
  let auth = useAuth();
  return (
    <>
      {/* {auth.isAuthenticated ? (
        <App>
          
        </App>
      ) : (
        <Home />
      )} */}
    </>
  );
}

export default Container;

import PercInput from '../components/inputs/PercInput';
import CurrencyInput from '../components/inputs/CurrencyInput';
import UsdInput from '../components/inputs/UsdInput';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ApiClient, { Token } from '../../../utils/ApiClient';

export default function CreateTokenAlert() {
  const [value, setValue] = useState(0);
  const [croValue, setCroValue] = useState(0);
  const [usdValue, setUsdValue] = useState(0);
  const { collectionAddress, tokenId } = useParams();

  const [token, setToken] = useState<Token>();

  useEffect(() => {
    if (!collectionAddress || !tokenId) return;
    ApiClient.getToken(collectionAddress, tokenId).then(data => {
      if (data.token.pListPrice) {
        setCroValue(Number(data.token.pListPrice));
        setUsdValue(
          Math.round(
            (data.token.collection.pFloorUsd / data.token.collection.pFloor) *
              Number(data.token.pListPrice) *
              100,
          ) / 100,
        );
      }
      setToken(data.token);
    });
  }, []);

  const percChange = (event: any) => {
    setCroValue(0);
    setUsdValue(0);
    setValue(parseInt(event.target.value));
  };
  const croChange = (event: any) => {
    if (token == undefined) return;
    setCroValue(event.target.value);
    setUsdValue(
      Math.round(
        (token.collection.pFloorUsd / token.collection.pFloor) *
          Number(event.target.value) *
          100,
      ) / 100,
    );
  };
  const usdChange = (event: any) => {
    if (token == undefined) return;
    setUsdValue(event.target.value);
    setCroValue(
      Number(event.target.value) /
        (token.collection.pFloorUsd / token.collection.pFloor),
    );
  };
  const navigate = useNavigate();
  const setAlert = (a: any) => {
    if (token === undefined || collectionAddress === undefined) return;
    ApiClient.addNftAlert(
      token.id,
      collectionAddress,
      Math.round(
        croValue
          ? Number(croValue)
          : Number((token.collection.pFloor / 100) * value) +
              Number(token.collection.pFloor),
      ),
      Number(token.pListPrice ? token.pListPrice : token.collection.pFloor),
    )
      .then(() => {
        navigate('/app/smart-alerts');
      })
      .catch((err: any) => {
        console.error(err);
      });
  };
  if (token === undefined) return <div></div>;
  return (
    <>
      <div className="bg-white dark:bg-zinc-800 shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
            Create Alert
          </h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500 dark:text-gray-300">
            <p className="dark:text-gray-300">
              Set Price Alert for {token.collection.name} #{token.tokenId}.
            </p>
          </div>
          <div className="dark:text-gray-300 dark:text-gray-300">
            Current floor: {token.collection.pFloor} CRO -{' '}
            {token.collection.pFloorUsd.toFixed(2)} USD
          </div>
          {token.pListPrice && (
            <div className="dark:text-gray-300">
              Current best list price: {token.pListPrice} CRO
            </div>
          )}
          <form className="mt-5 sm:flex sm:items-center">
            <div className="w-full sm:max-w-xs">
              <label htmlFor="email" className="sr-only">
                Price
              </label>

              <PercInput onChange={percChange} value={value}></PercInput>
              <CurrencyInput
                onChange={croChange}
                value={
                  croValue
                    ? Number(croValue)
                    : Number((token.collection.pFloor / 100) * value) +
                      Number(token.collection.pFloor)
                }
                currency={token.collection.network}
              ></CurrencyInput>
              <UsdInput
                onChange={usdChange}
                value={
                  usdValue
                    ? Number(usdValue)
                    : (
                        token.collection.pFloorUsd +
                        (token.collection.pFloorUsd / 100) * value
                      ).toFixed(2)
                }
              ></UsdInput>
            </div>
          </form>
          <button
            type="button"
            onClick={e => {
              setAlert(e);
            }}
            className="my-6 px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Set Alert
          </button>
        </div>
      </div>
    </>
  );
}

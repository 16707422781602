export const configVars = {
  rpcNetwork_mainnet:
    process.env.ENV === 'production' ||
    process.env.REACT_APP_ENV === 'production'
      ? {
          rpcUrl: 'https://rpc.vvs.finance',
          chainId: 25,
          chainIdHex: '0x19',
          chainName: 'Cronos Mainnet Beta',
          chainType: 'mainnet',
          nativeCurrency: {
            name: 'CRO',
            symbol: 'CRO',
            decimals: 18,
          },
          blockExplorerUrl: 'https://cronoscan.com/',
        }
      : {
          rpcUrl: 'https://cronos-testnet.drpc.org',
          chainId: 338,
          chainIdHex: '0x152',
          chainName: 'Cronos Testnet',
          chainType: 'testnet',
          nativeCurrency: {
            name: 'TCRO',
            symbol: 'TCRO',
            decimals: 18,
          },
          blockExplorerUrl: 'https://cronos.org/explorer/testnet3',
        },
  polygon:
    process.env.ENV === 'production' ||
    process.env.REACT_APP_ENV === 'production'
      ? {
          chainId: 137, // 137 mainnet
          chainIdHex: '0x89', // 0x89 mainnet
          rpcUrl: 'https://polygon-rpc.com', // https://polygon-rpc.com mainnnet
        }
      : {
          chainId: 80001, // 137 mainnet
          chainIdHex: '0x13881', // 0x89 mainnet
          rpcUrl: 'https://rpc-mumbai.maticvigil.com', // https://polygon-rpc.com mainnnet
        },
  base: {
    chainId: 8453, // 137 mainnet
    chainIdHex: '0x2105', // 0x89 mainnet
    rpcUrl: 'https://mainnet.base.org', // https://polygon-rpc.com mainnnet
  },
};

export const parsePlatform = (
  platform: string,
): {
  name: string;
  icon: string;
  link: string;
  collectionPrefix: {
    POLYGON?: (address: string) => string;
    CRONOS?: (address: string) => string;
  };
  tokenPrefix: {
    POLYGON?: (address: string, tokenId: string | number) => string;
    CRONOS?: (address: string, tokenId: string | number) => string;
  };
} => {
  switch (platform) {
    case 'MOONFLOW':
      return {
        name: 'Time Launcher',
        link: 'https://time-launcher.com',
        icon: '/platforms/timelauncher.png',
        collectionPrefix: {
          POLYGON: address =>
            `https://time-launcher.com/collection/polygon/${address}`,
          CRONOS: address =>
            `https://time-launcher.com/collection/cronos/${address}`,
        },
        tokenPrefix: {
          POLYGON: (address, tokenId) =>
            `https://time-launcher.com/collection/polygon/${address}/nft/${tokenId}`,
          CRONOS: (address, tokenId) =>
            `https://time-launcher.com/collection/cronos/${address}/nft/${tokenId}`,
        },
      };
    case 'OPENSEA':
      return {
        name: 'OpenSea',
        link: 'https://opensea.io',
        icon: '/platforms/opensea.svg',
        collectionPrefix: {
          POLYGON: (address: string) =>
            `https://opensea.io/assets/matic/${address}`,
        },
        tokenPrefix: {
          POLYGON: (address, tokenId) =>
            `https://opensea.io/assets/matic/${address}/${tokenId}`,
        },
      };
    case 'EBISUS':
      return {
        name: "Ebisu's Bay",
        link: 'https://ebisusbay.com',
        icon: '/platforms/ebisus.svg',
        collectionPrefix: {
          CRONOS: (address: string) =>
            `https://app.ebisusbay.com/collection/${address}`,
        },
        tokenPrefix: {
          CRONOS: (address, tokenId) =>
            `https://app.ebisusbay.com/collection/${address}/${tokenId}`,
        },
      };
    default:
      throw Error('Invalid platform');
  }
};

export type ERC20ContractKey = 'cro';

export const ERC20Tokens: Record<
  ERC20ContractKey,
  { contract: string } | undefined
> = {
  cro: {
    contract: '0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b',
  },
};

export type CRC20ContractKey = 'ttt' | 'emit';

export const CRC20Tokens: Record<
  CRC20ContractKey,
  { contract: string } | undefined
> = {
  ttt: {
    contract: '0x16a6b79fe8dbe95fa9518c0e16672f37238a6cc6',
  },
  emit: {
    contract: '0x9FA6552c1E9df51070A3b456355B5d76cBD59B5A',
  },
};

export type PoolStakingContractKeys = 'ttt';
export const poolStakingContracts: Record<
  PoolStakingContractKeys,
  { name: string } | undefined
> = {
  ttt: {
    name: '$TTT',
  },
};

export type SoftStakingContractKeys =
  | 'a102s'
  | 'atg'
  | 'guardians'
  | 'ag'
  | 'unknown'; // Add more keys as needed

export const softStakingContracts: Record<
  SoftStakingContractKeys,
  | {
      name: string;
      contract: string;
      image: string;
      tokens: { name: string; contract: string }[];
    }
  | undefined
> = {
  // '101s': {
  //   name: '101s',
  //   contract: '0x9b72F7d5AecEE857CC3d1a1A3357AfF0e342d0a8',
  //   tokens: [
  //     { name: 'WCRO', contract: '0x5c7f8a570d578ed84e63fdfa7b1ee72deae1ae23' },
  //   ],
  //   image:
  //     'https://cdn-prod.ebisusbay.com/img/collections/time-machine-101/avatar.webp',
  // },
  // '102s': {
  //   name: '102s',
  //   contract: '0x482eBe3063ED2d2281AAfec31941705787432A1A',
  //   tokens: [
  //     { name: 'WCRO', contract: '0x5c7f8a570d578ed84e63fdfa7b1ee72deae1ae23' },
  //   ],
  //   image:
  //     'https://cdn-prod.ebisusbay.com/img/collections/time-machine-102-the-travellers/avatar.webp',
  // },
  a102s: {
    name: 'Animated 102s',
    contract: '0xCF926cB69b860Da8c33a2a601217856536ed0bcA',
    tokens: [
      { name: 'TTT', contract: '0x16A6b79fE8DBE95fA9518c0E16672F37238a6Cc6' },
      { name: 'WCRO', contract: '0x5c7f8a570d578ed84e63fdfa7b1ee72deae1ae23' },
    ],
    image:
      'https://cdn-prod.ebisusbay.com/img/collections/animated-time-machine-102/avatar.webp',
  },
  atg: {
    name: 'Animated Time Guardians',
    contract: '0x6cC790CAE45EC057f9637784279A475CC91cf517',
    tokens: [
      { name: 'TTT', contract: '0x16A6b79fE8DBE95fA9518c0E16672F37238a6Cc6' },
      { name: 'WCRO', contract: '0x5c7f8a570d578ed84e63fdfa7b1ee72deae1ae23' },
    ],
    image:
      'https://cdn-prod.ebisusbay.com/img/collections/animated-time-gods/avatar.webp?width=150&height=150',
  },
  ag: {
    name: 'Animated Guardians',
    contract: '0xb76415D33268391bfaE2E671f45CeDAD9bC99dc9',
    tokens: [
      { name: 'TTT', contract: '0x16A6b79fE8DBE95fA9518c0E16672F37238a6Cc6' },
      { name: 'WCRO', contract: '0x5c7f8a570d578ed84e63fdfa7b1ee72deae1ae23' },
    ],
    image:
      'https://cdn-prod.ebisusbay.com/files/collection-images/animated-guardians/avatar.gif?width=150&height=150',
  },
  // creaturea: {
  //   name: 'Creature A',
  //   contract: '0x1c4924f36Fe60374DB6ac206894D82F03d522933',
  //   tokens: [
  //     { name: 'TTT', contract: '0x16A6b79fE8DBE95fA9518c0E16672F37238a6Cc6' },
  //   ],
  //   image:
  //     'https://cdn-prod.ebisusbay.com/img/collections/time-creatures-class-a/avatar.webp',
  // },
  // creatureb: {
  //   name: 'Creature B',
  //   contract: '0x15Af2503a934D211A1936684fEd986E7E3f417db',
  //   tokens: [
  //     { name: 'TTT', contract: '0x16A6b79fE8DBE95fA9518c0E16672F37238a6Cc6' },
  //   ],
  //   image:
  //     'https://cdn-prod.ebisusbay.com/img/collections/time-creatures-class-b/avatar.webp',
  // },
  guardians: {
    name: 'Guardians',
    contract: '0x146cf0ee7b6b986769782ea832e8327f9dff55e7',
    tokens: [
      { name: 'TTT', contract: '0x16A6b79fE8DBE95fA9518c0E16672F37238a6Cc6' },
      { name: 'WCRO', contract: '0x5c7f8a570d578ed84e63fdfa7b1ee72deae1ae23' },
    ],
    image:
      'https://cdn-prod.ebisusbay.com/img/collections/tatas-guardians/avatar.webp',
  },
  // planets: {
  //   name: 'Time Planets',
  //   contract: '0xb049edd7e9580c0bbe708c1a3374c74066ff9e7f',
  //   tokens: [
  //     {
  //       name: 'STARDUST',
  //       contract: '0xfb89081373b66194d873EdcdBd0bbA9A53f0092d',
  //     },
  //     {
  //       name: 'DM',
  //       contract: '0xeABE63977aFf1a97F462E0C0D006aF341Ad9ea7b',
  //     },
  //     {
  //       name: 'TITANIUM',
  //       contract: '0xeABE63977aFf1a97F462E0C0D006aF341Ad9ea7b',
  //     },
  //     {
  //       name: 'ABYSS2',
  //       contract: '0xeABE63977aFf1a97F462E0C0D006aF341Ad9ea7b',
  //     },
  //   ],
  //   image:
  //     'https://cdn-prod.ebisusbay.com/img/collections/time-cronos-planets/avatar.webp',
  // },
  // tatatonics: {
  //   name: 'Tatas Twisted Tonics',
  //   contract: '0x739b5380156Fa14C7956145048d52dF27EDedfCE',
  //   tokens: [
  //     { name: 'TTT', contract: '0x16A6b79fE8DBE95fA9518c0E16672F37238a6Cc6' },
  //   ],
  //   image:
  //     'https://cdn-prod.ebisusbay.com/img/collections/tatas-twisted-tonics/avatar.webp',
  // },
  unknown: undefined,
};

import PlaceholderLoading from 'react-placeholder-loading';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useState } from 'react';
import './RaffleCard.css';
import { BigNumber, Contract, ethers } from 'ethers';
import { configVars } from '../../../../web3/config';
import RafflesABI from '../../../../web3/ABI/contracts/raffles.abi.json';
import {
  ensureBase,
  ensureCronos,
  ensurePolygon,
  txWait,
} from '../../../../web3/utils';
import { useAuth } from '../../../../hooks/useAuth';

export function RaffleCard({ raffle, contracts, reloadRaffles }: any) {
  const [isHovered, setIsHovered] = useState(false);
  const [amount, setAmount] = useState(1);
  const [loadingPurchase, setLoadingPurchase] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const { activeWallet } = useAuth();
  async function onPurchase() {
    if (amount <= 0 || amount > Number(raffle.ticketsLeft)) {
      alert('Invalid amount entered');
      return;
    }
    setError('');
    setSuccess('');
    setLoadingPurchase(true);

    function getSigner() {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      return signer;
    }

    const value = BigNumber.from(amount).mul(raffle.price);
    switch (raffle.network) {
      case 'polygon':
        await ensurePolygon();
        const polySigner = getSigner();
        const polygonContract = new Contract(
          contracts.polygon,
          RafflesABI,
          polySigner,
        );

        const tx = await txWait(
          polygonContract.joinRaffle(raffle.raffleId, amount, { value }),
        ).catch(e => {
          setError(e.data.message);
          console.log(e);
          setLoadingPurchase(false);
          throw e;
        });

        if (tx) {
          setSuccess(`Bought ${amount} tickets!`);
          reloadRaffles();
        }
        break;
      case 'base':
        await ensureBase();
        const baseSigner = getSigner();
        const baseContract = new Contract(
          contracts.base,
          RafflesABI,
          baseSigner,
        );

        const tx2 = await txWait(
          baseContract.joinRaffle(raffle.raffleId, amount, { value }),
        ).catch(e => {
          console.log(e);
          setError(e.data.message);
          setLoadingPurchase(false);
          throw e;
        });

        if (tx2) {
          setSuccess(`Bought ${amount} tickets!`);
          reloadRaffles();
        }
        break;
      case 'cronos':
        await ensureCronos();
        const cronosSigner = getSigner();
        const cronosContract = new Contract(
          contracts.cronos,
          RafflesABI,
          cronosSigner,
        );

        const tx3 = await txWait(
          cronosContract.joinRaffle(raffle.raffleId, amount, { value }),
        ).catch(e => {
          setError(e.data.message);
          setLoadingPurchase(false);
          throw e;
        });

        if (tx3) {
          setSuccess(`Bought ${amount} tickets!`);
          reloadRaffles();
        }
        break;
    }

    setLoadingPurchase(false);
  }
  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="min-w-[100px] max-w-[350px] flex flex-col w-full relative cursor-pointer rounded-t-lg rounded-b-lg dark:border-zinc-600 bg-white dark:bg-zinc-800 shadow-sm hover:border-gray-400 bg-white border-x border-b dark:border-x-zinc-700 dark:border-b-zinc-700 dark:bg-zinc-900 rounded-b-lg"
    >
      <div
        className=" bg-image-container bg-zinc-300 w-full overflow-hidden rounded-t-md flex justify-center items-center"
        onClick={e => {}}
      >
        <LazyLoadImage
          className={`z-[2] 
            object-cover w-full`}
          src={raffle.image}
          placeholder={
            <PlaceholderLoading
              shape="rect"
              width={300}
              height={300}
              colorStart="transparent"
              colorEnd="#9CA3AF"
            />
          }
          alt={raffle.title}
        />
      </div>
      <div className="pt-4 p-5 ">
        <h2 className="text-md inline-block font-bold text-gray-900 flex">
          <span className="inline-block mr-1 dark:text-white">
            {raffle.title}
          </span>
        </h2>
        <div className="flex flex-col gap-y-2 justify-between">
          <div className="mt-4 text-gray-900 dark:text-white font-semibold flex flex-row justify-between">
            <span>Ticket Price:</span>
            <span>
              {ethers.utils.formatUnits(raffle.price.toString(), 18)}{' '}
              {raffle.networkId === 1
                ? 'CRO'
                : raffle.networkId === 5
                ? 'MATIC'
                : 'BASE'}
            </span>
          </div>
          <div className="text-gray-900 dark:text-white font-semibold flex flex-row justify-between">
            <span>Remaining:</span>
            <span>{raffle.ticketsLeft || 'Sold Out'}</span>
          </div>
          {raffle.rank && (
            <div className="text-gray-900 dark:text-white font-semibold flex flex-row justify-between">
              <span>Rank</span>

              <span className="rounded-lg  text-sm py-[4px] px-3 text-[#fff] dark:text-white bg-purple-500">
                Rank {raffle.rank.toString()}
              </span>
            </div>
          )}
        </div>
        <div className="flex-col justify-center mt-6">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Tickets
          </label>
          <input
            type="range"
            value={amount}
            onChange={e => {
              setAmount(Number(e.target.value));
            }}
            min={1}
            max={raffle.ticketsLeft}
            className="w-full h-2 bg-gray-100 rounded-lg appearance-none cursor-pointer dark:bg-gray-600"
          />

          <div className="flex justify-center mt-4">
            <button
              onClick={onPurchase}
              disabled={loadingPurchase}
              className={`relative bg-blue-500 disabled:opacity-75 dark:bg-blue-600 px-14 py-3 hover:bg-blue-600 dark:hover:bg-blue-700 text-gray-100 inline-flex items-center px-4 py-2 rounded-lg text-sm font-medium focus:z-10 focus:outline-none`}
            >
              Buy {amount} Ticket{amount > 1 ? 's' : ''}
            </button>
          </div>
          {raffle.players &&
            activeWallet &&
            raffle.players.includes(activeWallet) && (
              <div className="text-gray-900 text-sm dark:text-white font-semibold justify-center flex mt-2">
                <span>
                  My Tickets:{' '}
                  {
                    raffle.players.filter(
                      (p: string) =>
                        p.toLowerCase() === activeWallet.toLowerCase(),
                    ).length
                  }
                </span>
              </div>
            )}
          {error !== '' ? (
            <div className="mt-4 flex justify-center text-center text-sm text-white p-2 bg-[red]">
              {error}
            </div>
          ) : null}
          {success !== '' ? (
            <div className="mt-4 flex justify-center text-center text-sm text-white p-2 bg-[green]">
              {success}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

import { createContext } from 'react';

export interface NetworkContextProps {
  network: string;
  updateNetwork: (network: string) => void;
  tttUSDPrice: number;
  emitUSDPrice: number;
  updateTTTUSDPrice: (price: number) => void;
  updateEMITUSDPrice: (price: number) => void;
  showWalletConnect: boolean;
  setShowWalletConnect: React.Dispatch<React.SetStateAction<boolean>>;
}

const NetworkContext = createContext<NetworkContextProps>({
  network: 'CRONOS',
  tttUSDPrice: 0,
  emitUSDPrice: 0,
  showWalletConnect: false,
} as NetworkContextProps);

export default NetworkContext;

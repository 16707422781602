import { useState, useEffect } from 'react';
import ApiClient from '../../../utils/ApiClient';
import { SubscriptionList } from '../components/payment/SubscriptionList';
import { MySubscriptionList } from '../components/payment/MySubscriptionList';

const Payment = () => {
  const [activePackages, setActivePackages] = useState(1);

  return process.env.REACT_APP_PAYWALL_ENABLED === 'true' ? (
    <div>
      {activePackages > 0 && (
        <>
          <div className="mb-1 max-w-3xl grid grid-cols-1 gap-6  lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <h2 className="text-2xl font-semibold dark:text-white">
              Your Active Services
            </h2>
          </div>
          <p className="mb-4">View & manage your existing packages</p>
          <MySubscriptionList setActivePackages={setActivePackages} />
        </>
      )}

      <div className="mb-1 mt-10 max-w-3xl grid grid-cols-1 gap-6  lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <h2 className="text-2xl font-semibold dark:text-white">
          Time Sniper Packages
        </h2>
      </div>
      <p className="mb-6">
        Renew your access to Time Sniper or purchase addons for your existing
        services
      </p>

      <SubscriptionList />
    </div>
  ) : (
    <div>
      <div className="mb-1 max-w-3xl grid grid-cols-1 gap-6  lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <h2 className="text-2xl font-semibold dark:text-white">
          Your Active Services
        </h2>
      </div>
      <p className="mb-4">View & manage your existing packages</p>
      <MySubscriptionList setActivePackages={setActivePackages} />

      <div className="mt-8 text-center flex justify-center flex-col mt-8 mb-1  gap-2 lg:max-w-7xl ">
        <h2 className="text-2xl font-semibold dark:text-white">
          Time Sniper is currently free!
        </h2>
        <p className="mb-4">
          Enjoy the full benefits for the first 30-days of the Time Sniper
          opening
        </p>
      </div>
    </div>
  );
};
export default Payment;

import { useEffect, useState } from 'react';
import { useAuth } from '../../../../hooks/useAuth';
import ApiClient from '../../../../utils/ApiClient';
import AdminTabs from '../../components/admin/AdminTabs';
import { ethers } from 'ethers';
import { ensureCronos, ensurePolygon } from '../../../../web3/utils';
import DialogScreen from '../../components/DialogScreen';
import { Dialog } from '@headlessui/react';
import { configVars } from '../../../../web3/config';
import ERC20ABI from '../../../../web3/ABI/erc20.abi.json';
const acceptedChains = ['CRONOS', 'POLYGON'];
const acceptedTypes = ['ERC20', 'NATIVE'];

type PaymentWalletType = {
  id: string;
  address: string;
  acceptedTokens: AcceptedTokenType[];
  tag: string;
  active: boolean;
  isEVM: boolean;
};
type AcceptedTokenType = {
  chain: string;
  name: string;
  decimals: number;
  type: string;
  address?: string;
};

const WITHDRAW_WHITELIST = [
  '0x1d9e0BD4ed0C45B646E844b706D9Fe8224249D72',
  '0xbB4D8b7948Bd7c60698e7B45E410751F0bBF26b9',
  '0x4c91FD901d9D96DfBBefB3A60F8266F11dd19eAD',
];

export default function PaymentWallets() {
  const [wallets, setWallets] = useState<PaymentWalletType[]>([]);
  const [newWallet, setNewWallet] = useState({
    tag: '',
    isEVM: true,
    active: false,
  });
  const [newToken, setNewToken] = useState({
    name: '',
    type: acceptedTypes[0],
    address: '',
    decimals: 16,
    chain: 'CRONOS',
  });
  const [tokensToSubmit, setTokensToSubmit] = useState<AcceptedTokenType[]>([]);
  const [showValidate, setShowValidate] = useState(false);
  const [validateInfo, setValidateInfo] = useState<any>();

  useEffect(() => {
    ApiClient.admin_getPaymentWallets().then(r => {
      setWallets(r.wallets);
    });
  }, []);
  const handleNewWalletChange = (e: any) => {
    setNewWallet({ ...newWallet, [e.target.name]: e.target.value });
  };

  const handleEditWalletChange = (e: any) => {
    if (!managingWallet) return;

    setManagingWallet({ ...managingWallet, [e.target.name]: e.target.value });
  };

  const [managingWallet, setManagingWallet] = useState<
    PaymentWalletType | undefined
  >(undefined);
  const [showManageDialog, setShowManageDialog] = useState(false);
  const [tokensToEdit, setTokensToEdit] = useState<AcceptedTokenType[]>([]);

  const [showValidateEdit, setShowValidateEdit] = useState(false);
  const [validateInfoEdit, setValidateInfoEdit] = useState<any>();
  const [newTokenEdit, setNewTokenEdit] = useState({
    name: '',
    type: acceptedTypes[0],
    address: '',
    chain: '',
    decimals: 16,
  });

  const [showBalanceDialog, setShowBalanceDialog] = useState(false);
  const [balanceInfo, setBalanceInfo] = useState<any>();
  async function showBalance(wallet: PaymentWalletType) {
    if (!wallet) return;

    setShowBalanceDialog(true);

    let balanceMap: any = {
      id: wallet.id,
      address: wallet.address,
      wallet: wallet,
      chains: [],
    };
    for (let i = 0; i < wallet.acceptedTokens.length; i += 1) {
      const token = wallet.acceptedTokens[i];
      switch (token.chain) {
        case 'POLYGON':
          const polygonProvider = new ethers.providers.JsonRpcProvider(
            configVars.polygon.rpcUrl,
          );
          if (token.type === 'ERC20') {
            const erc20PolyContract = new ethers.Contract(
              token.address || '',
              ERC20ABI,
              polygonProvider,
            );

            const balance = await erc20PolyContract
              .balanceOf(wallet.address)
              .catch((e: any) => e);
            if (balance instanceof Error) {
              console.log(token);
              console.log(balance);
            }

            if (balance) {
              balanceMap.chains.push({
                chain: token.chain,
                name: token.name,
                balance:
                  balance instanceof Error
                    ? 'Error (Probably chain mismatch)'
                    : ethers.utils.formatUnits(
                        balance.toString(),
                        token.decimals,
                      ),
              });
            }
          } else if (token.type === 'NATIVE' || !token.type) {
            const balance = await polygonProvider
              .getBalance(wallet.address)
              .catch((e: any) => e);
            if (balance instanceof Error) {
              console.log(token);
              console.log(balance);
            }

            if (balance) {
              balanceMap.chains.push({
                chain: token.chain,
                name: token.name,
                balance:
                  balance instanceof Error
                    ? 'Error (Probably chain mismatch)'
                    : ethers.utils.formatUnits(
                        balance.toString(),
                        token.decimals,
                      ),
              });
            }
          }
          break;
        case 'CRONOS':
          const cronosProvider = new ethers.providers.JsonRpcProvider(
            configVars.rpcNetwork_mainnet.rpcUrl,
          );
          if (token.type === 'ERC20') {
            const erc20CronosContract = new ethers.Contract(
              token.address || '',
              ERC20ABI,
              cronosProvider,
            );

            const balance = await erc20CronosContract
              .balanceOf(wallet.address)
              .catch((e: any) => e);

            const decimals = await erc20CronosContract.decimals();
            const formattedBalance = ethers.utils.formatUnits(
              balance,
              decimals,
            );
            if (balance) {
              balanceMap.chains.push({
                chain: token.chain,
                name: token.name,
                balance: formattedBalance,
              });
            }
          } else if (token.type === 'NATIVE' || !token.type) {
            const balance = await cronosProvider
              .getBalance(wallet.address)
              .catch((e: any) => e);
            if (balance instanceof Error) {
              console.log(token);
              console.log(balance);
            }

            if (balance) {
              balanceMap.chains.push({
                chain: token.chain,
                name: token.name,
                balance:
                  balance instanceof Error
                    ? 'Error (Probably chain mismatch)'
                    : ethers.utils.formatUnits(
                        balance.toString(),
                        token.decimals,
                      ),
              });
            }
          }
          break;
      }
    }

    setBalanceInfo(balanceMap);
  }

  console.log(balanceInfo);
  function manageWallet(wallet: PaymentWalletType) {
    setManagingWallet(wallet);
    setTokensToEdit(wallet.acceptedTokens);
    setShowManageDialog(true);
  }

  const handleNewTokenChange = (e: any) => {
    setShowValidate(false);
    setValidateInfo(undefined);
    setNewToken({ ...newToken, [e.target.name]: e.target.value });
  };

  const handleNewTokenChangeEdit = (e: any) => {
    setShowValidateEdit(false);
    setValidateInfoEdit(undefined);
    setNewTokenEdit({ ...newTokenEdit, [e.target.name]: e.target.value });
  };

  const validateToken = async (token: any, edit: boolean = false) => {
    if (token.chain === 'CRONOS') {
      await ensureCronos();
    } else if (token.chain === 'POLYGON') {
      await ensurePolygon();
    }

    if (token.name === '' || token.name === undefined) {
      alert('Invalid token name');
      return;
    }

    if (
      token.type !== 'NATIVE' &&
      (token.address === undefined || token.address === '')
    ) {
      alert('Invalid contract address');
      return;
    }

    ApiClient.admin_validatePricing(
      token.chain,
      token.name,
      token.type,
      token.address,
    )
      .then(r => {
        if (!r || !r.tokenPrice) {
          alert(
            'Token price could not be found, ensure all details are correct.',
          );
          return;
        }
        if (edit) {
          setShowValidateEdit(true);
          setValidateInfoEdit(r);
          console.log('res:');

          console.log(r);
          if (r.decimals) {
            setNewTokenEdit({ ...newTokenEdit, decimals: r.decimals });
          }
        } else {
          setShowValidate(true);
          setValidateInfo(r);
          if (r.decimals) {
            setNewToken({ ...newToken, decimals: r.decimals });
          }
        }
      })
      .catch(e => {
        console.log(e);
        alert('Error adding token, ensure all details are correct.');
      });
  };

  const addToken = (e: any) => {
    e.preventDefault();
    if (!showValidate) {
      validateToken(newToken);
      return;
    }

    setShowValidate(false);
    setValidateInfo(undefined);

    setTokensToSubmit([...tokensToSubmit, newToken]);
    setNewToken({
      name: '',
      type: newToken.type,
      address: '',
      chain: 'CRONOS',
      decimals: 16,
    }); // Reset the token form
  };

  const addTokenEdit = (e: any) => {
    e.preventDefault();
    if (!showValidateEdit) {
      validateToken(newTokenEdit, true);
      return;
    }

    setShowValidateEdit(false);
    setValidateInfoEdit(undefined);

    setTokensToEdit([...tokensToEdit, newTokenEdit]);
    setNewTokenEdit({
      name: '',
      type: newTokenEdit.type,
      address: '',
      decimals: 16,
      chain: 'CRONOS',
    }); // Reset the token form
  };

  const removeToken = (tokenName: any, chain: any) => {
    setTokensToSubmit(
      tokensToSubmit.filter(
        token => token.name !== tokenName && token.chain !== chain,
      ),
    );
  };

  const removeTokenEdit = (tokenName: any, chain: any) => {
    setTokensToEdit(
      tokensToEdit.filter(
        token => token.name + token.chain !== tokenName + chain,
      ),
    );
  };

  const onSubmitNewWallet = (e: any) => {
    e.preventDefault();
    // Submit logic here
    const tokens = tokensToSubmit.map(t => {
      t.address = t.address === '' ? undefined : t.address;

      return t;
    });

    if (tokens.length === 0) {
      alert('Please add atleast 1 token');
      return;
    }

    if (!newWallet.tag) {
      alert('Invalid tag');
    }

    console.log('New Wallet Data:', newWallet, 'Tokens:', tokens);

    ApiClient.admin_createPaymentWallet(
      newWallet.tag,
      newWallet.isEVM,
      newWallet.active,
      tokens,
    )
      .then(r => {
        if (r.success) {
          alert('Added!');
          ApiClient.admin_getPaymentWallets().then(r => {
            setWallets(r.wallets);
          });
        }
      })
      .catch(e => {
        if (e.response?.data) {
          alert(e.response?.data.message);
        } else {
          alert(e.message);
        }
      });
    // Reset form after submission
  };

  function onSubmitEditWallet() {
    if (!managingWallet) return;
    const tokens = tokensToEdit.map(t => {
      t.address = t.address === '' ? undefined : t.address;

      return t;
    });

    if (tokens.length === 0) {
      alert('Please add atleast 1 token');
      return;
    }

    ApiClient.admin_editPaymentWallet(
      managingWallet?.id,
      managingWallet.tag,
      managingWallet.isEVM,
      managingWallet.active,
      tokens,
    )
      .then(r => {
        if (r.success) {
          alert('Edit successful!');
          ApiClient.admin_getPaymentWallets().then(r => {
            setWallets(r.wallets);
          });

          setShowManageDialog(false);
          setManagingWallet(undefined);
        }
      })
      .catch(e => {
        if (e.response?.data) {
          alert(e.response?.data.message);
        } else {
          alert(e.message);
        }
      });
  }

  const [withdrawWallet, setWithdrawWallet] = useState(WITHDRAW_WHITELIST[0]);
  const [loadingWithdraw, setLoadingWithdraw] = useState(false);

  return (
    <>
      <DialogScreen open={showBalanceDialog} setOpen={setShowBalanceDialog}>
        {balanceInfo && (
          <div>
            <div>
              <div className="mt-3 sm:mt-5">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-semibold leading-6 text-gray-900"
                >
                  Balances
                </Dialog.Title>

                <div className="mt-4">
                  <h2>Address: {balanceInfo.address}</h2>
                  <label
                    htmlFor="walletwithdraw"
                    className="mt-4 block text-sm font-medium text-gray-700"
                  >
                    Withdraw Wallet
                  </label>
                  <select
                    id="walletwithdraw"
                    name="walletwithdraw"
                    value={withdrawWallet}
                    onChange={t => {
                      setWithdrawWallet(t.target.value);
                    }}
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <option value="">Select a Chain</option>
                    {WITHDRAW_WHITELIST.map(wallet => (
                      <option key={wallet} value={wallet}>
                        {wallet}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mt-6">
                  {balanceInfo.chains.map((c: any, i: number) => {
                    return (
                      <div key={i}>
                        {c.name} ({c.chain}):{' '}
                        {c.balance instanceof Error
                          ? 'Error'
                          : c.balance.toString()}{' '}
                        <div
                          onClick={() => {
                            setLoadingWithdraw(true);
                            ApiClient.admin_withdrawWallet(
                              balanceInfo.id,
                              withdrawWallet,
                              c.name,
                              c.chain,
                            )
                              .then(r => {
                                if (r.success) {
                                  showBalance(balanceInfo.wallet);
                                  alert('Successful withdraw');
                                } else {
                                  alert(r.message);
                                }

                                setLoadingWithdraw(false);
                              })
                              .catch(e => {
                                console.log(e);
                                setLoadingWithdraw(false);
                                alert(e.response.data.message);
                              });
                          }}
                          className="text-purple-500 mb-4 hover:text-purple-400 cursor-pointer"
                        >
                          Withdraw
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="mt-5 sm:mt-6 flex flex-row gap-x-4">
              {loadingWithdraw ? (
                <div>Withdrawing, please wait...</div>
              ) : (
                <button
                  type="button"
                  onClick={() => {
                    setShowBalanceDialog(false);
                    setBalanceInfo(undefined);
                  }}
                  className="inline-flex w-full justify-center rounded-md bg-gray-600 disabled:opacity-50 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 disabled:hover:bg-gray-600"
                >
                  Close
                </button>
              )}
            </div>
          </div>
        )}
        {!balanceInfo && <p>Loading balances...</p>}
      </DialogScreen>
      <DialogScreen open={showManageDialog} setOpen={setShowManageDialog}>
        {managingWallet && (
          <div>
            <div>
              <div className="mt-3 sm:mt-5">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-semibold leading-6 text-gray-900"
                >
                  Manage Wallet
                </Dialog.Title>

                <h2>Address: {managingWallet.address}</h2>

                <div className="mt-4">
                  <label
                    htmlFor="editTag"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Tag
                  </label>
                  <input
                    type="text"
                    id="editTag"
                    name="tag"
                    value={managingWallet.tag}
                    onChange={handleEditWalletChange}
                    placeholder={'E.g. ACCESS, TIME_PRESALE'}
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <div className="mt-4 flex flex-row">
                  <label
                    htmlFor="tag"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Active
                  </label>
                  <input
                    type="checkbox"
                    id="active"
                    name="active"
                    checked={managingWallet.active}
                    onChange={e =>
                      setManagingWallet({
                        ...managingWallet,
                        active: e.target.checked,
                      })
                    }
                    className="mt-1 ml-2"
                  />
                </div>

                <div>
                  <h2 className="font-semibold mt-4">Tokens:</h2>
                  <div className="flex flex-wrap gap-2 mt-2 mb-4">
                    {tokensToEdit.map((token, index) => (
                      <span
                        key={index}
                        className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm flex items-center"
                      >
                        {token.name} ({token.chain})
                        <button
                          type="button"
                          onClick={() =>
                            removeTokenEdit(token.name, token.chain)
                          }
                          className="ml-2 text-blue-600 hover:text-blue-800"
                        >
                          &#10005;
                        </button>
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-8">
              <h3 className="text-lg font-medium text-gray-900">Add Tokens</h3>
              <p>Atleast one is required to create a new wallet</p>
              <div>
                <label
                  htmlFor="chain"
                  className="block text-sm font-medium text-gray-700"
                >
                  Chain
                </label>
                <select
                  id="chain"
                  name="chain"
                  value={newTokenEdit.chain}
                  onChange={handleNewTokenChangeEdit}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option value="">Select a Chain</option>
                  {acceptedChains.map(chain => (
                    <option key={chain} value={chain}>
                      {chain}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mt-2">
                <label
                  htmlFor="tokenName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Token Name
                </label>
                <input
                  type="text"
                  id="tokenName"
                  name="name"
                  value={newTokenEdit.name}
                  onChange={handleNewTokenChangeEdit}
                  placeholder={'E.g. USDC, TTT'}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div className="mt-2">
                <label
                  htmlFor="tokenType"
                  className="block text-sm font-medium text-gray-700"
                >
                  Token Type
                </label>
                <select
                  id="tokenType"
                  name="type"
                  value={newTokenEdit.type}
                  onChange={handleNewTokenChangeEdit}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  {acceptedTypes.map((t, index) => (
                    <option key={index} value={t}>
                      {t}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mt-2">
                <label
                  htmlFor="tokenAddress"
                  className="block text-sm font-medium text-gray-700"
                >
                  Token Contract Address
                </label>
                <input
                  type="text"
                  id="tokenAddress"
                  name="address"
                  value={
                    newTokenEdit.type === 'NATIVE' ? '' : newTokenEdit.address
                  }
                  onChange={handleNewTokenChangeEdit}
                  disabled={newTokenEdit.type === 'NATIVE'}
                  placeholder={'0x'}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              {showValidateEdit && (
                <div className="mt-4">
                  <p>
                    You are going to add the following token:{' '}
                    <b>
                      {newTokenEdit.type === 'NATIVE'
                        ? `${newTokenEdit.name} (NATIVE)`
                        : newTokenEdit.address
                        ? newTokenEdit.address
                        : `${newTokenEdit.name} (NATIVE)`}
                    </b>
                  </p>
                  <p>
                    Price (USD):{' '}
                    <b>{validateInfoEdit && validateInfoEdit.tokenPrice}</b>
                    <br />
                    Decimals:{' '}
                    <b>{validateInfoEdit && validateInfoEdit.decimals}</b>
                  </p>
                  <p>Please confirm:</p>
                </div>
              )}
              <button
                type="button"
                onClick={addTokenEdit}
                className={`mt-6 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white ${
                  showValidateEdit
                    ? 'bg-purple-600 hover:bg-purple-700'
                    : 'bg-green-600 hover:bg-green-700'
                } focus:outline-none`}
              >
                {showValidateEdit ? 'Add Token' : 'Validate Token'}
              </button>
            </div>
            <div className="mt-5 sm:mt-6 flex flex-row gap-x-4">
              <button
                type="button"
                onClick={() => {
                  setShowManageDialog(false);
                  setManagingWallet(undefined);
                }}
                className="inline-flex w-full justify-center rounded-md bg-gray-600 disabled:opacity-50 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 disabled:hover:bg-gray-600"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={() => {
                  onSubmitEditWallet();
                }}
                className="inline-flex w-full justify-center rounded-md bg-purple-600 disabled:opacity-50 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 disabled:hover:bg-purple-600"
              >
                Save
              </button>
            </div>
          </div>
        )}
      </DialogScreen>
      <AdminTabs />
      <h2 className="mt-6 text-xl font-semibold text-gray-900 dark:text-white">
        Existing Payment Wallets
      </h2>
      <div className="flow-root overflow-hidden">
        <div className=" max-w-7xl">
          <table className="w-full text-left">
            <thead className="bg-white">
              <tr>
                <th
                  scope="col"
                  className="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                >
                  Tag
                  <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
                  <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Address
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                >
                  Tokens
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Active
                </th>
                <th scope="col" className="relative py-3.5 pl-3">
                  <span className="sr-only">Manage</span>
                </th>
                <th scope="col" className="relative py-3.5 pl-3">
                  <span className="sr-only">Balance</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {wallets.map(wallet => (
                <tr key={wallet.id}>
                  <td className="relative py-4 pr-3 text-sm font-medium text-gray-900">
                    {wallet.tag}
                    <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                    <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    {wallet.address}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                    {wallet.acceptedTokens
                      .map((t: any) => `${t.name} (${t.chain})`)
                      .join(', ')}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    {wallet.active ? 'Yes' : 'No'}
                  </td>
                  <td className="relative py-4 pl-3 text-right text-sm font-medium">
                    <a
                      href="#"
                      onClick={() => {
                        manageWallet(wallet);
                      }}
                      className="text-purple-600 hover:text-purple-700"
                      rel="noreferrer"
                    >
                      Manage
                    </a>
                  </td>
                  <td className="relative py-4 pl-3 text-right text-sm font-medium">
                    <a
                      href="#"
                      onClick={() => {
                        showBalance(wallet);
                      }}
                      className="text-green-600 hover:text-green-700"
                      rel="noreferrer"
                    >
                      Balances
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <h2 className="mt-8 text-xl font-semibold text-gray-900 dark:text-white">
        Create A New Wallet
      </h2>
      <div className="mt-2">
        <label
          htmlFor="tag"
          className="block text-sm font-medium text-gray-700"
        >
          Tag
        </label>
        <input
          type="text"
          id="tag"
          name="tag"
          value={newWallet.tag}
          onChange={handleNewWalletChange}
          placeholder={'E.g. ACCESS, TIME_PRESALE'}
          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div className="mt-2">
        <label
          htmlFor="tag"
          className="block text-sm font-medium text-gray-700"
        >
          Is EVM Compatible?
        </label>
        <input
          type="checkbox"
          id="isEVM"
          name="isEVM"
          checked={newWallet.isEVM}
          onChange={e =>
            setNewWallet({ ...newWallet, isEVM: e.target.checked })
          }
          className="mt-1"
        />
      </div>
      <div className="justify-center gap-6 w-full pt-4">
        <form onSubmit={onSubmitNewWallet}>
          <div className="mt-8">
            <h3 className="text-lg font-medium text-gray-900">Add Tokens</h3>
            <p>Atleast one is required to create a new wallet</p>
            <div className="flex flex-wrap gap-2 mt-2 mb-4">
              {tokensToSubmit.map((token, index) => (
                <span
                  key={index}
                  className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm flex items-center"
                >
                  {token.name} ({token.chain})
                  <button
                    type="button"
                    onClick={() => removeToken(token.name, token.chain)}
                    className="ml-2 text-blue-600 hover:text-blue-800"
                  >
                    &#10005;
                  </button>
                </span>
              ))}
            </div>
            <div>
              <label
                htmlFor="chain"
                className="block text-sm font-medium text-gray-700"
              >
                Chain
              </label>
              <select
                id="chain"
                name="chain"
                value={newToken.chain}
                onChange={handleNewTokenChange}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="">Select a Chain</option>
                {acceptedChains.map(chain => (
                  <option key={chain} value={chain}>
                    {chain}
                  </option>
                ))}
              </select>
            </div>
            <div className="mt-2">
              <label
                htmlFor="tokenName"
                className="block text-sm font-medium text-gray-700"
              >
                Token Name
              </label>
              <input
                type="text"
                id="tokenName"
                name="name"
                value={newToken.name}
                onChange={handleNewTokenChange}
                placeholder={'E.g. USDC, TTT'}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div className="mt-2">
              <label
                htmlFor="tokenType"
                className="block text-sm font-medium text-gray-700"
              >
                Token Type
              </label>
              <select
                id="tokenType"
                name="type"
                value={newToken.type}
                onChange={handleNewTokenChange}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                {acceptedTypes.map((t, index) => (
                  <option key={index} value={t}>
                    {t}
                  </option>
                ))}
              </select>
            </div>

            <div className="mt-2">
              <label
                htmlFor="tokenAddress"
                className="block text-sm font-medium text-gray-700"
              >
                Token Contract Address
              </label>
              <input
                type="text"
                id="tokenAddress"
                name="address"
                value={newToken.type === 'NATIVE' ? '' : newToken.address}
                onChange={handleNewTokenChange}
                disabled={newToken.type === 'NATIVE'}
                placeholder={'0x'}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            {showValidate && (
              <div className="mt-4">
                <p>
                  You are going to add the following token:{' '}
                  <b>
                    {newToken.type === 'NATIVE'
                      ? `${newToken.name} (NATIVE)`
                      : newToken.address
                      ? newToken.address
                      : `${newToken.name} (NATIVE)`}
                  </b>
                </p>
                <p>
                  Price (USD): <b>{validateInfo && validateInfo.tokenPrice}</b>
                  <br />
                  Decimals: <b>{validateInfo && validateInfo.decimals}</b>
                </p>
                <p>Please confirm:</p>
              </div>
            )}
            <button
              type="button"
              onClick={addToken}
              className={`mt-6 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white ${
                showValidate
                  ? 'bg-purple-600 hover:bg-purple-700'
                  : 'bg-green-600 hover:bg-green-700'
              } focus:outline-none`}
            >
              {showValidate ? 'Add Token' : 'Validate Token'}
            </button>
          </div>

          <button
            type="submit"
            className="mt-6 inline-flex justify-center py-2 px-4 w-full border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
          >
            Create Wallet
          </button>
        </form>
      </div>
    </>
  );
}

/* eslint-disable no-restricted-globals */
import { useEffect, useState } from 'react';
import ApiClient from '../../../../utils/ApiClient';
import AdminTabs from '../../components/admin/AdminTabs';
import { Dialog } from '@headlessui/react';
import DialogScreen from '../../components/DialogScreen';
import { TrashIcon } from '@heroicons/react/24/outline';

const acceptedPages = ['my-nfts', 'top-collections'];
export default function AdminPromotions() {
  const [promotions, setPromotions] = useState<any[]>([]);
  const [msg, setMsg] = useState('');
  const [newPromotion, setNewPromotion] = useState<{
    name: string;
    image: string;
    isBanner: boolean;
    pages: string[];
    link?: string;
  }>({ name: '', image: '', isBanner: true, pages: [], link: '' });
  const [editingPromotion, setEditingPromotion] = useState<{
    id: number;
    name: string;
    image: string;
    isBanner: boolean;
    pages: string[];
    link: string;
  }>({ id: 0, name: '', image: '', isBanner: true, pages: [], link: '' });

  const handleEditPromotion = (promo: any) => {
    setEditingPromotion(promo);
    setNewPromotion({
      name: promo.name,
      image: promo.image,
      isBanner: promo.isBanner,
      pages: promo.pages,
      link: promo.link,
    });
  };

  useEffect(() => {
    ApiClient.admin_getPromotions().then(data => {
      setPromotions(data.promotions);
    });
  }, []);

  const handleNewChange = (e: any) => {
    if (e.target.name === 'pages') {
      // Convert the NodeList to an array and filter out unselected options
      const selectedOptions = Array.from(e.target.options)
        .filter((option: any) => option.selected)
        .map((option: any) => option.value);
      setNewPromotion({ ...newPromotion, pages: selectedOptions });
    } else {
      setNewPromotion({ ...newPromotion, [e.target.name]: e.target.value });
    }
  };

  const handleCheckboxChange = (page: any) => {
    const updatedPages = newPromotion.pages.includes(page)
      ? newPromotion.pages.filter(p => p !== page) // remove the page if it's already selected
      : [...newPromotion.pages, page]; // add the page if it's not selected

    setNewPromotion({ ...newPromotion, pages: updatedPages });
  };

  const handleNewPromotionSubmit = async (e: any) => {
    e.preventDefault();

    if (editingPromotion && editingPromotion.id !== 0) {
      // Call the update API
      ApiClient.admin_updatePromotion(
        editingPromotion.id,
        newPromotion.name,
        newPromotion.image,
        newPromotion.isBanner,
        newPromotion.pages,
        newPromotion.link,
      ).then((r: any) => {
        if (r.success) {
          setMsg('Successfully edited.');
          setEditingPromotion({
            id: 0,
            name: '',
            image: '',
            isBanner: true,
            pages: [],
            link: '',
          });
          setNewPromotion({
            name: '',
            image: '',
            isBanner: true,
            pages: [],
            link: '',
          });

          ApiClient.admin_getPromotions().then(data => {
            setPromotions(data.promotions);
          });
        } else {
          setMsg(r.message);
        }
      });
    } else {
      ApiClient.admin_createPromotion(
        newPromotion.name,
        newPromotion.image,
        newPromotion.isBanner,
        newPromotion.pages,
        newPromotion.link,
      ).then(data => {
        if (data.success) {
          ApiClient.admin_getPromotions().then(data => {
            setPromotions(data.promotions);
          });
          setMsg(`Successfully added ${newPromotion.name}!`);
          setNewPromotion({ ...newPromotion, link: '', image: '', name: '' });
        } else {
          alert(data.message);
        }
      });

      ApiClient.admin_getPromotions().then(data => {
        setPromotions(data.promotions);
      });
    }
  };

  return (
    <>
      <AdminTabs />
      <h2 className="mt-6 text-xl font-semibold text-gray-900 dark:text-white">
        Promotions
      </h2>
      <div className="flow-root overflow-hidden">
        <div className=" max-w-7xl">
          <table className="w-full text-left">
            <thead className="bg-white">
              <tr>
                <th
                  scope="col"
                  className="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                >
                  Name
                  <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
                  <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Image
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                >
                  Is Banner
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Pages
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Link
                </th>
                <th scope="col" className="relative py-3.5 pl-3">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {promotions.map((promo: any) => (
                <tr key={promo.id}>
                  <td className="relative py-4 pr-3 text-sm font-medium text-gray-900">
                    {promo.name}
                    <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                    <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    <a
                      href={promo.image}
                      target="_blank"
                      rel="noreferrer"
                      className="text-blue-600 hover:text-blue-500"
                    >
                      View
                    </a>
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                    {promo.isBanner ? 'Yes' : 'No'}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                    {promo.pages.join(', ')}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    {promo.link}
                  </td>
                  <td className="relative py-4 pl-3 text-right text-sm font-medium">
                    <button
                      onClick={() => {
                        if (editingPromotion.id === promo.id) {
                          setEditingPromotion({ ...editingPromotion, id: 0 });
                          setNewPromotion({
                            name: '',
                            image: '',
                            isBanner: true,
                            pages: [],
                            link: '',
                          });
                        } else {
                          handleEditPromotion(promo);
                        }
                      }}
                      className={`${
                        editingPromotion.id === promo.id
                          ? ' text-[red]'
                          : ' text-gray-600 hover:text-gray-700'
                      }`}
                    >
                      {editingPromotion.id === promo.id
                        ? 'Cancel Edit'
                        : 'Edit'}
                    </button>
                  </td>
                  <td className="relative py-4 pl-3 text-right text-sm font-medium">
                    <button
                      onClick={() => {
                        if (
                          confirm(`Are you sure you want to delete promotion: 
${promo.name}`)
                        ) {
                          ApiClient.admin_deletePromotion(promo.id).then(() => {
                            ApiClient.admin_getPromotions().then(data => {
                              setPromotions(data.promotions);
                            });
                          });
                        }
                      }}
                      className={`text-[red]`}
                    >
                      <TrashIcon className="w-4 h-4 mt-1" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="mt-4">
        <h2>New Promotion</h2>
        <form onSubmit={handleNewPromotionSubmit}>
          <div className="mb-3">
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Promotion Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={newPromotion.name}
              onChange={handleNewChange}
              placeholder="E.g. Special Offer"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="image"
              className="block text-sm font-medium text-gray-700"
            >
              Image URL
            </label>
            <input
              type="text"
              id="image"
              name="image"
              value={newPromotion.image}
              onChange={handleNewChange}
              placeholder="E.g. http://example.com/image.jpg"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="isBanner"
              className="block text-sm font-medium text-gray-700"
            >
              Is Banner
            </label>
            <input
              type="checkbox"
              id="isBanner"
              name="isBanner"
              checked={newPromotion.isBanner}
              onChange={e =>
                setNewPromotion({ ...newPromotion, isBanner: e.target.checked })
              }
              className="mt-1"
            />
          </div>
          <div className="mb-3">
            <label className="block text-sm font-medium text-gray-700">
              Pages
            </label>
            <div className="mt-1">
              {acceptedPages.map((page, index) => (
                <div key={index} className="flex items-center mb-2">
                  <input
                    id={`page-${page}`}
                    name="pages"
                    type="checkbox"
                    value={page}
                    checked={newPromotion.pages.includes(page)}
                    onChange={() => handleCheckboxChange(page)}
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  />
                  <label
                    htmlFor={`page-${page}`}
                    className="ml-2 block text-sm text-gray-900"
                  >
                    {page}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="mb-3">
            <label
              htmlFor="link"
              className="block text-sm font-medium text-gray-700"
            >
              Link (Optional)
            </label>
            <input
              type="text"
              id="link"
              name="link"
              value={newPromotion.link || ''}
              onChange={handleNewChange}
              placeholder="E.g. http://example.com"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <button
            type="submit"
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {editingPromotion && editingPromotion.id !== 0
              ? 'Edit Promotion'
              : 'Add Promotion'}
          </button>
          {editingPromotion && editingPromotion.id !== 0 && (
            <button
              onClick={() => {
                setEditingPromotion({ ...editingPromotion, id: 0 });
                setNewPromotion({
                  name: '',
                  image: '',
                  isBanner: true,
                  pages: [],
                  link: '',
                });
              }}
              className="inline-flex ml-4 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel Edit
            </button>
          )}
        </form>
        {msg !== '' && <div className="mt-4 dark:text-white">{msg}</div>}
      </div>
    </>
  );
}

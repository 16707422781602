// import CollectionNav from "../CollectionNav";
import { useEffect, useRef, useState } from 'react';
import ApiClient from '../../../../utils/ApiClient';

import { useAuth } from '../../../../hooks/useAuth';
import { ethers } from 'ethers';
import { dateToDMY } from '../../../../utils/parser';

export function MySubscriptionList({ setActivePackages }: any) {
  const { isAuthenticated, hasFullAccess, user } = useAuth();
  const [subscriptions, setSubscriptions] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    ApiClient.getMySubscriptions().then(subs => {
      setSubscriptions(subs.subscriptions.reverse());
      setLoading(false);
    });
  }, [user]);

  useEffect(() => {
    setActivePackages(subscriptions.length);
  }, [subscriptions]);
  if (loading) {
    return <></>;
  }

  if (subscriptions.length === 0) {
    return (
      <p className="font-semibold dark:text-white">You have no services.</p>
    );
  }

  return (
    <div className="flow-root overflow-hidden">
      <div className=" max-w-7xl">
        <table className="w-full text-left">
          <thead className="bg-white">
            <tr>
              <th
                scope="col"
                className="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
              >
                Name
                <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
                <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Purchased On
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
              >
                Expires
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Paid
              </th>
              <th scope="col" className="relative py-3.5 pl-3">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {subscriptions.map(sub => (
              <tr key={sub.id}>
                <td className="relative py-4 pr-3 text-sm font-medium text-gray-900">
                  {sub.Subscription.title}
                  <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                  <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                </td>
                <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                  {dateToDMY(sub.createdAt)}
                </td>
                <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                  {dateToDMY(sub.expires)}
                </td>
                <td className="px-3 py-4 text-sm text-gray-500">
                  {ethers.utils
                    .formatUnits(
                      sub.transaction.amount,
                      sub.transaction.decimals,
                    )
                    .toString()}{' '}
                  {sub.transaction.token || ''} ({sub.transaction.chain})
                </td>
                <td className="relative py-4 pl-3 text-right text-sm font-medium">
                  <a
                    target="_blank"
                    href="https://discord.gg/gDYDAQA78K"
                    className="text-purple-600 hover:text-purple-700"
                    rel="noreferrer"
                  >
                    Report problem
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

// https://dynamicdropstatas-production.up.railway.app/partners/time-launcher/drops
import { useEffect, useState } from 'react';
import { useAuth } from '../../../hooks/useAuth';
import { DropsList } from '../components/drops/DropsList';

export default function Drops() {
  const { user, updateUser, activeWallet } = useAuth();
  const [showCreateDrop, setShowCreateDrop] = useState(
    localStorage.getItem('hideCreateDrop') !== '1',
  );
  return (
    <>
      <h1 className="text-2xl font-semibold text-gray-900 dark:text-white">
        Drops
      </h1>
      {showCreateDrop && (
        <div className="flex justify-center mb-4 mt-4 md:mt-2">
          <div className="relative">
            <p className="text-xs dark:text-zinc-200 text-center p-3 px-10 border opacity-75 border-gray-400 rounded-md">
              Do you want to advertise your drop? <br />
              <a
                href="https://discord.gg/gDYDAQA78K"
                target="_blank"
                className="hover:text-gray-600 dark:hover:text-gray-200 dark:text-white"
                rel="noreferrer"
              >
                Open a ticket for drop inquires →
              </a>
              <button
                onClick={() => {
                  setShowCreateDrop(false);
                  localStorage.setItem('hideCreateDrop', '1');
                }}
                className="absolute top-0 right-0 p-2 pr-3 dark:text-white hover:text-gray-400 dark:hover:text-gray-300"
                aria-label="Close"
              >
                &#10005;
              </button>
            </p>
          </div>
        </div>
      )}
      <div className="justify-center w-full pt-4">
        <DropsList />
      </div>
    </>
  );
}

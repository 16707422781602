function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

export default function AdminTabs() {
  const tabs = [
    {
      name: 'Users',
      href: '/app/admin/users',
      current: false,
    },
    {
      name: 'Payment Wallets',
      href: '/app/admin/payment-wallets',
      current: false,
    },
    { name: 'Subscriptions', href: '/app/admin/subscriptions', current: false },
    {
      name: 'Promotions',
      href: '/app/admin/promotions',
      current: false,
    },
    {
      name: 'Soft Staking',
      href: '/app/admin/soft-staking',
      current: false,
    },
    {
      name: 'Token Track',
      href: '/app/admin/token-track',
      current: false,
    },
    {
      name: 'Token Contests',
      href: '/app/admin/token-contests',
      current: false,
    },
  ];

  const currentHash = window.location.href;

  const updatedTabs = tabs.map(tab => ({
    ...tab,
    // Set the tab as current if its href matches the current hash
    current: currentHash.includes(tab.href),
  }));

  return (
    <div className="flex flex-row gap-x-12 items-center">
      <h2 className="text-xl dark:text-white">Admin Panel</h2>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          defaultValue={updatedTabs.find(tab => tab.current)?.name}
        >
          {updatedTabs.map(tab => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {updatedTabs.map(tab => (
            <a
              key={tab.name}
              href={tab.href}
              className={classNames(
                tab.current
                  ? 'bg-gray-100 text-gray-700 dark:bg-zinc-800 dark:text-white'
                  : 'text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-white',
                'rounded-md px-3 py-2 text-sm font-medium',
              )}
              aria-current={tab.current ? 'page' : undefined}
            >
              {tab.name}
            </a>
          ))}
        </nav>
      </div>
    </div>
  );
}

/* eslint-disable consistent-return */
/* eslint-disable no-await-in-loop */
/* eslint-disable class-methods-use-this */
import { Contract, providers } from 'ethers';
import TIMEWARS_COMPONENTS_ABI from '../web3/ABI/timeshipcomponents.abi.json';
import TIMEWARS_SHIPS_ABI from '../web3/ABI/timeships.abi.json';
import axios from 'axios';
import { configVars } from '../web3/config';
import _ from 'lodash';
import ApiClient from './ApiClient';

type Ship = {
  id: number;
  name: string;
  image: string;
  edition: number;
  accountId?: number;
  ShipStatus: 'NOT_BUILT' | 'BUILT' | 'BUILDING';
  TimeWarsComponent: Component[];
};

export type Component = {
  id: number;
  name: string;
  image: string;
  attributes: any[];
  tier: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  status: 'ATTACHED' | 'PENDING_ATTACH' | 'UNUSED' | 'PENDING_DETACH';
  type: 'ARMOR' | 'WEAPON' | 'SHIELD' | 'SPECIAL' | 'ENGINE';
  TimeWarsShip: Ship;
  count?: number;
  selected?: boolean;
};

const TIMEWARS_SHIPS = '0x13c2803c4e0d0e727a6b1820ecc814550a9d7a35';
const TIMEWARS_COMPONENTS = '0xfF567a62d7BF2CF984F02175C8bd485E3C4E2c0e';

const provider = configVars.rpcNetwork_mainnet.rpcUrl;
const shipsContract = new Contract(
  TIMEWARS_SHIPS,
  TIMEWARS_SHIPS_ABI,
  new providers.JsonRpcProvider(provider, 25),
);

const componentsContract = new Contract(
  TIMEWARS_COMPONENTS,
  TIMEWARS_COMPONENTS_ABI,
  new providers.JsonRpcProvider(provider, 25),
);

class TimeWarsClient {
  public getShipsOfOwner = async (owner: string): Promise<number[]> => {
    const tokens = await shipsContract
      .walletOfOwner(owner)
      .catch((e: any) => []);

    return tokens.map((t: any) => t.toNumber());
  };

  public getComponentsOfOwner = async (owner: string): Promise<number[]> => {
    const tokens = await componentsContract
      .tokensOfWallet(owner)
      .catch(() => []);

    return tokens.map((t: any) => t.toNumber());
  };

  public async getShipData(tokenId: number) {
    const data = await axios.get(
      `https://cdn.ltsglxy.network/ipfs/bafybeib7xksuxsyg5utxd5bh7seml23pibupfqpsqolrc7nnf5efdtbllm/${tokenId}.json`,
    );

    if (data.data) {
      return data.data;
    }
  }

  public async getUniqueListOfUnusedComponentsInChunks(tokenIds: number[]) {
    let data = await this.getComponentDataBulk(tokenIds).catch(e => e);
    if (!data) {
      return [];
    }

    data = data.filter((c: Component) => c.status === 'UNUSED');

    return data;
  }

  public async getListOfShipsInChunks(tokenIds: number[]) {
    let data = await this.getShipDataBulk(tokenIds).catch(e => e);
    if (!data) {
      return [];
    }

    return data;
  }

  public async getComponentData(tokenId: number): Promise<Component> {
    const data = await ApiClient.timewars_getCDNComponent(tokenId).catch(
      e => e,
    );

    if (data) {
      data.id = tokenId;
      data.tier = data.attributes.find(
        (a: any) => a.trait_type === 'Tier',
      )?.value;
      data.type = data.attributes.find(
        (a: any) => a.trait_type === 'Type',
      )?.value;
      return data;
    }

    throw new Error('No data found');
  }

  public async getComponentDataBulk(tokenIds: number[]): Promise<Component[]> {
    const data = await ApiClient.timewars_getCDNComponentBulk(tokenIds).catch(
      e => e,
    );

    if (data) {
      return data;
    }

    throw new Error('No data found');
  }

  public async getShipDataBulk(tokenIds: number[]): Promise<Ship[]> {
    const data = await ApiClient.timewars_getCDNShipBulk(tokenIds).catch(
      e => e,
    );

    if (data) {
      return data;
    }

    throw new Error('No data found');
  }

  public async getShipsDataOfOwner(owner: string) {
    const tokens = await this.getShipsOfOwner(owner);
    const ships = [];

    for (let i = 0; i < tokens.length; i += 1) {
      const shipData = await ApiClient.timewars_getCDNShip(tokens[i]).catch(
        e => e,
      );
      if (!shipData) continue;

      shipData.image = 'https://cdn.ltsglxy.network/ipfs/' + shipData.image;

      ships.push(shipData);
    }

    return ships;
  }
}

const timeWarsClient = new TimeWarsClient();

export default timeWarsClient;

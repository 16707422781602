import { useEffect, useState } from 'react';
import ApiClient, { Collection, Token } from '../../../utils/ApiClient';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { useParams, useSearchParams } from 'react-router-dom';
import { InventoryTokenList } from '../components/token/InventoryTokenList';
import {
  CronosSvg,
  PolygonSvg,
  collectionImage,
  convertLinksToHtml,
  isMp4,
  numberWithCommas,
} from '../../../utils/parser';
import './CollectionInventory.css';
import { useAuth } from '../../../hooks/useAuth';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PlaceholderLoading from 'react-placeholder-loading';

export default function CollectionInventory() {
  //   let [searchParams, setSearchParams] = useSearchParams();
  const [collection, setCollection] = useState<Collection>();
  const { isAuthenticated, hasFullAccess } = useAuth();
  const isPolygon = collection?.network === 'POLYGON';
  const [refreshing, setRefreshing] = useState(false);
  const [successRefresh, setSuccessRefresh] = useState(false);
  let { address } = useParams();
  useEffect(() => {
    if (address === undefined) return;
    ApiClient.getCollection(address)
      .then(data => {
        setCollection(data.data.data);
      })
      .catch(e => console.error(e));
  }, []);

  console.log(collection);
  async function handleCollectionRefresh() {
    if (refreshing || successRefresh) return;

    setRefreshing(true);
    const response = await ApiClient.refreshCollectionMetadata(
      collection?.address || '',
    );
    setRefreshing(false);
    if (response.success) {
      setSuccessRefresh(true);
      setTimeout(() => setSuccessRefresh(false), 4000);
    } else {
      alert(response.error);
    }
  }
  //   const [tokens, setTokens] = useState<Token[]>([]);
  //   const [order, setOrder] = useState('tokenId');
  //   const [pages, setPages] = useState(0);
  //   const [pageSize, setPageSize] = useState(0);
  //   const [page, setPage] = useState(1);
  //   const [total, setTotal] = useState(0);
  //   const [collectionId, setCollectionId] = useState('');
  //   const [search, setSearch] = useState('');
  //   const [direction, setDirection] = useState('desc');
  //   const [listed, setListed] = useState('none');
  //   useEffect(() => {
  //     if (!router.query.cid) return;

  //     const collectionId1 = router.query.cid.toString();
  //     setCollectionId(router.query.cid.toString())

  //     ApiClient.getTokens(parseInt(collectionId1), parseInt(query.get("page")) || 1, 12, order, direction, search, listed).then((data) => {
  //       setTokens(data.data);
  //       setPages(data.totalPages);
  //       setPageSize(data.pageSize);
  //       setTotal(data.total);

  //       console.log(data)
  //     });
  //   }, [order, router, direction, search, listed]);
  if (collection === undefined) return <div></div>;

  const imgSrcUrl = collectionImage(collection.network, collection.image);

  return (
    <div className="bg-white dark:bg-zinc-900 pb-12 rounded-lg">
      <div>
        <img
          className="h-32 w-full rounded-lg object-cover lg:h-48"
          src={collectionImage(collection.network, collection.himage)}
          alt=""
        />
      </div>
      {/* <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8"> */}
      <div className="-mt-12 sm:-mt-16 sm:flex sm:space-x-5 flex items-center justify-center ">
        {isMp4(imgSrcUrl) ? (
          // Render video tag for MP4
          <video
            autoPlay
            muted
            loop
            className="h-24 w-24 self-center rounded-full ring-4 ring-white bg-black dark:bg-white sm:h-32 sm:w-32"
          >
            <source src={imgSrcUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          // Render LazyLoadImage for images
          <LazyLoadImage
            className="h-24 w-24 self-center rounded-full ring-4 ring-white bg-black dark:bg-white sm:h-32 sm:w-32"
            src={imgSrcUrl}
            placeholder={
              <PlaceholderLoading
                shape="rect"
                width={200}
                height={200}
                colorStart="transparent"
                colorEnd="#9CA3AF"
              />
            }
            alt={collection.name}
          />
        )}
        {/* </div> */}
      </div>

      <div className="hidden sm:block 3xl:hidden mt-6 min-w-0 flex-1">
        <h1 className="text-2xl font-bold text-center text-gray-900 dark:text-gray-300 truncate">
          {collection.name}{' '}
          {collection.network === 'POLYGON' ? (
            <PolygonSvg className="h-5 w-5 inline-block" />
          ) : collection.network === 'CRONOS' ? (
            <CronosSvg className="h-5 w-5 inline-block" />
          ) : null}
        </h1>
        <div className="relative text-center inline-block flex justify-center mt-2">
          <a
            href={
              collection.network === 'POLYGON'
                ? `https://time-launcher.com/collection/polygon/${collection.address.toLowerCase()}`
                : collection.network === 'CRONOS'
                ? collection.tokens[0]?.TokenStats[0]?.source === 'MOONFLOW'
                  ? `https://time-launcher.com/collection/cronos/${collection.address}`
                  : `https://app.ebisusbay.com/collection/${collection.address}`
                : ''
            }
            target="_blank"
            className="text-center text-gray-500 hover:text-gray-400 dark:text-gray-500 hover:dark:text-gray-400 inline-block cursor-pointer"
            rel="noreferrer"
          >
            {collection.network === 'POLYGON' ? (
              <PolygonSvg
                className="h-4 w-4 inline-block mb-1 "
                style={{
                  WebkitFilter: 'grayscale(1)',
                  filter: 'grayscale(1)',
                }}
              />
            ) : collection.network === 'CRONOS' ? (
              <CronosSvg
                className="h-4 w-4 inline-block mb-1 "
                style={{
                  WebkitFilter: 'grayscale(1)',
                  filter: 'grayscale(1)',
                }}
              />
            ) : null}{' '}
            {collection.address}{' '}
          </a>
          {isAuthenticated && hasFullAccess && (
            <>
              <a
                href="#"
                onClick={handleCollectionRefresh}
                className="ml-3 text-gray-500 dark:text-gray-500 hover:dark:text-gray-400 hover:text-gray-400"
              >
                {successRefresh ? (
                  <>
                    <ArrowPathIcon className="inline-block h-4 w-4 mb-1 mb-1" />{' '}
                    Successfully refreshed!
                  </>
                ) : !refreshing && !successRefresh ? (
                  <>
                    <ArrowPathIcon className="inline-block h-4 w-4 mb-1" />{' '}
                    Refresh Metadata
                  </>
                ) : refreshing ? (
                  <>
                    <ArrowPathIcon className="inline-block h-4 w-4 mb-1" />{' '}
                    Refreshing...
                  </>
                ) : null}
              </a>
            </>
          )}
        </div>

        <p
          className=" m-10 px-10 text-center dark:text-gray-400"
          dangerouslySetInnerHTML={{
            __html: convertLinksToHtml(collection.desc),
          }}
        ></p>
      </div>
      <div className="mt-10 pb-12 bg-white dark:bg-zinc-900 sm:pb-16">
        <div className="relative">
          <div className="absolute inset-0 h-1/2 bg-gray-50 dark:bg-zinc-900" />
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-4xl mx-auto">
              <dl
                className={`rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3`}
              >
                <div className="flex flex-col border-b border-gray-100 dark:border-zinc-700 dark:bg-zinc-800 p-6 text-center sm:border-0 sm:border-r">
                  <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500 dark:text-gray-400">
                    Floor{' '}
                    {collection.network === 'POLYGON' ? '(MATIC)' : '(CRO)'}
                  </dt>
                  <dd className="order-1 text-4xl font-extrabold text-black dark:text-white">
                    {Number(collection.pFloor).toFixed(2)}
                  </dd>
                </div>
                <div className="flex flex-col border-t border-b border-gray-100 dark:border-zinc-700 dark:bg-zinc-800 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                  <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500 dark:text-gray-400">
                    Volume{' '}
                    {collection.network === 'POLYGON' ? '(MATIC)' : '(CRO)'}
                  </dt>
                  <dd className="order-1 text-4xl font-extrabold text-black dark:text-white">
                    {numberWithCommas(collection.pVolume.toFixed(2))}
                  </dd>
                </div>
                {!isPolygon ? (
                  <div className="flex flex-col border-t border-gray-100 dark:border-zinc-700 dark:bg-zinc-800 p-6 text-center sm:border-0 sm:border-l">
                    <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500 dark:text-gray-400">
                      Sales
                    </dt>
                    <dd className="order-1 text-4xl font-extrabold text-black dark:text-white">
                      {numberWithCommas(collection.pSales)}
                    </dd>
                  </div>
                ) : (
                  <div className="flex flex-col border-t border-gray-100 dark:border-zinc-700 dark:bg-zinc-800 p-6 text-center sm:border-0 sm:border-l">
                    <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500 dark:text-gray-400">
                      Items
                    </dt>
                    <dd className="order-1 text-4xl font-extrabold text-black dark:text-white">
                      {numberWithCommas(
                        collection.supply === 0 || collection.supply === 1
                          ? '?'
                          : collection.supply,
                      )}
                    </dd>
                  </div>
                )}
              </dl>
            </div>
          </div>
        </div>
      </div>
      {/* <SearchButtons
        direction={direction}
        setOrder={setOrder}
        order={order}
        setListed={setListed}
        setDirection={setDirection}
        search={setSearch}
        title=""
      ></SearchButtons> */}
      <InventoryTokenList collection={collection.address}></InventoryTokenList>
    </div>
  );
}

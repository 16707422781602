// https://dynamicdropstatas-production.up.railway.app/partners/time-launcher/drops

import {
  BellIcon,
  BoltIcon,
  ChartBarIcon,
  CheckCircleIcon,
  ChevronDownIcon,
} from '@heroicons/react/24/solid';
import Countdown from 'react-countdown';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import chartLine from '@iconify/icons-ph/chart-line';
import { Icon } from '@iconify/react';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ApiClient from '../../../utils/ApiClient';
import { useAuth } from '../../../hooks/useAuth';
import { ensureCronos, ensurePolygon } from '../../../web3/utils';
import { ethers } from 'ethers';
import InfoAlert from '../components/InfoAlert';

function getMarchFirstEST() {
  return 1709287200000;
}

const Image3D = ({ src, alt }: any) => {
  const [style, setStyle] = useState({});

  const handleMouseMove = (e: any) => {
    const { clientX, clientY, currentTarget } = e;
    const { left, top, width, height } = currentTarget.getBoundingClientRect();
    const xVal = ((clientX - left) / width - 0.5) * 20; // Adjust multiplier for effect intensity
    const yVal = ((clientY - top) / height - 0.5) * -20; // Adjust multiplier for effect intensity
    setStyle({
      transform: `rotateY(${xVal}deg) rotateX(${yVal}deg)`,
    });
  };

  const handleMouseLeave = () => {
    setStyle({
      transform: 'rotateY(0deg) rotateX(0deg)',
    });
  };

  return (
    <img
      src={src}
      alt={alt}
      className="image-3d px-4"
      width={400}
      style={style}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    />
  );
};

SwiperCore.use([Autoplay]);
export default function Time() {
  const { activeWallet } = useAuth();
  const myRef = useRef(null);
  const executeScroll = () => {
    if (myRef.current) {
      (myRef.current as any).scrollIntoView({ behavior: 'smooth' });
    }
  };

  const [value, setValue] = useState(1000);

  const handleChange = (e: any) => {
    let inputValue = parseInt(e.target.value, 10);
    if (isNaN(inputValue)) {
      inputValue = 0;
    }
    setValue(inputValue);
  };

  const handleBlur = () => {
    // Round the value to the nearest 1000 when input loses focus
    const roundedValue = Math.round(value / 1000) * 1000;
    setValue(roundedValue);
  };

  const increment = () => {
    setValue(prevValue => prevValue + 1000);
  };

  const decrement = () => {
    setValue(prevValue => Math.max(0, prevValue - 1000)); // Prevents negative values
  };

  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.getElementById(hash.replace('#', ''));
      if (element) {
        window.scrollTo({
          top: element.offsetTop,
          behavior: 'smooth', // This enables smooth scrolling
        });
      }
    }
  }, [location]);

  const [tokensSold, setTokensSold] = useState(0); // Example starting value
  const maxTokens = 10000000; // Maximum number of tokens
  const [costPer1000, setCostPer1000] = useState(30.0);
  // Calculate the width of the progress bar based on tokens sold
  const progressWidth = Math.min((tokensSold / maxTokens) * 100, 100);

  const [paymentWallet, setPaymentWallet] = useState<any>(undefined);

  useEffect(() => {
    ApiClient.getPresaleInfo(activeWallet || undefined)
      .then(async (r: any) => {
        setPaymentWallet(r.paymentWallet);
        setCostPer1000(r.timePresaleUSDCCostPer1000);

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const chainId = (await provider.getNetwork()).chainId;

        setSelectedToken(
          r.paymentWallet.acceptedTokens.find(
            (a: any) =>
              a.name === 'USDC' &&
              a.chain === (chainId === 25 ? 'CRONOS' : 'POLYGON'),
          ),
        );

        setMyTokensBought(r.mySales);
        setTokensSold(r.sales);
      })
      .catch(e => console.log('sale not started'));
  }, [activeWallet]);

  const [selectedToken, setSelectedToken] = useState<any>();
  const [polling, setPolling] = useState<string | null>(null);
  const [message, setMessage] = useState<{
    message: string;
    title: string;
    type: 'success' | 'error';
  }>({
    message: '',
    title: '',
    type: 'error',
  });

  const [myTokensBought, setMyTokensBought] = useState(0);
  const [loadingPresaleInfo, setLoadingPresaleInfo] = useState(true);
  useEffect(() => {
    let interval: any;
    interval = setInterval(() => {
      ApiClient.getPresaleInfo(activeWallet || undefined)
        .then(r => {
          setTokensSold(r.sales);
          setMyTokensBought(r.mySales);
          setLoadingPresaleInfo(false);
        })
        .catch(e => {
          console.log('sale not started');
          setLoadingPresaleInfo(false);
        });
    }, 15000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [activeWallet]);

  useEffect(() => {
    const dateNow = new Date();
    if (dateNow.getTime() >= getMarchFirstEST()) return;

    let interval: any;

    interval = setInterval(() => {
      if (new Date().getTime() >= getMarchFirstEST()) {
        window.location.reload();
      }
    }, 5000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);
  useEffect(() => {
    let interval: NodeJS.Timer | undefined;
    if (polling) {
      interval = setInterval(() => {
        ApiClient.checkPayment(polling, true).then((res: any) => {
          if (res.status === 'paid') {
            setMessage({
              title: 'Success',
              message: `Your order for ${res.metadata.tokens} $Time was successful, thank you.`,
              type: 'success',
            });
            ApiClient.getPresaleInfo(activeWallet || undefined)
              .then(r => {
                setTokensSold(r.sales);
                setMyTokensBought(r.mySales);
              })
              .catch(e => console.log('sale not started'));
            setPolling(null);
          }
        });
      }, 2000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [polling, activeWallet]);

  async function requestPayment(
    toAddress: string,
    amountInWei: string,
    paymentId: string,
    contractAddress?: string,
  ) {
    try {
      // Request access to the user's wallet
      await window.ethereum.request({ method: 'eth_requestAccounts' });

      // Create a new instance of the Ethers.js provider
      const provider = new ethers.providers.Web3Provider(window.ethereum);

      // Get the signer from the provider
      const signer = provider.getSigner();
      console.log('req payment:');
      console.log({ toAddress, amountInWei, paymentId, contractAddress });
      if (contractAddress) {
        const tokenContract = new ethers.Contract(
          contractAddress,
          ['function transfer(address to, uint amount) returns (bool)'],
          signer,
        );

        const transactionResponse = await tokenContract.transfer(
          toAddress,
          amountInWei,
        );
        console.log('Transaction Response:', transactionResponse);

        setPolling(paymentId);
      }
    } catch (err: any) {
      console.log(err);
      if (err.data) {
        setMessage({
          title: 'Error',
          message: err.data.message,
          type: 'error',
        });
      } else {
        setMessage({
          title: 'Error',
          message: 'Something went wrong, please contact the support team.',
          type: 'error',
        });
      }
    }
  }

  const [loadingPurchase, setLoadingPurchase] = useState(false);

  async function onPurchase() {
    if (!paymentWallet) return;

    if (!activeWallet || !window.ethereum) {
      if (selectedToken.chain === 'CRONOS') {
        await ensureCronos();
      } else {
        await ensurePolygon();
      }
      alert('Please confirm your order again.');
    }

    if (selectedToken.chain === 'CRONOS') {
      await ensureCronos();
    } else {
      await ensurePolygon();
    }
    setLoadingPurchase(true);
    ApiClient.presalePurchase(
      activeWallet!,
      selectedToken.chain!,
      selectedToken.name,
      {
        tokens: value,
      },
    )
      .then(r => {
        setLoadingPurchase(false);
        requestPayment(
          r.payment.address,
          r.payment.amount,
          r.payment.id,
          r.payment.contractAddress,
        );
      })
      .catch(e => {
        setLoadingPurchase(false);
        console.log(e);
      });
  }

  return (
    <>
      <div>
        {/* Hero section */}
        <div className="relative isolate">
          <div className="mx-auto max-w-7xl px-6 mt-14  lg:flex lg:items-center lg:gap-x-10 lg:px-8 ">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
              <div className="flex">
                <div className="relative flex items-center gap-x-4 rounded-full px-4 py-1 text-sm leading-6 text-gray-600 dark:text-gray-200 dark:ring-gray-100/10 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                  <span className="font-semibold text-gray-600 dark:text-white">
                    Pre-sale ended March 15th
                  </span>
                </div>
              </div>
              <h1 className="mt-10 max-w-lg text-4xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-6xl">
                $Time Token <br />
                is here
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-200">
                We at Time Machine NFTs are thrilled to announce an event that
                marks a monumental milestone for our platform and the entire
                ecosystem: <br />
                the pre-sale of $Time tokens.
              </p>
              <div className="mt-10 flex items-center gap-x-6">
                <a
                  href="https://discord.gg/gDYDAQA78K"
                  target="_blank"
                  className="rounded-md bg-purple-500 hover:bg-purple-400 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm "
                  rel="noreferrer"
                >
                  Join the Discord
                </a>
              </div>
            </div>
            <div className="mt-16 sm:mt-24 lg:mt-16 lg:flex-shrink-0 lg:flex-grow">
              <img
                src={'/time.png'}
                alt={'Time'}
                className="mx-auto w-[22.875rem] max-w-full drop-shadow-xl"
              />
            </div>
          </div>
        </div>

        {/* Feature section */}
        <div className="w-full bg-gray-50 dark:bg-zinc-800 rounded-2xl pb-12">
          <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-56 py-24 lg:px-8">
            <div className="mx-auto max-w-2xl lg:text-center">
              <h2 className="text-base font-semibold leading-7 text-purple-600">
                Time Sniper
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
                The first of its kind platform for the Polygon and Cronos chains
                and holders of our NFTs
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-200">
                Explore new functionality such as:
              </p>
            </div>
            <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
              <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                <div className="relative rounded-lg pl-16">
                  <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900 dark:text-white">
                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-purple-500">
                      <BoltIcon
                        className="h-5 w-5 flex-none text-white"
                        aria-hidden="true"
                      />
                    </div>
                    Multi-wallet support
                  </dt>
                  <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600 dark:text-gray-200">
                    <p className="flex-auto dark:text-white">
                      Connect multiple wallets to one dashboard
                    </p>
                  </dd>
                </div>
                <div className="relative rounded-lg pl-16">
                  <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900 dark:text-white">
                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-purple-500 ">
                      <Icon
                        icon={chartLine}
                        className="h-5 w-5 flex-none text-white"
                        aria-hidden="true"
                      />
                    </div>
                    Keep track and in control
                  </dt>
                  <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600 dark:text-white">
                    <p className="flex-auto dark:text-white">
                      Total NFT portfolio holdings value in CRO and USD
                    </p>
                  </dd>
                </div>
                <div className="relative rounded-lg pl-16">
                  <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900 dark:text-white">
                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-purple-500">
                      <BellIcon
                        className="h-5 w-5 flex-none text-white"
                        aria-hidden="true"
                      />
                    </div>
                    Be the first to the party
                  </dt>
                  <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                    <p className="flex-auto dark:text-white">
                      Smart alerts on specific NFTs and/or collections
                    </p>
                  </dd>
                </div>
                <div className="relative rounded-lg pl-16">
                  <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900 dark:text-white">
                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-purple-500">
                      <ChartBarIcon
                        className="h-5 w-5 flex-none text-white"
                        aria-hidden="true"
                      />
                    </div>
                    Analyse your next move
                  </dt>
                  <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600 dark:text-white">
                    <p className="flex-auto dark:text-white">
                      Smart Charts providing an array of analytics
                    </p>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
        <div className="w-full bg-white dark:bg-zinc-900 rounded-2xl">
          <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
            <div className="mx-auto flex max-w-2xl flex-col items-end justify-between gap-16 lg:mx-0 lg:max-w-none lg:flex-row">
              <div className="w-full lg:max-w-lg lg:flex-auto">
                <p className="text-base font-semibold leading-7 text-purple-600">
                  It's here
                </p>
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
                  The Token of the Future, $Time
                </h2>
                <p className="mt-6 text-xl leading-8 text-gray-600 dark:text-gray-200">
                  Time token will be the native token to the Time Machine
                  ecosystem on the polygon chain, and in Time Wars web3 game.
                  <br />
                  <br />
                  Time tokens purpose is to reward all NFT lovers and investors
                  by providing a NFT purchasing rewards platform to for any NFT
                  project on the <strong>Cronos and Polygon Chains</strong>.
                </p>
                <img
                  src="/nft.png"
                  alt=""
                  className="mt-16 aspect-[6/5] w-full rounded-2xl bg-gray-50 object-cover lg:aspect-auto lg:h-[34.5rem]"
                />
              </div>
              <div className="w-full lg:max-w-xl lg:flex-auto">
                <h3 className="sr-only">Information</h3>
                <ul className="-my-8 divide-y divide-gray-100">
                  <li className="py-8">
                    <dl className="relative flex flex-wrap gap-x-3">
                      <dd className="w-full flex-none text-lg font-semibold tracking-tight text-gray-900 dark:text-white">
                        <a>
                          <CheckCircleIcon
                            className="mb-1 mr-2 h-5 w-5 flex-none text-purple-600 inline"
                            aria-hidden="true"
                          />
                          Minimum Purchase and Token Price
                          <span
                            className="absolute inset-0"
                            aria-hidden="true"
                          />
                        </a>
                      </dd>
                      <dt className="sr-only">Description</dt>
                      <dd className="mt-2 w-full flex-none text-base leading-7 text-gray-600 dark:text-gray-200">
                        The pre-sale is open to everyone on the polygon chain,
                        with a minimum purchase of 1000 tokens at a price of
                        $0.03 per token.
                      </dd>
                    </dl>
                  </li>
                  <li className="py-8">
                    <dl className="relative flex flex-wrap gap-x-3">
                      <dd className="w-full flex-none text-lg font-semibold tracking-tight text-gray-900 dark:text-white">
                        <a>
                          <CheckCircleIcon
                            className="mb-1 mr-2 h-5 w-5 flex-none text-purple-600 inline"
                            aria-hidden="true"
                          />
                          Token Allocation and Tokenomics
                          <span
                            className="absolute inset-0"
                            aria-hidden="true"
                          />
                        </a>
                      </dd>
                      <dt className="sr-only">Description</dt>
                      <dd className="mt-2 w-full flex-none text-base leading-7 text-gray-600 dark:text-gray-200">
                        To ensure a fair distribution, only up to 1% of the
                        total supply of $Time tokens will be sold during the
                        pre-sale. After the two-weeks pre-sale period, any
                        remaining unsold tokens will be allocated to community
                        rewards, reinforcing our commitment to the community.
                      </dd>
                    </dl>
                  </li>
                  <li className="py-8">
                    <dl className="relative flex flex-wrap gap-x-3">
                      <dd className="w-full flex-none text-lg font-semibold tracking-tight text-gray-900 dark:text-white">
                        <a>
                          <CheckCircleIcon
                            className="mb-1 mr-2 h-5 w-5 flex-none text-purple-600 inline"
                            aria-hidden="true"
                          />
                          Token Lockup and Release
                          <span
                            className="absolute inset-0"
                            aria-hidden="true"
                          />
                        </a>
                      </dd>
                      <dt className="sr-only">Description</dt>
                      <dd className="mt-2 w-full flex-none text-base leading-7 text-gray-600 dark:text-gray-200">
                        Please note that the tokens acquired during the pre-sale
                        will be locked for 9 months and linearly released.
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white dark:bg-zinc-900 px-6 py-32 mt-16 lg:px-8">
          <div className="mx-auto max-w-7xl  text-base text-center leading-7 text-gray-700">
            <p className="text-base font-semibold leading-7 text-purple-600">
              ...and don't forget about the
            </p>
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
              Rewards and Incentives
            </h1>
            <div className="mx-auto mt-10 max-w-2xl text-start">
              <div className="content dark:text-white">
                <CheckCircleIcon
                  className="mb-1 mr-2 h-5 w-5 flex-none text-purple-600 inline"
                  aria-hidden="true"
                />
                <strong>Free registration</strong> for 1 month of Time Sniper
                features
                <br />
                <br />
                <CheckCircleIcon
                  className="mb-1 mr-2 h-5 w-5 flex-none text-purple-600 inline"
                  aria-hidden="true"
                />
                <strong>
                  $Time token will have a soulbound/wallet bound token
                </strong>
                , called $WARP attached to it only obtainable during the
                pre-sale or staking in the Time Bank.
                <br />
                During the pre-sale investors will get a 1:1 ratio for $Time
                tokens purchased. In the Time bank users will get different
                ratios depending on the length staked. <br />
                $WARP token will be Power or "stamina" within Time War's. <br />
                This will allow for faster repairing, quicker firing, and better
                overall stats in game.
                <br />
                <br />
                <CheckCircleIcon
                  className="mb-1 mr-2 h-5 w-5 flex-none text-purple-600 inline"
                  aria-hidden="true"
                />
                <strong>An exclusive Proof-of-Attendance NFT</strong>, with
                rarity based on the number of tokens purchased, The
                Proof-of-Attendance NFTs come in various rarities, with
                different Time Stamps assigned to each:
                <br /> <br />
                <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
                  <li>
                    <strong>Common NFT</strong>: 1,000 tokens, Time Stamp T1
                  </li>
                  <li>
                    <strong>Uncommon NFT</strong>: 5,000 tokens, Time Stamp T2
                  </li>
                  <li>
                    <strong>Rare NFT</strong>: 25,000 tokens, Time Stamp T3
                  </li>
                  <li>
                    <strong>Epic NFT</strong>: 50,000 tokens, Time Stamp T4
                  </li>
                  <li>
                    <strong>Legendary NFT</strong>: 250,000 tokens, Time Stamp
                    T5
                  </li>
                </ul>
                <br />
                These Proof of Attendance NFTs Will come with several utility's,
                such as boosting base APR in the Time Bank, With an{' '}
                <strong>exclusive drop</strong> in the future for a Time Reaver
                on the Polygon Chain. Time Reavers will have a special role in
                the out come of the Time War battles you participate in.
                <br />
                <br />
                <CheckCircleIcon
                  className="mb-1 mr-2 h-5 w-5 flex-none text-purple-600 inline"
                  aria-hidden="true"
                />
                <strong>Introducing the Time Sniper leveling system</strong>,
                based on the amount you spend through the sniper purchasing your
                favorite NFTs, each level will come with free $Time tokens to
                claim.
                <br />
                Exact figures will be revealed closer to the day the level
                system goes live.
                <br />
                Soon after $Time pre-sale is over $Time Single Stake will go
                live. <br />
                Starting APR will be 200% boostable with Cronos planets or Time
                Stamps. as far as the nft boosts, Time stamps will boost base
                APR at a rate going by tier:
                <br />
                <br />
                <ul
                  style={{
                    listStyleType: 'disc',
                    marginLeft: '20px',
                  }}
                >
                  <li>
                    <strong>Tier 1</strong>: 1%
                  </li>
                  <li>
                    <strong>Tier 2</strong>: 2%
                  </li>
                  <li>
                    <strong>Tier 3</strong>: 3%
                  </li>
                  <li>
                    <strong>Tier 4</strong>: 4%
                  </li>
                  <li>
                    <strong>Tier 5</strong>: 5%
                  </li>
                </ul>
                <br />
                <br />
                <CheckCircleIcon
                  className="mb-1 mr-2 h-5 w-5 flex-none text-purple-600 inline"
                  aria-hidden="true"
                />
                <strong>
                  The Time Poly Planets will boost up 50% boost on current APR:
                </strong>
                <br />
                <br />
                <ul
                  style={{
                    listStyleType: 'disc',
                    marginLeft: '20px',
                  }}
                >
                  <li>
                    <strong>10% boost</strong> per NFT
                  </li>
                </ul>
                <br />
                Max 5 slots overall of both kinds:
                <br />
                <br />
                <ul
                  style={{
                    listStyleType: 'disc',
                    marginLeft: '20px',
                  }}
                >
                  <li>
                    <strong>Slot 1</strong>: Free
                  </li>
                  <li>
                    <strong>Slot 2</strong>: 100 $Time
                  </li>
                  <li>
                    <strong>Slot 3</strong>: 150 $Time
                  </li>
                  <li>
                    <strong>Slot 4</strong>: 200 $Time
                  </li>
                  <li>
                    <strong>Slot 5</strong>: 250 $Time
                  </li>
                </ul>
                <br />
                100% of the $Time from unlocking slots is automatically burned.
              </div>
            </div>
            <Swiper
              className="mt-20 rounded-lg swiper-container"
              autoplay={true}
              slidesPerView={1}
              loop={true}
              breakpoints={{
                // When window width is <= 768px, set slidesPerView to 1
                769: {
                  slidesPerView: 3,
                },
              }}
            >
              <SwiperSlide>
                <Image3D src="/content/4.png" alt="Description" />
              </SwiperSlide>
              <SwiperSlide>
                <Image3D src="/content/1.png" alt="Description" />
              </SwiperSlide>
              <SwiperSlide>
                <Image3D src="/content/3.png" alt="Description" />
              </SwiperSlide>
              <SwiperSlide>
                <Image3D src="/content/2.png" alt="Description" />
              </SwiperSlide>
              <SwiperSlide>
                <Image3D src="/content/5.png" alt="Description" />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
}

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import ApiClient, { Collection, Token } from '../../../utils/ApiClient';
import { collectionImage, numberWithCommas } from '../../../utils/parser';
import FloorDistributionChart from '../components/chart/FloorDistributionChart';
import VolumeChart from '../components/chart/VolumeChart';
import TokenCard from '../components/token/TokenCard';
import TraitList from '../components/token/TraitList';

export default function ChartPage() {
  const [collection, setCollection] = useState<Collection>();
  const [collectionTokens, setCollectionTokens] = useState([]);

  const isDark = document.body.parentElement?.classList.contains('dark');

  const isPolygon = collection?.network === 'POLYGON';

  const { user } = useAuth();

  let { collectionAddress } = useParams();
  useEffect(() => {
    if (collectionAddress === undefined) return;
    ApiClient.getCollection(collectionAddress)
      .then(data => {
        setCollection(data.data.data);
      })
      .catch(e => console.error(e));
  }, []);

  if (collection === undefined)
    return <div className="dark:text-gray-200"></div>;

  return (
    <div className="bg-white dark:bg-zinc-900 pb-12 rounded-lg">
      <div>
        <img
          className="h-32 w-full rounded-lg object-cover lg:h-48"
          src={collectionImage(collection.network, collection.himage)}
          alt=""
        />
      </div>
      {/* <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8"> */}
      <div className="-mt-12 sm:-mt-16 sm:flex sm:space-x-5 flex items-center justify-center">
        <img
          className="h-24 w-24 self-center rounded-full ring-4 ring-white sm:h-32 sm:w-32"
          src={collectionImage(collection.network, collection.image)}
          alt=""
        />
        {/* </div> */}
      </div>

      <div className="hidden sm:block 3xl:hidden mt-6 min-w-0 flex-1 ">
        <h1 className="text-2xl font-bold text-center text-gray-900 dark:text-gray-200 truncate">
          {collection.name}
        </h1>
        <p className=" m-10 px-10 text-center dark:text-gray-400">
          {collection.desc}
        </p>
      </div>
      <div className="mt-10 pb-12 bg-white dark:bg-zinc-900 sm:pb-16 ">
        <div className="relative">
          <div className="absolute inset-0 h-1/2 bg-gray-50 dark:bg-zinc-900" />
          <div className="relative max-w-8xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
              <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-4">
                <div className="flex flex-col border-b border-gray-100 dark:border-zinc-700 dark:bg-zinc-800 p-6 text-center sm:border-0 sm:border-r">
                  <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500 dark:text-gray-400">
                    Floor {isPolygon ? '(MATIC)' : '(CRO)'}
                  </dt>
                  <dd className="order-1 text-4xl font-extrabold text-blue-600">
                    {Number(collection.pFloor).toFixed(2)}
                  </dd>
                </div>
                <div className="flex flex-col border-t border-b border-gray-100 dark:border-zinc-700 dark:bg-zinc-800 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                  <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500 dark:text-gray-400">
                    Volume {isPolygon ? '(MATIC)' : '(CRO)'}
                  </dt>
                  <dd className="order-1 text-4xl font-extrabold text-blue-600">
                    {numberWithCommas(Math.round(collection.pVolume))}
                  </dd>
                </div>
                {!isPolygon ? (
                  <div className="flex flex-col border-t border-gray-100 dark:border-zinc-700 dark:bg-zinc-800 p-6 text-center sm:border-0 sm:border-l">
                    <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500 dark:text-gray-400">
                      Sales
                    </dt>
                    <dd className="order-1 text-4xl font-extrabold text-blue-600">
                      {numberWithCommas(collection.pSales)}
                    </dd>
                  </div>
                ) : (
                  <div className="flex flex-col border-t border-gray-100 dark:border-zinc-700 dark:bg-zinc-800 p-6 text-center sm:border-0 sm:border-l">
                    <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500 dark:text-gray-400">
                      Unique Items
                    </dt>
                    <dd className="order-1 text-4xl font-extrabold text-blue-600">
                      {numberWithCommas(
                        collection.supply === 0 || collection.supply === 1
                          ? '?'
                          : collection.supply,
                      )}
                    </dd>
                  </div>
                )}
                <div className="flex flex-col border-t border-gray-100 dark:border-zinc-700 dark:bg-zinc-800 p-6 text-center sm:border-0 sm:border-l">
                  <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500 dark:text-gray-400">
                    FOMO Meter
                  </dt>
                  <dd className="order-1 text-4xl font-extrabold text-blue-600">
                    N/A
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col pl-6 pr-6">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-3">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <h3
                  className="ml-5 mt-5 mb-0 pb-0"
                  style={{
                    fontWeight: '600',
                    color: isDark ? '#E5E7EB' : 'rgb(17, 24, 39)',
                    fontSize: '16px',
                    fontFamily:
                      'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                  }}
                >
                  Daily Volume + Average Price Paid{' '}
                  {collection.network === 'POLYGON' ? '(MATIC)' : '(CRO)'}
                </h3>
                {/* <VolumeChart collection={collection} /> */}
              </div>
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <h3
                  className="ml-5 mt-5 mb-0 pb-0"
                  style={{
                    fontWeight: '600',
                    color: isDark ? '#E5E7EB' : 'rgb(17, 24, 39)',
                    fontSize: '16px',
                    fontFamily:
                      'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                  }}
                >
                  Current Floor Distribution (Sell Walls)
                </h3>
                {/* <FloorDistributionChart collection={collection} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col pl-6 pr-6 mt-6">
        <div className="grid sm:grid-cols-1 md:grid-cols-1 gap-3">
          {/* <div className="overflow-hidden shadow ring-1 dark:bg-zinc-800 ring-black ring-opacity-5 md:rounded-lg pb-4 pt-4 pl-6">
            <h3 className=" font-semibold text-gray-900 dark:text-gray-200 pb-4">
              Project Profile
            </h3>
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <dl className="sm:mx-auto sm:grid sm:max-w-3xl sm:grid-cols-3 sm:gap-8">
                  <div className="flex flex-col">
                    <dt className="order-2 text-gray-500 text-sm font-medium leading-6 dark:text-zinc-400">
                      Coming soon...
                    </dt>
                    <dd className="order-1 text-2xl text-gray-800 font-bold tracking-tight dark:text-zinc-100"></dd>
                  </div>
                </dl>
              </div>
            </div>
          </div> */}

          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg pb-4 pt-4 pl-6 dark:bg-zinc-800">
            <h3 className=" font-semibold text-gray-900 pb-4 dark:text-gray-200">
              Historical Price and Volume
            </h3>
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8 pr-6">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black dark:ring-zinc-800 ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300 dark:divide-zinc-700">
                    <thead className="bg-gray-50 dark:bg-zinc-800">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-200 sm:pl-3"
                        >
                          Timeframe
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
                        >
                          1 Day
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
                        >
                          7 Day
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
                        >
                          30 Day
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white dark:bg-zinc-800 dark:divide-zinc-800">
                      <tr>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400">
                          Volume
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400">
                          {collection.pVolume1d}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400">
                          {collection.pVolume7d}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400">
                          {collection.pVolume30d}
                        </td>
                      </tr>
                      <tr>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400">
                          Number of Sales
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400">
                          {numberWithCommas(collection.pSales1d)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400">
                          {numberWithCommas(collection.pSales7d)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400">
                          {numberWithCommas(collection.pSales30d)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

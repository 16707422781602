/* eslint-disable no-restricted-globals */
import { useEffect, useState } from 'react';
import { useAuth } from '../../../../hooks/useAuth';
import ApiClient from '../../../../utils/ApiClient';
import AdminTabs from '../../components/admin/AdminTabs';
import { ethers } from 'ethers';
import { ensureCronos, ensurePolygon } from '../../../../web3/utils';
import DialogScreen from '../../components/DialogScreen';
import { PlusCircleIcon } from '@heroicons/react/24/outline';

const acceptedChains = ['CRONOS', 'POLYGON'];

export default function Subscriptions() {
  const [subscriptions, setSubscriptions] = useState<any[]>([]);
  const [whitelists, setWhitelists] = useState<any[]>([]);
  const [showAddWhitelist, setShowAddWhitelist] = useState(false);
  const [chain, setChain] = useState('CRONOS');
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');

  useEffect(() => {
    ApiClient.admin_getSubscriptions().then(data => {
      setSubscriptions(data.subscriptions);
    });

    ApiClient.admin_getProjectWhitelists().then(data => {
      setWhitelists(data.whitelists);
    });
  }, []);

  function onDeleteWhitelist(id: number) {
    ApiClient.admin_deleteProjectWhitelist(id)
      .then(r => {
        if (r.success) {
          ApiClient.admin_getProjectWhitelists().then(data => {
            setWhitelists(data.whitelists);
          });
        }
      })
      .catch(e => {
        alert(e.response.data.message);
      });
  }

  function onNewWhitelist() {
    try {
      if (!ethers.utils.getAddress(address)) {
      }
    } catch {
      alert('Invalid address');
    }

    ApiClient.admin_newProjectWhitelist(
      name,
      ethers.utils.getAddress(address),
      chain,
    )
      .then(data => {
        if (data.success) {
          setShowAddWhitelist(false);
          setName('');
          setAddress('');
          ApiClient.admin_getProjectWhitelists().then(data => {
            setWhitelists(data.whitelists);
          });
        } else {
          alert(data.error);
        }
      })
      .catch(e => {
        alert(e.response.data.message);
      });
  }

  return (
    <>
      <DialogScreen open={showAddWhitelist} setOpen={setShowAddWhitelist}>
        <div>
          <h2 className="font-bold mb-4">Add Project Whitelist</h2>
          <div className="mt-4">
            <label
              htmlFor="editTag"
              className="block text-sm font-medium text-gray-700"
            >
              Name
            </label>
            <input
              type="text"
              id="editTag"
              name="tag"
              value={name}
              onChange={e => {
                setName(e.target.value);
              }}
              placeholder={'E.g. Candy City, MMB'}
              className="mt-1 mb-4 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <label
            htmlFor="chain"
            className="block text-sm font-medium text-gray-700"
          >
            Chain
          </label>
          <select
            id="chain"
            name="chain"
            value={chain}
            onChange={e => {
              setChain(e.target.value);
            }}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="">Select a Chain</option>
            {acceptedChains.map(chain => (
              <option key={chain} value={chain}>
                {chain}
              </option>
            ))}
          </select>
          <div className="mt-4">
            <label
              htmlFor="editTag"
              className="block text-sm font-medium text-gray-700"
            >
              Contract Address
            </label>
            <input
              type="text"
              id="address"
              name="address"
              value={address}
              onChange={e => {
                setAddress(e.target.value);
              }}
              placeholder={'0x'}
              className="mt-1 mb-4 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <button
            type="button"
            onClick={onNewWhitelist}
            className={`mt-6 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none`}
          >
            Add Project
          </button>
        </div>
      </DialogScreen>
      <AdminTabs />
      <h2 className="mt-6 text-xl font-semibold text-gray-900 dark:text-white">
        Subscriptions
      </h2>
      <div className="flow-root overflow-hidden">
        <div className=" max-w-7xl">
          <table className="w-full text-left">
            <thead className="bg-white">
              <tr>
                <th
                  scope="col"
                  className="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                >
                  Title
                  <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
                  <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Type
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                >
                  Active Users
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Price (USD)
                </th>
                <th scope="col" className="relative py-3.5 pl-3">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {subscriptions.map((sub: any) => (
                <tr key={sub.id}>
                  <td className="relative py-4 pr-3 text-sm font-medium text-gray-900">
                    {sub.title}
                    <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                    <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    {sub.type}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                    {sub._count.AccountSubscription}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    {sub.amount_usd}
                  </td>
                  <td className="relative py-4 pl-3 text-right text-sm font-medium">
                    <a
                      target="_blank"
                      href="https://discord.gg/gDYDAQA78K"
                      className="text-purple-600 hover:text-purple-700"
                      rel="noreferrer"
                    >
                      Edit
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <h2 className="mt-12 text-xl font-semibold text-gray-900 dark:text-white mb-6">
        Project Whitelists{' '}
        <PlusCircleIcon
          className="h-5 w-5 inline cursor-pointer"
          onClick={() => {
            setShowAddWhitelist(true);
          }}
        />
      </h2>
      <div className="flow-root overflow-hidden">
        <div className=" max-w-7xl">
          <table className="w-full text-left">
            <thead className="bg-white">
              <tr>
                <th
                  scope="col"
                  className="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                >
                  Project Name
                  <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
                  <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Project Address
                </th>
                <th scope="col" className="relative py-3.5 pl-3">
                  <span className="sr-only">Delete</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {whitelists.map((sub: any) => (
                <tr key={sub.id}>
                  <td className="relative py-4 pr-3 text-sm font-medium text-gray-900">
                    {sub.name}
                    <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                    <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    {sub.address}
                  </td>

                  <td className="relative py-4 pl-3 text-right text-sm font-medium">
                    <a
                      href="#"
                      onClick={() => {
                        if (
                          confirm(
                            `Are you sure you want to delete ${sub.name} (${sub.address})?`,
                          )
                        ) {
                          onDeleteWhitelist(sub.id);
                        }
                      }}
                      className="text-purple-600 hover:text-purple-700"
                      rel="noreferrer"
                    >
                      Delete
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

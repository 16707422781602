import { useCallback, useEffect, useRef, useState } from 'react';
import timeWarsClient, { Component } from '../../../../utils/TimeWarsClient';
import '../../../styles/TimeWars.css';
import ApiClient from '../../../../utils/ApiClient';
import { useAuth } from '../../../../hooks/useAuth';
import classNames from '../../../../utils/classNames';
import _, { set } from 'lodash';
import { numberWithCommas } from '../../../../utils/parser';
import TIME_PAYMENTS_ABI from '../../../../web3/ABI/timepayments.abi.json';
import { BigNumber, ethers } from 'ethers';
import { ensureCronos, txWait } from '../../../../web3/utils';
import ERC20_ABI from '../../../../web3/ABI/erc20.cro.abi.json';
const wallet = null;

const TIMEWARS_PAYMENT_ADDRESS = '0x726ABF85b4610d233c7C2C9a7d105497c16D43fd';

const EMIT_ADDRESS = '0x9FA6552c1E9df51070A3b456355B5d76cBD59B5A';
enum RESOURCES {
  TITANIUM = 'titanium',
  DARK_MATTER = 'dark_matter',
  ABYSS = 'abyss',
  STARDUST = 'stardust',
}
interface ResourceCosts {
  [RESOURCES.TITANIUM]: number;
  [RESOURCES.DARK_MATTER]: number;
  [RESOURCES.ABYSS]: number;
  [RESOURCES.STARDUST]: number;
}

const SHIP_NAME_TO_TIER = {
  Trailblazer: 1,
  Patience: 2,
  Carthage: 3,
  Muashi: 4,
  Thebes: 5,
  'HG Behemoth': 6,
  'CS Intrepid': 7,
  'Red Imperial': 8,
  'Silver Anaconda': 9,
  'X-43 Gladius': 10,
  'Pirate Verminus': 11,
  'Starforce Dagger': 12,
  'ISS Battlestar': 13,
  'Planetary Rascal': 14,
  'SR-119 Cataclysm': 15,
};

/* eslint-disable react/jsx-no-undef */
const ShipYardDashboard = () => {
  const { user } = useAuth();
  const [myResources, setMyResources] = useState<any>({
    [RESOURCES.TITANIUM]: 0,
    [RESOURCES.DARK_MATTER]: 0,
    [RESOURCES.ABYSS]: 0,
    [RESOURCES.STARDUST]: 0,
  });

  useEffect(() => {
    ApiClient.getMyTimewarsResources().then(r => {
      setMyResources(r);
    });
  }, []);

  const [loadingShips, setLoadingShips] = useState(true);
  const [loadingComponents, setLoadingComponents] = useState(true);

  const [onComponentSelectReset, setOnComponentSelectReset] = useState(false);
  const [unverifiedShips, setUnverifiedShips] = useState<any[]>([]);
  const [upgradedShips, setUpgradedShips] = useState<any[]>([]);

  const [showShips, setShowShips] = useState(false);
  const showShipsRef = useOutsideClick(() => setShowShips(false));

  const [selectedShip, setSelectedShip] = useState<undefined | any>(undefined);

  const [components, setComponents] = useState<any[]>([]);

  const [allowedEmit, setAllowedEmit] = useState<BigNumber>(
    BigNumber.from('0'),
  );
  const retrieveShipsAndComponents = useCallback(
    function retrieveShipsAndComponents(redirectToShip?: number) {
      (async () => {
        setComponents([]);

        const componentIds = _.chunk(
          await timeWarsClient.getComponentsOfOwner(
            wallet || user.data?.address!,
          ),
          10,
        );

        console.log(`Component ids length: ${componentIds.length}`);
        const localComponents: any = [];
        for (let i = 0; i < componentIds.length; i += 1) {
          const data =
            await timeWarsClient.getUniqueListOfUnusedComponentsInChunks(
              componentIds[i],
            );

          const componentsInChunk: any = [];
          for (let x = 0; x < data.length; x += 1) {
            const component: any = data[x];
            if (localComponents.find((c: any) => c.id === component.id))
              continue;

            localComponents.push({
              ...component,
            });
            componentsInChunk.push({
              ...component,
            });
          }

          setComponents(prev => {
            return [...prev, ...componentsInChunk];
          });

          setLoadingComponents(false);
        }

        // make sure components doesnt have duplicate combination of type and tier

        setComponents(localComponents);
      })();

      (async () => {
        setUpgradedShips([]);
        setUnverifiedShips([]);
        const shipIds = _.chunk(
          await timeWarsClient.getShipsOfOwner(wallet || user.data?.address!),
          50,
        );

        const ships: any = [];
        for (let i = 0; i < shipIds.length; i += 1) {
          const data = await timeWarsClient.getListOfShipsInChunks(shipIds[i]);

          const shipsInChunk: any = [];
          for (let x = 0; x < data.length; x += 1) {
            const ship: any = data[x];

            ships.push(ship);
            shipsInChunk.push(ship);
          }

          setUpgradedShips(prev => {
            return [
              ...prev,
              ...shipsInChunk.filter((s: any) => s.ShipStatus !== 'NOT_BUILT'),
            ];
          });

          setUnverifiedShips(prev => {
            return [
              ...prev,
              ...shipsInChunk.filter((s: any) => s.ShipStatus === 'NOT_BUILT'),
            ];
          });
          setLoadingShips(false);
        }

        setUpgradedShips(
          ships.filter((s: any) => s.ShipStatus !== 'NOT_BUILT'),
        );

        setUnverifiedShips(
          ships.filter((s: any) => s.ShipStatus === 'NOT_BUILT'),
        );

        if (redirectToShip) {
          setSelectedShip(ships.find((s: any) => s.id === redirectToShip));
        }
      })();
    },
    [user.data?.address],
  );

  const FilledSlot = useCallback(
    function FilledSlot({ slot, index }: any) {
      const [showTimer, setShowTimer] = useState(false);
      const [componentBuildCountdown, setComponentBuildCountdown] =
        useState<number>(0);

      useEffect(() => {
        const secondsUntilBuild =
          slot?.status === 'PENDING_ATTACH'
            ? new Date(slot?.timeUntilActionComplete).getTime() >
              new Date().getTime()
              ? Math.floor(
                  (new Date(slot.timeUntilActionComplete).getTime() -
                    new Date().getTime()) /
                    1000,
                )
              : 0
            : -1;

        if (secondsUntilBuild) {
          setComponentBuildCountdown(secondsUntilBuild);
          const interval = setInterval(() => {
            setComponentBuildCountdown(prev => {
              if (prev === 0) {
                clearInterval(interval);
                retrieveShipsAndComponents();
                return 0;
              }

              return prev - 1;
            });
          }, 1000);

          return () => clearInterval(interval);
        }

        // if countdown is met, refetch ships and components
        if (secondsUntilBuild <= 0) {
          setTimeout(() => retrieveShipsAndComponents(selectedShip?.id), 5000);

          return () => {
            setComponentBuildCountdown(0);
          };
        }
      }, [slot]);

      return (
        <div
          onMouseOver={() => setShowTimer(true)}
          onMouseLeave={() => setShowTimer(false)}
          key={index}
          className={`${
            slot.status === 'PENDING_ATTACH'
              ? 'console-orange orange-text-glow cursor-pointer'
              : slot.status === 'ATTACHED'
              ? 'console-green green-text-glow cursor-pointer'
              : ''
          }`}
        >
          {`T${slot.tier} ${slot.name.replace(
            'Time Ship',
            _.capitalize(slot.type),
          )}`}
          {showTimer && (
            <div
              className={`absolute mt-0.5 ml-0.5 w-max z-50 px-2 
              
             bg-black bg-opacity-80 border rounded-sm shadow-lg console-white white-text-glow `}
            >
              {slot.status === 'PENDING_ATTACH' ? (
                <div>
                  <div className="text-center text-sm">
                    Build time left:
                    <br />
                    {formatBuildTimeCountdown(componentBuildCountdown)}
                  </div>
                </div>
              ) : slot.status === 'ATTACHED' ? (
                <div>
                  <div className="text-center">Attached</div>
                </div>
              ) : (
                <div>
                  <div className="text-center">Will take </div>
                </div>
              )}
            </div>
          )}
        </div>
      );
    },
    [selectedShip],
  );

  useEffect(() => {
    retrieveShipsAndComponents();
  }, []);

  const [actions, setActions] = useState<{
    attach: any[];
    detach: any[];
    shipId: number;
  }>({ attach: [], detach: [], shipId: 0 });

  const [estimatedActions, setEstimatedActions] = useState<{
    attach: any[];
    detach: any[];
    componentsResourceCost?: ResourceCosts;
    shipResourceCost?: ResourceCosts;
    totalResourceCost?: ResourceCosts;
    totalBuildTimeMins?: number;
    emitInstantBuildCost?: number;
    shipId: number;
  }>({ attach: [], detach: [], shipId: 0 });

  useEffect(() => {
    if (!selectedShip) return;

    setActions({ attach: [], detach: [], shipId: selectedShip.id });
    setEstimatedActions({ attach: [], detach: [], shipId: selectedShip.id });
    setComponents(prev => {
      return prev.map(p => {
        delete p.selected;
        return p;
      });
    });

    setOnComponentSelectReset(true);
  }, [selectedShip]);

  useEffect(() => {
    if (
      !actions ||
      (actions.attach.length === 0 && actions.detach.length === 0)
    )
      return;

    ApiClient.timewars_estimateActionCost(actions.shipId, actions).then(r => {
      console.log(r);
      setEstimatedActions(r);
    });
  }, [actions.shipId, actions.attach, actions.detach, actions]);

  // Calculate estimate actions cost and add it to the resource count
  const [resourceCost, setResourceCost] = useState<ResourceCosts>({
    titanium: 0,
    dark_matter: 0,
    abyss: 0,
    stardust: 0,
  });
  const [buildTimeMins, setBuildTimeMins] = useState<number>(0);
  const formattedBuildTime = formatBuildTime(buildTimeMins);

  function resetConsoleAndResources() {
    setActions({ attach: [], detach: [], shipId: 0 });
    setEstimatedActions({ attach: [], detach: [], shipId: 0 });
    setMyResources({
      [RESOURCES.TITANIUM]: 0,
      [RESOURCES.DARK_MATTER]: 0,
      [RESOURCES.ABYSS]: 0,
      [RESOURCES.STARDUST]: 0,
    });
    setSelectedShip(undefined);
    setOnComponentSelectReset(true);
    setBuildTimeMins(0);
    setResourceCost({
      titanium: 0,
      dark_matter: 0,
      abyss: 0,
      stardust: 0,
    });
    ApiClient.getMyTimewarsResources().then(r => {
      setMyResources(r);
    });
  }

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState<'error' | 'success'>('error');
  function showAlert(message: string, type: 'error' | 'success') {
    setAlertMessage(message);
    setAlertType(type);
    setAlertVisible(true);
  }

  async function onApproveEmit(amount: BigNumber) {
    if (!user || !user.wallet || !user.isConnected || !user.isAuthenticated) {
      console.log('User or wallet not fully initialized or not connected');
      alert('User or wallet not fully initialized or not connected');
      return;
    }

    if (amount.lte(0)) {
      alert('Invalid amount.');
      return;
    }

    try {
      await ensureCronos();
      const provider = new ethers.providers.Web3Provider(window.ethereum);

      const signer = provider.getSigner();
      const contract = new ethers.Contract(EMIT_ADDRESS, ERC20_ABI, signer);

      await txWait(contract.approve(TIMEWARS_PAYMENT_ADDRESS, amount));
      getAllowance();
    } catch (error: any) {
      console.log(error.code);
      switch (error.code) {
        case -32603:
          alert('You do not have enough funds to perform this transaction.');
          break;
        default:
          //alert('Something went wrong, please view the console logs.');
          break;
      }
      console.error('Failed:', error);
    }
  }

  useEffect(() => {
    if (!estimatedActions || !estimatedActions.totalResourceCost) {
      setResourceCost({
        titanium: 0,
        dark_matter: 0,
        abyss: 0,
        stardust: 0,
      });

      setBuildTimeMins(0);

      return;
    }

    setResourceCost(estimatedActions.totalResourceCost);
    setBuildTimeMins(estimatedActions.totalBuildTimeMins || 0);
  }, [estimatedActions]);
  const selectedShipArmorFilledSlots: any =
    selectedShip?.TimeWarsComponent.filter((c: any) => c.type === 'ARMOR');
  const selectedShipArmorOpenSlots = selectedShip?.attributes.find(
    (a: any) => a.trait_type === 'Armour',
  )?.value; // todo get backend ones

  const selectedShipEngineFilledSlots: any =
    selectedShip?.TimeWarsComponent.filter((c: any) => c.type === 'ENGINE');
  const selectedShipEngineOpenSlots = selectedShip?.attributes.find(
    (a: any) => a.trait_type === 'Engine',
  )?.value; // todo get backend ones

  const selectedShipWeaponFilledSlots: any =
    selectedShip?.TimeWarsComponent.filter((c: any) => c.type === 'WEAPON');
  const selectedShipWeaponOpenSlots = selectedShip?.attributes.find(
    (a: any) => a.trait_type === 'Weapon',
  )?.value; // todo get backend ones

  const selectedShipShieldFilledSlots: any =
    selectedShip?.TimeWarsComponent.filter((c: any) => c.type === 'SHIELD');
  const selectedShipShieldOpenSlots = selectedShip?.attributes.find(
    (a: any) => a.trait_type === 'Shield',
  )?.value; // todo get backend ones

  const selectedShipSpecialFilledSlots: any =
    selectedShip?.TimeWarsComponent.filter((c: any) => c.type === 'SPECIAL');
  const selectedShipSpecialOpenSlots = selectedShip?.attributes.find(
    (a: any) => a.trait_type === 'Special',
  )?.value; // todo get backend ones

  const selectedShipSkin = selectedShip?.attributes.find(
    (a: any) => a.trait_type === 'Skins',
  )?.value; // todo get backend one

  const selectedShipShipName = selectedShip?.attributes.find(
    (a: any) => a.trait_type === 'Ship',
  )?.value; // todo get backend one

  const uniqueComponentsEngine = components.filter(c => c.type === 'ENGINE');

  const uniqueComponentsWeapon = components.filter(c => c.type === 'WEAPON');

  const uniqueComponentsShield = components.filter(c => c.type === 'SHIELD');

  const uniqueComponentsSpecial = components.filter(c => c.type === 'SPECIAL');

  const uniqueComponentsArmor = components.filter(c => c.type === 'ARMOR');

  console.log({ estimatedActions, formattedBuildTime });

  const [actionPending, setActionPending] = useState(false);

  // if selected ship is building stuff
  const [buildCountdown, setBuildCountdown] = useState<number>(0);
  // selectedShip?.timeUntilActionCompleteUTC, need to convert to local time from DateTime

  async function getAllowance() {
    await ensureCronos();
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const timewarsPaymentContract = new ethers.Contract(
      EMIT_ADDRESS,
      ERC20_ABI,
      provider,
    );

    const gotTokenAllowance = await timewarsPaymentContract.allowance(
      wallet ? wallet! : user.data?.address,
      TIMEWARS_PAYMENT_ADDRESS,
    );

    setAllowedEmit(gotTokenAllowance);
  }

  useEffect(() => {
    getAllowance();
  }, [user.data?.address]);

  console.log({ allowedEmit });
  useEffect(() => {
    const secondsUntilBuild =
      selectedShip?.ShipStatus === 'BUILDING'
        ? new Date(selectedShip?.timeUntilActionComplete).getTime() >
          new Date().getTime()
          ? Math.floor(
              (new Date(selectedShip.timeUntilActionComplete).getTime() -
                new Date().getTime()) /
                1000,
            )
          : 0
        : -1;

    if (secondsUntilBuild) {
      setBuildCountdown(secondsUntilBuild);
      const interval = setInterval(() => {
        setBuildCountdown(prev => {
          if (prev === 0) {
            clearInterval(interval);
            retrieveShipsAndComponents();
            return 0;
          }

          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(interval);
    }

    // if countdown is met, refetch ships and components
    if (secondsUntilBuild <= 0) {
      setTimeout(() => retrieveShipsAndComponents(selectedShip?.id), 2000);

      return () => {
        setBuildCountdown(0);
      };
    }
  }, [selectedShip]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {}, [components, selectedShip]);
  return (
    <div className="h-full w-full">
      <div className="w-full h-full flex justify-center items-center three-two-seventy">
        <div
          className="w-full max-w-screen-lg aspect-w-16 aspect-h-9 rounded-lg overflow-hidden p-4"
          style={{
            backgroundImage: 'url(/timewars/metal.jpg)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="overlay"></div>
          <div className="w-full h-full text-zinc-200  recharge mb-2 font-bold uppercase font-mono text-2xl  text-opacity-90">
            SHIPYARD
          </div>
          {/** Absolute alert, with X at the top */}
          {alertVisible && (
            <div
              className={`absolute top-0 left-0 w-full h-full flex justify-center items-center z-[54]`}
            >
              <div
                id="overlay"
                className="absolute w-full h-full bg-black opacity-30 z-[55]"
              />
              <div
                className={`bg-black  min-w-[20rem]  md:py-12 lg:py-12 pb-4 px-8 rounded-md z-[56] text-center text-white`}
              >
                <h3
                  className={`text-2xl font-bold uppercase text-center  three-two-seventy ${
                    alertType === 'error' ? 'console-red' : 'console-green'
                  }`}
                >
                  {alertType === 'error' ? 'Error' : 'Success'}
                </h3>
                {alertMessage}

                <div className="mt-4">
                  <button
                    onClick={() => setAlertVisible(false)}
                    className="recharge px-6 py-2 bg-black border border-white text-white uppercase disabled:opacity-50 disabled:cursor-not-allowed three-two-seventy text-sm font-bold text-center cursor-pointer hover:opacity-80"
                  >
                    {alertType === 'error' ? 'Ok' : 'Confirm'}
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className="w-full h-[26rem] bg-black rounded-sm mb-4 shadow-embed-inner green-glow relative">
            {/* View blueprints button */}
            {!loadingShips ? (
              <div
                className="absolute top-0 left-0 w-54 z-50"
                ref={showShipsRef}
              >
                <div
                  onClick={() => {
                    setShowShips(!showShips);
                  }}
                  className="console-button w-full m-0.5 select-none cursor-pointer z-10 flex flex-row items-center gap-x-4 console-green green-text-glow three-two-seventy text-xl"
                >
                  {showShips ? (
                    <UpIcon className="w-6 h-6" />
                  ) : (
                    <DownIcon className="w-6 h-6" />
                  )}
                  <div className="uppercase">
                    {selectedShip
                      ? `T${
                          SHIP_NAME_TO_TIER[
                            selectedShip.attributes?.find(
                              (a: any) => a.trait_type === 'Ship',
                            )?.value as keyof typeof SHIP_NAME_TO_TIER
                          ]
                        } ${
                          selectedShip.attributes?.find(
                            (a: any) => a.trait_type === 'Ship',
                          )?.value
                        } #${selectedShip.id}`
                      : showShips
                      ? 'HIDE BLUEPRINTS'
                      : 'VIEW BLUEPRINTS'}
                  </div>
                </div>
                {showShips && (
                  <div className="select-none absolute mt-0.5 ml-0.5 w-[100%] px-2 left-0 bg-green bg-opacity-75 border green-border rounded-sm shadow-lg green-glow">
                    <ul className="max-h-60 overflow-y-auto select-none console-scrollbar">
                      <li className="text-black font-bold p-2">
                        Upgraded Ships
                      </li>
                      {upgradedShips.map((ship, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            setSelectedShip(ship);

                            setShowShips(false);
                          }}
                          className="white-text-glow text-white three-two-seventy p-2 hover:opacity-50 cursor-pointer select-none"
                        >
                          {`T${
                            SHIP_NAME_TO_TIER[
                              ship.attributes?.find(
                                (a: any) => a.trait_type === 'Ship',
                              )?.value as keyof typeof SHIP_NAME_TO_TIER
                            ]
                          } ${
                            ship.attributes?.find(
                              (a: any) => a.trait_type === 'Ship',
                            )?.value
                          } #${ship.id}`}
                        </li>
                      ))}
                      <li className="text-black font-bold p-2">
                        Available Ships
                      </li>
                      {unverifiedShips.map((ship, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            setSelectedShip(ship);
                            setShowShips(false);
                          }}
                          className="white-text-glow text-white three-two-seventy p-2 hover:opacity-50 cursor-pointer select-none"
                        >
                          {`T${
                            SHIP_NAME_TO_TIER[
                              ship.attributes?.find(
                                (a: any) => a.trait_type === 'Ship',
                              )?.value as keyof typeof SHIP_NAME_TO_TIER
                            ]
                          } ${
                            ship.attributes?.find(
                              (a: any) => a.trait_type === 'Ship',
                            )?.value
                          } #${ship.id}`}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            ) : null}

            {/* Rest */}

            <div
              className={`relative h-full ${
                loadingShips || loadingComponents
                  ? 'flex justify-center align-middle items-center text-center'
                  : 'grid grid-flow-row grid-cols-3'
              } overflow-y-visible select-none`}
            >
              {!selectedShip && <div></div>}
              {loadingShips || loadingComponents ? (
                <div className="flex justify-center items-center self-center w-full">
                  <div className="white-text-glow console-white three-two-seventy text-xl w-full">
                    Loading your ships and components...
                  </div>
                </div>
              ) : (
                !selectedShip && (
                  <div className="flex justify-center items-center self-center">
                    <div className="white-text-glow console-white three-two-seventy text-xl">
                      {/* No ship selected. */}
                    </div>
                  </div>
                )
              )}
              {selectedShip && (
                <div className="console-green mt-6 three-two-seventy green-text-glow flex justify-center flex-col gap-y-12 items-start ml-16">
                  <div className="text-end">
                    Engine:
                    <br />
                    {selectedShipEngineFilledSlots.map(
                      (slot: any, index: number) => (
                        <FilledSlot slot={slot} index={index} />
                      ),
                    )}
                    {Array.from(
                      {
                        length:
                          selectedShipEngineOpenSlots -
                          selectedShipEngineFilledSlots.length,
                      },
                      (_, index) => (
                        <div key={index}>
                          <OpenSlotDropdown
                            ship={selectedShip}
                            label="Open Slot"
                            onReset={onComponentSelectReset}
                            setOnReset={setOnComponentSelectReset}
                            items={uniqueComponentsEngine}
                            onItemSelected={(
                              component: Component,
                              replacing: Component,
                            ) => {
                              setComponents(prev => {
                                if (replacing) {
                                  const replacingIndex = prev.findIndex(
                                    c => c.id === replacing.id,
                                  );
                                  prev[replacingIndex] = {
                                    ...replacing,
                                    selected: false,
                                  };
                                }

                                const oldIndex = prev.findIndex(
                                  c => c.id === component.id,
                                );
                                const old = prev[oldIndex];

                                prev[oldIndex] = { ...old, selected: true };
                                return prev;
                              });
                              setActions(prev => {
                                return {
                                  ...prev,
                                  attach: [
                                    ...prev.attach.filter((a: any) => {
                                      return replacing
                                        ? a.componentId !== replacing.id
                                        : true;
                                    }),
                                    {
                                      componentId: component.id,
                                    },
                                  ],
                                };
                              });
                            }}
                          />
                        </div>
                      ),
                    )}
                  </div>

                  <div className="ml-1 text-end">
                    Weapons:
                    <br />
                    {selectedShipWeaponFilledSlots.map(
                      (slot: any, index: number) => (
                        <FilledSlot slot={slot} index={index} />
                      ),
                    )}
                    {Array.from(
                      {
                        length:
                          selectedShipWeaponOpenSlots -
                          selectedShipWeaponFilledSlots.length,
                      },
                      (_, index) => (
                        <div key={index}>
                          <OpenSlotDropdown
                            ship={selectedShip}
                            label="Open Slot"
                            items={uniqueComponentsWeapon}
                            onReset={onComponentSelectReset}
                            setOnReset={setOnComponentSelectReset}
                            onItemSelected={(
                              component: Component,
                              replacing: Component,
                            ) => {
                              setComponents(prev => {
                                if (replacing) {
                                  const replacingIndex = prev.findIndex(
                                    c => c.id === replacing.id,
                                  );
                                  prev[replacingIndex] = {
                                    ...replacing,
                                    selected: false,
                                  };
                                }

                                const oldIndex = prev.findIndex(
                                  c => c.id === component.id,
                                );
                                const old = prev[oldIndex];

                                prev[oldIndex] = { ...old, selected: true };
                                return prev;
                              });

                              setActions(prev => {
                                return {
                                  ...prev,
                                  attach: [
                                    ...prev.attach.filter((a: any) => {
                                      return replacing
                                        ? a.componentId !== replacing.id
                                        : true;
                                    }),
                                    {
                                      componentId: component.id,
                                    },
                                  ],
                                };
                              });
                            }}
                          />
                        </div>
                      ),
                    )}
                  </div>
                </div>
              )}
              {selectedShip ? (
                <div className="flex justify-center items-center flex-col gap-y-1">
                  <div className="green-text-glow console-green three-two-seventy text-2xl text-center">
                    T
                    {
                      SHIP_NAME_TO_TIER[
                        selectedShipShipName as keyof typeof SHIP_NAME_TO_TIER
                      ]
                    }{' '}
                    {selectedShipShipName} ({selectedShipSkin})
                  </div>
                  <img
                    src={
                      'https://cdn.ltsglxy.network/ipfs/' + selectedShip.image
                    }
                    alt="ship"
                    className=" w-full relative opacity-90 rounded-md"
                    style={{ rotate: '90deg' }}
                    loading={'lazy'}
                  />
                </div>
              ) : null}
              {selectedShip && (
                <div className="console-green mt-6 three-two-seventy green-text-glow flex justify-center flex-col gap-y-9 items-center ">
                  <div className="text-start">
                    Specials:
                    <br />
                    {selectedShipSpecialFilledSlots.map(
                      (slot: any, index: number) => (
                        <FilledSlot slot={slot} index={index} />
                      ),
                    )}
                    {Array.from(
                      {
                        length:
                          selectedShipSpecialOpenSlots -
                          selectedShipSpecialFilledSlots.length,
                      },
                      (_, index) => (
                        <div key={index}>
                          <OpenSlotDropdown
                            onRight={true}
                            label="Open Slot"
                            items={uniqueComponentsSpecial}
                            onReset={onComponentSelectReset}
                            setOnReset={setOnComponentSelectReset}
                            ship={selectedShip}
                            onItemSelected={(
                              component: Component,
                              replacing: Component,
                            ) => {
                              setComponents(prev => {
                                if (replacing) {
                                  const replacingIndex = prev.findIndex(
                                    c => c.id === replacing.id,
                                  );
                                  prev[replacingIndex] = {
                                    ...replacing,
                                    selected: false,
                                  };
                                }

                                const oldIndex = prev.findIndex(
                                  c => c.id === component.id,
                                );
                                const old = prev[oldIndex];

                                prev[oldIndex] = { ...old, selected: true };
                                return prev;
                              });

                              setActions(prev => {
                                return {
                                  ...prev,
                                  attach: [
                                    ...prev.attach.filter((a: any) => {
                                      return replacing
                                        ? a.componentId !== replacing.id
                                        : true;
                                    }),
                                    {
                                      componentId: component.id,
                                    },
                                  ],
                                };
                              });
                            }}
                          />
                        </div>
                      ),
                    )}
                  </div>

                  <div className="text-start">
                    Armor:
                    <br />
                    {selectedShipArmorFilledSlots.map(
                      (slot: any, index: number) => (
                        <FilledSlot slot={slot} index={index} />
                      ),
                    )}
                    {Array.from(
                      {
                        length:
                          selectedShipArmorOpenSlots -
                          selectedShipArmorFilledSlots.length,
                      },
                      (_, index) => (
                        <div key={index}>
                          <OpenSlotDropdown
                            onRight={true}
                            label="Open Slot"
                            items={uniqueComponentsArmor}
                            onReset={onComponentSelectReset}
                            setOnReset={setOnComponentSelectReset}
                            ship={selectedShip}
                            onItemSelected={(
                              component: Component,
                              replacing: Component,
                            ) => {
                              setComponents(prev => {
                                if (replacing) {
                                  const replacingIndex = prev.findIndex(
                                    c => c.id === replacing.id,
                                  );
                                  prev[replacingIndex] = {
                                    ...replacing,
                                    selected: false,
                                  };
                                }

                                const oldIndex = prev.findIndex(
                                  c => c.id === component.id,
                                );
                                const old = prev[oldIndex];

                                prev[oldIndex] = { ...old, selected: true };
                                return prev;
                              });

                              setActions(prev => {
                                return {
                                  ...prev,
                                  attach: [
                                    ...prev.attach.filter((a: any) => {
                                      return replacing
                                        ? a.componentId !== replacing.id
                                        : true;
                                    }),
                                    {
                                      componentId: component.id,
                                    },
                                  ],
                                };
                              });
                            }}
                          />
                        </div>
                      ),
                    )}
                  </div>

                  <div className="text-start">
                    Shield:
                    <br />
                    {selectedShipShieldFilledSlots.map(
                      (slot: any, index: number) => (
                        <FilledSlot slot={slot} index={index} />
                      ),
                    )}
                    {Array.from(
                      {
                        length:
                          selectedShipShieldOpenSlots -
                          selectedShipShieldFilledSlots.length,
                      },
                      (_, index) => (
                        <div key={index}>
                          <OpenSlotDropdown
                            onRight={true}
                            label="Open Slot"
                            items={uniqueComponentsShield}
                            onReset={onComponentSelectReset}
                            setOnReset={setOnComponentSelectReset}
                            ship={selectedShip}
                            onItemSelected={(
                              component: Component,
                              replacing: Component,
                            ) => {
                              setComponents(prev => {
                                if (replacing) {
                                  const replacingIndex = prev.findIndex(
                                    c => c.id === replacing.id,
                                  );
                                  prev[replacingIndex] = {
                                    ...replacing,
                                    selected: false,
                                  };
                                }

                                const oldIndex = prev.findIndex(
                                  c => c.id === component.id,
                                );
                                const old = prev[oldIndex];

                                prev[oldIndex] = { ...old, selected: true };
                                return prev;
                              });
                              setActions(prev => {
                                return {
                                  ...prev,
                                  attach: [
                                    ...prev.attach.filter((a: any) => {
                                      return replacing
                                        ? a.componentId !== replacing.id
                                        : true;
                                    }),
                                    {
                                      componentId: component.id,
                                    },
                                  ],
                                };
                              });
                            }}
                          />
                        </div>
                      ),
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* Side Screens */}
          <div className="w-full grid lg:grid-cols-[1fr,1.2fr] h-1/3 gap-x-4">
            <div className="w-full h-56 bg-black rounded-sm shadow-embed-inner mr-2 flex justify-start items-start relative screen-gloss-less">
              {!selectedShip ? (
                <div className="pl-4 pt-1.5 px-4 console-white white-text-glow font-mono text-lg three-two-seventy">
                  Select a ship
                </div>
              ) : (
                <div>
                  <div className="pl-4 pt-1.5 px-4 console-green green-text-glow font-mono text-base three-two-seventy">
                    <div className="flex flex-1 flex-col">
                      <div>
                        <span className="underline text-lg">
                          T
                          {
                            SHIP_NAME_TO_TIER[
                              selectedShip.attributes?.find(
                                (a: any) => a.trait_type === 'Ship',
                              )?.value as keyof typeof SHIP_NAME_TO_TIER
                            ]
                          }{' '}
                          {
                            selectedShip.attributes?.find(
                              (a: any) => a.trait_type === 'Ship',
                            )?.value
                          }{' '}
                          #{selectedShip.id}
                        </span>
                      </div>
                      <div>Health: {selectedShip.health}</div>
                      <div>Armor: {selectedShip.armor}</div>
                      <div>Speed: {selectedShip.speed}</div>
                    </div>

                    <div className="bottom-0 mt-2">
                      Registered to:{' '}
                      {selectedShip.ownerAddress.slice(0, 6) +
                        '...' +
                        selectedShip.ownerAddress.slice(-4)}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="flex flex-col w-full space-y-2">
              <div className="h-28 bg-black rounded-sm shadow-embed-inner flex justify-start flex-col relative screen-gloss-less">
                <div className="pl-4 pt-1.5 px-4 console-green green-text-glow font-mono text-lg three-two-seventy">
                  Resource cost
                </div>
                <div className="ml-3  h-[1px] bg-green green-text-glow w-1/2 green-glow-shadow"></div>
                <div className="console-green green-text-glow font-mono text-base three-two-seventy grid grid-flow-col grid-cols-4 mt-3 ">
                  <div className="text-center">
                    <div className="console-white white-text-glow">
                      Titanium
                    </div>
                    <div
                      className={`${
                        myResources[RESOURCES.TITANIUM] >=
                        resourceCost[RESOURCES.TITANIUM]
                          ? 'console-green green-text-glow'
                          : 'console-red red-text-glow'
                      }`}
                    >
                      {numberWithCommas(resourceCost[RESOURCES.TITANIUM])}
                    </div>
                  </div>
                  <div className="text-center">
                    <div className="console-white white-text-glow">
                      Dark Matter
                    </div>
                    <div
                      className={`three-two-seventy ${
                        myResources[RESOURCES.DARK_MATTER] >=
                        resourceCost[RESOURCES.DARK_MATTER]
                          ? 'console-green green-text-glow'
                          : 'console-red red-text-glow'
                      }`}
                    >
                      {numberWithCommas(resourceCost[RESOURCES.DARK_MATTER])}
                    </div>
                  </div>
                  <div className="text-center">
                    <div className="console-white white-text-glow">Abyss</div>
                    <div
                      className={`three-two-seventy ${
                        myResources[RESOURCES.ABYSS] >=
                        resourceCost[RESOURCES.ABYSS]
                          ? 'console-green green-text-glow'
                          : 'console-red red-text-glow'
                      }`}
                    >
                      {numberWithCommas(resourceCost[RESOURCES.ABYSS])}
                    </div>
                  </div>
                  <div className="text-center">
                    <div className="console-white white-text-glow">
                      Stardust
                    </div>
                    <div
                      className={`three-two-seventy ${
                        myResources[RESOURCES.STARDUST] >=
                        resourceCost[RESOURCES.STARDUST]
                          ? 'console-green green-text-glow'
                          : 'console-red red-text-glow'
                      }`}
                    >
                      {numberWithCommas(resourceCost[RESOURCES.STARDUST])}
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-[1fr,1.2fr] gap-x-2">
                <div>
                  <h2 className="text-base recharge text-center text-zinc-200 text-opacity-90 mt-3">
                    BUILD INSTANTLY
                  </h2>
                  <div className="grid grid-cols-[1fr,1fr] gap-x-3 mt-2 items-center flex-col">
                    <div className="h-fit bg-black rounded-sm shadow-embed-inner flex justify-center items-center relative screen-gloss-less">
                      <div className="console-green green-text-glow font-mono three-two-seventy text-xl my-2 text-center flex flex-col justify-center items-center">
                        {estimatedActions &&
                        estimatedActions.emitInstantBuildCost ? (
                          <>
                            {estimatedActions.emitInstantBuildCost.toFixed(2)}
                          </>
                        ) : (
                          <>0</>
                        )}

                        <img
                          src="https://dd.dexscreener.com/ds-data/tokens/cronos/0x9fa6552c1e9df51070a3b456355b5d76cbd59b5a.png"
                          className="h-8 w-8"
                          alt="EMIT"
                        />
                      </div>
                    </div>
                    <div>
                      <button
                        className="recharge w-full px-0 py-2 border-white border-2 text-2xl bg-black text-white disabled:bg-red-300 uppercase disabled:text-red-600 disabled:border-red-600 disabled:opacity-50 disabled:cursor-not-allowed three-two-seventy font-bold text-center cursor-pointer hover:opacity-80"
                        disabled={
                          actionPending
                            ? true
                            : selectedShip?.ShipStatus === 'BUILDING'
                            ? false
                            : !(
                                myResources[RESOURCES.TITANIUM] >=
                                  resourceCost[RESOURCES.TITANIUM] &&
                                myResources[RESOURCES.DARK_MATTER] >=
                                  resourceCost[RESOURCES.DARK_MATTER] &&
                                myResources[RESOURCES.ABYSS] >=
                                  resourceCost[RESOURCES.ABYSS] &&
                                myResources[RESOURCES.STARDUST] >=
                                  resourceCost[RESOURCES.STARDUST] &&
                                (estimatedActions.attach.length > 0 ||
                                  estimatedActions.detach.length > 0)
                              )
                        }
                        onClick={async () => {
                          setActionPending(true);
                          if (!estimatedActions.emitInstantBuildCost) return;

                          const amountinWei = ethers.utils.parseEther(
                            estimatedActions.emitInstantBuildCost.toString(),
                          );

                          if (allowedEmit.lt(amountinWei)) {
                            await onApproveEmit(
                              amountinWei.sub(allowedEmit),
                            ).catch(e => e);
                            setActionPending(false);
                            return;
                          }
                          if (selectedShip?.ShipStatus === 'BUILDING') {
                            return;
                          }

                          if (
                            selectedShip?.ShipStatus === 'NOT_BUILT' ||
                            selectedShip?.ShipStatus === 'BUILT'
                          ) {
                            ApiClient.timewars_instantBuild(
                              actions.shipId,
                              estimatedActions,
                            )
                              .then(async r => {
                                console.log({ r });
                                if (r.success) {
                                  await ensureCronos();
                                  const provider =
                                    new ethers.providers.Web3Provider(
                                      window.ethereum,
                                    );
                                  const signer = provider.getSigner();
                                  const contract = new ethers.Contract(
                                    r.domain.verifyingContract,
                                    TIME_PAYMENTS_ABI,
                                    signer,
                                  );

                                  const gasEstimate = await signer.estimateGas({
                                    to: r.domain.verifyingContract,
                                    from: await signer.getAddress(),
                                    data: contract.interface.encodeFunctionData(
                                      'payTimewars',
                                      [r.values, r.signature],
                                    ),
                                  });

                                  const gasLimit = gasEstimate.mul(12).div(10);

                                  const response = await contract.payTimewars(
                                    r.values,
                                    r.signature,
                                    {
                                      gasLimit,
                                    },
                                  );

                                  console.log(response);
                                } else {
                                  showAlert('Something went wrong', 'error');
                                }

                                setActionPending(false);
                              })
                              .catch(e => {
                                console.log(e);
                                showAlert(
                                  e.data?.message ||
                                    e.response?.data?.message ||
                                    'Something went wrong.',
                                  'error',
                                );
                                setActionPending(false);
                              });
                            return;
                          }

                          if (selectedShip?.ShipStatus === 'BUILT') {
                          }
                        }}
                      >
                        {selectedShip?.ShipStatus === 'BUILDING'
                          ? 'NOW'
                          : selectedShip?.ShipStatus === 'BUILT'
                          ? `${
                              estimatedActions.attach.length > 0 &&
                              estimatedActions.detach.length === 0
                                ? 'NOW'
                                : ''
                            }`
                          : 'NOW'}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="grid grid-flow-row gap-y-2">
                  <div className="h-fit bg-black rounded-sm shadow-embed-inner flex justify-center items-center relative screen-gloss-less">
                    <div className="console-green green-text-glow font-mono three-two-seventy text-base my-2">
                      {selectedShip ? (
                        <>
                          {selectedShip.ShipStatus === 'BUILDING' &&
                          buildCountdown > 0
                            ? `Time left: ${formatBuildTimeCountdown(
                                buildCountdown,
                              )}`
                            : `Time to Build: ${formattedBuildTime}`}
                        </>
                      ) : (
                        'No ship selected'
                      )}
                    </div>
                  </div>
                  <div>
                    <button
                      className="recharge w-full px-6 py-4 bg-black text-white uppercase disabled:opacity-50 disabled:cursor-not-allowed three-two-seventy text-xl font-bold text-center cursor-pointer hover:opacity-80"
                      disabled={
                        actionPending
                          ? true
                          : selectedShip?.ShipStatus === 'BUILDING'
                          ? false
                          : !(
                              myResources[RESOURCES.TITANIUM] >=
                                resourceCost[RESOURCES.TITANIUM] &&
                              myResources[RESOURCES.DARK_MATTER] >=
                                resourceCost[RESOURCES.DARK_MATTER] &&
                              myResources[RESOURCES.ABYSS] >=
                                resourceCost[RESOURCES.ABYSS] &&
                              myResources[RESOURCES.STARDUST] >=
                                resourceCost[RESOURCES.STARDUST] &&
                              (estimatedActions.attach.length > 0 ||
                                estimatedActions.detach.length > 0)
                            )
                      }
                      onClick={() => {
                        setActionPending(true);

                        if (selectedShip?.ShipStatus === 'BUILDING') {
                          ApiClient.timewars_cancelBuild(selectedShip.id)
                            .then(r => {
                              if (r.success) {
                                showAlert('Build Cancelled', 'success');
                                resetConsoleAndResources();
                                retrieveShipsAndComponents(selectedShip.id);
                              } else {
                                showAlert('Something went wrong', 'error');
                              }
                              setActionPending(false);
                            })
                            .catch(e => {
                              showAlert(
                                e.data?.message ||
                                  e.response?.data?.message ||
                                  'Something went wrong.',
                                'error',
                              );
                              setActionPending(false);
                            });

                          return;
                        }

                        if (
                          selectedShip?.ShipStatus === 'NOT_BUILT' ||
                          selectedShip?.ShipStatus === 'BUILT'
                        ) {
                          ApiClient.timewars_performActions(
                            actions.shipId,
                            estimatedActions,
                          )
                            .then(r => {
                              if (r.success) {
                                const shipId = selectedShip.id;
                                showAlert(
                                  `${selectedShip.name} is under construction!`,
                                  'success',
                                );
                                resetConsoleAndResources();

                                retrieveShipsAndComponents(shipId);
                              } else {
                                showAlert('Something went wrong', 'error');
                              }

                              setActionPending(false);
                            })
                            .catch(e => {
                              showAlert(
                                e.data?.message ||
                                  e.response?.data?.message ||
                                  'Something went wrong.',
                                'error',
                              );
                              setActionPending(false);
                            });
                          return;
                        }

                        if (selectedShip?.ShipStatus === 'BUILT') {
                        }
                      }}
                    >
                      {selectedShip?.ShipStatus === 'BUILDING'
                        ? 'Cancel Build'
                        : selectedShip?.ShipStatus === 'BUILT'
                        ? `${
                            estimatedActions.attach.length > 0 &&
                            estimatedActions.detach.length === 0
                              ? 'Upgrade Ship'
                              : 'Rework Ship'
                          }`
                        : 'Build'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShipYardDashboard;

const DownIcon = ({ className }: any) => {
  return (
    <div className={`${className}`}>
      <svg
        fill="currentColor"
        height="24px"
        width="24px"
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 386.257 386.257"
        xmlSpace="preserve"
      >
        <defs>
          <filter id="green-glow" x="-50%" y="-50%" width="200%" height="200%">
            <feGaussianBlur stdDeviation="3" result="coloredBlur" />
            <feMerge>
              <feMergeNode in="coloredBlur" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>
        <polygon points="0,96.879 193.129,289.379 386.257,96.879 " />
      </svg>
    </div>
  );
};

const OpenSlotDropdown = ({
  label,
  items,
  onItemSelected,
  onRight,
  onReset,
  setOnReset,
  ship,
}: any) => {
  const [showItems, setShowItems] = useState(false);
  const [oldItem, setOldItem] = useState<any>(undefined);
  const [selectedItemName, setSelectedItemName] = useState<any>(undefined);
  const dropdownRef = useOutsideClick(() => {
    setShowItems(false);
  });

  const uniqUnusedItems = _.uniqBy(
    items.filter((i: Component) => !i.selected && i.status === 'UNUSED'),
    (c: Component) => c.type + c.tier,
  );

  // add count of duplicate to the item
  uniqUnusedItems.forEach((item: any) => {
    item.count = items
      .filter((i: Component) => !i.selected)
      .filter(
        (c: Component) => c.type === item.type && c.tier === item.tier,
      ).length;
  });

  useEffect(() => {
    if (onReset) {
      setSelectedItemName(undefined);
      setOldItem(undefined);
      setOnReset(false);
    }
  }, [onReset]);
  return (
    <div className="relative" ref={dropdownRef}>
      <div
        onClick={() =>
          ship?.ShipStatus === 'BUILDING' ? null : setShowItems(!showItems)
        }
        className={classNames(
          `console-green green-text-glow three-two-seventy select-none`,
          ship?.ShipStatus === 'BUILDING'
            ? 'opacity-50 cursor-default'
            : selectedItemName
            ? 'opacity-100 console-yellow yellow-text-glow hover:opacity-80 cursor-pointer'
            : 'opacity-50 hover:opacity-100 cursor-pointer',
        )}
      >
        <div className="uppercase">
          {ship?.ShipStatus === 'BUILDING' && !selectedItemName
            ? 'BUILD IN PROGRESS'
            : selectedItemName
            ? `${selectedItemName.replace('Time Ship', '')}*`
            : label}
        </div>
      </div>
      {showItems && (
        <div
          className={`absolute mt-0.5 ml-0.5 w-max z-50 px-2 ${
            !onRight ? `${selectedItemName ? 'left-6' : 'left-0'}` : '-left-12'
          } bg-green bg-opacity-80 border green-border rounded-sm shadow-lg green-glow`}
        >
          <ul className="max-h-60 overflow-y-hidden z-50 select-none text-start console-scrollbar relative w-full">
            <li className="text-black font-bold p-2">Unused Components</li>
            {uniqUnusedItems.map((item: Component, index: any) => (
              <li
                key={index}
                onClick={() => {
                  onItemSelected(item, oldItem);
                  setSelectedItemName(
                    `T${item.tier} ${item.name.replace(
                      'Time Ship',
                      _.capitalize(item.type),
                    )}`,
                  );
                  setShowItems(false);
                  setOldItem(item);
                }}
                className="white-text-glow text-white three-two-seventy p-2 hover:opacity-50 cursor-pointer select-none"
              >
                {`T${item.tier} ${_.capitalize(item.type)} Component`}{' '}
                {item.count && item.count > 1 ? `x${item.count} ` : ''}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const UpIcon = ({ className }: any) => {
  return (
    <div className={`${className}`}>
      <svg
        fill="currentColor"
        height="24px"
        width="24px"
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 386.257 386.257"
        xmlSpace="preserve"
        style={{ transform: 'rotate(180deg)' }} // Rotate the icon
      >
        <defs>
          <filter id="green-glow" x="-50%" y="-50%" width="200%" height="200%">
            <feGaussianBlur stdDeviation="3" result="coloredBlur" />
            <feMerge>
              <feMergeNode in="coloredBlur" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>
        <polygon points="0,96.879 193.129,289.379 386.257,96.879" />
      </svg>
    </div>
  );
};

const useOutsideClick = (callback: () => void) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback]);

  return ref;
};

const formatBuildTime = (minutes: number): string => {
  const days = Math.floor(minutes / 1440); // 1440 minutes in a day
  const hours = Math.floor((minutes % 1440) / 60);
  const mins = minutes % 60;

  return `${Math.floor(days)}d ${Math.floor(hours)}h ${Math.floor(mins)}m`;
};

// format build time for the countdown, in seconds
const formatBuildTimeCountdown = (seconds: number): string => {
  const days = Math.floor(seconds / 86400); // 86400 seconds in a day
  const hours = Math.floor((seconds % 86400) / 3600);
  const mins = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  return `${Math.floor(days)}d ${Math.floor(hours)}h ${Math.floor(
    mins,
  )}m ${Math.floor(secs)}s`;
};

import { useEffect, useState } from 'react';
import ApiClient from '../../../utils/ApiClient';
import { parseNumberToShortForm } from '../../../utils/parser';
import { SocialIcon } from 'react-social-icons';
import { Tooltip } from 'flowbite-react';
import classNames from '../../../utils/classNames';
import { ClipboardIcon } from '@heroicons/react/24/outline';
import { useAuth } from '../../../hooks/useAuth';

const TokenTracker = () => {
  const [leaderboard, setLeaderboard] = useState([]);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [search, setSearch] = useState('');
  const [tooltip, setTooltip] = useState({
    visible: false,
    fading: false,
    x: 0,
    y: 0,
    content: 'Address copied',
  });

  const handleRowClick = (
    e: React.MouseEvent,
    index: number,
    content: string,
  ) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setTooltip({
      visible: true,
      fading: false,
      x: e.clientX,
      y: rect.top + window.scrollY - 10,
      content: `${content}`,
    });

    navigator.clipboard.writeText(content);

    setTimeout(() => {
      setTooltip(prev => ({ ...prev, fading: true }));
    }, 2700);

    setTimeout(() => {
      setTooltip(prev => ({ ...prev, visible: false, fading: false }));
    }, 3000);
  };

  useEffect(() => {
    ApiClient.getTokenTracks(page, 10, search).then(res => {
      setLeaderboard(res.tokens);
      setHasNextPage(res.hasNextPage);
      setHasPreviousPage(res.hasPreviousPage);
    });
  }, [page, search]);

  const [portfolio, setPortfolio] = useState([]);
  const [portfolioPage, setPortfolioPage] = useState(1);

  useEffect(() => {
    ApiClient.getTokenPortfolio(portfolioPage, 10).then(res => {
      setPortfolio(res);
    });
  }, [setPortfolioPage]);

  const { user, isAuthenticated } = useAuth();
  return (
    <>
      <div className="grid grid-cols-1 gap-6 px-0 md:px-2">
        <div>
          <div className="mb-4 px-4 pb-2 pt-2 rounded-md border dark:border-zinc-800 md:max-w-12xl">
            <div className="w-full flex justify-between mt-2 mb-5">
              <h1 className="text-2xl font-semibold text-gray-900 dark:text-gray-100 ">
                Trending Tokens
              </h1>
              <input
                placeholder="Search..."
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearch(e.target.value);
                }}
                className=" rounded-md px-2 py-1 border border-gray-300 dark:border-zinc-600 dark:bg-zinc-900 dark:text-gray-100"
              />
            </div>
            <table className="table-auto w-full ">
              <thead>
                <tr className="border-b dark:border-none">
                  <th className="relative isolate text-sm py-1 pb-2 text-left font-semibold  text-gray-900 dark:text-gray-100">
                    Token
                  </th>
                  <th className="relative  isolate text-sm py-1  pb-2 pr-3 text-end font-semibold  text-gray-900 dark:text-gray-100">
                    Price
                  </th>
                  <th className="relative isolate text-sm py-1 pb-2 pr-3 text-center font-semibold text-gray-900 dark:text-gray-100">
                    24h <span className="hidden md:inline">Change</span>
                  </th>
                  <th className="relative  hidden md:table-cell isolate text-sm py-1 pb-2 pr-3 text-center font-semibold text-gray-900 dark:text-gray-100">
                    Volume
                  </th>
                  <th className="relative hidden md:table-cell isolate text-sm py-1 pb-2 pr-3 text-center font-semibold text-gray-900 dark:text-gray-100">
                    Market Cap
                  </th>
                  <th className="relative hidden lg:table-cell isolate text-sm py-1 pb-2 pr-3 text-end font-semibold text-gray-900 dark:text-gray-100"></th>
                </tr>
              </thead>
              <tbody>
                {leaderboard.map((entry: any, index) => (
                  <tr
                    key={index}
                    className={` bg-white dark:bg-zinc-900 ${
                      index % 2 === 0 ? 'bg-gray-50 dark:bg-zinc-800' : ''
                    } hover:bg-gray-100 dark:hover:bg-zinc-700 cursor-pointer`}
                  >
                    <td
                      className="relative py-2 text-sm font-bold text-gray-800 dark:text-gray-100"
                      onClick={e =>
                        handleRowClick(e, index, entry.tokenAddress)
                      }
                    >
                      <div className="flex pl-1">
                        <img
                          src={entry.tokenLogo}
                          alt={entry.tokenName}
                          className="rounded-full w-7 h-7 bg-gray-500"
                        />
                        <div className="ml-2 align-middle self-center">
                          {entry.tokenName.toUpperCase()}{' '}
                          <span className="text-[0.7rem] font-bold text-gray-600/50 dark:text-gray-400/70">
                            ${entry.tokenSymbol.toUpperCase()}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td
                      className="relative py-2 pr-3 text-sm text-end font-medium text-gray-800 dark:text-gray-100 leading-tight"
                      onClick={e =>
                        handleRowClick(
                          e,
                          index,
                          `${entry.priceNative} ${entry.nativeTokenSymbol} (${entry.priceUsd} USD)`,
                        )
                      }
                    >
                      <div className="">
                        {entry.priceNative} {entry.nativeTokenSymbol}
                      </div>
                      <div className="text-[0.7rem] font-bold text-gray-600/50 dark:text-gray-400/70">
                        ${entry.priceUsd} USD
                      </div>
                    </td>
                    <td
                      className="relative py-2 pr-3 text-sm font-medium text-center text-gray-800 dark:text-gray-100 leading-tight"
                      onClick={e =>
                        handleRowClick(
                          e,
                          index,
                          `${
                            entry.change24h === 0
                              ? ''
                              : entry.change24h > 0
                              ? '+'
                              : ''
                          } ${entry.change24h}%`,
                        )
                      }
                    >
                      <div
                        className={`${
                          entry.change24h > 0
                            ? 'text-green-500'
                            : 'text-red-500'
                        }`}
                      >
                        {entry.change24h === 0
                          ? ''
                          : entry.change24h > 0
                          ? '+'
                          : ''}
                        {entry.change24h}%
                      </div>
                    </td>
                    <td
                      className="relative hidden md:table-cell py-2 text-sm font-medium text-center text-gray-800 dark:text-gray-100 leading-tight"
                      onClick={e =>
                        handleRowClick(
                          e,
                          index,
                          `${parseNumberToShortForm(entry.volume)}`,
                        )
                      }
                    >
                      {parseNumberToShortForm(entry.volume)}
                    </td>
                    <td
                      className="relative py-2 hidden md:table-cell text-sm font-medium text-center text-gray-800 dark:text-gray-100 leading-tight"
                      onClick={e =>
                        handleRowClick(
                          e,
                          index,
                          `${parseNumberToShortForm(entry.marketCap)}`,
                        )
                      }
                    >
                      {parseNumberToShortForm(entry.marketCap)}
                    </td>
                    <td className="relative hidden py-2 text-sm font-medium text-left text-gray-800 dark:text-gray-100 leading-tight flex-row lg:flex justify-end">
                      {entry.links.map((link: any, index: number) => {
                        return (
                          <Tooltip content={link.url}>
                            <SocialIcon
                              url={link.url}
                              href={link.url}
                              target="_blank"
                              rel="noreferrer"
                              className="border-gray-300 dark:border-gray-600 border rounded-full mx-1 hover:opacity-75 dark:hover:opacity-50"
                              bgColor="transparent"
                              fgColor="#576373"
                              style={{ height: 30, width: 30 }}
                            />
                          </Tooltip>
                        );
                      })}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="flex justify-center mt-2">
              <div>
                <button
                  onClick={() => {
                    setPage(page - 1);
                  }}
                  disabled={!hasPreviousPage}
                  className="px-2 py-1 text-xs border rounded-md border-gray-300 hover:bg-gray-100 dark:hover:bg-zinc-700 dark:text-gray-100 mr-2 disabled:opacity-50 dark:border-zinc-600 disabled:dark:opacity-50"
                >
                  Previous
                </button>

                <button
                  onClick={() => {
                    setPage(page + 1);
                  }}
                  disabled={!hasNextPage}
                  className="mb-1 px-2 py-1 text-xs border rounded-md border-gray-300 hover:bg-gray-100 dark:hover:bg-zinc-700  dark:text-gray-100 disabled:opacity-50 dark:border-zinc-600 disabled:dark:opacity-50"
                >
                  Next
                </button>
              </div>
            </div>

            {tooltip.visible && (
              <div
                className={classNames(
                  'absolute bg-gray-800 text-white text-sm rounded py-1 px-3 transition-opacity duration-300 items-center align-middle',
                  !tooltip.fading ? 'opacity-100' : 'opacity-0',
                )}
                style={{ top: tooltip.y, left: tooltip.x }}
              >
                <ClipboardIcon className="inline h-4 w-4 mb-0.5" />{' '}
                {tooltip.content}
              </div>
            )}
          </div>
        </div>
        {/* <div>
          <h1 className="text-2xl font-semibold text-gray-900 dark:text-gray-100 mb-4">
            Sponsored
          </h1>
          <div className="mb-4 px-4 pb-6 pt-2 rounded-md border">
            <p className="text-gray-900 dark:text-gray-100">
              Content for another box goes here.
            </p>
          </div>
        </div> */}
      </div>
      {isAuthenticated ? (
        <div className="px-0 md:px-2 pt-4">
          <div>
            <div className="mb-4 px-4 pb-6 pt-2 rounded-md border dark:border-zinc-800 md:max-w-12xl">
              <h1 className="text-2xl font-semibold text-gray-900 dark:text-gray-100 mt-3 mb-5">
                My Wallet{' '}
                <span className=" text-sm text-zinc-500 font-normal ml-1">
                  {user?.data?.address}
                </span>
              </h1>

              <table className="table-auto w-full ">
                <thead>
                  <tr className="border-b dark:border-none">
                    <th className="relative isolate text-sm py-1 pb-2 text-left font-semibold  text-gray-900 dark:text-gray-100">
                      Token
                    </th>
                    <th className="relative  isolate text-sm py-1  pb-2 pr-3 text-end font-semibold  text-gray-900 dark:text-gray-100">
                      Balance
                    </th>
                    <th className="relative  isolate text-sm py-1 pb-2 pr-3 text-center font-semibold text-gray-900 dark:text-gray-100">
                      6h Change
                    </th>
                    <th className="relative  isolate text-sm py-1 pb-2 pr-3 text-center font-semibold text-gray-900 dark:text-gray-100">
                      12h Change
                    </th>
                    <th className="relative  isolate text-sm py-1 pb-2 pr-3 text-center font-semibold text-gray-900 dark:text-gray-100">
                      24h Change
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {portfolio.map((entry: any, index) => (
                    <tr
                      key={index}
                      className={` bg-white dark:bg-zinc-900 ${
                        index % 2 === 0 ? 'bg-gray-50 dark:bg-zinc-800' : ''
                      } hover:bg-gray-100 dark:hover:bg-zinc-700 cursor-pointer`}
                    >
                      <td
                        className="relative py-2 text-sm font-bold text-gray-800"
                        onClick={e =>
                          handleRowClick(e, index, entry.tokenAddress)
                        }
                      >
                        <div className="flex pl-1">
                          <img
                            src={entry.TokenTrack.tokenLogo}
                            alt={entry.tokenName}
                            className="rounded-full w-7 h-7 bg-gray-500"
                          />
                          <div className="ml-2 align-middle self-center dark:text-gray-100">
                            {entry.TokenTrack.tokenName.toUpperCase()}{' '}
                            <span className="text-[0.7rem] font-bold text-gray-600/50 dark:text-gray-400/70">
                              ${entry.TokenTrack.tokenSymbol.toUpperCase()}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td
                        className="relative py-2 pr-3 text-sm text-end font-medium text-gray-800 dark:text-gray-100 leading-tight"
                        onClick={e =>
                          handleRowClick(
                            e,
                            index,
                            `${entry.balance} ${entry.TokenTrack.nativeTokenSymbol} (${entry.balanceUsd} USD)`,
                          )
                        }
                      >
                        <div className="">
                          {entry.balance} {entry.TokenTrack.nativeTokenSymbol}
                        </div>
                        <div className="text-[0.7rem] font-bold text-gray-600/50 dark:text-gray-400/70">
                          ${entry.balanceUsd} USD
                        </div>
                      </td>
                      <td
                        className="relative py-2 pr-3 text-sm font-medium text-center text-gray-800 leading-tight"
                        onClick={e =>
                          handleRowClick(
                            e,
                            index,
                            `${
                              entry.change6HourUsd === 0
                                ? ''
                                : entry.change6HourUsd > 0
                                ? '+'
                                : ''
                            } $${Number(entry.change6HourUsd).toFixed(2)} USD`,
                          )
                        }
                      >
                        <div
                          className={`${
                            entry.change6HourUsd > 0
                              ? 'text-green-500'
                              : entry.change6HourUsd === '0'
                              ? 'text-gray-800 dark:text-gray-100'
                              : 'text-red-500'
                          }`}
                        >
                          {entry.change6HourUsd === '0'
                            ? ''
                            : entry.change6HourUsd > 0
                            ? '+'
                            : '-'}
                          ${Math.abs(Number(entry.change6HourUsd)).toFixed(2)}{' '}
                          USD
                        </div>
                      </td>
                      <td
                        className="relative py-2 pr-3 text-sm font-medium text-center text-gray-800 leading-tight"
                        onClick={e =>
                          handleRowClick(
                            e,
                            index,
                            `${
                              entry.change12HourUsd === 0
                                ? ''
                                : entry.change12HourUsd > 0
                                ? '+'
                                : ''
                            } $${Number(entry.change12HourUsd).toFixed(2)} USD`,
                          )
                        }
                      >
                        <div
                          className={`${
                            entry.change12HourUsd > 0
                              ? 'text-green-500'
                              : entry.change12HourUsd === '0'
                              ? 'text-gray-800 dark:text-gray-100'
                              : 'text-red-500'
                          }`}
                        >
                          {entry.change12HourUsd === '0'
                            ? ''
                            : entry.change12HourUsd > 0
                            ? '+'
                            : '-'}
                          ${Math.abs(Number(entry.change12HourUsd)).toFixed(2)}{' '}
                          USD
                        </div>
                      </td>
                      <td
                        className="relative py-2 pr-3 text-sm font-medium text-center text-gray-800 leading-tight"
                        onClick={e =>
                          handleRowClick(
                            e,
                            index,
                            `${
                              entry.change24HourUsd === 0
                                ? ''
                                : entry.change24HourUsd > 0
                                ? '+'
                                : '-'
                            } $${Number(entry.change24HourUsd).toFixed(2)} USD`,
                          )
                        }
                      >
                        <div
                          className={`${
                            entry.change24HourUsd > 0
                              ? 'text-green-500'
                              : entry.change24HourUsd === '0'
                              ? 'text-gray-800 dark:text-gray-100'
                              : 'text-red-500'
                          }`}
                        >
                          {entry.change24HourUsd === '0'
                            ? ''
                            : entry.change24HourUsd > 0
                            ? '+'
                            : '-'}
                          ${Math.abs(Number(entry.change24HourUsd)).toFixed(2)}{' '}
                          USD
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
export default TokenTracker;

import { Fragment, useEffect, useState } from 'react';
import { useAuth } from '../../../hooks/useAuth';
import {
  getBalance,
  getCRC20Balance,
  getERC20Balance,
} from '../../../web3/utils';
import ApiClient from '../../../utils/ApiClient';
import { numberWithCommas } from '../../../utils/parser';
import { useNetwork } from '../../../hooks/useNetwork';
import { AxiosError } from 'axios';
import { Menu, Transition } from '@headlessui/react';
import classNames from '../../../utils/classNames';

const currencies: any = [
  {
    name: 'EMIT',
    img: 'https://dd.dexscreener.com/ds-data/tokens/cronos/0x9fa6552c1e9df51070a3b456355b5d76cbd59b5a.png',
  },
  { name: 'TTT', img: '/tokens/ttt.webp' },
];
const BalanceCard = () => {
  const { user, activeWallet, disconnected } = useAuth();
  const { updateTTTUSDPrice, updateEMITUSDPrice } = useNetwork();
  let lastKnown: any = localStorage.getItem('lastKnownBalance') || undefined;
  if (lastKnown) {
    lastKnown = JSON.parse(lastKnown);
  }

  let lastKnownCurrency: any =
    localStorage.getItem('lastKnownCurrency') || 'EMIT';

  const [currencyBalances, setCurrencyBalances] = useState({
    TTT: lastKnown ? Number(lastKnown.tttBalance)?.toString() || '0' : '0',
    EMIT: lastKnown ? Number(lastKnown.emitBalance)?.toString() || '0' : '0',
    USDCTTT: lastKnown
      ? Number(lastKnown.usdcTTTBalance)?.toString() || '0'
      : '0',
    USDCEMIT: lastKnown
      ? Number(lastKnown.usdcEmitBalance)?.toString() || '0'
      : '0',
  });

  const [loading, setLoading] = useState(lastKnown ? false : true);
  const [selectedCurrency, setSelectedCurrency] = useState<'TTT' | 'EMIT'>(
    lastKnownCurrency || 'EMIT',
  );

  useEffect(() => {
    async function balance() {
      if (!user.isAuthenticated) return;

      const getTTTBalance = await getCRC20Balance(
        activeWallet || user.wallet.address,
        'ttt',
      );

      const usdPrice = await ApiClient.getTokenUsdPrice('ttt').catch(e => e);
      const usdPriceEmit = await ApiClient.getTokenUsdPrice('emit').catch(
        e => e,
      );
      if (
        usdPrice instanceof AxiosError &&
        usdPrice?.response?.status === 401
      ) {
        console.log('disconnecting');
        disconnected();
        return;
      }

      const getEmitBalance = await getCRC20Balance(
        activeWallet || user.wallet.address,
        'emit',
      );
      setCurrencyBalances(old => {
        old.TTT = Number(getTTTBalance).toFixed(0).toString();
        old.EMIT = Number(getEmitBalance).toFixed(0).toString();
        return old;
      });

      if (usdPriceEmit) {
        updateEMITUSDPrice(usdPriceEmit);
        setCurrencyBalances(old => {
          old.USDCEMIT = Number(Number(getEmitBalance) * usdPriceEmit)
            .toFixed(2)
            .toString();
          return old;
        });
      }

      if (usdPrice instanceof AxiosError) {
        setCurrencyBalances(old => {
          old.USDCTTT = '';
          return old;
        });
      } else {
        setCurrencyBalances(old => {
          old.USDCTTT = Number(Number(getTTTBalance) * usdPrice)
            .toFixed(2)
            .toString();
          return old;
        });

        updateTTTUSDPrice(usdPrice);

        localStorage.setItem(
          'lastKnownBalance',
          JSON.stringify({
            usdcTTTBalance: Number(Number(getTTTBalance) * usdPrice)
              .toFixed(2)
              .toString(),
            tttBalance: Number(getTTTBalance).toFixed(0).toString(),
            emitBalance: Number(getEmitBalance).toFixed(0).toString(),
            usdcEmitBalance:
              usdPriceEmit instanceof Error
                ? '0'
                : Number(Number(getEmitBalance) * usdPriceEmit),
          }),
        );
      }

      setLoading(false);
    }

    balance();
  }, [activeWallet, user.isAuthenticated]);

  if (loading) {
    return null;
  }

  return (
    <>
      <Menu as="div" className="ml-3 relative">
        {' '}
        <Menu.Button>
          <div className="hidden md:inline-flex cursor-pointer w-full h-10 items-center  rounded-md border border-gray-100 dark:border-zinc-700  bg-white dark:bg-zinc-900 dark:text-white px-1 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 dark:hover:bg-zinc-800 focus:outline-none">
            <div className="mr-3 pl-2">
              <div className="flex flex-row pr-2 inline-flex items-center mt-[2px]">
                <div className="inline-flex items-center mr-2">
                  <img
                    alt="Currency"
                    src={
                      currencies.find((c: any) => c.name === selectedCurrency)
                        ?.img || ''
                    }
                    className={
                      selectedCurrency === 'TTT'
                        ? 'w-[22px] h-[22px]'
                        : 'w-[32px] h-[32px]'
                    }
                  />
                  <span className="text-[16.3px] inline-flex ml-2 whitespace-nowrap">
                    {numberWithCommas(currencyBalances[selectedCurrency])}{' '}
                    {selectedCurrency}
                  </span>
                </div>
                {currencyBalances[`USDC${selectedCurrency}`] !== 'NaN' &&
                  currencyBalances[`USDC${selectedCurrency}`] !== '' && (
                    <div className="w-px h-4 inline bg-gray-200 dark:bg-zinc-700 mx-3"></div>
                  )}
                {currencyBalances[`USDC${selectedCurrency}`] !== 'NaN' &&
                  currencyBalances[`USDC${selectedCurrency}`] !== '' && (
                    <div className="">
                      <span className="text-[16.3px] inline whitespace-nowrap">
                        $
                        {numberWithCommas(
                          Number(
                            currencyBalances[`USDC${selectedCurrency}`] || 0,
                          ).toFixed(2),
                        )}{' '}
                        USDC
                      </span>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-6 mt-6 w-48 text-center rounded-md shadow-lg py-1 bg-white dark:bg-zinc-800 dark:text-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            {currencies.map((currency: any) => (
              <Menu.Item key={currency.name}>
                {({ active }: any) => (
                  <a
                    href={'#'}
                    onClick={() => {
                      setSelectedCurrency(currency.name);
                      localStorage.setItem('lastKnownCurrency', currency.name);
                    }}
                    className={classNames(
                      active
                        ? 'bg-gray-100 dark:bg-zinc-700 dark:text-white'
                        : '',
                      'block px-4 py-2 text-sm text-gray-700 dark:text-gray-100',
                    )}
                  >
                    <div className="flex flex-row justify-start items-center font-semibold">
                      <img
                        className={
                          currency.name === 'TTT'
                            ? 'h-6 w-6 mr-2 ml-1'
                            : 'h-8 w-8 mr-1'
                        }
                        src={currency.img}
                        alt="Token"
                      />
                      <div>{currency.name}</div>
                    </div>
                  </a>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};
export default BalanceCard;

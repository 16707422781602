/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from '@heroicons/react/24/outline';
import { useEffect } from 'react';
import { useState } from 'react';
import { IWallet } from '../contexts/AuthContext';
import classNames from '../utils/classNames';
import ConnectAlert from './web3/ConnectAlert';
import ConnectWalletButtons from './web3/ConnectWalletButtons';
import { SiweMessage } from 'siwe';
import { defaultWallet } from '../web3/utils';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import ApiClient from '../utils/ApiClient';

export enum AssetType {
  XP,
}
const createSiweMessage = async (
  address: string,
  statement: string,
  nonce: string,
) => {
  const domain = process.env.REACT_APP_DOMAIN;
  const origin = 'https://' + process.env.REACT_APP_DOMAIN;

  const message = new SiweMessage({
    domain,
    address,
    statement,
    uri: origin,
    version: '1',
    chainId: 25,
    nonce,
  });
  return message.prepareMessage();
};

export default function ConnectVerifySign({
  setOpen = (closeVar: boolean) => {},
}) {
  const { connected, authenticated } = useAuth();
  const [wallet, setWallet] = useState<IWallet>(defaultWallet);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState([
    {
      name: 'Connect Wallet',
      description: 'Connect your wallet to Time Sniper',
      href: '#',
      status: 'current',
    },
    {
      name: 'Sign Message',
      description: 'Sign a message to login',
      href: '#',
      status: 'upcoming',
    },
  ]);

  const connectedSuccessfully = (wallet: IWallet) => {
    connected(wallet);
    setLoading(false);
    setWallet(wallet);
    const step1 = {
      description: 'Connected to Time Sniper with address: ' + wallet.address,
      status: 'complete',
    };
    const step2 = {
      status: 'current',
    };
    setError('');
    setSteps([
      { ...steps[0], ...step1 },
      { ...steps[1], ...step2 },
    ]);
  };

  let navigate = useNavigate();
  const signMessage = async () => {
    setLoading(true);
    const nonce = await ApiClient.getNonce();
    const message = await createSiweMessage(
      wallet.address,
      'Sign in to Time Sniper with your wallet.',
      nonce.nonce,
    );

    if (wallet.browserWeb3Provider === null) {
      alert('No web3 browser/extension detected');
      return false;
    }

    const signer = wallet.browserWeb3Provider.getSigner();
    const signature = await signer.signMessage(message);
    const result = await ApiClient.siwe(message, signature, nonce.cdcToken);

    if (result.accessToken) {
      const activeSubscriptions =
        result.accountSubscription && result.accountSubscription.length > 0
          ? result.accountSubscription
          : null;

      authenticated({
        token: result.accessToken,
        email: result.account.email,
        image: result.account.image,
        name: result.account.name,
        id: result.account.id,
        address: result.account.address,
        wallets: result.account.wallets,
        tokenCount: result.tokenCount,
        collectionCount: result.collectionCount,
        firstImportDone: result.account.firstImportDone,
        lastTokenImport: result.account.lastTokenImport,
        activeSubscriptions,
        xpForNextLevel: result.account.xpForNextLevel,
        xp: result.account.xp,
        requiredXP: result.account.requiredXP,
        level: result.account.level,
        hasFullAccess: result.account.hasFullAccess,
        role: result.account.role,
      });
      setOpen(false);

      if (!result.account.hasFullAccess) {
        window.location.href = '/app/services';
      }
    } else {
      setError(result.error);
    }
  };

  useEffect(() => {
    setLoading(false);
  }, [error]);

  return (
    <nav aria-label="Progress">
      <ol className="">
        {steps.map((step, stepIdx) => (
          <li
            key={step.name}
            className={classNames(
              stepIdx !== steps.length - 1 ? 'pb-10' : '',
              'relative',
            )}
          >
            {step.status === 'complete' ? (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-blue-600"
                    aria-hidden="true"
                  />
                ) : null}
                <a href={step.href} className="relative flex items-start group">
                  <span className="h-9 flex items-center">
                    <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-blue-600 rounded-full group-hover:bg-blue-800">
                      <CheckIcon
                        className="w-5 h-5 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </span>
                  <span className="ml-4 min-w-0 flex flex-col">
                    <span className="text-xs font-semibold tracking-wide uppercase">
                      {step.name}
                    </span>
                    <span className="text-sm text-gray-500">
                      {step.description}
                    </span>
                  </span>
                </a>
              </>
            ) : step.status === 'current' ? (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
                    aria-hidden="true"
                  />
                ) : null}
                <a
                  href={step.href}
                  className="relative flex items-start group"
                  aria-current="step"
                >
                  <span className="h-9 flex items-center" aria-hidden="true">
                    <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-blue-600 rounded-full">
                      <span className="h-2.5 w-2.5 bg-blue-600 rounded-full" />
                    </span>
                  </span>
                  <span className="ml-2 min-w-0 flex flex-col">
                    <span className="text-xs font-semibold tracking-wide uppercase text-blue-600">
                      {step.name}
                    </span>
                    <span className="text-sm text-gray-500">
                      {step.description}
                    </span>
                  </span>
                </a>
                <ConnectAlert message={error} title={'Error'} />
                {loading ? (
                  <>
                    <img
                      src="/loader.svg"
                      alt="loading"
                      width="100"
                      className="ml-8"
                    />
                  </>
                ) : (
                  <div>
                    {stepIdx === 0 && (
                      <ConnectWalletButtons
                        onClick={(connector: any) => {
                          setLoading(true);
                          connector
                            .connect()
                            .then(connectedSuccessfully)
                            .catch((err: any) => {
                              setError(err.message);
                            });
                        }}
                      />
                    )}

                    {stepIdx === 1 && (
                      <div className="ml-10 mt-4">
                        <button
                          onClick={signMessage}
                          className="bg-blue-600 text-white p-2 px-4 rounded-lg"
                        >
                          Sign message with wallet
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </>
            ) : (
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
                    aria-hidden="true"
                  />
                ) : null}
                <a href={step.href} className="relative flex items-start group">
                  <span className="h-9 flex items-center" aria-hidden="true">
                    <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                      <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" />
                    </span>
                  </span>
                  <span className="ml-4 min-w-0 flex flex-col">
                    <span className="text-xs font-semibold tracking-wide uppercase text-gray-500">
                      {step.name}
                    </span>
                    <span className="text-sm text-gray-500">
                      {step.description}
                    </span>
                  </span>
                </a>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}

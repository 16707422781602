import { createContext } from 'react';
import ethers from 'ethers';
export interface Subscription {
  subscriptionId: number;
  expires: Date;
}

export interface AuthData {
  address: string;
  token: string;
  email: string;
  image: string;
  name: string;
  id: string;
  wallets: string[];
  tokenCount: number;
  collectionCount: number;
  role: string;
  firstImportDone: boolean;
  xp: string;
  requiredXP: number;
  xpForNextLevel: number;
  level: number;
  lastTokenImport: Date;
  activeSubscriptions: null | Subscription[];
  hasFullAccess: boolean;
}
export interface IWallet {
  walletProviderName: string; // for example, "metamask" or "defiwallet"
  address: string; // 0x address of the user
  browserWeb3Provider: ethers.providers.Web3Provider | null; // Web3 provider connected to the wallet's browser extension
  serverWeb3Provider: ethers.providers.JsonRpcProvider | null; // cloud based Web3 provider for read-only
  wcConnector: any; // connector object provided by some wallet connection methods, stored if relevant
  wcProvider: any; // provider object provided by some wallet connection methods, stored if relevant
  connected: boolean; // is the wallet connected to the Dapp, or not?
  chainId: number; // for example, 25 for Cronos mainnet, and 338 for Cronos testnet
}
export interface AuthUser {
  isConnected: boolean;
  isAuthenticated: boolean;
  data?: AuthData;
  wallet: IWallet;
}
export interface AuthContextProps {
  user: AuthUser;
  activeWallet: string | null;
  setActiveWallet: any;
  loadingActiveWallet: boolean;
  isConnected: boolean;
  isAuthenticated: boolean;
  hasFullAccess: boolean;
  isAdmin: boolean;
  connected: (wallet: IWallet) => void;
  authenticated: (data: AuthData) => void;
  disconnected: () => void;
  updateUser: (user: AuthUser) => void;
  forceUserUpdate: () => void;
}
const AuthContext = createContext<AuthContextProps>({
  isConnected: false,
  isAuthenticated: false,
  hasFullAccess: false,
  isAdmin: false,
} as AuthContextProps);

export default AuthContext;

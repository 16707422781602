import { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import {
  ArrowLongUpIcon,
  ArrowLongDownIcon,
} from '@heroicons/react/24/outline';

const publishingOptions = [
  { title: 'Listed', description: '', value: 'listed', current: false },
  { title: 'Unlisted', description: '', value: 'unlisted', current: false },
  { title: 'Both', description: '', value: 'both', current: true },
];

const marketsOptions = [
  { title: 'All Markets', description: '', value: 'all', current: true },
  { title: "Ebisu's Bay", description: '', value: 'EBISUS', current: false },
  { title: 'Opensea', description: '', value: 'OPENSEA', current: false },
  {
    title: 'Time Launcher',
    description: '',
    value: 'MOONFLOW',
    current: false,
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}
export default function SearchButtons({
  setOrder,
  order,
  title,
  search,
  setDirection,
  setListed,
  listed,
  source,
  setSource,
  direction,
  floor = true,
}: any) {
  let cur = publishingOptions[2];
  if (listed === 'unlisted') {
    cur = publishingOptions[1];
  } else if (listed === 'listed') {
    cur = publishingOptions[0];
  }

  const [selected, setSelected] = useState(cur);
  const [selectedSource, setSelectedSource] = useState(source || 'all');
  useEffect(() => {
    if (!setListed) return;

    setListed(selected.value);
  }, [selected]);

  useEffect(() => {
    if (!setSource || !selectedSource) return;

    setSource(selectedSource);
  }, [setSource, selectedSource]);

  console.log(selectedSource);

  console.log({ selectedSource });
  return (
    <>
      <div className="grid md:flex items-end md:justify-end mb-6">
        <div className="flex-1">
          <div className="max-w-7xl">
            <h1 className="text-2xl font-semibold text-gray-900 dark:text-white">
              {title}
            </h1>
          </div>
        </div>
        {search && (
          <span className="justify-center relative md:pr-6 z-0 flex shadow-sm rounded-md">
            <div>
              <label htmlFor="email" className="sr-only">
                Search
              </label>
              <input
                type="search"
                name="search"
                id="search"
                onChange={e => {
                  search(e.target.value);
                }}
                className="shadow-sm focus:ring-purple-500 focus:border-purple-500 block w-full sm:text-sm border-gray-300 dark:border-zinc-600 rounded-md dark:bg-zinc-800 dark:text-white dark:placeholder-zinc-300"
                placeholder="Search..."
              />
            </div>
          </span>
        )}

        {order && (
          <span className="mt-3 mb-3 md:m-0 justify-center md:pr-6 relative z-0 inline-flex shadow-sm rounded-md">
            <button
              type="button"
              onClick={() => {
                setOrder('listPrice');
                setDirection((prevCheck: string) =>
                  prevCheck === 'asc' ? 'desc' : 'asc',
                );
              }}
              className={classNames(
                order === 'listPrice'
                  ? 'text-white bg-purple-600 dark:text-white dark:hover:bg-purple-500 dark:hover:text-white'
                  : 'text-gray-900 dark:text-white dark:bg-zinc-800 dark:hover:bg-zinc-700',
                'relative inline-flex items-center hover:text-gray-600 px-4 py-2 rounded-l-md border border-gray-300 dark:border-zinc-600 bg-white text-sm font-medium hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500',
              )}
            >
              List Price
              {order === 'listPrice' ? (
                direction === 'asc' ? (
                  <ArrowLongUpIcon className="h-4 w-4" aria-hidden="true" />
                ) : (
                  <ArrowLongDownIcon className="h-4 w-4" aria-hidden="true" />
                )
              ) : (
                <></>
              )}
            </button>
            {floor && (
              <button
                type="button"
                onClick={() => {
                  setOrder('floorPrice');
                  setDirection((prevCheck: string) =>
                    prevCheck === 'asc' ? 'desc' : 'asc',
                  );
                }}
                className={classNames(
                  order === 'floorPrice'
                    ? 'text-white bg-purple-600 dark:hover:bg-purple-500 dark:hover:text-white dark:text-white'
                    : 'text-gray-900 dark:bg-zinc-800 dark:text-white',
                  'relative inline-flex items-center hover:text-gray-600 px-4 py-2 border border-gray-300 dark:border-zinc-600 bg-white text-sm font-medium  hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500',
                )}
              >
                Floor Price
                {order === 'floorPrice' ? (
                  direction === 'asc' ? (
                    <ArrowLongUpIcon className="h-4 w-4" aria-hidden="true" />
                  ) : (
                    <ArrowLongDownIcon className="h-4 w-4" aria-hidden="true" />
                  )
                ) : (
                  <></>
                )}
              </button>
            )}

            <button
              type="button"
              onClick={() => {
                setOrder('rank');
                setDirection((prevCheck: string) =>
                  prevCheck === 'asc' ? 'desc' : 'asc',
                );
              }}
              className={classNames(
                order === 'rank'
                  ? 'text-white bg-purple-600 dark:hover:bg-purple-500 dark:hover:text-white dark:text-white'
                  : 'text-gray-900 dark:bg-zinc-800 dark:hover:bg-zinc-700 dark:text-white',
                '-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 dark:border-zinc-600 bg-white text-sm font-medium text-gray-700 hover:text-gray-600 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500',
              )}
            >
              Rank
              {order === 'rank' ? (
                direction === 'asc' ? (
                  <ArrowLongUpIcon className="h-4 w-4" aria-hidden="true" />
                ) : (
                  <ArrowLongDownIcon className="h-4 w-4" aria-hidden="true" />
                )
              ) : (
                <></>
              )}
            </button>
          </span>
        )}
        {listed && (
          <Listbox value={selected} onChange={setSelected}>
            {({ open }) => (
              <>
                <Listbox.Label className="sr-only">
                  Change listed status
                </Listbox.Label>
                <div className="flex justify-center relative">
                  <div className="inline-flex shadow-sm rounded-md divide-x divide-purple-500">
                    <div className="relative z-0 inline-flex shadow-sm rounded-md divide-x divide-purple-600">
                      <div className="relative inline-flex items-center bg-purple-600 py-2 pl-3 pr-4 border border-transparent rounded-l-md shadow-sm text-white">
                        <p className="ml-2.5 text-sm font-medium dark:text-white text-white">
                          {selected.title}
                        </p>
                      </div>
                      <Listbox.Button className="relative inline-flex items-center bg-purple-600 p-2 rounded-l-none rounded-r-md text-sm font-medium text-white hover:bg-purple-600 focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-purple-500">
                        <span className="sr-only">Change listed status</span>
                        <ChevronDownIcon
                          className="h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                      </Listbox.Button>
                    </div>
                  </div>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="origin-top-right absolute z-10 right-0 mt-2 w-72 rounded-md shadow-lg overflow-hidden bg-white dark:bg-zinc-800 divide-y divide-gray-200 dark:divide-zinc-600 ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {publishingOptions.map(option => (
                        <Listbox.Option
                          key={option.title}
                          className={({ active }) =>
                            classNames(
                              active
                                ? 'text-white bg-purple-600'
                                : 'text-gray-900',
                              'cursor-pointer select-none relative p-4 text-sm',
                            )
                          }
                          value={option}
                        >
                          {({ selected, active }) => (
                            <div className="flex flex-col">
                              <div className="flex justify-between">
                                <p
                                  className={
                                    selected ? 'font-semibold' : 'font-normal'
                                  }
                                >
                                  {option.title}
                                </p>
                                {selected ? (
                                  <span
                                    className={
                                      active ? 'text-white' : 'text-purple-500'
                                    }
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        )}

        {selectedSource && setSource && (
          <Listbox value={selectedSource} onChange={setSelectedSource}>
            {({ open }) => (
              <>
                <Listbox.Label className="sr-only">Change market</Listbox.Label>
                <div className="flex justify-center relative ml-2">
                  <div className="inline-flex shadow-sm rounded-md divide-x divide-purple-500">
                    <div className="relative z-0 inline-flex shadow-sm rounded-md divide-x divide-purple-600">
                      <div className="relative inline-flex items-center bg-purple-600 py-2 pl-3 pr-4 border border-transparent rounded-l-md shadow-sm text-white">
                        <p className="ml-2.5 text-sm font-medium dark:text-white text-white">
                          {marketsOptions.find(m => m.value === selectedSource)
                            ?.title || 'All Markets'}
                        </p>
                      </div>
                      <Listbox.Button className="relative inline-flex items-center bg-purple-600 p-2 rounded-l-none rounded-r-md text-sm font-medium text-white hover:bg-purple-600 focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-purple-500">
                        <span className="sr-only">Change market</span>
                        <ChevronDownIcon
                          className="h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                      </Listbox.Button>
                    </div>
                  </div>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="origin-top-right absolute z-10 right-0 mt-2 w-72 rounded-md shadow-lg overflow-hidden bg-white dark:bg-zinc-800 divide-y divide-gray-200 dark:divide-zinc-600 ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {marketsOptions.map(option => (
                        <Listbox.Option
                          key={option.title}
                          className={({ active }) =>
                            classNames(
                              active
                                ? 'text-white bg-purple-600'
                                : 'text-gray-900',
                              'cursor-pointer select-none relative p-4 text-sm',
                            )
                          }
                          value={option.value}
                        >
                          {({ selected, active }) => (
                            <div className="flex flex-col">
                              <div className="flex justify-between">
                                <p
                                  className={
                                    selected ? 'font-semibold' : 'font-normal'
                                  }
                                >
                                  {option.title}
                                </p>
                                {selected ? (
                                  <span
                                    className={
                                      active ? 'text-white' : 'text-purple-500'
                                    }
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        )}
      </div>
    </>
  );
}

// Provider component that wraps our app and makes auth object ...

import { useEffect, useState } from 'react';
import NetworkContext, {
  NetworkContextProps,
} from '../contexts/NetworkContext';

// ... available to any child component that calls useAuth().
interface NetworkProviderProps {
  children: React.ReactNode; // | JSX.Element | JSX.Element[]
}
function getInitialState() {
  const auth = localStorage.getItem('network');
  const result = auth ? auth : 'CRONOS';

  return result;
}

export default function NetworkProvider({ children }: NetworkProviderProps) {
  const network = useProvideNetwork();
  return (
    <NetworkContext.Provider value={network}>
      {children}
    </NetworkContext.Provider>
  );
}

// Provider hook that creates auth object and handles state
function useProvideNetwork(): NetworkContextProps {
  const [network, setNetwork] = useState<string>(getInitialState);
  const [tttUSDPrice, setTTTUSDPrice] = useState<number>(0);
  const [emitUSDPrice, setEmitUSDPrice] = useState<number>(0);
  const [showWalletConnect, setShowWalletConnect] = useState(false);
  useEffect(() => {
    // if (user.wallet.serverWeb3Provider !== null) {
    //   localStorage.setItem(
    //     'rpcUrl',
    //     ,
    //   );
    // }

    localStorage.setItem('network', network);
  }, [network]);

  const updateNetwork = (network: string): void => {
    setNetwork(network);
    localStorage.setItem('network', network);
  };
  const updateTTTUSDPrice = (price: number): void => {
    setTTTUSDPrice(price);
  };
  const updateEMITUSDPrice = (price: number): void => {
    setEmitUSDPrice(price);
  };
  return {
    network,
    updateNetwork,
    updateTTTUSDPrice,
    emitUSDPrice,
    tttUSDPrice,
    updateEMITUSDPrice,
    showWalletConnect,
    setShowWalletConnect,
  };
}

import WalletConnectConnector from '../../web3/connectors/WalletConnectConnector';
import MetaMaskConnector from '../../web3/connectors/MetaMaskConnector';
import DefiConnectConnector from '../../web3/connectors/DefiConnectConnector';

const applications = [
  {
    name: 'MetaMask',
    imageUrl: '/metamask.webp',
    connector: MetaMaskConnector,
  },
  {
    name: 'Defi Wallet',
    imageUrl: '/defi.png',
    connector: DefiConnectConnector,
  },
  {
    name: 'Wallet Connect',
    imageUrl: '/walletconnect.png',
    connector: WalletConnectConnector,
  },
];

export default function ConnectWalletButtons({
  onClick,
}: {
  onClick: (connector: any) => void;
}) {
  return (
    <div className="ml-8 bg-white shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {applications.map(application => (
          <li key={application.name}>
            <a
              href={application.name}
              onClick={e => {
                e.preventDefault();
                onClick(application.connector);
              }}
              className="block hover:bg-gray-50"
            >
              <div className="flex items-center px-4 py-4 sm:px-6">
                <div className="min-w-0 flex-1 flex items-center">
                  <div className="flex-shrink-0">
                    <img
                      className="h-12 w-12 rounded-full"
                      src={application.imageUrl}
                      alt=""
                    />
                  </div>

                  <div className="block ml-6">
                    <p className="font-medium text-blue-600 ">
                      {application.name}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

// Provider component that wraps our app and makes auth object ...

import { useEffect, useState } from 'react';
import AuthContext, {
  AuthContextProps,
  AuthData,
  AuthUser,
  IWallet,
} from '../contexts/AuthContext';
import { ethers } from 'ethers';
import ApiClient from '../utils/ApiClient';

// ... available to any child component that calls useAuth().
interface AuthProviderProps {
  children: React.ReactNode; // | JSX.Element | JSX.Element[]
}
function getInitialState() {
  const auth = localStorage.getItem('auth');
  const result = auth ? JSON.parse(auth) : {};
  if (result !== undefined) {
    result.serverWeb3Provider = new ethers.providers.JsonRpcProvider(
      result.serverWeb3Provider,
    );
  }
  return result;
}

export default function AuthProvider({ children }: AuthProviderProps) {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

// Provider hook that creates auth object and handles state
function useProvideAuth(): AuthContextProps {
  const [user, setUser] = useState<AuthUser>(getInitialState);
  useEffect(() => {
    // if (user.wallet.serverWeb3Provider !== null) {
    //   localStorage.setItem(
    //     'rpcUrl',
    //     ,
    //   );
    // }

    localStorage.setItem(
      'auth',
      JSON.stringify({
        ...user,
        wallet: {
          ...user.wallet,
          wcProvider: null,
          wcConnector: null,
          serverWeb3Provider: user.wallet
            ? user.wallet.serverWeb3Provider?.connection?.url
            : null,
          browserWeb3Provider: null,
        },
      }),
    );
  }, [user]);
  const connected = (wallet: IWallet): void => {
    setUser({
      isConnected: true,
      isAuthenticated: false,
      wallet: wallet,
    } as AuthUser);
  };

  const authenticated = async (data: AuthData): Promise<void> => {
    setUser({
      isConnected: true,
      isAuthenticated: true,
      wallet: user.wallet,
      data: data,
    } as AuthUser);
  };

  const updateUser = (user: AuthUser): void => {
    setUser({ ...user });
  };

  const forceUserUpdate = (): void => {
    ApiClient.getMe().then((result: any) => {
      const activeSubscriptions =
        result.account.accountSubscription &&
        result.account.accountSubscription.length > 0
          ? result.account.accountSubscription
          : null;

      setUser({
        isConnected: true,
        isAuthenticated: true,
        wallet: user.wallet,
        data: {
          token: '',
          ...user.data,
          email: result.account.email,
          image: result.account.image,
          name: result.account.name,
          id: result.account.id,
          address: result.account.address,
          wallets: result.account.wallets,
          tokenCount: result.tokenCount,
          collectionCount: result.collectionCount,
          lastTokenImport: result.account.lastTokenImport,
          firstImportDone: result.account.firstImportDone,
          activeSubscriptions,
          level: result.account.level,
          requiredXP: result.account.requiredXP,
          xpForNextLevel: result.account.xpForNextLevel,
          xp: result.account.xp,
          hasFullAccess: result.account.hasFullAccess, // result.account.role === 'ADMIN' ? true :  TODO
          role: result.account.role,
        },
      });
    });
  };

  const disconnected = (): void => {
    setUser({
      isConnected: false,
      isAuthenticated: false,
    } as AuthUser);
    localStorage.removeItem('auth');
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  //   useEffect(() => {
  // const unsubscribe = firebase.auth().onAuthStateChanged(user => {
  //   if (user) {
  //     setUser(user);
  //   } else {
  //     setUser(null);
  //   }
  // });
  // Cleanup subscription on unmount
  // return () => unsubscribe();
  //   }, []);
  // Return the user object and auth methods

  const [activeWallet, setActiveWallet] = useState<string | null>(null);
  const [loadingActiveWallet, setLoadingActiveWallet] = useState(true);

  useEffect(() => {
    if (!window.ethereum) {
      setLoadingActiveWallet(false);
      return;
    }
    const fetchActiveWallet = async (provider: any) => {
      try {
        if (!provider) {
          setLoadingActiveWallet(false);
          return;
        }

        await window.ethereum.request({
          method: 'eth_requestAccounts',
        });

        const signer = provider.getSigner();
        const address = await signer.getAddress();
        setActiveWallet(address);

        setLoadingActiveWallet(false);
      } catch (error) {
        console.error('Error fetching active wallet:', error);
        setActiveWallet(null);
        setLoadingActiveWallet(false);
      }
    };

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);

      // Function to fetch and set the active wallet address

      // Subscribe to account change events
      window.ethereum.on('accountsChanged', (accounts: any) => {
        if (accounts.length > 0) {
          setActiveWallet(accounts[0]);
        } else {
          setActiveWallet(null);
        }
      });

      // Fetch the active wallet address initially
      fetchActiveWallet(provider);
    } catch (err) {
      console.log(err);
    }

    // Cleanup function to unsubscribe from the event
    return () => {
      window.ethereum.removeListener('accountsChanged', fetchActiveWallet);
    };
  }, [window.ethereum]);

  return {
    user,
    activeWallet,
    loadingActiveWallet,
    setActiveWallet,
    connected,
    disconnected,
    forceUserUpdate,
    authenticated,
    isConnected: user.isConnected,
    isAuthenticated: user.isAuthenticated,
    hasFullAccess: (user.isAuthenticated && user.data?.hasFullAccess) || false, // TODO: add user.data?.role === 'ADMIN'
    isAdmin: user.data?.role === 'ADMIN',
    updateUser,
  };
}

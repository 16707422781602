import { useEffect, useState } from 'react';
import ApiClient from '../../../utils/ApiClient';
import {
  numberWithCommas,
  parseNumberToShortForm,
} from '../../../utils/parser';
import { useAuth } from '../../../hooks/useAuth';
import { useParams } from 'react-router-dom';
import classNames from '../../../utils/classNames';
import { CalendarDaysIcon } from '@heroicons/react/24/outline';

const ContestLeaderboard = () => {
  const { id } = useParams();
  const [contest, setContest] = useState<any>();
  const { user, isAuthenticated } = useAuth();

  useEffect(() => {
    if (!id) return;

    ApiClient.getContest(id as string).then(res => {
      window.history.replaceState(
        null,
        res.contest.TokenTrack.tokenSymbol + ' Contest',
        '/app/contest/' +
          res.contest.id +
          '/' +
          res.contest.TokenTrack.tokenSymbol,
      );
      setContest(res.contest);
    });
  }, [id]);

  const getRowClass = (index: number) => {
    let baseClass = '';
    if (index === 0) {
      baseClass =
        'border-yellow-300/50 bg-yellow-300/20 text-yellow-500/90 dark:text-yellow-400 dark:bg-yellow-300/30';
    } else if (index === 1) {
      baseClass =
        'border-gray-300 bg-gray-300/40 text-gray-500 dark:text-gray-400 dark:bg-gray-300/20';
    } else if (index === 2) {
      baseClass =
        'border-yellow-600/30 bg-yellow-500/20 text-yellow-600/90 dark:text-yellow-500';
    } else {
      baseClass =
        'border-gray-200 bg-white text-gray-600 dark:bg-zinc-900 dark:text-zinc-100';
    }

    return baseClass;
  };

  if (contest === undefined) return <></>;
  return (
    <>
      <div className="flex flex-row justify-between">
        <h1 className="text-2xl font-semibold text-gray-900 dark:text-zinc-100 mx-6">
          {contest ? contest.TokenTrack.tokenSymbol + ' Contest' : 'Contest'}
          <p className="dark:text-zinc-300">
            {contest ? new Date(contest.startsAt).toDateString() : ''} -{' '}
            {contest ? new Date(contest.endsAt).toDateString() : ''}
          </p>
        </h1>
        <div className="mx-6 dark:text-zinc-300">
          {' '}
          <div className="text-base ">
            {!contest.hasStarted ? (
              <div className="px-8 py-2 border rounded-md text-blue-500 dark:border-blue-500 font-semibold border-blue-300 bg-blue-600/10">
                Upcoming
              </div>
            ) : contest.hasEnded ? (
              <div className="px-8 py-2 border rounded-md font-semibold text-gray-500 border-gray-300 dark:border-gray-500 bg-gray-600/10">
                Ended
              </div>
            ) : (
              <div className="px-8 py-2 border rounded-md text-pink-500 font-semibold border-pink-300 dark:border-pink-500 bg-pink-600/10">
                Live
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-[2fr,1fr] gap-8 px-0 mt-4 mx-6">
        <div className="rounded-md shadow-md">
          {contest ? (
            <div>
              <table className="min-w-full divide-y divide-gray-200 dark:divide-zinc-900 rounded-md">
                <thead className="bg-gray-50 dark:bg-zinc-900">
                  <tr>
                    <th className="pl-6 py-3 text-left text-xs font-medium text-gray-500 dark:bg-zinc-900 uppercase tracking-wider">
                      #
                    </th>
                    <th className=" py-3 text-left text-xs font-medium text-gray-500 dark:bg-zinc-900 uppercase tracking-wider">
                      User
                    </th>
                    <th className="px-6 py-3 text-end text-xs font-medium text-gray-500 dark:bg-zinc-900 uppercase tracking-wider">
                      Points
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white dark:bg-zinc-900 divide-y divide-gray-200 dark:divide-zinc-900">
                  {contest.Entries.map((entry: any, index: number) => (
                    <tr key={index} className={`text-sm ${getRowClass(index)}`}>
                      <td className="pl-6 py-4 whitespace-nowrap text-lg font-semibold">
                        {index + 1}
                      </td>
                      <td className=" py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <img
                            src={entry.Account.image || '/time.png'}
                            className="rounded-full w-10 h-10 mr-2"
                            alt={entry.Account.address}
                          />
                          <div>
                            <div
                              className={`leading-5 font-medium text-lg ${
                                user?.data?.address === entry.Account.address
                                  ? 'font-bold underline'
                                  : ''
                              }`}
                            >
                              {entry.Account.address.slice(0, 6) +
                                '...' +
                                entry.Account.address.slice(-4)}
                            </div>
                            <div className="text-xs ">
                              <a
                                href={`https://cronoscan.com/address/${entry.Account.address}`}
                                className="hover:text-gray-400 hover:underline"
                                target="_blank"
                                rel="noreferrer"
                              >
                                View on Cronoscan
                              </a>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap font-semibold text-lg text-end">
                        <div className="flex flex-col">
                          <div>
                            {parseNumberToShortForm(
                              entry.points > 0 ? entry.points : 0,
                              2,
                            )}{' '}
                            pts
                          </div>
                          <div className="text-sm">
                            $
                            {parseNumberToShortForm(
                              Number(Number(entry.totalBoughtUsd).toFixed(2)),
                            )}{' '}
                            USD
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="text-center p-4">Loading...</div>
          )}
        </div>
        <div className="rounded-md shadow-md bg-white dark:bg-zinc-900 dark:border dark:rounded-lg dark:border-zinc-800 h-fit">
          <div className="p-4 lg:p-6">
            <div className="flex flex-row justify-between">
              <div>
                <h2 className="text-2xl font-bold text-gray-900 dark:text-zinc-100 mb-4">
                  Contest Statistics
                </h2>
                <div className="text-sm text-gray-500 dark:text-zinc-100">
                  <p className="font-bold text-lg dark:text-zinc-100">
                    Prizes:
                  </p>
                  <div className="flex flex-wrap items-center mb-4">
                    {contest.metadata.prizes
                      ?.filter((p: string) => p !== '')
                      .map((prize: string, index: number) => (
                        <div
                          key={index}
                          className={`flex items-center font-medium text-sm border rounded-md px-3 py-2 mt-2 mr-2 ${
                            index === 0
                              ? 'border-yellow-300/50 bg-yellow-300/20 text-yellow-500/90 dark:border-yellow-300/80 dark:text-yellow-400'
                              : index === 1
                              ? 'border-gray-300 bg-gray-300/40 text-gray-500 dark:text-gray-400 dark:bg-gray-300/20 dark:border-gray-400'
                              : index === 2
                              ? 'border-yellow-600/30 bg-yellow-500/20 text-yellow-600/90 dark:border-yellow-600/60 dark:text-yellow-500'
                              : 'border-gray-200 bg-white text-gray-600 dark:bg-zinc-900 dark:text-zinc-100'
                          }`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1em"
                            height="1em"
                            viewBox="0 0 24 24"
                            className="inline mr-2"
                          >
                            <path
                              fill="currentColor"
                              d="M14.94 19.5L12 17.77L9.06 19.5l.78-3.34l-2.59-2.24l3.41-.29L12 10.5l1.34 3.13l3.41.29l-2.59 2.24M20 2H4v2l4.86 3.64a8 8 0 1 0 6.28 0L20 4m-2 11a6 6 0 1 1-7.18-5.88a5.9 5.9 0 0 1 2.36 0A6 6 0 0 1 18 15m-5.37-8h-1.26l-4-3h9.34Z"
                            ></path>
                          </svg>
                          {prize}
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              <div>
                <img
                  src={contest.TokenTrack.tokenLogo}
                  className="rounded-full w-16 h-16"
                  alt={contest.TokenTrack.tokenName}
                />
              </div>
            </div>
            <div className="flex justify-between">
              <p className="text-lg dark:text-zinc-100">
                <b>Total points:</b>
                <br />
                {contest ? numberWithCommas(contest.sum._sum.points) : ''}
              </p>
              <p className="text-lg text-end dark:text-zinc-100">
                <b>Total USD:</b>
                <br />
                {contest
                  ? '$' +
                    numberWithCommas(
                      Number(contest.sum._sum.totalBoughtUsd).toFixed(2),
                    )
                  : '-'}
              </p>
            </div>
            <div className="text-base font-semibold text-gray-600 flex justify-center mt-6">
              <div className="w-fit border-2 rounded-sm px-6 py-1 border-zinc-700 text-zinc-700 dark:border-zinc-200 dark:text-zinc-100">
                {!contest.hasStarted ? (
                  <>
                    <CalendarDaysIcon className="h-4 w-4 inline mr-1 self-start align-top" />
                    Starts in{' '}
                    {contest.daysUntilStart !== 0
                      ? `${contest.daysUntilStart} days`
                      : contest.hoursUntilStart
                      ? `${contest.hoursUntilStart} hour${
                          contest.hoursUntilStart > 1 ? 's' : ''
                        }`
                      : `${
                          contest.minutesUntilStart > 0
                            ? `${contest.minutesUntilStart} minutes`
                            : 'one moment'
                        }`}
                  </>
                ) : !contest.hasEnded ? (
                  <>
                    Ends in{' '}
                    {contest.daysUntilEnd !== 0
                      ? `${contest.daysUntilEnd} day${
                          contest.daysUntilEnd > 1 ? 's' : ''
                        }`
                      : contest.hoursUntilEnd
                      ? `${contest.hoursUntilEnd} hour${
                          contest.hoursUntilEnd > 1 ? 's' : ''
                        }`
                      : `${
                          contest.minutesUntilEnd > 0
                            ? `${contest.minutesUntilEnd} minute${
                                contest.minutesUntilEnd > 1 ? 's' : ''
                              }`
                            : 'one moment'
                        }`}
                  </>
                ) : (
                  <>Ended</>
                )}
              </div>
            </div>
            {contest.hasEnded && (
              <div className="flex justify-center mt-6">
                <p className="text-sm dark:text-zinc-300">
                  {contest.noWinner
                    ? 'No winner was chosen for this contest.'
                    : !contest.prizeGiven
                    ? 'Prizes have not been distributed yet.'
                    : 'Prizes have been distributed.'}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContestLeaderboard;

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import { ADDRESS, ABI } from '../../../web3/ABI/contracts/time-soft-staking';
import TimeABI from '../../../web3/ABI/time.abi.json';
import { ethers } from 'ethers';
import {
  SoftStakingContractKeys,
  softStakingContracts,
} from '../../../web3/config';
import { formatEther } from 'ethers/lib/utils';
import { MyStakingTokenList } from '../components/token/MyStakingTokenList';
import { ensureCronos, txWait } from '../../../web3/utils';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNetwork } from '../../../hooks/useNetwork';
import WalletRequired from '../components/WalletRequired';

export type UserReward = {
  token: `0x${string}`;
  rewards: bigint;
};
export type CollectionInfoData = {
  enabled: boolean;
  size: bigint;
  seasonDuration: bigint;
  seasonEndTimestamp: bigint;
  contracts: {
    main: `0x${string}`;
    booster: `0x${string}`;
    bonusCap: bigint;
  };
  rewardInfos: readonly {
    enabled: boolean;
    total: bigint;
    pendingTotalPerToken: bigint;
    rate: bigint;
    collectionSize: bigint;
  }[];
  tokenSymbols: readonly string[];
};

export default function SoftStaking() {
  const { user, activeWallet, loadingActiveWallet } = useAuth();

  const [balance, setBalance] = useState(0);
  const [userRewards, setUserRewards] = useState<
    readonly UserReward[] | undefined
  >(undefined);
  const [stakeInfo, setStakeInfo] = useState<CollectionInfoData | undefined>(
    undefined,
  );
  const [bonusPercent, setBonusPercent] = useState<bigint | undefined>(
    undefined,
  );
  const [fee, setFee] = useState<bigint | undefined>(undefined);
  let { name } = useParams<{ name?: SoftStakingContractKeys }>(); // Ensure name is a string or undefined

  const collectionAddress =
    softStakingContracts[name as SoftStakingContractKeys]?.contract;

  const collectionName =
    softStakingContracts[name as SoftStakingContractKeys]?.name;

  const collectionImage =
    softStakingContracts[name as SoftStakingContractKeys]?.image;
  let testWallet: string | null = null;
  const { setShowWalletConnect } = useNetwork();
  useEffect(() => {
    if (collectionAddress === undefined || !activeWallet) return;

    fetchBalance();
    retrieveRewards();
  }, [activeWallet, collectionAddress]);

  if (collectionAddress === undefined) {
    window.location.href = '/';
    return <></>;
  }

  if (loadingActiveWallet) {
    return <></>;
  }

  if (!activeWallet) {
    return <WalletRequired />;
  }

  // name: 101s, etc
  async function fetchBalance() {
    try {
      if (
        !user ||
        !user.wallet ||
        !user.isConnected ||
        !activeWallet ||
        !collectionAddress
      ) {
        setBalance(0);
        return;
      }

      const provider = new ethers.providers.Web3Provider(window.ethereum);

      const collectionContract = new ethers.Contract(
        collectionAddress,
        TimeABI,
        provider,
      );
      const balance = await collectionContract.balanceOf(
        testWallet || activeWallet,
      );

      setBalance(ethers.BigNumber.from(balance).toNumber());

      // get tokens of wallet
      const tokens = await collectionContract.tokensOfWallet(
        testWallet || activeWallet,
      );
    } catch (error) {
      console.error('Failed to fetch contract data', error);
    }
  }

  function claim() {
    claimRewards();
  }

  async function retrieveRewards() {
    await ensureCronos();
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const stakingContract = new ethers.Contract(ADDRESS, ABI, provider);
    const stakeInfo = await stakingContract.getInfo(collectionAddress);
    setStakeInfo(stakeInfo);
    const userRewards = await stakingContract.getAllRewards(
      collectionAddress,
      testWallet! || activeWallet!,
    );
    setUserRewards(userRewards);

    const stakingContractSigned = new ethers.Contract(ADDRESS, ABI, signer);
    const bonusPercent = await stakingContractSigned.getBonusPercent(
      activeWallet!,
      collectionAddress,
    );
    setBonusPercent(bonusPercent);

    const fee = await stakingContract.fee();
    setFee(fee);
  }

  const claimRewards = async () => {
    if (!user || !user.wallet || !user.isConnected || !activeWallet) {
      console.log('User or wallet not fully initialized or not connected');
      alert('User or wallet not fully initialized or not connected');
      return;
    }

    try {
      await ensureCronos();
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const stakingContract = new ethers.Contract(ADDRESS, ABI, signer);

      const fee = await stakingContract.fee();

      const gasEstimate = await provider.estimateGas({
        to: ADDRESS,
        from: await signer.getAddress(),
        data: stakingContract.interface.encodeFunctionData('claimRewards', [
          collectionAddress,
        ]),
        value: fee ?? 0,
      });

      await txWait(
        stakingContract.claimRewards(collectionAddress, {
          value: fee ?? 0,
          gasLimit: gasEstimate,
        }),
      );
      console.log('Rewards claimed');
      alert('Rewards claimed');
      fetchBalance();
      retrieveRewards();
    } catch (error: any) {
      console.log(error.code);
      switch (error.code) {
        case -32603:
          alert('You do not have enough funds to perform this transaction.');
          break;
        default:
          alert('Something went wrong, please view the console logs.');
          break;
      }
      console.error('Failed to claim rewards:', error);
    }
  };

  const tokenList = [];
  const stakingEndDate =
    stakeInfo && Number(stakeInfo.seasonEndTimestamp) > 0
      ? new Date(Number(stakeInfo.seasonEndTimestamp) * 1000)
      : undefined;

  return (
    <>
      <h1 className="text-2xl font-semibold text-gray-900 dark:text-white">
        Soft Stake {collectionName}
      </h1>
      <div className="flex flex-col sm:flex-row justify-center gap-x-6 w-full">
        <div className="flex-1 bg-white dark:bg-zinc-900 dark:text-white my-6 shadow border border-gray-100 dark:border-zinc-600 bg-white p-6 rounded-lg">
          <div className="flex flex-col md:flex-row justify-between">
            <div className="flex md:hidden justify-center">
              <LazyLoadImage
                src={collectionImage}
                className="rounded-full w-32"
              />
            </div>

            <div className="mt-6 md:mt-0 text-center md:text-start">
              <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
                My Wallet
              </h1>
              <div>
                <div className="flex flex-col mt-0">
                  <p className="text-sm dark:text-white">
                    Owned NFTs: {balance}
                  </p>
                  <p className="text-sm dark:text-white">
                    {bonusPercent !== undefined &&
                      `${(Number(bonusPercent) / 100).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}% Boost`}
                  </p>
                  <p className="text-sm mt-4 text-center md:text-start dark:text-white">
                    Available to claim
                  </p>
                  <h2 className="text-xl font-bold text-center md:text-start">
                    {stakeInfo &&
                      stakeInfo.rewardInfos.map((at, i) => (
                        <div key={'rikey' + i}>
                          {userRewards && userRewards.length > i
                            ? Number(
                                formatEther(userRewards[i].rewards),
                              ).toFixed(2)
                            : '-'}{' '}
                          {stakeInfo.tokenSymbols[i]}
                        </div>
                      ))}
                  </h2>
                </div>
                <div className="mt-2">
                  <button
                    onClick={() => claim()}
                    className="cursor-pointer disabled:cursor-default bg-white dark:bg-zinc-800 dark:text-white dark:border-zinc-700 dark:disabled:opacity-50 dark:hover:bg-zinc-700 dark:disabled:hover:bg-zinc-800 disabled:bg-gray-200 py-2 px-6 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 disabled:text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    disabled={balance === 0}
                  >
                    Claim
                  </button>
                </div>
              </div>
            </div>

            <div className="hidden md:flex items-center ">
              <LazyLoadImage
                src={collectionImage}
                className="rounded-full w-32"
              />
            </div>

            <div className="text-center md:text-end mt-8 md:mt-0">
              <h1 className="text-xl font-semibold text-gray-900 dark:text-white">
                Pool
              </h1>
              {stakeInfo &&
                stakeInfo.rewardInfos.map((at, i) => (
                  <div key={'ri2' + i} className="text-md">
                    {formatEther(stakeInfo.rewardInfos[i].total)}{' '}
                    {stakeInfo.tokenSymbols[i]}
                  </div>
                ))}

              <h1 className="mt-6 text-xl font-semibold text-gray-900 dark:text-white">
                End Date
              </h1>
              {stakingEndDate ? (
                <span
                  className={`text-md ${
                    new Date() >
                    new Date(Number(stakeInfo?.seasonEndTimestamp) * 1000)
                      ? 'text-[red]'
                      : ''
                  }`}
                >
                  {stakingEndDate.toLocaleString(undefined, {
                    dateStyle: 'short',
                    timeStyle: 'short',
                  })}
                </span>
              ) : (
                '-'
              )}
            </div>
          </div>
        </div>
      </div>
      <MyStakingTokenList
        collectionName={collectionName || ''}
        address={collectionAddress}
      ></MyStakingTokenList>
    </>
  );
}

/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import { useState } from 'react';
import { ArrowRightIcon } from '@heroicons/react/24/solid';
import { RadioGroup } from '@headlessui/react';
import { BellIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../hooks/useAuth';
import { Tooltip } from 'flowbite-react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Collection, Token } from '../../../../utils/ApiClient';

const product = {
  name: 'Everyday Ruck Snack',
  href: '#',
  price: '$220',
  description:
    "Don't compromise on snack-carrying capacity with this lightweight and spacious bag. The drawstring top keeps all your favorite chips, crisps, fries, biscuits, crackers, and cookies secure.",
  imageSrc:
    'https://tailwindui.com/img/ecommerce-images/product-page-04-featured-product-shot.jpg',
  imageAlt:
    'Model wearing light green backpack with black canvas straps and front zipper pouch.',
  breadcrumbs: [
    { id: 1, name: 'Travel', href: '#' },
    { id: 2, name: 'Bags', href: '#' },
  ],
  sizes: [
    { name: '18L', description: 'Perfect for a reasonable amount of snacks.' },
    { name: '20L', description: 'Enough room for a serious amount of snacks.' },
  ],
};
const reviews = { average: 4, totalCount: 1624 };

export default function TokenCard({
  token,
  collection,
}: {
  token: Token;
  collection: Collection | undefined;
}) {
  const navigate = useNavigate();
  const { isAuthenticated, user } = useAuth();

  const [selectedSize, setSelectedSize] = useState(product.sizes[0]);
  if (token === undefined || collection === undefined) return <div></div>;
  console.log(token);
  return (
    <div className="bg-white dark:bg-zinc-900">
      <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-x-8">
        {/* Product details */}
        <div className="lg:max-w-lg lg:self-end">
          <nav aria-label="Breadcrumb">
            <ol role="list" className="flex items-center space-x-2">
              <div className="flex items-center text-sm">
                <a
                  href={'/app/collection/' + collection.address}
                  className="font-medium text-gray-500 hover:text-gray-900 dark:text-gray-400"
                >
                  {collection.name}
                </a>
              </div>
            </ol>
          </nav>

          <div className="mt-4">
            <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 dark:text-gray-300 sm:text-4xl">
              {token.name}
            </h1>
          </div>

          <section
            aria-labelledby="information-heading"
            className="mt-4 gap-6 grid grid-cols-2"
          >
            <div className="border rounded-lg px-6 py-3">
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Floor price
              </p>
              <p className="mt-6 text-base font-medium text-gray-900 dark:text-gray-300">
                {Number(collection.pFloor).toFixed(2)}
              </p>
            </div>
            <div className="border rounded-lg px-6 py-3">
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Best list/last price
              </p>
              <div className="flex">
                <p className="mt-4 flex-inline text-base font-medium text-gray-900 dark:text-gray-300">
                  {parseFloat(token.pListPrice.toString()).toFixed(1)} /{' '}
                  {token.pLastPrice === 0
                    ? 'mint'
                    : token.pLastPrice
                    ? parseFloat(token.pLastPrice.toString()).toFixed(1)
                    : 0}
                </p>

                <button
                  onClick={() => {
                    navigate(
                      '/app/alert/add/token/' +
                        token.collection.address +
                        '/' +
                        token.tokenId,
                    );
                  }}
                  disabled={!isAuthenticated}
                  className="ml-auto -mt-0 text-sm font-medium text-gray-900 disabled:text-gray-500 dark:text-gray-300 disabled:dark:text-gray-500"
                >
                  <BellIcon className="flex-inline mt-4 w-7 h-7" />
                </button>
              </div>
            </div>

            {Number(token.rank) && Number(token.rank) !== -1 ? (
              <div className="border rounded-lg px-6 py-3">
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  Rarity rank
                </p>
                <p className="mt-6 text-base font-medium text-gray-900 dark:text-gray-300">
                  #{token.rank}
                </p>
              </div>
            ) : null}

            <div className="border rounded-lg px-6 py-3">
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Average price
              </p>
              <p className="mt-6 text-base font-medium text-gray-900 dark:text-gray-300">
                {`${Math.round(collection.pAvgPrice * 100) / 100}`}
              </p>
            </div>
          </section>
        </div>

        {/* Product image */}
        <div className="mt-10 lg:mt-0 lg:col-start-2 lg:row-span-2 lg:self-center">
          <div className="aspect-w-1 aspect-h-1 rounded-lg overflow-hidden">
            <LazyLoadImage
              src={token.image.trim() === '' ? '/nav.png' : token.image}
              alt={token.name}
              className="w-full h-full object-center object-cover"
            />
          </div>
        </div>

        {/* Product form */}
        <div className="mt-10 lg:max-w-lg lg:col-start-1 lg:row-start-2 lg:self-start">
          <section aria-labelledby="options-heading"></section>
        </div>
      </div>
    </div>
  );
}

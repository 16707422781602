import React, { createContext, useContext, useEffect, useState } from 'react';

const ToastNotification = React.memo(({ show, title, message, type }: any) => {
  if (!show) return null;

  return (
    <div
      className={`fixed top-0 left-1/2 min-w-[20vw] transform -translate-x-1/2 mt-5 bg-white shadow-lg text-black text-sm rounded-md z-50`}
    >
      <div className="p-4">
        <div className="font-semibold">{title}</div>
        <div>{message}</div>
      </div>
      <div className={`h-1 bg-gray-200 w-full rounded-bl-md rounded-br-md`}>
        <div
          className={`rounded-bl-md ${
            type === 'success'
              ? 'bg-green-500'
              : type === 'info'
              ? 'bg-blue-500'
              : 'bg-red-500'
          } h-full progress-bar-animation`}
        ></div>
      </div>
    </div>
  );
});

const ToastContext = createContext({
  triggerToast: (
    title: string,
    msg: string,
    type: 'error' | 'success' | 'info',
  ) => {},
});

export const useToast = () => useContext(ToastContext);

export const ToastProvider = ({ children }: any) => {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');
  const [type, setType] = useState<'error' | 'success' | 'info'>('info');

  const triggerToast = (
    title: string,
    msg: string,
    type: 'error' | 'success' | 'info',
  ) => {
    setType(type);
    setMessage(msg);
    setTitle(title);
    setShow(true);

    setTimeout(() => {
      setShow(false);
    }, 5000);
  };

  return (
    <ToastContext.Provider value={{ triggerToast }}>
      {children}
      <ToastNotification
        show={show}
        title={title}
        message={message}
        type={type}
      />
    </ToastContext.Provider>
  );
};

import { useEffect, useState } from 'react';
import { useAuth } from '../../../../hooks/useAuth';
import ApiClient from '../../../../utils/ApiClient';
import AdminTabs from '../../components/admin/AdminTabs';
import { BigNumber, ethers } from 'ethers';
import ERC20ABI from '../../../../web3/ABI/erc20.cro.abi.json';
import { ensureCronos, txWait } from '../../../../web3/utils';
import {
  SoftStakingContractKeys,
  softStakingContracts,
} from '../../../../web3/config';
import { ABI, ADDRESS } from '../../../../web3/ABI/contracts/time-soft-staking';
import { parseEther } from 'ethers/lib/utils';

const collections = Object.keys(softStakingContracts).filter(
  k => k !== 'unknown' && k !== undefined,
);

const contractConfig = {
  address: ADDRESS,
  abi: ABI,
};

const testWallet: string | null = null;
export default function AdminSoftStaking() {
  const { user, activeWallet, isAuthenticated } = useAuth();
  const [activeCollection, setActiveCollection] = useState(
    collections[0] || '',
  );
  const collectionTokens =
    softStakingContracts[activeCollection as SoftStakingContractKeys]?.tokens;
  const [allowance, setAllowance] = useState<
    Record<string, { allowance: bigint; enteredAmount: number }>
  >(
    collectionTokens
      ? collectionTokens.reduce((acc, token) => {
          acc[token.name] = {
            allowance: BigInt(0),
            enteredAmount: 0,
          };
          return acc;
        }, {} as Record<string, { allowance: bigint; enteredAmount: number }>)
      : {},
  );
  const [canStartSeason, setCanStartSeason] = useState(false);

  const handleChangeAmount: any = (amount: any, tokenName: string) => {
    setAllowance(old => {
      // Creating a new object for the new state
      return {
        ...old,
        [tokenName]: {
          ...old[tokenName],
          enteredAmount: amount,
        },
      };
    });
  };

  useEffect(() => {
    const keys = Object.keys(allowance);
    let canStartSeasonCheck = true;
    for (let i = 0; i < keys.length; i += 1) {
      const tokenName = keys[i];
      const token = allowance[tokenName];

      const amount = parseEther(`${Number(token.enteredAmount)}`);

      if (
        canStartSeasonCheck &&
        (!amount.gt(0) || !amount.lte(token.allowance))
      ) {
        canStartSeasonCheck = false;
      }
    }

    setCanStartSeason(canStartSeasonCheck);
  }, [allowance]);

  const collectionAddress =
    softStakingContracts[activeCollection as SoftStakingContractKeys]?.contract;

  async function getAllowance() {
    if ((!testWallet && !activeWallet) || !collectionTokens) return;

    await ensureCronos();
    // WCRO
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    let newAllowance: any = {};
    for (let i = 0; i < collectionTokens.length; i += 1) {
      const poolContract = new ethers.Contract(
        collectionTokens[i].contract,
        ERC20ABI,
        provider,
      );

      const gotTokenAllowance = await poolContract.allowance(
        testWallet ? testWallet! : activeWallet!,
        ADDRESS,
      );

      newAllowance[collectionTokens[i].name] = {
        allowance: gotTokenAllowance,
        enteredAmount: 0,
      };
    }

    setAllowance(newAllowance);
  }

  useEffect(() => {
    getAllowance();
  }, [activeCollection, activeWallet]);

  async function onStartSeason() {
    if (!canStartSeason || !collectionAddress) {
      alert('Season is not startable');
      return;
    }

    await ensureCronos();
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const startSeasonContract = new ethers.Contract(
      contractConfig.address,
      contractConfig.abi,
      provider,
    );

    const allowances = Object.keys(allowance).flatMap(k => {
      return [allowance[k].enteredAmount];
    });
    const addresses = Object.keys(allowance).flatMap(k => {
      return [
        softStakingContracts[
          activeCollection as SoftStakingContractKeys
        ]?.tokens.map(t => t.contract),
      ];
    });

    console.log({ allowances });
    console.log({ addresses });
    const startSeason = await startSeasonContract
      .startSeason(collectionAddress, allowances, addresses)
      .catch((e: any) => e);

    if (startSeason instanceof Error) {
      console.log(startSeason);
      alert(startSeason.message);
    }
  }

  async function onApprove(tokenName: string) {
    if (
      !user ||
      !user.wallet ||
      !user.isConnected ||
      !user.isAuthenticated ||
      !activeWallet
    ) {
      console.log('User or wallet not fully initialized or not connected');
      alert('User or wallet not fully initialized or not connected');
      return;
    }

    const collection = collectionTokens?.find(t => t.name === tokenName);
    if (!collection?.contract) {
      console.log('invalid col');
      return;
    }

    const token = allowance[tokenName];
    const amount = parseEther(`${Number(token.enteredAmount)}`);
    if (amount.lte(0)) {
      alert('Invalid amount.');
      return;
    }

    try {
      await ensureCronos();
      const provider = new ethers.providers.Web3Provider(window.ethereum);

      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        collection?.contract,
        ERC20ABI,
        signer,
      );

      await txWait(contract.approve(ADDRESS, amount));
      getAllowance();
    } catch (error: any) {
      console.log(error.code);
      switch (error.code) {
        case -32603:
          alert('You do not have enough funds to perform this transaction.');
          break;
        default:
          //alert('Something went wrong, please view the console logs.');
          break;
      }
      console.error('Failed:', error);
    }
  }

  async function onWithdrawAll() {
    if (
      !user ||
      !user.wallet ||
      !user.isConnected ||
      !user.isAuthenticated ||
      !activeWallet
    ) {
      console.log('User or wallet not fully initialized or not connected');
      alert('User or wallet not fully initialized or not connected');
      return;
    }

    try {
      await ensureCronos();
      const provider = new ethers.providers.Web3Provider(window.ethereum);

      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        contractConfig.address,
        contractConfig.abi,
        signer,
      );

      await txWait(contract.withdrawAll());
      getAllowance();
    } catch (error: any) {
      console.log(error.code);
      switch (error.code) {
        case -32603:
          alert('You do not have enough funds to perform this transaction.');
          break;
        default:
          //alert('Something went wrong, please view the console logs.');
          break;
      }
      console.error('Failed:', error);
    }
  }

  return (
    <>
      <AdminTabs />
      <h2 className="mt-6 text-xl font-semibold text-gray-900 dark:text-white">
        Soft Staking
      </h2>
      <div className="mt-4">
        <select
          id="chain"
          name="chain"
          value={activeCollection}
          onChange={e => {
            setActiveCollection(e.target.value);
          }}
          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        >
          {collections.map(col => (
            <option key={col} value={col}>
              {softStakingContracts[col as SoftStakingContractKeys]?.name}
            </option>
          ))}
        </select>
        <div className="block md:flex gap-x-8">
          {Object.keys(allowance).map(k => {
            return (
              <div key={k}>
                <label
                  htmlFor="wcro"
                  className="mt-4 block text-sm font-medium text-gray-700"
                >
                  {k}
                </label>
                <input
                  type="number"
                  id={k}
                  value={allowance[k].enteredAmount}
                  onChange={event => {
                    handleChangeAmount(Number(event.target.value) || 0, k);
                  }}
                  placeholder={'0'}
                  required
                  className="mt-1 block py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <button
                  onClick={() => onApprove?.(k)}
                  disabled={allowance[k].enteredAmount === 0}
                  className="cursor-pointer mt-3 disabled:cursor-default bg-white dark:bg-zinc-800 dark:text-white dark:border-zinc-700 dark:disabled:opacity-50 dark:hover:bg-zinc-700 dark:disabled:hover:bg-zinc-800 disabled:bg-gray-200 py-2 px-6 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 disabled:text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Approve {k}
                </button>
              </div>
            );
          })}
        </div>

        <div className="mt-6">
          <h2 className="font-semibold text-xl">Season</h2>
          <button
            onClick={() => onStartSeason?.()}
            disabled={!canStartSeason}
            className="cursor-pointer mt-3 disabled:cursor-default bg-white dark:bg-zinc-800 dark:text-white dark:border-zinc-700 dark:disabled:opacity-50 dark:hover:bg-zinc-700 dark:disabled:hover:bg-zinc-800 disabled:bg-gray-200 py-2 px-6 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 disabled:text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {canStartSeason ? 'Start Season' : 'Cannot start season'}
          </button>
        </div>

        <div className="mt-6">
          <h2 className="font-semibold text-xl">Fees</h2>
          <button
            onClick={() => onWithdrawAll?.()}
            className="cursor-pointer mt-3 disabled:cursor-default bg-white dark:bg-zinc-800 dark:text-white dark:border-zinc-700 dark:disabled:opacity-50 dark:hover:bg-zinc-700 dark:disabled:hover:bg-zinc-800 disabled:bg-gray-200 py-2 px-6 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 disabled:text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Withdraw Fees
          </button>
        </div>
      </div>
    </>
  );
}

import axios, { AxiosInstance } from 'axios';
import { ethers } from 'ethers';

export interface PaginatedResponse<T> {
  message: string;
  page: number;
  pageSize: number;
  order: string;
  direction: string;
  search: string;
  totalPages: number;
  total: number;
  data: T[];
}

export type Network = 'POLYGON' | 'CRONOS';

export interface CollectionStats {
  sales24h: number;
  volume24h: number;
  volume1d: number;
  volume7d: number;
  volume30d: number;
  volume: number;
  sales: number;
  sales1d: number;
  sales7d: number;
  sales30d: number;
  floor: number;
  floorUsd: number;
  floorChange24h: number;
  avgPrice: number;
}
export interface Collection {
  address: string;
  collectionStats: CollectionStats;
  pFloor: number;
  pFloorUsd: number;
  pFloorChange24h: number;
  pVolume: number;
  pVolume1d: number;
  pVolume7d: number;
  pVolume30d: number;
  pSales: number;
  pSales1d: number;
  pSales7d: number;
  pSales30d: number;
  pAvgPrice: number;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  image: string;
  desc: string;
  links: string;
  himage: string;
  supply: number;
  owners: number;
  id: number;
  network: Network;
  tokens: any;
}

export interface TokenStat {
  tokenId: string;
  source: string;
  listed: Boolean;
  listPrice: number;
  lastPrice: number;
  originalCurrency: string;
  updatedAt: string;
  conversionRate: string;
  collectionAddress: string;
}

export interface Token {
  createdAt: Date;
  updatedAt: Date;
  image: string;
  id: number;
  name: string;
  collectionAddress: string;
  pListPrice: number;
  pLastPrice: number;
  TokenStats: TokenStat[];
  collection: Collection;
  ERC1155Balance: {
    balance: number;
    tokenId: number;
  }[];
  rank: number;
  tokenId: string;
  owner: string;
  accountId: bigint;
  traits: { type: string; value: string; rarity: number }[];
}

export interface Alert {
  accountId: bigint;
  collection: Collection;
  collectionId: bigint;
  createdAt: Date;
  floor: number;
  id: bigint;
  type: string;
  updatedAt: Date;
  value: number;
  fired: boolean;
  fireDate: Date;
  token: Token;
}

class ApiClient {
  // admin
  async admin_withdrawWallet(
    paymentWalletId: string,
    wallet: string,
    token: string,
    chain: string,
  ) {
    return (
      await this.authPost(`admin/paymentwallet/${paymentWalletId}/withdraw`, {
        wallet,
        token,
        chain,
      })
    ).data;
  }
  async admin_getPresaleCSV() {
    return (await this.authGet(`admin/presale/csv`)).data;
  }
  async admin_getVaultsCSV() {
    return (await this.authGet('admin/vaults/report')).data;
  }
  async admin_getPaymentWallets() {
    return (await this.authGet(`admin/paymentwallets`)).data;
  }
  async admin_newProjectWhitelist(
    name: string,
    address: string,
    chain: string,
  ) {
    return (
      await this.authPost('admin/projectwhitelists', {
        name,
        address,
        chain,
      })
    ).data;
  }
  async admin_deleteProjectWhitelist(id: number) {
    return (await this.authDelete(`admin/projectwhitelist/${id}`)).data;
  }
  async admin_getProjectWhitelists() {
    return (await this.authGet(`admin/projectwhitelists`)).data;
  }
  async admin_getSubscriptions() {
    return (await this.authGet(`admin/subscriptions`)).data;
  }
  async admin_uploadXpTroops(file: any) {
    const formData = new FormData();
    formData.append('file', file);

    console.log(file);
    return (await this.authPost(`admin/xp/troops`, formData)).data;
  }

  async admin_getUserStats() {
    return (await this.authGet(`admin/userStats`)).data;
  }
  async admin_searchUser(address: string) {
    return (await this.authGet(`admin/user/${address}`)).data;
  }
  async admin_getPromotions() {
    return (await this.authGet(`admin/promotions`)).data;
  }
  async admin_getPresaleInfo() {
    return (await this.authGet(`admin/presaleinfo`)).data;
  }
  async admin_addTime(address: string, amount: number) {
    return (await this.authPost(`admin/givetime`, { address, amount })).data;
  }
  async admin_getTime(address: string) {
    return (await this.authGet(`admin/time`, { address })).data;
  }
  async getRewards() {
    return (await this.authGet(`accounts/rewards`)).data;
  }
  async getRewardsEmit() {
    return (await this.authGet(`accounts/emitrewards`)).data;
  }
  async admin_deletePromotion(promotionId: number) {
    return (await this.authDelete(`promotions/${promotionId}`)).data;
  }
  async admin_updatePromotion(
    promotionId: number,
    name: string,
    image: string,
    isBanner: boolean,
    pages: string[],
    link?: string,
  ) {
    return (
      await this.authPost(`promotions/${promotionId}`, {
        name,
        image,
        isBanner,
        pages,
        link,
      })
    ).data;
  }
  async admin_createPromotion(
    name: string,
    image: string,
    isBanner: boolean,
    pages: string[],
    link?: string,
  ) {
    return (
      await this.authPost(`promotions`, {
        name,
        image,
        isBanner,
        pages,
        link,
      })
    ).data;
  }

  async admin_editPaymentWallet(
    id: string,
    tag: string,
    isEVM: boolean,
    active: boolean,
    acceptedTokens: any[],
  ) {
    return (
      await this.authPost(`admin/paymentwallet/${id}`, {
        tag,
        isEVM,
        active,
        acceptedTokens,
      })
    ).data;
  }

  async admin_createPaymentWallet(
    tag: string,
    isEVM: boolean,
    active: boolean,
    acceptedTokens: any[],
  ) {
    return (
      await this.authPost(`admin/paymentwallet`, {
        tag,
        isEVM,
        active,
        acceptedTokens,
      })
    ).data;
  }
  async admin_getContests() {
    return (await this.authGet(`admin/tokentrack/contests`)).data;
  }
  async admin_validateTokenTrack(
    tokenAddress: string,
    network: 'CRONOS' | 'POLYGON',
  ) {
    return (
      await this.authPost(`admin/tokentrack/validate`, {
        tokenAddress,
        network,
      })
    ).data;
  }

  async admin_newTokenTrack(
    tokenAddress: string,
    network: 'CRONOS' | 'POLYGON',
  ) {
    return (
      await this.authPost(`admin/tokentrack`, {
        tokenAddress,
        network,
      })
    ).data;
  }

  async admin_newTokenContest(
    endsAt: string,
    tokenAddress: string,
    startsAt: string,
    prizes: string[],
  ) {
    return (
      await this.authPost(`admin/tokentrack/new-contest`, {
        endsAt,
        tokenAddress,
        startsAt,
        prizes,
      })
    ).data;
  }

  async getContest(id: string) {
    return (await this.authGet(`tokentracks/contest/${id}`)).data;
  }

  async admin_distributePrize(contestId: string) {
    return (
      await this.authPost(`admin/tokentrack/distribute-content-prize`, {
        contestId,
      })
    ).data;
  }

  async admin_editTokenTrack(
    id: number,
    tokenName: string,
    tokenSymbol: string,
    tokenLogo: string,
    links: undefined | any[],
    remove: boolean = false,
  ) {
    return (
      await this.authPost(`admin/tokentrack/edit`, {
        id,
        tokenName,
        tokenSymbol,
        tokenLogo,
        links,
        remove,
      })
    ).data;
  }

  async admin_editContest(
    id: string,
    endsAt: string,
    prizes: string[],
    remove: boolean = false,
  ) {
    return (
      await this.authPost(`admin/tokentrack/contest/edit`, {
        id,
        endsAt,
        prizes,
        remove,
      })
    ).data;
  }

  async admin_validatePricing(
    chain: string,
    tokenName: string,
    type: string,
    address?: string,
  ) {
    return (
      await this.authGet(`admin/validatepricing`, {
        chain,
        tokenName,
        address,
        type,
      })
    ).data;
  }

  // user
  getAccountChart(address: string) {
    return this.authGet(`accounts/chartData`);
  }
  getStats(address: string) {
    return this.authGet(`accounts/stats`);
  }
  async refreshMyTokens() {
    return (await this.authGet('accounts/tokens/refresh')).data;
  }

  async getMyTokens(
    page: number,
    pageSize: number,
    order?: string,
    direction?: string,
    search?: string,
    listed?: string,
    address?: string,
    source?: string,
  ): Promise<PaginatedResponse<Token>> {
    return (
      await this.authGet(`accounts/tokens`, {
        page,
        pageSize,
        order,
        direction,
        search,
        listed,
        address,
        source,
      })
    ).data;
  }
  async getMyTimewarsResources(): Promise<any> {
    return (await this.authGet(`timewars/me/resources`)).data?.resources;
  }
  async getCollectionTokenImages(
    address: string,
    tokenIds: string[],
  ): Promise<any> {
    return (
      await this.client.get(
        `collections/${address}/tokens/images?tokenIds=${JSON.stringify(
          tokenIds,
        )}`,
      )
    ).data?.tokens;
  }

  async getCollectionTokenImagesEmit(
    address: string,
    tokenIds: { tokenId: string; ca: string }[],
  ): Promise<any> {
    return (
      await this.client.get(
        `collections/${address}/tokens/images?tokenIds=${JSON.stringify(
          tokenIds.map(t => t.tokenId),
        )}`,
      )
    ).data?.tokens;
  }

  async getTokenTracks(
    page: number,
    pageSize: number,
    search: string = '',
  ): Promise<any> {
    return (
      await this.client.get(`tokentracks`, {
        params: {
          page,
          pageSize,
          search,
        },
      })
    ).data;
  }

  async getContests(): Promise<any> {
    return (await this.authGet(`tokentracks/contests`)).data;
  }

  async getTokenPortfolio(
    page: number,
    pageSize: number,
    search: string = '',
  ): Promise<any> {
    return (
      await this.authGet(`tokentracks/portfolio`, {
        page,
        pageSize,
        search,
      })
    ).data.portfolio;
  }

  async getCollectionTokens(
    address: string,
    page: number,
    pageSize: number,
    order: string,
    direction: string,
    search: string,
    listed: string,
    source: string = 'all',
  ): Promise<PaginatedResponse<Token>> {
    return (
      await this.authGet(`collections/${address}/tokens`, {
        page,
        pageSize,
        order,
        direction,
        search,
        listed,
        source,
      })
    ).data;
  }

  client: AxiosInstance;
  accessToken: string = '';

  constructor(
    private apiUrl = `https://${process.env.REACT_APP_DOMAIN}/api/v1/`,
  ) {
    if (window.location.href.includes('localhost')) {
      this.apiUrl = 'http://localhost:8080/v1/';
    } else if (window.location.href.includes('test')) {
      this.apiUrl = `https://${process.env.REACT_APP_DOMAIN}/api/v1/`;
    } else {
      this.apiUrl = `https://${process.env.REACT_APP_DOMAIN}/api/v1/`;
    }

    this.client = axios.create({
      baseURL: this.apiUrl,
      withCredentials: true,
    });

    this.client.defaults.headers.common.Accept = 'application/json';
    this.client.defaults.headers.common['Content-Type'] = 'application/json';
  }

  // Time wars
  async timewars_performActions(
    shipId: number,
    actions: {
      attach: { componentId: number }[];
      detach: { componentId: number }[];
    },
  ) {
    return (
      await this.authPost(`timewars/ships/${shipId}/action`, {
        actions,
      })
    ).data;
  }

  async timewars_instantBuild(
    shipId: number,
    actions: {
      attach: { componentId: number }[];
      detach: { componentId: number }[];
    },
  ) {
    return (
      await this.authPost(`timewars/ships/${shipId}/instant_build`, {
        actions,
      })
    ).data;
  }

  async timewars_cancelBuild(shipId: number) {
    return (await this.authPost(`timewars/ships/${shipId}/cancel_build`)).data;
  }

  async timewars_getCDNComponent(tokenId: number) {
    return (await this.authGet(`timewars/cdn/component/${tokenId}`)).data
      ?.component;
  }

  async timewars_getCDNComponentBulk(tokenIds: number[]) {
    return (
      await this.authPost(`timewars/cdn/components`, {
        tokenIds,
      })
    ).data?.components;
  }

  async timewars_getCDNShipBulk(tokenIds: number[]) {
    return (
      await this.authPost(`timewars/cdn/ships`, {
        tokenIds,
      })
    ).data?.ships;
  }

  async timewars_estimateActionCost(shipId: number, actions: any) {
    return (
      await this.authPost(`timewars/ships/${shipId}/estimate`, {
        actions,
      })
    ).data?.actions;
  }

  async timewars_getCDNShip(tokenId: number) {
    return (await this.authGet(`timewars/cdn/ship/${tokenId}`)).data?.ship;
  }

  async siwe(
    message: string,
    signature: string,
    cdcToken: string | null,
  ): Promise<any> {
    const response: any = await this.client
      .post('accounts/siwe', {
        message,
        signature,
        cdcToken: cdcToken || undefined,
      })
      .catch((e: any) => {
        console.log(e.message);
        return {
          data: {
            error: e.response?.data?.message || e.message,
          },
        };
      });

    this.accessToken = response.data.accessToken;
    return response.data;
  }

  async getNonce(): Promise<{ nonce: string; cdcToken: string | null }> {
    const response: any = await this.client.get('accounts/nonce').catch(e => e);
    return response.data;
  }

  async getTokenUsdPrice(token: string): Promise<number> {
    const response = await this.authGet(`pricing/${token}`);

    return Number(response.data?.usd) || 0;
  }

  async tokenPurchaseComplete(
    address: string,
    tokenId: string,
    purchaseId: string,
    transactionHash: string,
  ) {
    return this.authPost(
      `collections/${address}/token/${tokenId}/purchaseComplete`,
      { purchaseId, transactionHash },
    );
  }

  async getToken(
    collectionAddress: string,
    tokenId: string,
  ): Promise<{ token: Token; collection: Collection }> {
    const response = await this.client.get(
      `collections/${collectionAddress}/token/${tokenId}`,
    );
    return response.data;
  }

  async getPresaleInfo(wallet?: string): Promise<any> {
    const response = await this.client.get(`presale`, {
      params: { wallet },
    });
    return response.data;
  }

  async presalePurchase(
    address: string,
    chain: string,
    tokenName: string,
    metadata: any,
  ): Promise<any> {
    const response = await this.client.post(`presale`, {
      address,
      chain,
      tokenName,
      metadata,
    });
    return response.data;
  }

  async getTokenFromMoonflow(
    collectionAddress: string,
    tokenId: string,
    network: number,
  ): Promise<any> {
    const response = await axios.get(
      `https://api.moonflow.club/collection/${collectionAddress}/nft/${tokenId}?partner=tt&network=${network}`,
    );
    return response.data;
  }

  async purchaseToken(
    collectionAddress: string,
    tokenId: string,
    source: string,
    wallet: string,
  ): Promise<{
    purchaseId: string;
    data: any;
    network: string;
    protocol: string;
    contract: string;
  }> {
    const response = await this.authPost(
      `collections/${collectionAddress}/token/${tokenId}/purchase`,
      {
        source,
        wallet,
      },
    );
    return response.data;
  }

  async requestPayment(
    subscriptionId: number,
    chain: string,
    token: string,
    metadata?: any,
  ): Promise<{
    address: string;
    amountInWei: string;
    pendingPayment: string;
    expires: string;
    type: string;
    contractAddress?: string;
  }> {
    const response = await this.authPost(`payment/request`, {
      subscriptionId,
      chain,
      token,
      metadata,
    });
    return response.data;
  }

  async deletePayment(paymentId: string): Promise<void> {
    await this.authDelete(`/payment/pending/${paymentId}`);
  }

  async deleteCollection(collectionAddress: string): Promise<void> {
    await this.authDelete(`/collections`, { collectionAddress });
  }

  async checkPayment(
    paymentId: string,
    accountless: boolean = false,
  ): Promise<{ status: string; type: string }> {
    let response: any;
    if (accountless) {
      response = await this.client.get(
        `/payment/pending/accountless/${paymentId}`,
        {
          params: {
            accountless,
          },
        },
      );
    } else {
      response = await this.authGet(`/payment/pending/${paymentId}`, {
        accountless,
      });
    }
    return response.data;
  }

  async getTokens(
    collectionId: number,
    page = 1,
    pageSize = 500,
    order = 'tokenId',
    direction = 'desc',
    search = '',
    listed = 'all',
  ) {
    const response = await this.authGet(`collections/${collectionId}/tokens`, {
      params: {
        page,
        pageSize,
        order,
        direction,
        search,
        listed,
      },
    });
    if (response.data.message !== 'success') {
      throw new Error(response.data);
    }
    return response.data;
  }
  async addCollection(
    collectionId: string,
    source: string,
  ): Promise<{ success: boolean; error?: string }> {
    const response = await this.authPost(`collections`, {
      collectionId,
      source,
    }).catch((e: Error) => e);

    if (response instanceof Error) {
      return { success: false, error: 'An unexpected error occurred.' };
    }

    return {
      success: response.data.message === 'success',
      error: response.data.error,
    };
  }
  async refreshCollectionMetadata(
    collectionId: string,
  ): Promise<{ success: boolean; error?: string }> {
    const response = await this.authGet(`collections/${collectionId}/refresh`, {
      collectionId,
    }).catch((e: Error) => e);

    if (response instanceof Error) {
      return { success: false, error: 'An unexpected error occurred.' };
    }

    return {
      success: response.data.message === 'success',
      error: response.data.error,
    };
  }
  async getCollections(
    page = 1,
    pageSize = 500,
    order = 'pVolume1d',
    direction = 'desc',
    search = '',
    network = 'CRONOS',
  ): Promise<{
    data: Collection[];
    direction: String;
    message: string;
    order: string;
    page: number;
    pageSize: number;
    total: number;
    totalPages: number;
  }> {
    const response = await this.authGet(`collections`, {
      page,
      pageSize,
      order,
      direction,
      search,
      network,
    });

    if (response.data.message !== 'success') {
      throw new Error(response.data);
    }
    return response.data;
  }
  async getWatchlist(
    page = 1,
    pageSize = 500,
    order = 'pVolume1d',
    direction = 'desc',
    search = '',
    source = 'CRONOS',
  ): Promise<{
    data: Collection[];
    direction: String;
    message: string;
    order: string;
    page: number;
    pageSize: number;
    total: number;
    totalPages: number;
  }> {
    const response = await this.authGet(`collections/watchlist/all`, {
      page,
      pageSize,
      order,
      direction,
      search,
      source,
    });
    if (response.data.message !== 'success') {
      throw new Error(response.data);
    }
    return response.data;
  }

  async addWatchlist(address: string) {
    return this.authGet(`collections/watchlist/add/${address}`);
  }

  async addAlert(address: string, value: number, floor: number) {
    return this.authGet(`alerts/add/floor/${address}/${value}/${floor}`);
  }

  async addNftAlert(
    id: number,
    collectionAddress: string,
    value: number,
    floor: number,
  ) {
    return this.authGet(
      `alerts/addnft/${id}/${value}/${floor}/${collectionAddress}`,
    );
  }

  async getWallet(page = 1) {
    return this.authGet(`accounts/me`, {
      page,
      pageSize: 9,
    });
  }

  async getIsPremium() {
    return this.authGet(`accounts/isPremium`);
  }

  async getFiredAlerts() {
    return (await this.authGet(`alerts/fired/`)).data.data;
    // return (await this.authGet(`alerts/fired/`)).data.data
  }
  async setProfileImage(id: string) {
    return this.authGet(`accounts/image/${id}`);
  }

  async getPromotions() {
    return (await this.client.get(`promotions`)).data?.content;
  }

  async getClaimTimeRewardsSignature() {
    return (await this.authPost(`accounts/rewards/time/claim`)).data;
  }

  async getClaimEmitRewardsSignature() {
    return (await this.authPost(`accounts/rewards/emit/claim`)).data;
  }

  async getAPRVaults(vault: 'TIME' | 'EMIT' = 'TIME') {
    return (await this.authGet(`accounts/aprvaults?vault=${vault}`)).data
      .vaults;
    // return (await this.authGet(`alerts/fired/`)).data.data
  }

  async createAPRVaultRequest(amount: number, days: number) {
    return (
      await this.authPost(`accounts/aprvaults`, {
        amount: Number(amount),
        days: Number(days),
      })
    ).data;
  }

  async createAPRVaultRequestEmit(amount: number, days: number) {
    return (
      await this.authPost(`accounts/aprvaultsemit`, {
        amount: Number(amount),
        days: Number(days),
      })
    ).data;
  }

  async createWithdrawAPRVaultRequest(index: number) {
    return (
      await this.authPost(`accounts/aprvaults/withdraw`, {
        index,
      })
    ).data;
  }

  async createWithdrawAPRVaultRequestEmit(index: number) {
    return (
      await this.authPost(`accounts/aprvaultsemit/withdraw`, {
        index,
      })
    ).data;
  }

  async createDepositAPRVaultRequest(index: number, amount: number) {
    return (
      await this.authPost(`accounts/aprvaults/deposit`, {
        index,
        amount,
      })
    ).data;
  }

  async createDepositAPRVaultRequestEmit(index: number, amount: number) {
    return (
      await this.authPost(`accounts/aprvaultsemit/deposit`, {
        index,
        amount,
      })
    ).data;
  }

  async createCompoundAPRVaultRequest(index: number) {
    return (
      await this.authPost(`accounts/aprvaults/compound`, {
        index,
      })
    ).data;
  }

  async createCompoundAPRVaultRequestEmit(index: number) {
    return (
      await this.authPost(`accounts/aprvaultsemit/compound`, {
        index,
      })
    ).data;
  }

  async addEmail(email: string) {
    return this.authGet(`accounts/email/${email}`);
  }
  async setAvatar(collectionAddress: string, tokenId: string) {
    return this.authPost(`accounts/avatar`, {
      collectionAddress,
      tokenId,
    });
  }
  async addUsername(username: string) {
    return this.authGet(`accounts/username/${username}`);
  }

  async getAlerts(
    page = 1,
    pageSize = 500,
    order = 'pVolume1d',
    direction = 'desc',
    search = '',
    source = 'CRONOS',
  ): Promise<{
    data: Alert[];
    direction: String;
    message: string;
    order: string;
    page: number;
    pageSize: number;
    total: number;
    totalPages: number;
  }> {
    const response = await this.authGet(`alerts`, {
      page,
      pageSize,
      order,
      direction,
      search,
      source,
    });
    if (response.data.message !== 'success') {
      throw new Error(response.data);
    }
    return response.data;
  }
  async deleteAlert(id: number) {
    return this.authGet(`alerts/remove/${id}`);
  }
  async getSubscriptions() {
    return await this.authGet(`subscriptions`);
  }
  async getCollection(address: string) {
    return await this.authGet(`collections/${address}`);
  }
  async removeWatchlist(address: string) {
    return this.authGet(`collections/watchlist/remove/${address}`);
  }

  async removeWallet(address: string) {
    return this.authGet(`accounts/wallet/remove/${address}`);
  }

  async addWallet(address: string) {
    return this.authGet(`accounts/wallet/add/${address}`);
  }

  async getMe() {
    const response = await this.authGet('/accounts/me');
    return response.data;
  }

  async getMySubscriptions() {
    const response = await this.authGet('/accounts/subscriptions');
    return response.data;
  }

  authGet(url: string, params?: any) {
    if (this.accessToken === '') {
      const auth = localStorage.getItem('auth');
      const result = auth ? JSON.parse(auth) : {};
      if (result.data && result.data.token) {
        this.accessToken = result.data.token;
      } else {
        throw new Error('No access token');
      }
    }
    return this.client.get(url, {
      params,
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
      },
    });
  }

  authDelete(url: string, params?: any) {
    if (this.accessToken === '') {
      const auth = localStorage.getItem('auth');
      const result = auth ? JSON.parse(auth) : {};
      if (result.data && result.data.token) {
        this.accessToken = result.data.token;
      } else {
        throw new Error('No access token');
      }
    }
    return this.client.delete(url, {
      params,
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
      },
    });
  }

  authPost(url: string, body?: any) {
    if (this.accessToken === '') {
      const auth = localStorage.getItem('auth');
      const result = auth ? JSON.parse(auth) : {};
      if (result.data && result.data.token) {
        this.accessToken = result.data.token;
      } else {
        throw new Error('No access token');
      }
    }
    return this.client.post(url, body, {
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
      },
    });
  }
}

export default new ApiClient(process.env.API);

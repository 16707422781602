import React from 'react';
import { useAuth } from '../../hooks/useAuth';
import useWindowSize from '../../hooks/useWindowSize';

function ConnectButton({ setOpen }: { setOpen: (open: boolean) => void }) {
  let auth = useAuth();
  const { width } = useWindowSize();
  let text = 'Connect';
  if (auth.isConnected) {
    if (auth.isAuthenticated) {
      text = 'Disconnect';
    } else {
      text = 'Connect';
    }
  }

  return (
    <>
      <button
        onClick={() => {
          if (text === 'Sign out') {
            auth.disconnected();
          } else {
            setOpen(true);
          }
        }}
        className="wallet-button"
      >
        {text} {width >= 600 && width !== 0 ? 'Wallet' : ''}
      </button>
    </>
  );
}

export default ConnectButton;

/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import useInterval from '@use-it/interval';
import { Tooltip } from 'flowbite-react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import parachuteLight from '@iconify/icons-ph/parachute-light';
import packageIcon from '@iconify/icons-ph/package';
import chartLine from '@iconify/icons-ph/chart-line';
import moneyIcon from '@iconify/icons-ph/money';
import {
  BellIcon,
  ChartBarIcon,
  QuestionMarkCircleIcon,
  StarIcon,
  SunIcon,
  MoonIcon,
  CurrencyDollarIcon,
  TicketIcon,
  Square3Stack3DIcon,
  XMarkIcon,
  Bars3Icon,
  BoltIcon,
  ArrowRightCircleIcon,
  RocketLaunchIcon,
  ArrowTrendingUpIcon,
} from '@heroicons/react/24/outline';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  StarIcon as StarIconSolid,
  LockClosedIcon,
} from '@heroicons/react/24/solid';
import classNames from '../utils/classNames';
import MyNfts from './app/pages/MyNfts';
import { useAuth } from '../hooks/useAuth';
import ConnectButton from './web3/ConnectButton';
import ConnectModal from './web3/ConnectModal';
import NotiSidebar from './NotiSidebar';
import Watchlist from './app/pages/Watchlist';
import TopCollections from './app/pages/TopCollections';
import SmartAlerts from './app/pages/SmartAlerts';
import Settings from './app/pages/Settings';
import CollectionInventory from './app/pages/CollectionInventory';
import TokenPage from './app/pages/TokenPage';
import CreateAlert from './app/pages/CreateAlert';
import CreateTokenAlert from './app/pages/CreateTokenAlert';
import ApiClient from '../utils/ApiClient';
import Noti from './app/components/Noti';
import SmartCharts from './app/pages/SmartCharts';
import ChartPage from './app/pages/ChartPage';
import { useNetwork } from '../hooks/useNetwork';
import { CronosSvg, PolygonSvg, userImage } from '../utils/parser';
import { SoftStakingContractKeys, softStakingContracts } from '../web3/config';
import SoftStaking from './app/pages/SoftStaking';
import { getBalance, getCRC20Balance } from '../web3/utils';
import BalanceCard from './app/components/BalanceCard';
import PoolStaking from './app/pages/PoolStaking';
import Payment from './app/pages/Payment';
import Search from './app/components/Search';
import TTTLPStaking from './app/pages/TTTLPStaking';
import PaymentWallets from './app/pages/admin/paymentwallets';
import Subscriptions from './app/pages/admin/subscriptions';
import Drops from './app/pages/Drops';
import { Icon } from '@iconify/react';
import Raffles from './app/pages/Raffles';
import AdminSoftStaking from './app/pages/admin/softstaking';
import AdminPromotions from './app/pages/admin/promotions';
import DialogScreen from './app/components/DialogScreen';
import Time from './app/pages/Time';
import Users from './app/pages/admin/users';
import { Vault } from './app/components/vault/Vault';
import TimeAdmin from './app/pages/admin/time';
import Base from './app/pages/Base';
import { StakeTime } from './app/components/vault/StakeTime';
import { ToastProvider } from '../contexts/ToastContext'; // Adjust the import path as necessary
import TokenTrackAdmin from './app/pages/admin/tokentrack';
import TokenTracker from './app/pages/TokenTracker';
import TokenContestsAdmin from './app/pages/admin/tokencontests';
import Contests from './app/pages/Contests';
import ContestLeaderboard from './app/pages/ContestLeaderboard';
import TimeWars from './app/pages/TimeWars';
import { StakeEmit } from './app/components/vault/StakeEmit';
import { VaultEmit } from './app/components/vault/VaultEmit';

const NavigationIcon = ({ icon, className }: any) => {
  if (!icon) return <></>;

  if (icon.body) {
    // It's an Iconify icon
    return <Icon icon={icon} className={className} />;
  } else {
    // It's a Heroicon or similar
    const IconComponent = icon;
    return <IconComponent className={className} />;
  }
};

const bnavigation = [
  {
    name: 'Support',
    href: 'https://discord.gg/timemachinenfts',
    icon: QuestionMarkCircleIcon,
    current: false,
  },
];

type NavigationItem = {
  name: string;
  href: string;
  icon: any;
  current: boolean;
  premiumMessage?: string;
  children?: NavigationItem[];
  navigateToSignIn?: boolean;
  highlighted?: boolean;
};

type DividerItem = {
  divider: true;
};

type NavigationType = NavigationItem | DividerItem;

export default function App() {
  let navigate = useNavigate();
  const { updateNetwork, network, showWalletConnect, setShowWalletConnect } =
    useNetwork();
  const {
    user,
    isAuthenticated,
    disconnected,
    hasFullAccess,
    isAdmin,
    forceUserUpdate,
  } = useAuth();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [notiSidebarOpen, setNotiSidebarOpen] = useState(false);
  const [polling, setPolling] = useState(false);
  const [activeNoti, setActiveNoti] = useState(false);
  const [openVaultSelector, setOpenVaultSelector] = useState(false);
  const [openVault, setOpenVault] = useState(false);
  const [openVaultEmit, setOpenVaultEmit] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [oldAlerts, setOldAlerts] = useState([]);
  const [noti, setNoti] = useState({});
  const [notiShow, setNotiShow] = useState(false);
  const [showVaults, setShowVaults] = useState(false);
  const [themeChange, setThemeChange] = useState(
    localStorage.getItem('theme') === 'dark',
  );
  const [dropdownOpen, setDropdownOpen] = useState<Record<string, boolean>>({
    'Soft Staking': window.location.pathname.includes('/soft-staking/'),
  });

  useEffect(() => {
    if (!isAuthenticated) {
      if (
        window.location.pathname.includes('/app/contest') ||
        window.location.pathname.includes('/app/tokens')
      ) {
        setShowWalletConnect(true);
      }
    }
  }, [isAuthenticated]);

  // Step 3: Toggle Function
  const toggleDropdown = (itemName: string) => {
    setDropdownOpen(prev => ({
      ...prev,
      [itemName]: !prev[itemName], // toggle the boolean value for the specific item
    }));
  };

  const stakeTimeEnabled =
    process.env.REACT_APP_ENV === 'production' ||
    process.env.ENV === 'production'
      ? true
      : true;

  const timeWarsEnabled =
    isAdmin || process.env.REACT_APP_TIME_WARS_ENABLED === 'true'
      ? true
      : false;

  const navigation: NavigationType[] = [
    ...(stakeTimeEnabled && isAuthenticated
      ? [
          {
            name: 'Stake $TIME',
            href: '#StakeTime',
            icon: RocketLaunchIcon,
            current: false,
            highlighted: true,
          },
          {
            name: 'Stake $EMIT',
            href: '#StakeEmit',
            icon: RocketLaunchIcon,
            current: false,
            highlighted: true,
          },
        ]
      : []),
    // {
    //   name: '$Time Presale',
    //   href: '/app/time',
    //   icon: ArrowRightCircleIcon,
    //   current: false,
    //   highlighted: true,
    // },
    ...(!hasFullAccess
      ? [
          {
            name: 'Join Time Sniper',
            href: '/app/services',
            icon: packageIcon,
            current: false,
            navigateToSignIn: true,
          },
        ]
      : []),

    {
      name: 'NFT Raffles',
      href: '/app/raffles',
      icon: TicketIcon,
      current: false,
    },
    {
      name: 'NFT Launchpad',
      href: '/app/drops',
      icon: parachuteLight,
      current: false,
    },
    ...(isAuthenticated
      ? [
          {
            name: 'Token Tracker',
            href: '/app/tokens',
            icon: ArrowTrendingUpIcon,
            current: false,
          },
          {
            name: 'Token Contests',
            href: '/app/contests',
            icon: StarIcon,
            current: false,
          },
        ]
      : []),
    { divider: true },
    {
      name: 'Time Swap',
      href: 'https://swap.time-sniper.com',
      icon: chartLine,
      current: false,
    },
    {
      divider: true,
    },
    ...(isAuthenticated && hasFullAccess ? [] : []),
    // {
    //   name: "Lost Toy's VIP Soft Staking",
    //   href: '/app/my-nfts',
    //   icon: BoltIcon,
    //   current: false,
    // },
  ];

  const userNavigation = isAuthenticated
    ? [
        { name: 'Settings', href: '/app/settings' },
        (() => {
          if (isAdmin) {
            return {
              name: 'Add Collection',
              href: '#',
              onClick: async () => {
                // const network = prompt(
                //   'What network is the collection on?',
                //   'POLYGON',
                // )?.toLowerCase();
                // console.log(network);
                // if (!network || !['polygon', 'cronos'].includes(network)) {
                //   alert('Invalid network.');
                //   return;
                // }

                const collectionId = prompt(
                  '(POLYGON) What is the contract address?',
                );
                if (!collectionId) return;

                const response = await ApiClient.addCollection(
                  collectionId,
                  'polygon',
                );
                if (response.success) {
                  alert('Success.');
                } else {
                  alert(response.error);
                }
              },
            };
          }

          return undefined;
        })(),
        {
          name: 'Sign out',
          href: '/',
          onClick: () => {
            disconnected();
            navigate('/');
          },
        },
      ].filter(n => n !== undefined)
    : [];

  // const startPolling = () => {
  //   if (polling) return;
  //   setPolling(true);
  //   getAlerts();
  // };
  // const stopPolling = () => {
  //   setPolling(false);
  //   clearTimeout()
  // };
  useEffect(() => {
    if (notiShow) {
      setActiveNoti(true);
    }
  }, [notiShow]);

  useEffect(() => {
    if (hasFullAccess) {
      forceUserUpdate();
    }
  }, [hasFullAccess]);

  function getDifference(array1: any[], array2: any[]) {
    return array1.filter(object1 => {
      return !array2.some(object2 => {
        return object1.id === object2.id;
      });
    });
  }

  // useEffect(() => {
  //   startPolling();
  //   return () => {
  //     stopPolling()
  //   }
  // }, []);
  useInterval(() => {
    if (!isAuthenticated || !hasFullAccess) return;

    ApiClient.getFiredAlerts().then(alertss => {
      setAlerts(alertss);
    });
  }, 1000 * 25);

  useEffect(() => {
    if (!alerts.length) return;
    const diff = getDifference(alerts, oldAlerts);
    if (diff.length && oldAlerts.length) {
      setNoti(diff[0]);
      setNotiShow(true);
    }
    setOldAlerts(alerts);
  }, [alerts]);

  const [openConnectModal, setOpenConnectModal] = useState(false);

  useEffect(() => {
    if (showWalletConnect) {
      setOpenConnectModal(true);
    }
  }, [showWalletConnect]);
  useEffect(() => {
    const theme = themeChange ? 'dark' : 'light';
    if (theme === 'dark') {
      localStorage.setItem('theme', 'dark');
      document.getElementById('html')?.classList.add('dark');
      //@ts-ignore
      window.changeBodyColor('#121212');
    } else {
      localStorage.setItem('theme', 'light');
      document.getElementById('html')?.classList.remove('dark');
      //@ts-ignore
      window.changeBodyColor('#fff');
    }
  }, [themeChange]);

  const [showTimeStake, setShowTimeStake] = useState(false);
  const [showEmitStake, setShowEmitStake] = useState(false);
  return (
    <>
      <ToastProvider>
        <ConnectModal
          open={openConnectModal}
          setOpen={setOpenConnectModal}
        ></ConnectModal>
        {stakeTimeEnabled && (
          <DialogScreen open={showTimeStake} setOpen={setShowTimeStake}>
            <StakeTime />
          </DialogScreen>
        )}
        {showEmitStake && (
          <DialogScreen open={showEmitStake} setOpen={setShowEmitStake}>
            <StakeEmit />
          </DialogScreen>
        )}
        <div className="dark:bg-zinc-900">
          <Noti noti={noti} setShow={setNotiShow} show={notiShow}></Noti>
          <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog
              as="div"
              className="fixed inset-0 flex z-40 md:hidden "
              onClose={setSidebarOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              </Transition.Child>
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white dark:bg-zinc-900">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex-shrink-0 flex items-center px-4">
                    <a href="/app/drops">
                      {themeChange ? (
                        <img
                          className="h-8 w-auto"
                          src="/time-transparent.png"
                          alt="TimeSniper"
                        />
                      ) : (
                        <img
                          className="h-8 w-auto"
                          src="/time.png"
                          alt="TimeSniper"
                        />
                      )}
                    </a>
                  </div>
                  <div className="mt-5 flex-1 h-0 overflow-y-auto">
                    <nav className="px-2 space-y-1">
                      {navigation.map((item: any, i) => {
                        if (!item.divider && !item.name) return undefined;

                        if (item.divider === true) {
                          return <div key={'divider-' + i}></div>;
                        }

                        if (item.children && item.children.length > 0) {
                          return (
                            <div key={item.name}>
                              <a
                                href={item.href}
                                onClick={() => toggleDropdown(item.name)}
                                className={classNames(
                                  'flex items-center px-2 py-2 text-sm font-medium rounded-md ',
                                  item.current
                                    ? 'bg-gray-100 text-gray-900 dark:bg-zinc-900'
                                    : 'text-gray-600 hover:bg-gray-50 dark:hover:bg-zinc-800 dark:text-gray-300 dark:bg-zinc-900',
                                )}
                              >
                                <NavigationIcon
                                  icon={item.icon}
                                  className="mr-3 flex-shrink-0 h-6 w-6 text-gray-400 hover:text-gray-500"
                                  aria-hidden="true"
                                />
                                {item.name}
                              </a>
                              <div
                                className={`${
                                  dropdownOpen[item.name] ? 'block' : 'hidden'
                                }`}
                              >
                                <div className="bg-white overflow-hidden rounded-md dark:bg-zinc-900">
                                  {item.children.map(
                                    (child: NavigationItem) => (
                                      <a
                                        key={child.name}
                                        href={child.href}
                                        className={classNames(
                                          item.current
                                            ? 'bg-gray-100 text-gray-900 '
                                            : 'text-gray-600  hover:bg-gray-100 dark:bg-zinc-900 dark:hover:bg-zinc-800 dark:text-white',
                                          'flex items-center px-2 pl-4 py-2 text-sm font-medium rounded-md ',
                                        )}
                                      >
                                        {child.icon ? (
                                          <NavigationIcon
                                            icon={child.icon}
                                            className="mr-3 flex-shrink-0 h-6 w-6 text-gray-400 hover:text-gray-500"
                                            aria-hidden="true"
                                          />
                                        ) : (
                                          <div className="my-4"></div>
                                        )}
                                        {child.name}
                                      </a>
                                    ),
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        }

                        return item.premiumMessage ? (
                          <Tooltip
                            key={item.name}
                            content={item.premiumMessage}
                            className="text-center"
                            trigger="hover"
                          >
                            <a
                              href={item.href}
                              className={classNames(
                                item.current
                                  ? 'bg-gray-200 text-gray-900 dark:bg-zinc-800'
                                  : 'text-gray-600 hover:bg-gray-50 dark:hover:bg-zinc-800 dark:text-white',
                                `group flex items-center px-2 py-2 text-sm font-medium rounded-md ${
                                  item.premiumMessage
                                    ? 'text-gray-400 dark:text-gray-500 hover:bg-white dark:hover:bg-zinc-900 cursor-help'
                                    : ''
                                }`,
                              )}
                            >
                              <NavigationIcon
                                icon={item.icon}
                                className={classNames(
                                  item.current
                                    ? 'text-gray-500'
                                    : 'text-gray-400 group-hover:text-gray-500 dark:group-hover:text-gray-300',
                                  `mr-3 flex-shrink-0 h-6 w-6 ${
                                    item.premiumMessage
                                      ? 'text-gray-300 group-hover:text-gray-300 dark:text-gray-500 dark:group-hover:text-gray-500 dark:hover:text-gray-500'
                                      : ''
                                  }`,
                                )}
                                aria-hidden="true"
                              />
                              {item.name}
                            </a>
                          </Tooltip>
                        ) : (
                          <a
                            key={item.name}
                            href={item.href}
                            onClick={() => {
                              if (item.href === '#StakeTime') {
                                setShowTimeStake(true);
                              }

                              if (item.href === '#StakeEmit') {
                                setShowEmitStake(true);
                              }
                            }}
                            className={classNames(
                              item.current
                                ? 'bg-gray-100 text-gray-900 dark:bg-zinc-800'
                                : 'text-gray-600 hover:bg-gray-50 dark:hover:bg-zinc-800 dark:text-white',
                              `group flex items-center px-2 py-2 text-sm font-medium rounded-md ${
                                item.premiumMessage
                                  ? 'text-gray-400 dark:text-gray-500 hover:bg-white dark:hover:bg-zinc-900 cursor-help'
                                  : ''
                              }`,
                              item.highlighted && item.href === '#StakeTime'
                                ? 'border rounded-sm bg-yellow-100 dark:bg-yellow-200 dark:bg-transparent dark:rounded-lg text-yellow-400/80 dark:text-yellow-400 hover:bg-yellow-200/40 border-yellow-200 '
                                : item.highlighted && item.href === '#StakeEmit'
                                ? 'border rounded-sm bg-purple-100 dark:bg-purple-200 dark:bg-transparent dark:rounded-lg text-purple-200/80 dark:text-purple-400 hover:bg-purple-200/40 border-purple-200 '
                                : item.highlighted
                                ? ' border rounded-sm bg-purple-100 dark:bg-purple-200 dark:bg-transparent dark:rounded-lg text-purple-400 dark:text-purple-400 hover:bg-[#ffe0fb] border-purple-200 '
                                : '',
                            )}
                          >
                            <NavigationIcon
                              icon={item.icon}
                              className={classNames(
                                item.current
                                  ? 'text-gray-500'
                                  : 'text-gray-400 group-hover:text-gray-500 dark:group-hover:text-gray-300',
                                `mr-3 flex-shrink-0 h-6 w-6 ${
                                  item.premiumMessage
                                    ? 'text-gray-300 group-hover:text-gray-300 dark:text-gray-500 dark:group-hover:text-gray-500 dark:hover:text-gray-500'
                                    : ''
                                }`,
                                item.highlighted && item.href === '#StakeTime'
                                  ? 'text-yellow-400 hover:text-yellow-400/80'
                                  : item.highlighted &&
                                    item.href === '#StakeEmit'
                                  ? 'text-purple-400 hover:text-purple-400/80'
                                  : item.highlighted
                                  ? 'text-purple-400 hover:text-purple-400'
                                  : '',
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        );
                      })}

                      {bnavigation.map(item => (
                        <a
                          key={item.name}
                          href={item.href}
                          target={'_blank'}
                          rel={'noopener noreferrer'}
                          className={classNames(
                            item.current
                              ? 'bg-gray-100 text-gray-900 dark:text-white'
                              : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:text-white',
                            'group flex items-center px-2 py-2 text-base font-medium rounded-md',
                          )}
                        >
                          <NavigationIcon
                            icon={item.icon}
                            className={classNames(
                              item.current
                                ? 'text-gray-500'
                                : 'text-gray-400 group-hover:text-gray-500',
                              'mr-4 flex-shrink-0 h-6 w-6',
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      ))}

                      {/* <div className="flex justify-center block md:hidden lg:hidden flex-end pt-10">
                      <a
                        href="https://mm.finance/"
                        className="flex justify-center"
                        target="_blank"
                      >
                        <img
                          src="/MMF_ad.png"
                          className="pb-5 "
                          style={{ width: '90%' }}
                        />
                      </a>
                    </div> */}
                    </nav>
                  </div>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 w-14" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </Dialog>
          </Transition.Root>

          {/* Static sidebar for desktop */}
          <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex flex-col flex-grow border-r border-gray-200 dark:border-zinc-800 pt-5 bg-white dark:bg-zinc-900 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-4">
                <a href="/app/drops">
                  <img
                    className="h-20 w-auto"
                    src="/time.png"
                    alt="TimeSniper"
                  />
                </a>
              </div>
              <div className="mt-5 flex-grow flex flex-col">
                <nav className="flex-1 px-2 pb-4 space-y-1">
                  {navigation.map((item: any, index) => {
                    if (!item.divider && !item.name) return undefined;

                    if (item.divider) {
                      return (
                        <hr
                          key={item.name + index.toString() + 'hr'}
                          className="h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-500 to-transparent opacity-25 dark:opacity-50"
                        />
                      );
                    }

                    return item.children && item.children.length > 0 ? (
                      // Dropdown rendering logic here
                      <div key={item.name}>
                        <a
                          href={item.href}
                          onClick={() => toggleDropdown(item.name)}
                          className={classNames(
                            'flex items-center px-2 py-2 text-sm font-medium rounded-md ',
                            item.current
                              ? 'bg-gray-100 text-gray-900 dark:bg-zinc-900'
                              : 'text-gray-600 hover:bg-gray-50 dark:hover:bg-zinc-800 dark:text-gray-300 dark:bg-zinc-900',
                          )}
                        >
                          <NavigationIcon
                            icon={item.icon}
                            className="mr-3 flex-shrink-0 h-6 w-6 text-gray-400 hover:text-gray-500"
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                        <div
                          className={`${
                            dropdownOpen[item.name] ? 'block' : 'hidden'
                          }`}
                        >
                          <div className="bg-white overflow-hidden rounded-md dark:bg-zinc-900">
                            {item.children.map((child: NavigationItem) => (
                              <a
                                key={child.name}
                                href={child.href}
                                className={classNames(
                                  item.current
                                    ? 'bg-gray-100 text-gray-900 '
                                    : 'text-gray-600  hover:bg-gray-100 dark:bg-zinc-900 dark:hover:bg-zinc-800 dark:text-white',
                                  'flex items-center px-2 pl-4 py-2 text-sm font-medium rounded-md ',
                                )}
                              >
                                {child.icon ? (
                                  <NavigationIcon
                                    icon={child.icon}
                                    className="mr-3 flex-shrink-0 h-6 w-6 text-gray-400 hover:text-gray-500"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <div className="my-4"></div>
                                )}
                                {child.name}
                              </a>
                            ))}
                          </div>
                        </div>
                      </div>
                    ) : (
                      // Existing single level navigation logic here
                      <a
                        key={item.name}
                        href={
                          item.navigateToSignIn && !isAuthenticated
                            ? '#'
                            : item.href
                        }
                        onClick={() => {
                          if (item.navigateToSignIn && !isAuthenticated) {
                            setOpenConnectModal(true);
                          }

                          if (item.href === '#StakeTime') {
                            setShowTimeStake(true);
                          }

                          if (item.href === '#StakeEmit') {
                            setShowEmitStake(true);
                          }
                        }}
                        className={classNames(
                          item.current
                            ? 'bg-gray-200 text-gray-900'
                            : 'text-gray-600 hover:bg-gray-50 dark:hover:bg-zinc-800 dark:text-gray-300',
                          'flex items-center px-2 py-2 text-sm font-medium rounded-md',
                          item.highlighted && item.href === '#StakeTime'
                            ? 'border rounded-sm bg-yellow-100 dark:rounded-sm text-yellow-400 dark:text-yellow-400 hover:bg-yellow-200/40 dark:bg-transparent border-yellow-200 '
                            : item.highlighted && item.href === '#StakeEmit'
                            ? 'border rounded-sm bg-purple-100 dark:rounded-sm text-purple-400 dark:text-purple-400 hover:bg-purple-200/40 dark:bg-transparent border-purple-200 '
                            : item.highlighted
                            ? ' border rounded-sm bg-purple-100 dark:bg-purple-200 dark:bg-transparent dark:rounded-lg text-purple-400 dark:text-purple-400 hover:bg-[#ffe0fb] border-purple-200 '
                            : '',
                          item.href === '#StakeTime' ||
                            item.href === '#StakeEmit'
                            ? 'mb-2'
                            : '',
                        )}
                      >
                        <NavigationIcon
                          icon={item.icon}
                          className={classNames(
                            `mr-3 flex-shrink-0 h-6 w-6 text-gray-400 hover:text-gray-500`,
                            item.highlighted && item.href === '#StakeTime'
                              ? 'text-yellow-400 hover:text-yellow-400/80'
                              : item.highlighted && item.href === '#StakeEmit'
                              ? 'text-purple-400 hover:text-purple-400/80'
                              : item.highlighted
                              ? 'text-purple-400 hover:text-purple-400'
                              : '',
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </a>
                    );
                  })}
                </nav>
              </div>
              <div className="mt-5">
                <nav className="flex-1 px-3 space-y-1">
                  {/* <div className="flex justify-center">
                  <a href="https://mm.finance/" target="_blank">
                    <img
                      src="/MMF_ad.png"
                      className="pb-5 "
                      style={{ width: '90%' }}
                    />
                  </a>
                </div> */}
                  {bnavigation.map(item => (
                    <a
                      key={item.name}
                      href={item.href}
                      target={'_blank'}
                      rel={'noopener noreferrer'}
                      className={classNames(
                        item.current
                          ? 'bg-gray-100 text-gray-900 dark:text-white dark:bg-zinc-900'
                          : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:text-white dark:hover:text-white dark:hover:bg-zinc-800',
                        'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                      )}
                    >
                      <NavigationIcon
                        icon={item.icon}
                        className={classNames(
                          item.current
                            ? 'text-gray-500'
                            : 'text-gray-400 group-hover:text-gray-500 dark:group-hover:text-gray-300',
                          'mr-3 flex-shrink-0 h-6 w-6',
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </a>
                  ))}
                </nav>
              </div>
              {isAuthenticated ? (
                <>
                  <div className="flex-shrink-0 mt-2 flex  dark:border-zinc-700 p-4">
                    <a
                      href="/app/my-nfts"
                      className="flex-shrink-0 w-full group block"
                    >
                      <div className="flex items-center">
                        <div>
                          <img
                            className="inline-block h-9 w-9 rounded-full"
                            src={userImage(user.data?.image)}
                            alt=""
                          />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-black dark:text-white font-medium text-gray">
                            {user.data?.name}
                          </p>
                          <p className="text-xs font-medium text-purple-600 group-hover:text-gray">
                            {user.wallet?.address.substring(0, 8)}...
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                </>
              ) : (
                <div className="p-4" />
              )}
            </div>
          </div>
          {/* Top bar */}
          <div className="md:pl-64 flex flex-col flex-1">
            <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white dark:bg-zinc-900 shadow">
              <button
                type="button"
                className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
              <div className="items-center flex-1 px-2 lg:px-4 flex justify-start">
                <Search />
              </div>
              <div className=" md:flex-1 px-2 lg:px-4 flex justify-end">
                <div className="mt-3 lg:mr-2">
                  {isAuthenticated ? <BalanceCard /> : null}
                </div>

                <div className="mt-3">
                  {isAuthenticated ? (
                    <>
                      <Menu
                        as="div"
                        className="relative inline-block text-left mr-2"
                      >
                        <div>
                          <Menu.Button className="inline-flex w-full justify-center rounded-md  border-purple-400 border-2 hover:bg-purple-400 dark:border-zinc-600 dark:border dark:bg-zinc-900 dark:text-white px-6 py-2 text-sm font-medium text-purple-500 hover:text-white shadow-sm dark:hover:bg-zinc-800 focus:outline-none ">
                            Vaults
                            <LockClosedIcon
                              className="-mr-1 ml-2 h-4 w-4 self-center"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white dark:bg-zinc-900 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="#"
                                    onClick={() => {
                                      setOpenVault(true);
                                    }}
                                    className={classNames(
                                      active
                                        ? 'bg-gray-100 text-gray-900 dark:bg-zinc-800 dark:text-white'
                                        : 'text-gray-700 dark:text-white',
                                      'block px-4 py-2 text-sm font-semibold',
                                    )}
                                  >
                                    <img
                                      src="/timetoken.png"
                                      className="h-8 w-8 inline-block"
                                      alt="TIME"
                                    />{' '}
                                    $TIME Vault
                                  </a>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="#"
                                    onClick={() => {
                                      setOpenVaultEmit(true);
                                    }}
                                    className={classNames(
                                      active
                                        ? 'bg-gray-100 text-gray-900 dark:bg-zinc-800 dark:text-white'
                                        : 'text-gray-700 dark:text-white',
                                      'block px-4 py-2 text-sm font-semibold',
                                    )}
                                  >
                                    <img
                                      src="https://dd.dexscreener.com/ds-data/tokens/cronos/0x9fa6552c1e9df51070a3b456355b5d76cbd59b5a.png"
                                      className="h-6 w-6 ml-1 inline-block mr-1"
                                      alt="EMIT"
                                    />{' '}
                                    $EMIT Vault
                                  </a>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>

                      <DialogScreen setOpen={setOpenVault} open={openVault}>
                        <Vault setOpen={setOpenVault} />
                      </DialogScreen>

                      <DialogScreen
                        setOpen={setOpenVaultEmit}
                        open={openVaultEmit}
                      >
                        <VaultEmit setOpen={setOpenVaultEmit} />
                      </DialogScreen>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                {isAuthenticated ? (
                  <div className="ml-3 flex items-center md:ml-3">
                    {window.location.href.includes('/app/collection') ||
                    window.location.href.includes('top-collection') ||
                    window.location.href.includes('smart-alerts') ||
                    window.location.href.includes('smart-charts') ||
                    window.location.href.includes('watchlist') ? (
                      <Menu
                        as="div"
                        className="relative inline-block text-left mr-2"
                      >
                        <div>
                          <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-100 dark:border-zinc-700  bg-white dark:bg-zinc-900 dark:text-white dark:hover:bg-zinc-800 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none">
                            {network === 'CRONOS' ? (
                              <>
                                <CronosSvg className="h-5 w-5 inline-block mr-2" />{' '}
                                <span className="hidden md:block">Cronos</span>
                              </>
                            ) : network === 'POLYGON' ? (
                              <>
                                <PolygonSvg className="h-5 w-5 inline-block mr-2" />{' '}
                                <span className="hidden md:block">Polygon</span>
                              </>
                            ) : (
                              <>
                                <CronosSvg className="h-5 w-5 inline-block mr-2" />{' '}
                                <span className="hidden md:block">Cronos</span>
                              </>
                            )}
                            <ChevronDownIcon
                              className="-mr-1 ml-2 h-5 w-5"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white dark:bg-zinc-900 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="#"
                                    onClick={() => {
                                      updateNetwork('CRONOS');
                                    }}
                                    className={classNames(
                                      active
                                        ? 'bg-gray-100 text-gray-900 dark:bg-zinc-800 dark:text-white'
                                        : 'text-gray-700 dark:text-white',
                                      'block px-4 py-2 text-sm',
                                    )}
                                  >
                                    <CronosSvg className="h-5 w-5 inline-block mr-2" />{' '}
                                    Cronos
                                  </a>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="#"
                                    onClick={() => {
                                      updateNetwork('POLYGON');
                                    }}
                                    className={classNames(
                                      active
                                        ? 'bg-gray-100 text-gray-900 dark:bg-zinc-800 dark:text-white'
                                        : 'text-gray-700 dark:text-white',
                                      'block px-4 py-2 text-sm',
                                    )}
                                  >
                                    <PolygonSvg className="h-5 w-5 inline-block mr-2" />{' '}
                                    Polygon
                                  </a>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    ) : null}

                    <button
                      onClick={() => {
                        setThemeChange(!themeChange);
                      }}
                      type="button"
                      className="relative mr-1 bg-white dark:bg-zinc-900 dark:text-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none"
                    >
                      <span className="sr-only">
                        {themeChange ? 'Light mode' : 'Dark mode'}
                      </span>
                      {themeChange ? (
                        <SunIcon
                          className="h-5 w-5 dark:hover:text-yellow-300"
                          aria-hidden="true"
                        />
                      ) : (
                        <MoonIcon className="h-5 w-5" aria-hidden="true" />
                      )}
                    </button>
                    <button
                      onClick={() => {
                        setNotiSidebarOpen(prevCheck => !prevCheck);
                      }}
                      type="button"
                      className="relative bg-white dark:bg-zinc-900 dark:text-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                      {activeNoti ? (
                        <span
                          className="bg-green-400 absolute top-0 right-0 block h-2.5 w-2.5 rounded-full ring-2 ring-white"
                          aria-hidden="true"
                        />
                      ) : (
                        <span />
                      )}
                    </button>

                    {/* Profile dropdown */}
                    <Menu as="div" className="ml-3 relative">
                      <div>
                        <Menu.Button className="max-w-xs w-8 bg-white dark:bg-zinc-900 dark:text-white flex items-center text-sm rounded-full focus:outline-none ">
                          <span className="sr-only">Open user menu</span>
                          <img
                            className="h-8 w-8 rounded-full"
                            src={userImage(user.data?.image)}
                            alt=""
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right absolute right-0 mt-6 w-52 text-center rounded-md shadow-lg py-1 bg-white dark:bg-zinc-800 dark:text-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="poppins mb-3">
                            <div className="flex items-center justify-center p-2 pb-0">
                              <span className="text-md rounded-full font-semibold text-gray-800 dark:text-white">
                                Level {user.data?.level}
                              </span>
                            </div>
                            <div className="flex items-center justify-center space-x-2 p-4 pt-1 pb-1 mb-0">
                              <div className="w-32 bg-gray-200 rounded-full h-2 dark:bg-gray-700">
                                <div
                                  className="bg-purple-500 h-2 rounded-full"
                                  style={{
                                    width: `${
                                      user.data && user.data?.xp !== '0'
                                        ? (parseInt(user.data?.xp) /
                                            user.data?.xpForNextLevel) *
                                          100
                                        : 0
                                    }%`,
                                  }}
                                ></div>
                              </div>
                            </div>
                            {user.data?.requiredXP ? (
                              <div className="flex items items-center justify-center text-xs opacity-75">
                                XP Required: {user.data?.requiredXP}
                              </div>
                            ) : null}
                          </div>
                          {userNavigation
                            .filter(n => n)
                            .map((item: any) => (
                              <Menu.Item key={item.name}>
                                {({ active }: any) => (
                                  <a
                                    href={item.href}
                                    onClick={item.onClick}
                                    className={classNames(
                                      active
                                        ? 'bg-gray-100 dark:bg-zinc-700 dark:text-white'
                                        : '',
                                      'block px-4 py-2 text-sm text-gray-700 dark:text-gray-100',
                                    )}
                                  >
                                    {item.name}
                                  </a>
                                )}
                              </Menu.Item>
                            ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                ) : (
                  <div className="my-2 ml-4 flex items-center md:ml-6">
                    <Menu as="div" className="relative inline-block text-left ">
                      <div>
                        <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-100 dark:border-zinc-600  bg-white dark:bg-zinc-900 dark:text-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none">
                          {network === 'CRONOS' ? (
                            <>
                              <CronosSvg className="h-5 w-5 inline-block mr-2" />{' '}
                              <span className="hidden md:block">Cronos</span>
                            </>
                          ) : network === 'POLYGON' ? (
                            <>
                              <PolygonSvg className="h-5 w-5 inline-block mr-2" />{' '}
                              <span className="hidden md:block">Polygon</span>
                            </>
                          ) : (
                            <>
                              <CronosSvg className="h-5 w-5 inline-block mr-2" />{' '}
                              <span className="hidden md:block">Cronos</span>
                            </>
                          )}
                          <ChevronDownIcon
                            className="-mr-1 ml-2 h-5 w-5"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white dark:bg-zinc-900 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#"
                                  onClick={() => {
                                    updateNetwork('CRONOS');
                                  }}
                                  className={classNames(
                                    active
                                      ? 'bg-gray-100 text-gray-900 dark:bg-zinc-800 dark:text-white'
                                      : 'text-gray-700 dark:text-white',
                                    'block px-4 py-2 text-sm',
                                  )}
                                >
                                  <CronosSvg className="h-5 w-5 inline-block mr-2" />{' '}
                                  Cronos
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#"
                                  onClick={() => {
                                    updateNetwork('POLYGON');
                                  }}
                                  className={classNames(
                                    active
                                      ? 'bg-gray-100 text-gray-900 dark:bg-zinc-800 dark:text-white'
                                      : 'text-gray-700 dark:text-white',
                                    'block px-4 py-2 text-sm',
                                  )}
                                >
                                  <PolygonSvg className="h-5 w-5 inline-block mr-2" />{' '}
                                  Polygon
                                </a>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>

                    <button
                      onClick={() => {
                        setThemeChange(!themeChange);
                      }}
                      type="button"
                      className="relative ml-4 mr-5 bg-white dark:bg-zinc-900 dark:text-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none"
                    >
                      <span className="sr-only">
                        {themeChange ? 'Light mode' : 'Dark mode'}
                      </span>
                      {themeChange ? (
                        <SunIcon
                          className="h-5 w-5 dark:hover:text-yellow-300"
                          aria-hidden="true"
                        />
                      ) : (
                        <MoonIcon className="h-5 w-5" aria-hidden="true" />
                      )}
                    </button>
                    <ConnectButton setOpen={setOpenConnectModal} />
                  </div>
                )}
              </div>
            </div>

            <main className="flex-1 dark:bg-zinc-900">
              <div>
                {isAuthenticated && hasFullAccess ? (
                  <Routes>
                    {isAdmin ? (
                      <>
                        <Route
                          path="admin/payment-wallets"
                          element={
                            <div className="p-6">
                              <PaymentWallets />
                            </div>
                          }
                        />
                        <Route
                          path="admin/subscriptions"
                          element={
                            <div className="p-6">
                              <Subscriptions />
                            </div>
                          }
                        />
                        <Route
                          path="admin/soft-staking"
                          element={
                            <div className="p-6">
                              <AdminSoftStaking />
                            </div>
                          }
                        />
                        <Route
                          path="admin/token-track"
                          element={
                            <div className="p-6">
                              <TokenTrackAdmin />
                            </div>
                          }
                        />

                        <Route
                          path="admin/promotions"
                          element={
                            <div className="p-6">
                              <AdminPromotions />
                            </div>
                          }
                        />
                        <Route
                          path="admin/users"
                          element={
                            <div className="p-6">
                              <Users />
                            </div>
                          }
                        />
                        <Route
                          path="admin/token-contests"
                          element={
                            <div className="p-6">
                              <TokenContestsAdmin />
                            </div>
                          }
                        />
                        <Route path="base" element={<Base />} />
                        {/** todo: change **/}
                      </>
                    ) : (
                      <></>
                    )}
                    <Route
                      path="services"
                      element={
                        <div className="p-6">
                          <Payment />
                        </div>
                      }
                    />
                    <Route
                      path="tokens"
                      element={
                        <div className="p-6">
                          <TokenTracker />
                        </div>
                      }
                    />
                    <Route
                      path="contests"
                      element={
                        <div className="p-6">
                          <Contests />
                        </div>
                      }
                    />
                    <Route
                      path="contest/:id/*"
                      element={
                        <div className="p-6">
                          <ContestLeaderboard />
                        </div>
                      }
                    />
                    {timeWarsEnabled && (
                      <Route
                        path="timewars"
                        element={
                          <div className="p-6">
                            <TimeWars />
                          </div>
                        }
                      />
                    )}
                    <Route
                      path="drops"
                      element={
                        <div className="p-6">
                          <Drops />
                        </div>
                      }
                    />
                    <Route
                      path="raffles"
                      element={
                        <div className="p-6">
                          <Raffles />
                        </div>
                      }
                    />
                    <Route
                      path="time"
                      element={
                        <div className="p-6">
                          <Time />
                        </div>
                      }
                    />
                    <Route
                      path="settings"
                      element={
                        <div className="p-6">
                          <Settings />
                        </div>
                      }
                    />
                    <Route
                      path="soft-staking/:name"
                      element={
                        <div className="p-6">
                          <SoftStaking />
                        </div>
                      }
                    />
                    <Route
                      path="pools/:name"
                      element={
                        <div className="p-6">
                          <PoolStaking />
                        </div>
                      }
                    />
                    <Route
                      path="farms/ttt"
                      element={
                        <div className="p-6">
                          <TTTLPStaking />
                        </div>
                      }
                    />
                    <Route
                      path="collection/:address"
                      element={
                        <div className="p-6">
                          <CollectionInventory />
                        </div>
                      }
                    />
                    <Route
                      path="collection/:address/:tokenId"
                      element={
                        <div className="p-6">
                          <TokenPage />
                        </div>
                      }
                    />
                    <Route
                      path="alert/add/:collectionAddress"
                      element={
                        <div className="p-6">
                          <CreateAlert />
                        </div>
                      }
                    />
                    <Route
                      path="alert/add/token/:collectionAddress/:tokenId"
                      element={
                        <div className="p-6">
                          <CreateTokenAlert />
                        </div>
                      }
                    />
                    <Route
                      path="chart/:collectionAddress"
                      element={
                        <div className="p-6">
                          <ChartPage />
                        </div>
                      }
                    />
                  </Routes>
                ) : process.env.REACT_APP_MAINTENANCE_MODE !== 'true' ? (
                  <Routes>
                    <>
                      <Route
                        path="drops"
                        element={
                          <div className="p-6">
                            <Drops />
                          </div>
                        }
                      />
                      <Route
                        path="raffles"
                        element={
                          <div className="p-6">
                            <Raffles />
                          </div>
                        }
                      />
                      <Route
                        path="time"
                        element={
                          <div className="p-6">
                            <Time />
                          </div>
                        }
                      />
                      <Route
                        path="soft-staking/:name"
                        element={
                          <div className="p-6">
                            <SoftStaking />
                          </div>
                        }
                      />
                      <Route
                        path="pools/:name"
                        element={
                          <div className="p-6">
                            <PoolStaking />
                          </div>
                        }
                      />
                      <Route
                        path="farms/ttt"
                        element={
                          <div className="p-6">
                            <TTTLPStaking />
                          </div>
                        }
                      />

                      {isAuthenticated && (
                        <>
                          <Route
                            path="services"
                            element={
                              <div className="p-6">
                                <Payment />
                              </div>
                            }
                          />
                          <Route
                            path="tokens"
                            element={
                              <div className="p-6">
                                <TokenTracker />
                              </div>
                            }
                          />
                          <Route
                            path="contests"
                            element={
                              <div className="p-6">
                                <Contests />
                              </div>
                            }
                          />
                          <Route
                            path="contest/:id/*"
                            element={
                              <div className="p-6">
                                <ContestLeaderboard />
                              </div>
                            }
                          />
                        </>
                      )}
                    </>
                  </Routes>
                ) : null}
              </div>
            </main>
          </div>
          <NotiSidebar
            alerts={alerts}
            setOpen={setNotiSidebarOpen}
            open={notiSidebarOpen}
          />
        </div>
      </ToastProvider>
    </>
  );
}

function arraymove(arr: Array<object>, fromIndex: number, toIndex: number) {
  var element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
}

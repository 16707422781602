import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../../../../hooks/useAuth';

import RafflesABI from '../../../../web3/ABI/contracts/raffles.abi.json';

import { useNetwork } from '../../../../hooks/useNetwork';
import { RaffleCard } from './RaffleCard';
import { BaseSvg, CronosSvg, PolygonSvg } from '../../../../utils/parser';
import axios from 'axios';
import { Contract, ethers } from 'ethers';
import { configVars } from '../../../../web3/config';
import ApiClient from '../../../../utils/ApiClient';

export function RafflesList() {
  const { user } = useAuth();
  const { updateNetwork, network } = useNetwork();
  const [chain, setChain] = useState('cronos');
  const [contracts, setContracts] = useState<any>();
  const [raffles, setRaffles] = useState<any[]>([]);

  function loadContracts() {
    axios
      .get(
        'https://dynamicdropstatas-production.up.railway.app/partners/time-launcher/raffle-contracts',
      )
      .then(r => r.data)
      .then(data => {
        setContracts(undefined);
        setContracts(data.contracts);
      });
  }

  useEffect(() => {
    loadContracts();
  }, []);

  useEffect(() => {
    if (!contracts || !contracts.cronos) return;

    const allRaffles: any = [];
    const cronosProvider = new ethers.providers.JsonRpcProvider(
      configVars.rpcNetwork_mainnet.rpcUrl,
    );
    const polygonProvider = new ethers.providers.JsonRpcProvider(
      configVars.polygon.rpcUrl,
    );
    const baseProvider = new ethers.providers.JsonRpcProvider(
      'https://base.drpc.org/',
    );

    // load cronos raffles
    const cronosContract = new Contract(
      contracts.cronos,
      RafflesABI,
      cronosProvider,
    );

    async function getRaffles() {
      await cronosContract.getAllLastRaffles().then(async (r: any) => {
        const raffles = [];

        for (let i = 0; i < r.length; i++) {
          // we may not have a raffle in each slot
          if (!r[i]) continue;

          const raffle = r[i];

          const winner = await raffle.winner;
          const players = raffle.players;
          const maxPlayers = parseInt(raffle.playerLimit);

          let cancelled =
            raffle.winner === '0x0000000000000000000000000000000000000000' &&
            maxPlayers === players;

          //if not cancelled, check if its finished
          let ended =
            raffle.winner !== '0x0000000000000000000000000000000000000000' ||
            cancelled;

          const ticketsLeft = raffle.playerLimit - raffle.players.length;
          // winner different than 0x00000
          if (ended || ticketsLeft === 0) continue;

          if (!raffle || !raffle.players) continue;
          if (!raffle.ticketPrice) continue;

          const formatCollectionAddress = raffle.nftContract;
          const formatRank = raffle.rank;
          const poolId = parseInt(raffle.poolId);

          const activeRaffle = {
            title: raffle.name || 'Missing Raffle Title!',
            price: raffle.ticketPrice,
            paused: raffle.paused,
            raffleId: poolId,
            network: 'cronos',
            image:
              (
                await ApiClient.getTokenFromMoonflow(
                  raffle.nftContract,
                  raffle.tokenId,
                  1,
                ).catch(e => undefined)
              )?.image || '/nav.png',
            tokenId: raffle.tokenId,
            networkId: 1,
            rank: formatRank,
            playersLimit: maxPlayers,
            players: players,
            collectionAddress: formatCollectionAddress,
            ticketsLeft,
            winner,
            numberOfTickets: raffle.playerLimit,
          };

          raffles.push(activeRaffle);
        }

        allRaffles.push(...raffles);
      });

      // load polygon raffles
      const polygonContract = new Contract(
        contracts.polygon,
        RafflesABI,
        polygonProvider,
      );

      await polygonContract.getAllLastRaffles().then(async (r: any) => {
        const raffles = [];

        for (let i = 0; i < r.length; i++) {
          // we may not have a raffle in each slot
          if (!r[i]) continue;

          const raffle = r[i];

          const winner = await raffle.winner;
          const players = raffle.players;
          const maxPlayers = parseInt(raffle.playerLimit);

          let cancelled =
            raffle.winner === '0x0000000000000000000000000000000000000000' &&
            maxPlayers === players;

          //if not cancelled, check if its finished
          let ended =
            raffle.winner !== '0x0000000000000000000000000000000000000000' ||
            cancelled;

          const ticketsLeft = raffle.playerLimit - raffle.players.length;
          // winner different than 0x00000
          if (ended || ticketsLeft === 0) continue;

          if (!raffle || !raffle.players) continue;
          if (!raffle.ticketPrice) continue;

          const formatCollectionAddress = raffle.nftContract;
          const formatRank = raffle.rank;
          const poolId = parseInt(raffle.poolId);

          const activeRaffle = {
            title: raffle.name || 'Missing Raffle Title!',
            price: raffle.ticketPrice,
            paused: raffle.paused,
            network: 'polygon',
            raffleId: poolId,
            image:
              (
                await ApiClient.getTokenFromMoonflow(
                  raffle.nftContract,
                  raffle.tokenId,
                  5,
                ).catch(e => undefined)
              )?.image || '/nav.png',
            tokenId: raffle.tokenId,
            networkId: 5,
            rank: formatRank,
            playersLimit: maxPlayers,
            players: players,
            collectionAddress: formatCollectionAddress,
            ticketsLeft,
            winner,
            numberOfTickets: raffle.playerLimit,
          };

          raffles.push(activeRaffle);
        }

        allRaffles.push(...raffles);
      });

      // base raffles
      const baseContract = new Contract(
        contracts.base,
        RafflesABI,
        baseProvider,
      );

      await baseContract.getAllLastRaffles().then(async (r: any) => {
        const raffles = [];

        for (let i = 0; i < r.length; i++) {
          // we may not have a raffle in each slot
          if (!r[i]) continue;

          const raffle = r[i];

          const winner = await raffle.winner;
          const players = raffle.players;
          const maxPlayers = parseInt(raffle.playerLimit);

          let cancelled =
            raffle.winner === '0x0000000000000000000000000000000000000000' &&
            maxPlayers === players;

          //if not cancelled, check if its finished
          let ended =
            raffle.winner !== '0x0000000000000000000000000000000000000000' ||
            cancelled;

          const ticketsLeft = raffle.playerLimit - raffle.players.length;
          // winner different than 0x00000
          if (ended || ticketsLeft === 0) continue;

          if (!raffle || !raffle.players) continue;
          if (!raffle.ticketPrice) continue;

          const formatCollectionAddress = raffle.nftContract;
          const formatRank = raffle.rank;
          const poolId = parseInt(raffle.poolId);

          const activeRaffle = {
            title: raffle.name || 'Missing Raffle Title!',
            price: raffle.ticketPrice,
            paused: raffle.paused,
            raffleId: poolId,
            image:
              (
                await ApiClient.getTokenFromMoonflow(
                  raffle.nftContract,
                  raffle.tokenId,
                  17,
                ).catch(e => undefined)
              )?.image || '/nav.png',
            tokenId: raffle.tokenId,
            networkId: 17,
            network: 'base',
            rank: formatRank,
            playersLimit: maxPlayers,
            players: players,
            collectionAddress: formatCollectionAddress,
            ticketsLeft,
            winner,
            numberOfTickets: raffle.playerLimit,
          };

          raffles.push(activeRaffle);
        }

        allRaffles.push(...raffles);
      });

      setRaffles(allRaffles as any);
    }

    getRaffles();
  }, [contracts]);

  function reloadRaffles() {
    loadContracts();
  }
  const list = raffles
    .filter(r => r.network === chain)
    .map((raffle: any) => {
      return (
        <RaffleCard
          key={raffle.raffleId}
          raffle={raffle}
          contracts={contracts}
          reloadRaffles={reloadRaffles}
        ></RaffleCard>
      );
    });
  return (
    <div className="flex flex-col justify-center items-center gap-y-1">
      <div className="flex flex-row gap-x-2 items-center mb-2">
        <div
          onClick={() => {
            setChain('cronos');
          }}
          className={`cursor-pointer rounded-md p-2 bg-white ${
            chain === 'cronos' ? 'bg-gray-100' : ''
          } border border-b-gray-300 hover:bg-gray-100`}
        >
          <CronosSvg className="w-6 h-6" />
        </div>
        <div
          onClick={() => {
            setChain('polygon');
          }}
          className={`cursor-pointer rounded-md p-2 bg-white ${
            chain === 'polygon' ? 'bg-gray-100' : ''
          } border border-b-gray-300 hover:bg-gray-100`}
        >
          <PolygonSvg className="w-6 h-6" />
        </div>
        <div
          onClick={() => {
            setChain('base');
          }}
          className={`cursor-pointer rounded-md p-2 bg-white ${
            chain === 'base' ? 'bg-gray-100' : ''
          } border border-b-gray-300 hover:bg-gray-100`}
        >
          <BaseSvg className="w-6 h-6" />
        </div>
      </div>
      <div
        className={`grid gap-4 grid-cols-1 ${
          raffles.length >= 3 || raffles.length <= 1
            ? 'md:grid-cols-3'
            : 'md:grid-cols-2'
        }`}
      >
        {list}
      </div>
    </div>
  );
}

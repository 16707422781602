import { useState, useEffect } from 'react';
import { Token } from '../../../../utils/ApiClient';
import { useNavigate } from 'react-router-dom';
// import { TraitList } from '../trait/TraitList';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PlaceholderLoading from 'react-placeholder-loading';

interface Props {
  token: {
    image: string;
    name: string;
    tokenId: string;
    collectionAddress: string;
  };
}

export function MyStakingTokenCard({ token }: Props) {
  const navigate = useNavigate();

  const click = async () => {
    navigate(`/app/collection/${token.collectionAddress}/${token.tokenId}`);
  };

  return (
    <a
      href="#"
      onClick={click}
      key={token.tokenId}
      className="focus:outline-none rounded-lg dark:bg-zinc-800 border border-gray-100 dark:border-zinc-600 bg-white px-6 py-5 shadow-sm hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
    >
      <div className="flex-shrink-0">
        <LazyLoadImage
          className="w-auto rounded-lg "
          src={token.image.trim() === '' ? '/nav.png' : token.image}
          width={600}
          height={600}
          placeholder={
            <PlaceholderLoading
              shape="rect"
              width={200}
              height={200}
              colorStart="transparent"
              colorEnd="#9CA3AF"
            />
          }
          alt={token.name}
        />
      </div>
      <div className="mt-5 ">
        <h2 className="text-gray-700 dark:text-gray-200">{token.name}</h2>
      </div>
    </a>
  );
}

/* eslint-disable no-restricted-globals */
import { useEffect, useState } from 'react';
import ApiClient from '../../../../utils/ApiClient';
import AdminTabs from '../../components/admin/AdminTabs';
import DialogScreen from '../../components/DialogScreen';
import { Dialog } from '@headlessui/react';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid';
function toLocalDatetimeString(utcString: string) {
  const date = new Date(utcString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

function toUtcString(localString: string) {
  const date = new Date(localString);
  return date.toUTCString();
}

export default function TokenContestsAdmin() {
  const [tokenAddress, setTokenAddress] = useState<string>('');
  const [startsAt, setStartsAt] = useState<Date>(new Date());
  const [endsAt, setEndsAt] = useState<Date>(new Date());
  const [prizes, setPrizes] = useState<string[]>(['', '', '']);
  const [contests, setContests] = useState<any>([]);

  const [showManageDialog, setShowManageDialog] = useState(false);
  const [managingContest, setManagingContest] = useState<any>(undefined);

  const [currentDateTime, setCurrentDateTime] = useState('');
  useEffect(() => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
    setCurrentDateTime(formattedDateTime);
  }, []);

  useEffect(() => {
    ApiClient.admin_getContests().then(r => {
      setContests(r.contests || []);
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      ApiClient.admin_getContests().then(r => {
        setContests(r.contests || []);
      });
    }, 300000);
    return () => clearInterval(interval);
  }, []);

  function onNewTokenContest() {
    if (endsAt.getTime() < new Date().getTime()) {
      return alert('End date must be in the future');
    }

    if (startsAt.getTime() > endsAt.getTime()) {
      return alert('Start date must be before end date');
    }

    if (!tokenAddress || !tokenAddress.startsWith('0x')) {
      return alert('Token address is required');
    }

    ApiClient.admin_newTokenContest(
      endsAt.toUTCString(),
      tokenAddress,
      startsAt.toUTCString(),
      prizes,
    )
      .then(r => {
        if (!r || !r.tokenContest) {
          return alert('Failed, is it already added or supported?');
        }

        setTokenAddress('');
        setPrizes(['', '', '']);
        ApiClient.admin_getContests().then(r => {
          setContests(r.contests || []);
        });

        alert(
          'Successfully created contest. It will end in: ' +
            Number(r.endsAt.toString()).toFixed(1) +
            ' hours. The contest will begin in: ' +
            Number(r.startsAt.toString()).toFixed(1) +
            ' hours.',
        );
      })
      .catch(e => {
        alert(e.response?.data?.message || 'Failed');
      });
  }

  function handleEditContestChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;
    setManagingContest({
      ...managingContest,
      [name]: value,
    });
  }
  function onSubmitEditContest(remove: boolean = false) {
    if (remove) {
      if (new Date(managingContest.endsAt).getTime() < new Date().getTime()) {
        if (
          !confirm(
            `Are you sure you want to delete this contest that has not ended yet?`,
          )
        ) {
          return;
        }
      }
      ApiClient.admin_editContest(
        managingContest.id,
        new Date(managingContest.endsAt).toUTCString(),
        managingContest.metadata?.prizes,
        true,
      )
        .then(r => {
          ApiClient.admin_getContests().then(r => {
            setContests(r.contests || []);
          });
          setManagingContest(undefined);
          setShowManageDialog(false);
          alert('Deleted successfully');
        })
        .catch(e =>
          alert(e.response?.data?.message || 'Failed to delete contest'),
        );
    } else {
      ApiClient.admin_editContest(
        managingContest.id,
        new Date(managingContest.endsAt).toUTCString(),
        managingContest.metadata?.prizes,
      )
        .then(r => {
          ApiClient.admin_getContests().then(r => {
            setContests(r.contests || []);
          });
          setManagingContest(undefined);
          setShowManageDialog(false);
          alert('Successfully edited contest');
        })
        .catch(e =>
          alert(e.response?.data?.message || 'Failed to edit contest'),
        );
    }
  }

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndsAt(new Date(e.target.value));
  };

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartsAt(new Date(e.target.value));
  };

  const addPrize = () => {
    setPrizes([...prizes, '']);
  };

  return (
    <>
      <DialogScreen open={showManageDialog} setOpen={setShowManageDialog}>
        {managingContest && (
          <div>
            <div className="mt-3 sm:mt-5">
              <Dialog.Title
                as="h3"
                className="text-lg font-semibold leading-6 text-gray-900"
              >
                Manage Contest
              </Dialog.Title>
              <div className="mt-4">
                <label
                  htmlFor="editPrizes"
                  className="block text-sm font-medium text-gray-700"
                >
                  Prizes
                </label>
                <div className="grid grid-flow-row grid-cols-3 gap-x-2 gap-y-2">
                  {managingContest.metadata.prizes?.map(
                    (prize: string, index: number) => (
                      <div>
                        <label>{index + 1}.</label>
                        <input
                          key={index}
                          type="text"
                          name={`prizes-${index}`}
                          value={prize}
                          onChange={e => {
                            const newPrizes = [
                              ...managingContest.metadata.prizes,
                            ];
                            newPrizes[index] = e.target.value;
                            const managingContestCopy = { ...managingContest };
                            managingContestCopy.metadata.prizes = newPrizes;
                            setManagingContest({
                              ...managingContestCopy,
                            });
                          }}
                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    ),
                  )}
                </div>
              </div>
              <div className="mt-4">
                <label
                  htmlFor="editEndsAt"
                  className="block text-sm font-medium text-gray-700"
                >
                  Ends At
                </label>
                <input
                  type="datetime-local"
                  id="editEndsAt"
                  name="endsAt"
                  value={toLocalDatetimeString(managingContest.endsAt)}
                  onChange={handleEditContestChange}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="mt-4">
              <button
                type="button"
                onClick={() => {
                  if (
                    confirm('Are you sure you want to delete this contest?')
                  ) {
                    onSubmitEditContest(true);
                  }
                }}
                className="inline-flex w-24 mt-2 justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500"
              >
                Delete
              </button>
            </div>
            <div className=" sm:mt-6 flex flex-row gap-x-4">
              <button
                type="button"
                onClick={() => {
                  setShowManageDialog(false);
                  setManagingContest(undefined);
                }}
                className="inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={() => {
                  onSubmitEditContest();
                }}
                className="inline-flex w-full justify-center rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500"
              >
                Save
              </button>
            </div>
          </div>
        )}
      </DialogScreen>
      <AdminTabs />
      <h2 className="mt-6 mb-2 text-xl font-semibold text-gray-900 dark:text-white">
        Manage Contests
      </h2>
      <div>
        <h3 className="font-semibold">Add New Contest</h3>
        <div className="mb-3">
          <label
            htmlFor="tokenAddress"
            className="block text-sm font-medium text-gray-700"
          >
            Token Address
          </label>
          <input
            type="text"
            id="tokenAddress"
            name="tokenAddress"
            value={tokenAddress}
            onChange={e => setTokenAddress(e.target.value)}
            placeholder="0x"
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            required
          />
        </div>
        <div className="mb-3">
          <label
            htmlFor="startsAt"
            className="block text-sm font-medium text-gray-700"
          >
            Starts At
          </label>
          <input
            type="datetime-local"
            id="startsAt"
            name="startsAt"
            defaultValue={currentDateTime}
            onChange={handleStartDateChange}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            required
          />
        </div>
        <div className="mb-3">
          <label
            htmlFor="endsAt"
            className="block text-sm font-medium text-gray-700"
          >
            Ends At
          </label>
          <input
            type="datetime-local"
            id="endsAt"
            name="endsAt"
            onChange={handleDateChange}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            required
          />
        </div>
        <div className="mb-3">
          <div className="grid grid-flow-row gap-y-2 gap-x-4 grid-cols-4 lg:grid-cols-6">
            {prizes.map((prize, index) => (
              <div key={index}>
                <label
                  htmlFor={`prize${index + 1}`}
                  className="block text-sm font-medium text-gray-700"
                >
                  {index + 1}. Prize
                </label>
                <input
                  type="text"
                  id={`prize${index + 1}`}
                  name={`prize${index + 1}`}
                  value={prize}
                  onChange={e => {
                    const newPrizes = [...prizes];
                    newPrizes[index] = e.target.value;
                    setPrizes(newPrizes);
                  }}
                  placeholder={`1M $EMIT`}
                  className="mt-1 block py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                />
              </div>
            ))}
            <div className="flex items-center gap-x-4 w-full">
              <button
                type="button"
                onClick={addPrize}
                className="mt-2 py-1 px-3 border border-gray-300 bg-white rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              >
                + Add
              </button>
              <button
                type="button"
                onClick={() => {
                  setPrizes(prizes.slice(0, prizes.length - 1));
                }}
                className="mt-2 py-1 px-3 border border-gray-300 bg-white rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              >
                - Remove
              </button>
            </div>
          </div>
        </div>
        <button
          className="cursor-pointer mb-4 bg-white dark:bg-zinc-800 dark:text-white dark:border-zinc-700 disabled:opacity-50 dark:hover:bg-zinc-700 disabled:bg-gray-200 py-2 px-6 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
          onClick={() => onNewTokenContest()}
        >
          Create New Contest
        </button>
      </div>
      <h2 className="mt-6 text-xl font-semibold text-gray-900 dark:text-white">
        Existing Contests
      </h2>
      <div className="flow-root overflow-hidden">
        <div className="max-w-7xl">
          <table className="w-full text-left">
            <thead className="bg-white">
              <tr>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Token
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Winners
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Prizes
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Prize Distributed
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Participants
                </th>
                <th scope="col" className="relative py-3.5 pl-3 px-6">
                  <span className="sr-only">Manage</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {contests?.map((contest: any, i: number) => (
                <tr
                  key={contest.id}
                  className={i % 2 === 0 ? 'bg-gray-200' : ''}
                >
                  <td className="px-3 py-4 text-sm text-gray-500">
                    <div className="flex items-center">
                      <div className="font-semibold">
                        {contest.TokenTrack.tokenName} ($
                        {contest.TokenTrack.tokenSymbol})
                      </div>
                    </div>
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    {contest.noWinner
                      ? 'No winner'
                      : contest.Winners.length > 0
                      ? contest.Winners.map((w: any) => (
                          <>
                            {w.place}. {w.Account.address} <br />
                          </>
                        ))
                      : 'Pending...'}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    {!contest.hasStarted ? (
                      <>
                        Starts in{' '}
                        {contest.daysUntilStart !== 0
                          ? `${contest.daysUntilStart} days`
                          : contest.hoursUntilStart
                          ? `${contest.hoursUntilStart} hour${
                              contest.hoursUntilStart > 1 ? 's' : ''
                            }`
                          : `${
                              contest.minutesUntilStart > 0
                                ? `${contest.minutesUntilStart} minutes`
                                : 'one moment'
                            }`}
                      </>
                    ) : !contest.hasEnded ? (
                      <>
                        Ends in{' '}
                        {contest.daysUntilEnd !== 0
                          ? `${contest.daysUntilEnd} day${
                              contest.daysUntilEnd > 1 ? 's' : ''
                            }`
                          : contest.hoursUntilEnd
                          ? `${contest.hoursUntilEnd} hour${
                              contest.hoursUntilEnd > 1 ? 's' : ''
                            }`
                          : `${
                              contest.minutesUntilEnd > 0
                                ? `${contest.minutesUntilEnd} minute${
                                    contest.minutesUntilEnd > 1 ? 's' : ''
                                  }`
                                : 'one moment'
                            }`}
                      </>
                    ) : (
                      <>Ended</>
                    )}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    {contest.metadata.prizes
                      ?.filter((p: string) => p !== '')
                      .slice(0, 3)
                      .map((p: string, index: number) => (
                        <div key={index}>
                          <b>{index + 1}.</b> {p}
                          <br />
                        </div>
                      ))}

                    {contest.metadata.prizes?.length > 3 && (
                      <div className="text-sm">
                        and {contest.metadata.prizes?.length - 3} more...
                      </div>
                    )}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    {contest.Winners.length === 0 ||
                    contest.noWinner === true ? (
                      '-'
                    ) : contest.prizeGiven ? (
                      <div
                        className="font-xs flex align-middle items-center cursor-pointer"
                        onClick={() => {
                          ApiClient.admin_distributePrize(contest.id).then(
                            (r: any) => {
                              if (r) {
                                ApiClient.admin_getContests().then(r => {
                                  setContests(r.contests || []);
                                });
                              }
                            },
                          );
                        }}
                      >
                        <CheckIcon className="text-green-500 h-5 w-5" />
                      </div>
                    ) : (
                      <div
                        className="font-xs flex align-middle items-center cursor-pointer"
                        onClick={() => {
                          ApiClient.admin_distributePrize(contest.id).then(
                            (r: any) => {
                              if (r) {
                                ApiClient.admin_getContests().then(r => {
                                  setContests(r.contests || []);
                                });
                              }
                            },
                          );
                        }}
                      >
                        <XMarkIcon className="text-red-500 h-5 w-5" />
                      </div>
                    )}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    {contest._count.Entries}
                  </td>
                  <td className="relative py-4 pl-3 text-right text-sm font-medium">
                    <a
                      href="#"
                      onClick={() => {
                        setManagingContest(contest);
                        setShowManageDialog(true);
                      }}
                      className="text-purple-600 hover:text-purple-700 pr-4"
                      rel="noreferrer"
                    >
                      Manage
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

/* eslint-disable no-restricted-globals */
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../../hooks/useAuth';
import { BigNumber, ethers } from 'ethers';
import { configVars } from '../../../../web3/config';
import ERC20ABI from '../../../../web3/ABI/erc20.cro.abi.json';
import { ensureCronos, txWait } from '../../../../web3/utils';
import Particles from 'react-tsparticles';
import { loadSlim } from 'tsparticles-slim';
import { numberWithCommas } from '../../../../utils/parser';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import ApiClient from '../../../../utils/ApiClient';
import EMIT_REWARDS_ABI from '../../../../web3/ABI/emitrewards.abi.json';
import { ArrowRightIcon } from '@heroicons/react/24/solid';
import { APR_VAULT } from './StakeEmit';
import APRVAULTABI from '../../../../web3/ABI/aprvaults-emit.abi.json';
import { useToast } from '../../../../contexts/ToastContext';
import { formatUnits, parseUnits } from 'ethers/lib/utils';

const EMIT_REWARDS =
  process.env.ENV === 'production' || process.env.REACT_APP_ENV === 'production'
    ? '0xd15f5C0327aD4603e0903457bc8AC8E46dab1fb0'
    : '0xDDF90e43A804B1E8db9688CE3D93DE1e29240f42';

const ENABLED = true;
const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const ProgressBar = ({
  total,
  claimed,
  loading,
  timestamp,
}: {
  total: number;
  claimed: number;
  loading: boolean;
  timestamp: Date | undefined;
}) => {
  const percentage = total === 0 ? 0 : (claimed / total) * 100;

  const sectionDividers = Array.from({ length: 7 }).map((_, index) => (
    <div
      key={index}
      className="absolute bg-white/50"
      style={{
        left: `${(index + 1) * (100 / 8)}%`,
        top: 0,
        bottom: 0,
        width: '2px',
      }}
    />
  ));

  if (loading) {
    return (
      <div className="flex justify-center mt-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          style={{
            background: 'none',
            display: 'block',
            shapeRendering: 'auto',
          }}
          width="35px"
          height="35px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <circle
            cx="50"
            cy="50"
            fill="none"
            stroke="#cdcdcd"
            strokeWidth="7"
            r="18"
            strokeDasharray="84.82300164692441 30.274333882308138"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              repeatCount="indefinite"
              dur="1s"
              values="0 50 50;360 50 50"
              keyTimes="0;1"
            ></animateTransform>
          </circle>
        </svg>
      </div>
    );
  }

  return (
    <div className="mt-4 flex flex-col">
      <div className="flex justify-between">
        <div className="text-sm">0</div>
        <div>
          Vested: {numberWithCommas(claimed.toFixed(2))} /{' '}
          {numberWithCommas(total)}
        </div>
      </div>
      <div className="relative w-full h-8 bg-gray-200 rounded overflow-hidden">
        {/* Container for the progress bar and particles */}
        <div
          className="h-full bg-gradient-to-r from-violet-700 via-violet-500 to-violet-400"
          style={{ width: `${percentage}%` }}
        >
          <Particles
            id="progressParticles"
            init={particlesInit}
            options={{
              fullScreen: {
                enable: false,
                zIndex: -1,
              },
              particles: {
                number: {
                  value: 800,
                  density: {
                    enable: true,
                    area: 800,
                  },
                },
                color: {
                  value: '#fff', // Adjust the particle color as needed
                },
                opacity: {
                  value: 0.5,
                  anim: {
                    enable: true,
                    speed: 1,
                    opacity_min: 0.1,
                    sync: false,
                  },
                },
                size: {
                  value: 3,
                  random: true,
                },
                move: {
                  enable: true,
                  speed: 1.5,
                  direction: 'right',
                  random: false,
                  straight: true,
                  outMode: 'out',
                  attract: {
                    enable: false,
                    rotateX: 600,
                    rotateY: 1200,
                  },
                },
              },
              detectRetina: true,
            }}
          />
        </div>
        {/* Overlay section dividers */}
        {sectionDividers}
      </div>
      <div className="flex justify-end">
        {!loading && timestamp && (
          <div>
            Completes: {timestamp.getDate()} {months[timestamp.getMonth()]}{' '}
            {timestamp.getFullYear()}
          </div>
        )}
      </div>
    </div>
  );
};

const particlesInit = async (main: any) => {
  await loadSlim(main);
};

const SeasonProgressBar = ({
  // todo replace with karmic debt
  debt,
}: {
  debt: number;
}) => {
  // Current date

  // Calculate the current season number (0-indexed)
  const currentSeason = Math.floor(debt);

  // Calculate the percentage of the current season that has passed
  const percentage = (debt / 100) * 100;

  const sectionDividers = Array.from({ length: 4 }).map((_, index) => (
    <div
      key={index}
      className="absolute bg-white/50"
      style={{
        left: `${(index + 1) * (100 / 4)}%`,
        top: 0,
        bottom: 0,
        width: '2px',
      }}
    />
  ));

  return (
    <div className="mt-4 flex flex-col">
      <div className="flex justify-between">
        <div className="text-black">
          Current Debt ({percentage.toFixed(0)}%)
        </div>
      </div>
      <div className="relative w-full h-8 bg-gray-200 rounded overflow-hidden">
        {/* Container for the progress bar and particles */}
        <div
          className="h-full bg-gradient-to-r from-violet-700 via-violet-500 to-violet-400"
          style={{ width: `${percentage}%` }}
        >
          <Particles
            id="progressParticles"
            init={particlesInit}
            options={{
              fullScreen: {
                enable: false,
                zIndex: -1,
              },
              particles: {
                number: {
                  value: 800,
                  density: {
                    enable: true,
                    area: 800,
                  },
                },
                color: {
                  value: '#fff',
                },
                opacity: {
                  value: 0.5,
                  anim: {
                    enable: true,
                    speed: 1,
                    opacity_min: 0.1,
                    sync: false,
                  },
                },
                size: {
                  value: 3,
                  random: true,
                },
                move: {
                  enable: true,
                  speed: 1.5,
                  direction: 'right',
                  random: false,
                  straight: true,
                  outMode: 'out',
                  attract: {
                    enable: false,
                    rotateX: 600,
                    rotateY: 1200,
                  },
                },
              },
              detectRetina: true,
            }}
          />
        </div>
        {/* Overlay section dividers */}
        {sectionDividers}
      </div>
    </div>
  );
};

export function VaultEmit({ setClose }: any) {
  const { user } = useAuth();
  const [vault, setVault] = useState<{
    amount: BigNumber;
    claimed: BigNumber;
  }>({
    amount: BigNumber.from('0'),
    claimed: BigNumber.from('0'),
  });
  const [tabs, setTabs] = useState([
    { id: 0, name: '$Emit', current: false, disabled: ENABLED ? false : true },
  ]);
  const [rewards, setRewards] = useState<BigNumber>(BigNumber.from('0'));
  const [canClaim, setCanClaim] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [timeVaultsStartTimestamp, setTimeVaultsStartTimestamp] = useState<
    undefined | Date
  >();

  const [accountlessRewards, setAccountlessRewards] = useState([]);
  const [burn, setBurn] = useState<any>(0);
  const [unclaimedTimeRewardsAmount, setUnclaimedTimeRewardsAmount] =
    useState(0);
  const [showingRewards, setShowingRewards] = useState(false);
  const { triggerToast } = useToast();

  async function updateTimeBalance() {
    // const provider = new ethers.providers.JsonRpcProvider(
    //   process.env.ENV === 'production' ||
    //   process.env.REACT_APP_ENV === 'production'
    //     ? configVars.polygon.rpcUrl
    //     : configVars.rpcNetwork_mainnet.rpcUrl,
    // );
    // const contract = new ethers.Contract(TIME_TOKEN, ERC20ABI, provider);
    // console.log('getting time bal');
    // const balance = await contract.balanceOf(user.data?.address);
    // console.log('got time balance');
    // setTimeBalance(balance);
  }

  const [loadingCompound, setLoadingCompound] = useState(false);
  const [selectedVault, setSelectedVault] = useState<number | undefined>();
  async function onCompoundToSelectedVault() {
    if (
      process.env.ENV === 'production' ||
      process.env.REACT_APP_ENV === 'production'
    ) {
      await ensureCronos();
    } else {
      await ensureCronos();
    }

    if (unclaimedTimeRewardsAmount <= 0) {
      alert('Not enough rewards.');
      return;
    }

    if (selectedVault === undefined) {
      alert('Invalid vault selection');
      return;
    }

    setLoadingCompound(true);
    ApiClient.createCompoundAPRVaultRequestEmit(selectedVault)
      .then(async r => {
        if (r.signature) {
          const provider = new ethers.providers.Web3Provider(window.ethereum);

          const signer = provider.getSigner();
          const contract = new ethers.Contract(APR_VAULT, APRVAULTABI, signer);

          const gasEstimate = await signer.estimateGas({
            to: APR_VAULT,
            from: await signer.getAddress(),
            data: contract.interface.encodeFunctionData('compoundVault', [
              r.values,
              r.signature,
            ]),
          });

          const gasLimit = gasEstimate.mul(12).div(10);

          const response = await txWait(
            contract.compoundVault(r.values, r.signature, {
              gasLimit,
            }),
          );

          console.log({ response });
          setLoadingCompound(false);
          setSelectedVault(undefined);
          if (response) {
            triggerToast(
              'Success',
              `Successfully compounded ${formatUnits(
                r.values.amount,
                18,
              )} $TIME to Vault ${selectedVault + 1}`,
              'success',
            );
            setUnclaimedTimeRewardsAmount(0);
            for (let i = 1; i <= 3; i++) {
              setTimeout(() => {
                ApiClient.getRewardsEmit()
                  .then((r: any) => {
                    setAccountlessRewards(r.rewards);
                    setUnclaimedTimeRewardsAmount(r.unclaimedTimeAmount || 0);

                    setBurn(r.burn);
                  })
                  .catch(e => console.log(e));
                updateTimeBalance();
                retrieveAPRVaultsInfo();
              }, 3000 * i);
            }
          }
        }
      })
      .catch(e => {
        setLoadingCompound(false);
        console.log(e);
        triggerToast(
          'Error',
          e.response?.data?.message ||
            e.data?.message ||
            'Something went wrong.',
          'error',
        );
      });
  }

  const [loadingTimeClaim, setLoadingTimeClaim] = useState(false);
  async function onClaimTimeRewards() {
    if (burn >= 50) {
      // eslint-disable-next-line no-restricted-globals
      if (
        !confirm(
          `Current debt is high, are you sure you want to claim at ${burn}% debt?`,
        )
      ) {
        return;
      }
    }

    if (
      process.env.ENV === 'production' ||
      process.env.REACT_APP_ENV === 'production'
    ) {
      await ensureCronos();
    } else {
      await ensureCronos();
    }
    setLoadingTimeClaim(true);
    ApiClient.getClaimEmitRewardsSignature()
      .then(async r => {
        if (r.signature) {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const contract = new ethers.Contract(
            EMIT_REWARDS,
            EMIT_REWARDS_ABI,
            signer,
          );

          console.log(r);

          const gasEstimate = await signer.estimateGas({
            to: EMIT_REWARDS,
            from: await signer.getAddress(),
            data: contract.interface.encodeFunctionData('claim', [
              r.values,
              r.signature,
            ]),
          });

          const gasLimit = gasEstimate.mul(12).div(10);

          const response = await contract.claim(r.values, r.signature, {
            gasLimit,
          });

          setLoadingTimeClaim(false);
          if (response) {
            for (let i = 1; i <= 3; i++) {
              setTimeout(() => {
                ApiClient.getRewardsEmit()
                  .then(r => {
                    setAccountlessRewards(r.rewards);
                    setUnclaimedTimeRewardsAmount(r.unclaimedTimeAmount || 0);
                    setBurn(r.burn);
                  })
                  .catch(e => console.log(e));
                updateTimeBalance();
              }, 3000 * i);
            }
          }
        }
      })
      .catch(e => {
        setLoadingTimeClaim(false);
        console.log(e);
        triggerToast(
          'Error',
          e.response?.data?.message ||
            e.data?.message ||
            'Something went wrong.',
          'error',
        );
      });
  }

  const [vaults, setVaults] = useState<any>([]);
  console.log({ vaults });
  async function retrieveAPRVaultsInfo() {
    if (!user.data) return;

    if (
      process.env.ENV === 'production' ||
      process.env.REACT_APP_ENV === 'production'
    ) {
      await ensureCronos();
    } else {
      await ensureCronos();
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const contract = new ethers.Contract(APR_VAULT, APRVAULTABI, provider);

    let vaults = await contract.allAprVaults(user.data?.address);
    console.log({ vaults });
    const aprVaults = await ApiClient.getAPRVaults('EMIT');
    const newVaults = vaults
      .map((v: any, i: number) => {
        const newVault: any = {};
        newVault.api = aprVaults.find((a: any) => a.vaultIndex === i);
        console.log(i + ':');
        console.log(v.amount);
        newVault.amount = Number(formatUnits(v.amount));
        newVault.duration = v.duration.toNumber();
        newVault.startTimestamp = new Date(v.startTimestamp.toNumber() * 1000);
        newVault.index = i;

        return newVault;
      })
      .filter((v: any) => v.api && !v.api.closed);

    setVaults(newVaults);

    if (
      selectedVault !== undefined &&
      newVaults.find(
        (v: any) => v.index === selectedVault && v.api && !v.api?.closed,
      )
    ) {
      setSelectedVault(
        newVaults.find((v: any) => v.index === selectedVault).index,
      );
    }
    // update the selected vault
    setLoaded(true);
  }

  // planets staking info

  useEffect(() => {
    if (!ENABLED) return;

    ApiClient.getRewardsEmit()
      .then((r: any) => {
        setAccountlessRewards(r.rewards);
        setUnclaimedTimeRewardsAmount(r.unclaimedTimeAmount || 0);

        setBurn(r.burn);
      })
      .catch(e => console.log(e));
    updateTimeBalance();
    retrieveAPRVaultsInfo();
  }, []);

  const activeTabId = tabs.find(t => t.current)?.id || 0;
  const handleTabChange = (tabName: string) => {
    const updatedTabs = tabs.map(tab => ({
      ...tab,
      current: tab.name === tabName,
    }));
    setTabs(updatedTabs);
  };

  return (
    <div className="pt-0">
      <div className="flex flex-col border-yellow-400 text-start mt-2 mb-4 font-bold items-center text-lg  text-black">
        Your $EMIT Vault
      </div>
      <div className="px-1 md:px-6 ">
        <div className="md:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full "
            value={tabs.find(tab => tab.current)?.name}
            onChange={e => handleTabChange(e.target.value)}
          >
            {tabs.map(tab => (
              <option key={tab.name} value={tab.name}>
                {tab.name}
              </option>
            ))}
          </select>
        </div>
        <div className=" px-0 py-2">
          <div
            className=" justify-center gap-x-2 hidden md:flex"
            aria-label="Tabs"
          >
            {tabs.map(tab => (
              <div
                key={tab.name}
                className={`tab text-md headline px-5 py-1 ${
                  activeTabId === tab.id
                    ? 'selected text-white'
                    : 'text-gray-800'
                } font-semibold ${
                  tab.disabled ? 'opacity-30 cursor-not-allowed' : ''
                }`}
                onClick={() => {
                  if (tab.disabled) return;
                  handleTabChange(tab.name);
                }}
              >
                <em className={`${tab.disabled ? 'cursor-not-allowed' : ''}`}>
                  {tab.name}
                </em>
              </div>
            ))}
          </div>

          {/* Content based on the selected tab */}
          <div className="mt-4">
            {activeTabId === 0 ? (
              <div>
                <SeasonProgressBar debt={burn} />
                <div className="text p-4 mt-4 text-sm bg-gray-100 rounded-lg">
                  <span className="font-semibold">
                    Time Bank Rewards, Time Adventure rewards, and Time Sniper
                    rewards
                  </span>{' '}
                  will be claimed here.
                  <br />
                  <br />
                  This "Karmic debt" is a fee of up to 100% of the users
                  rewards. Starting at 100% and decreasing by 1% per day until a
                  0% fee is reached which after remains a 0% fee until claimed.
                  <br />
                  <br />
                  When you claim, fee is reset to 100%. <br />
                  <span className="text-xs">
                    NOTE: Compounding into an existing vault does NOT reset your
                    karmic debt counter nor does using rewards within the Time
                    Wars.
                  </span>
                  <br />
                  <div className="mt-8 flex flex-row justify-between">
                    <div className="">
                      <p className="text-base font-semibold">Current Rewards</p>
                      <div className="text-sm align-middle">
                        <img
                          src="/timetoken.png"
                          alt="time"
                          className="inline mr-1 h-6 w-6"
                        />
                        <span className="align-middle">
                          {Number(unclaimedTimeRewardsAmount).toFixed(2)}{' '}
                          <span className="text-xs">
                            (
                            {(
                              Number(unclaimedTimeRewardsAmount) -
                              (Number(unclaimedTimeRewardsAmount) * burn) / 100
                            ).toFixed(2)}{' '}
                            after debt)
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <button
                        disabled={
                          unclaimedTimeRewardsAmount <= 0 ||
                          loadingCompound ||
                          loadingTimeClaim
                        }
                        onClick={() => {
                          onClaimTimeRewards();
                        }}
                        className="px-4 py-2 mt-1 bg-violet-500 hover:bg-violet-600 disabled:bg-violet-500 disabled:opacity-50 text-white rounded-md font-semibold"
                      >
                        Claim
                      </button>
                      {loadingCompound ? (
                        <div className="flex justify-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            style={{
                              background: 'none',
                              display: 'block',
                              shapeRendering: 'auto',
                            }}
                            width="42px"
                            height="42px"
                            viewBox="0 0 100 100"
                            preserveAspectRatio="xMidYMid"
                          >
                            <circle
                              cx="50"
                              cy="50"
                              fill="none"
                              stroke="#cdcdcd"
                              strokeWidth="7"
                              r="18"
                              strokeDasharray="84.82300164692441 30.274333882308138"
                            >
                              <animateTransform
                                attributeName="transform"
                                type="rotate"
                                repeatCount="indefinite"
                                dur="1s"
                                values="0 50 50;360 50 50"
                                keyTimes="0;1"
                              ></animateTransform>
                            </circle>
                          </svg>
                        </div>
                      ) : (
                        <>
                          <select
                            disabled={
                              unclaimedTimeRewardsAmount <= 0 ||
                              !vaults ||
                              vaults.length === 0 ||
                              loadingCompound
                            }
                            value={selectedVault}
                            onChange={t => {
                              setSelectedVault(Number(t.target.value) || 0);
                            }}
                            className="mt-4 block w-full py-1 px-2 border disabled:opacity-50 border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                            <option value="">Select a vault</option>
                            {vaults && vaults.length > 0
                              ? vaults.map((v: any) => {
                                  return (
                                    <option value={v.index} key={v.index}>
                                      Vault {v.index + 1} ({v.amount} $EMIT)
                                    </option>
                                  );
                                })
                              : null}
                          </select>
                          <button
                            disabled={
                              unclaimedTimeRewardsAmount <= 0 ||
                              selectedVault === undefined ||
                              loadingCompound
                            }
                            onClick={() => {
                              onCompoundToSelectedVault();
                            }}
                            className="px-4 py-2 mt-1 bg-zinc-700 disabled:hover:bg-zinc-700 hover:bg-zinc-600  disabled:opacity-50 text-white rounded-md font-semibold"
                          >
                            Compound to Vault
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="p-4 mt-2 text-sm bg-gray-100 rounded-lg">
                  <div
                    className="flex justify-between cursor-pointer"
                    onClick={() => {
                      setShowingRewards(!showingRewards);
                    }}
                  >
                    <a href="#">
                      {showingRewards ? 'Hide' : 'Show'} Recent Rewards
                    </a>
                    <div>
                      <ChevronDownIcon className="h-4 w-4 cursor-pointer" />
                    </div>
                  </div>
                  {showingRewards ? (
                    <div className="mt-4">
                      {showingRewards ? (
                        <div
                          className="mt-4 overflow-auto"
                          style={{ maxHeight: '200px' }}
                        >
                          <table className="w-full">
                            <thead>
                              <tr>
                                <th className="text-gray-500 font-bold py-2 px-2">
                                  DATE
                                </th>
                                <th className="text-gray-500 font-bold py-2 px-2">
                                  REASON
                                </th>
                                <th className="text-gray-500 font-bold py-2 px-2 text-right">
                                  AMOUNT
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {accountlessRewards.map(
                                (reward: any, index: number) => (
                                  <tr
                                    key={reward.id}
                                    className={`${
                                      index % 2 === 0
                                        ? 'bg-gray-200'
                                        : 'bg-white'
                                    }`}
                                  >
                                    <td className="p-2 text-sm px-2">
                                      {reward.createdAt.split('T')[0]}
                                    </td>
                                    <td className="p-2 text-sm px-2 text-start">
                                      {reward.desc}
                                    </td>
                                    <td className="p-2 text-sm px-2 text-right flex justify-end items-center">
                                      {reward.amount.toFixed(2)}
                                      <img
                                        className="h-6 w-6 inline ml-1"
                                        src="/timetoken.png"
                                        alt="Time Token"
                                      />
                                    </td>
                                  </tr>
                                ),
                              )}
                            </tbody>
                          </table>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              </div>
            ) : (
              <div>Unknown Tab</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react';
import moment from 'moment';
import { Dialog, Transition } from '@headlessui/react';
import { TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';
import ApiClient from '../utils/ApiClient';
import { collectionImage } from '../utils/parser';
interface NotiSidebarProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  alerts: any[];
}
export default function NotiSidebar({
  open,
  setOpen,
  alerts,
}: NotiSidebarProps) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl dark:bg-zinc-800">
                    <div className="px-4 sm:px-6">
                      <Dialog.Title className="text-lg font-medium text-gray-900 dark:text-gray-300">
                        {' '}
                        Smart Alerts{' '}
                      </Dialog.Title>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      <ul className="flex-1 divide-y divide-gray-200 overflow-y-auto">
                        {alerts.map((alert: any) => (
                          <li key={alert.id}>
                            <div className="relative group py-6 px-5 flex items-center">
                              <a
                                href={
                                  alert.token
                                    ? '/app/collection/' +
                                      alert.collection?.address +
                                      '/' +
                                      alert.token?.tokenId
                                    : '/app/collection/' +
                                      alert.collection?.address
                                }
                                className="-m-1 flex-1 block p-1"
                              >
                                <div
                                  className="absolute inset-0 group-hover:bg-gray-50"
                                  aria-hidden="true"
                                />
                                <div className="flex-1 flex items-center min-w-0 relative">
                                  <span className="flex-shrink-0 inline-block relative">
                                    <img
                                      className="h-20 w-20 rounded-lg"
                                      src={
                                        alert.token
                                          ? alert.token?.image
                                          : collectionImage(
                                              alert.collection?.source,
                                              alert.collection?.himage,
                                            )
                                      }
                                      alt=""
                                    />
                                    {/* <span
                                    className={classNames(
                                      person.status === "online"
                                        ? "bg-green-400"
                                        : "bg-gray-300",
                                      "absolute top-0 right-0 block h-2.5 w-2.5 rounded-full ring-2 ring-white"
                                    )}
                                    aria-hidden="true"
                                  /> */}
                                  </span>
                                  <div className="ml-4 truncate">
                                    <p className="text-sm font-medium text-gray-900 dark:text-gray-400 truncate">
                                      {alert.token
                                        ? alert.token?.name
                                        : alert.collection?.name}
                                    </p>
                                    <p className="text-sm text-gray-500 dark:text-gray-300 truncate">
                                      {alert.token
                                        ? 'List price ' +
                                          alert.token?.pListPrice
                                        : 'Floor price ' +
                                          alert.collection?.pFloor}
                                    </p>
                                    <p className="text-sm text-gray-500 dark:text-gray-300 truncate">
                                      Alarm price: {alert.value}
                                    </p>
                                    <p className="text-sm text-gray-500 dark:text-gray-300 truncate">
                                      Triggered:{' '}
                                      {moment(alert.fireDate).fromNow()}
                                    </p>
                                  </div>
                                </div>
                              </a>
                              <button
                                style={{ zIndex: 101 }}
                                onClick={e => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  ApiClient.deleteAlert(alert.id).then(() => {
                                    alerts.forEach((item, index) => {
                                      if (item.id === alert.id) {
                                        alerts.splice(index, 1);
                                      }
                                    });
                                  });
                                }}
                              >
                                <TrashIcon className="w-5 h-5 text-gray-400 group=hover:text-blue-600" />
                              </button>
                            </div>
                          </li>
                        ))}
                      </ul>
                      {/* Replace with your content */}
                      {/* <div className="absolute inset-0 px-4 sm:px-6">
                        <div
                          className="h-full border-2 border-dashed border-gray-200"
                          aria-hidden="true"
                        />
                      </div> */}
                      {/* /End replace */}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

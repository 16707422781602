import './Base.css';

import { useState, useRef, useEffect } from 'react';
const DraggableBackground = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1); // Initial zoom level
  const [dragging, setDragging] = useState(false);
  const startPos = useRef({ x: 0, y: 0 });
  const containerRef = useRef<any>(null);

  const MIN_ZOOM = 0.95; // Adjust as needed
  const MAX_ZOOM = 1.1; // Adjust as needed

  useEffect(() => {
    const updatePositionForCenter = () => {
      if (!containerRef.current) return;

      const containerWidth = containerRef.current.offsetWidth;
      const containerHeight = containerRef.current.offsetHeight;
      const contentWidth = containerRef.current.scrollWidth * zoom;
      const contentHeight = containerRef.current.scrollHeight * zoom;

      // Calculating the new position to center the content
      const newPositionX = (containerWidth - contentWidth) / 2;
      const newPositionY = (containerHeight - contentHeight) / 2;

      setPosition({ x: newPositionX, y: newPositionY });
    };

    // Call the function to update position when component mounts or zoom changes
    updatePositionForCenter();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setPosition(prevPosition => {
        const containerWidth = containerRef.current?.offsetWidth || 0;
        const contentWidth = containerRef.current?.scrollWidth || 0;
        const sidebarWidth = window.innerWidth >= 768 ? 256 : 0; // 16rem in pixels, adjust if using a different unit
        const minX = containerWidth - contentWidth - sidebarWidth;
        const adjustedX = Math.min(Math.max(prevPosition.x, minX), 0);
        return { ...prevPosition, x: adjustedX };
      });
    };

    const handleWheel = (e: any) => {
      const delta = e.deltaY ? -e.deltaY : e.deltaX; // Normalize wheel to +1 or -1
      const zoomDirection = delta > 0 ? 1.1 : 0.9; // Adjust zoom speed
      const newZoom = Math.max(
        MIN_ZOOM,
        Math.min(zoom * zoomDirection, MAX_ZOOM),
      );

      console.log({ newZoom });
      setZoom(newZoom);
    };

    window.addEventListener('resize', handleResize);
    containerRef.current.addEventListener('wheel', handleWheel, {
      passive: false,
    });

    return () => {
      window.removeEventListener('resize', handleResize);
      containerRef.current.removeEventListener('wheel', handleWheel);
    };
  }, [zoom]);

  const onMouseDown = (e: any) => {
    setDragging(true);
    startPos.current = { x: e.clientX - position.x, y: e.clientY - position.y };
  };
  const onMouseMove = (e: any) => {
    if (!dragging) return;

    const newX = e.clientX - startPos.current.x;
    const newY = e.clientY - startPos.current.y;

    const containerWidth = containerRef.current.offsetWidth; // Use the full container width
    const containerHeight = window.innerHeight - 96; // Considering the top bar, adjust if necessary

    const contentWidth = containerRef.current.scrollWidth * zoom;
    const contentHeight = containerRef.current.scrollHeight * zoom;

    // Calculate boundaries considering the zoom level
    const minX = containerWidth - contentWidth;
    const minY = containerHeight - contentHeight;

    // Clamp the newX and newY values to ensure the content does not move beyond the container
    const clampedX = Math.min(Math.max(newX, minX), 0); // Clamping for X
    const clampedY = Math.min(Math.max(newY, minY), 0); // Clamping for Y

    setPosition({ x: clampedX, y: clampedY });
  };

  const onMouseUp = () => {
    setDragging(false);
  };

  return (
    <div
      ref={containerRef}
      className="draggable-container"
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseUp}
      onMouseUp={onMouseUp}
      style={{
        cursor: dragging ? 'grabbing' : 'grab',
        overflow: 'hidden',
        position: 'relative',
        backgroundColor: 'black', // Ensure black background
        width: `calc(100vw - ${window.innerWidth >= 768 ? '16rem' : '0px'})`,
        height: `calc(100vh - 4rem)`,
      }}
    >
      <div
        className="content"
        style={{
          transform: `translate(${position.x}px, ${position.y}px) scale(${zoom})`,

          position: 'absolute',
          top: 0,
          left: 0,
          // Ensure content does not extend beyond the viewable area
        }}
      >
        <img
          src="/baseexample.png"
          alt="Background"
          draggable="false"
          style={{ width: '100%', height: 'auto' }}
        />
        {/* Overlay Images */}
      </div>
    </div>
  );
};

export default function Base() {
  return <DraggableBackground />;
}

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import PaginationButton from './PaginationButton';
export default function Pagination({
  page,
  pages,
  pageSize,
  total,
  goto,
}: any) {
  const firstPages = [];
  const lastPages = [];
  if (pages > 7) {
    const start = page <= 2 ? 1 : page < pages - 4 ? page - 1 : pages - 5;
    for (let i = start; i <= start + 2; i++) {
      firstPages.push({ active: i === page, page: i });
    }
    for (let i = pages - 2; i <= pages; i++) {
      lastPages.push({ active: i === page, page: i });
    }
  } else {
    for (let i = 1; i <= pages; i++) {
      if (i < 3) firstPages.push({ active: i === page, page: i });
      else lastPages.push({ active: i === page, page: i });
    }
  }

  return (
    <div className="mt-6 pl-4 py-6 flex items-center justify-between border-t border-gray-200 dark:border-zinc-600">
      <div className="flex-1 flex justify-between sm:hidden">
        <button
          disabled={page === 1}
          onClick={() => {
            goto(page - 1);
          }}
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 dark:bg-zinc-800 dark:text-white dark:border-zinc-700"
        >
          Previous
        </button>
        <button
          disabled={pages === page}
          onClick={() => {
            goto(page + 1);
          }}
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 dark:bg-zinc-800 dark:text-white dark:border-zinc-700"
        >
          Next
        </button>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700 dark:text-gray-300">
            Showing{' '}
            <span className="font-medium">
              {page === pages ? total : (page - 1) * pageSize + pageSize}
            </span>{' '}
            of <span className="font-medium">{total}</span> results
          </p>
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <button
              disabled={page === 1}
              onClick={() => {
                goto(page - 1);
              }}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 dark:bg-zinc-800 dark:text-white dark:border-zinc-700"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
            {firstPages.map(p => (
              <PaginationButton
                key={p.page}
                active={p.active}
                page={p.page}
                click={goto}
              ></PaginationButton>
            ))}

            {pages.length > 3 && (
              <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 dark:bg-zinc-800 dark:text-white dark:border-zinc-700 dark:hover:bg-zinc-700">
                ...
              </span>
            )}
            {lastPages.map(p => (
              <PaginationButton
                key={p.page}
                active={p.active}
                page={p.page}
                click={goto}
              ></PaginationButton>
            ))}
            <button
              disabled={pages === page}
              onClick={() => {
                goto(page + 1);
              }}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 dark:bg-zinc-800 dark:text-white dark:border-zinc-700 dark:hover:bg-zinc-700"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}

import { useNetwork } from '../../../hooks/useNetwork';
import ConnectButton from '../../web3/ConnectButton';

export default function WalletRequired() {
  const { setShowWalletConnect } = useNetwork();

  return (
    <div className="mt-12 flex flex-col justify-center items-center">
      <h2 className="font-semibold text-xl">Connect your wallet</h2>
      <p className="mb-4">
        To continue to this page, you are required to connect your wallet.
      </p>
      <ConnectButton setOpen={setShowWalletConnect} />
    </div>
  );
}

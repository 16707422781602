import { useEffect, useState } from 'react';
import ApiClient, { Token } from '../../../utils/ApiClient';
// import ProfileImageModal from '../../components/ProfileImageModal';
import { TrashIcon } from '@heroicons/react/24/outline';
import { useAuth } from '../../../hooks/useAuth';
import { reloadApp } from '../../../web3/utils';
import InfoAlert, { InfoAlertData } from '../components/InfoAlert';
import { ethers } from 'ethers';
import validateEmail from '../../../utils/email';
import { removeItem } from '../../../utils/array';
import { userImage } from '../../../utils/parser';
import { Dialog } from '@headlessui/react';
import { FARMS_BOOSTERS_ADDRESS } from '../../../web3/ABI/contracts/time-farms';
import DialogScreen from '../components/DialogScreen';
import { TokenSelectNative } from '../components/token/TokenSelect';

export default function Settings() {
  const [wallet, setWallet] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [infoAlert, setInfoAlert] = useState<InfoAlertData>({
    message: '',
    type: 'error',
    title: '',
  });
  //   const [errorType, setErrorType] = useState('');
  const [image, setImage] = useState('');
  const [imageModal, setImageModal] = useState(false);
  const { user, updateUser, hasFullAccess } = useAuth();

  useEffect(() => {
    setUsername(user.data?.name || '');
    setImage(userImage(user.data?.image) || '');
    setEmail(user.data?.email || '');
  }, []);

  const setError = (error: string) => {
    setInfoAlert({ message: error, type: 'error', title: '' });
  };
  const setSuccess = (msg: string) => {
    setInfoAlert({ message: msg, type: 'success', title: '' });
  };

  const saveEmail = (e: any) => {
    if (!validateEmail(email)) {
      setError('Invalid email address');
      return;
    }
    ApiClient.addEmail(email)
      .then(e => {
        if (user.data === undefined) return;
        setSuccess('Email changed to ' + email);
        updateUser({ ...user, data: { ...user.data, email } });
      })
      .catch(e => setError(e.message));
  };
  const saveUsername = (e: any) => {
    ApiClient.addUsername(username)
      .then(e => {
        if (user.data === undefined) return;
        setSuccess('Username changed to ' + username);
        updateUser({ ...user, data: { ...user.data, name: username } });
      })
      .catch(e => {
        setError(e.message);
      });
  };
  const removeWallet = (address: string) => {
    ApiClient.removeWallet(address)
      .then(() => {
        if (user.data === undefined) return;
        removeItem(user.data.wallets, address);
        setSuccess(`Wallet ${address} removed`);
        updateUser(user);
      })
      .catch(e => {
        setError(e.message);
      });
  };
  const addWallet = () => {
    if (!ethers.utils.isAddress(wallet)) {
      setError('Invalid wallet address');
      return;
    }
    user.data?.wallets.includes(wallet) && setError('Wallet already added');

    ApiClient.addWallet(wallet)
      .then(() => {
        if (user.data === undefined) return;
        setSuccess('Wallet added');
        setWallet('');
        updateUser({
          ...user,
          data: { ...user.data, wallets: user.data.wallets.concat([wallet]) },
        });
        // reloadApp();
      })
      .catch(e => {
        setError(e.message);
      });
  };

  //   useEffect(() => {
  //     console.log(infoAlert);
  //     // ApiClient.getWallet(1).then(data => {
  //     //   setImage(data.data.data.account.image);
  //     //   setName(data.data.data.account.name);
  //     //   setEmail(data.data.data.account.email);
  //     // });
  //   }, [infoAlert]);

  const [myTokens, setMyTokens] = useState<Token[]>([]);
  const [selectedTokens, setSelectedTokens] = useState<Token[]>([]);
  useEffect(() => {
    ApiClient.getMyTokens(1, 100).then(r => {
      setMyTokens(r.data);
    });
  }, [user.isAuthenticated]);

  return (
    <>
      <DialogScreen open={imageModal} setOpen={setImageModal}>
        <div>
          <div className="mt-3 text-center sm:mt-5">
            <Dialog.Title
              as="h3"
              className="text-lg font-semibold leading-6 text-gray-900"
            >
              Change Photo
            </Dialog.Title>
            {hasFullAccess ? (
              <div className="mt-2">
                <TokenSelectNative
                  tokens={myTokens}
                  selectedTokens={selectedTokens}
                  setSelectedTokens={setSelectedTokens}
                  max={1}
                />
              </div>
            ) : (
              <p>No access.</p>
            )}
          </div>
        </div>
        <div className="mt-5 sm:mt-6 flex flex-row gap-x-4">
          <button
            type="button"
            className="cursor-pointer disabled:cursor-default bg-white dark:bg-zinc-800 dark:text-white dark:border-zinc-700 dark:disabled:opacity-50 dark:hover:bg-zinc-700 dark:disabled:hover:bg-zinc-800 disabled:bg-gray-200 py-2 px-6 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 disabled:text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            disabled={selectedTokens.length <= 0}
            onClick={() => {
              ApiClient.setAvatar(
                selectedTokens[0].collectionAddress,
                selectedTokens[0].tokenId,
              ).then(r => {
                if (user.data && r.data.success) {
                  updateUser({
                    ...user,
                    data: { ...user.data, image: selectedTokens[0].image },
                  });
                  setSelectedTokens([]);
                  setImageModal(false);
                }
              });
            }}
          >
            Set Photo
          </button>
        </div>
      </DialogScreen>
      <form className="space-y-8 divide-y divide-gray-200">
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div>
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
                Profile
              </h3>
              {/* <p className="mt-1 max-w-2xl text-sm text-gray-500">
                This information will be displayed publicly so be careful what
                you share.
              </p> */}
            </div>
            <InfoAlert data={infoAlert} />
            <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-100 sm:mt-px sm:pt-2"
                >
                  Username
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="flex">
                    <div className="flex-grow">
                      {' '}
                      <input
                        type="text"
                        name="username"
                        id="username"
                        onChange={e => setUsername(e.target.value)}
                        value={username}
                        className="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 dark:border-zinc-600 dark:bg-zinc-800 dark:text-white dark:placeholder-zinc-300"
                      />
                    </div>
                    <div className="">
                      <button
                        onClick={saveUsername}
                        type="button"
                        className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-100 sm:mt-px sm:pt-2"
                >
                  Email
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="flex">
                    <div className="flex-grow">
                      {' '}
                      <input
                        type="email"
                        name="email"
                        id="email"
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                        className="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 dark:border-zinc-600 dark:bg-zinc-800 dark:text-white dark:placeholder-zinc-300"
                      />
                    </div>
                    <div className="">
                      <button
                        onClick={saveEmail}
                        type="button"
                        className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-100 sm:mt-px sm:pt-2"
                >
                  Wallets
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="max-w-lg flex rounded-md shadow-sm">
                    <ul>
                      {user.data?.wallets.map((wallet: any, index: number) => (
                        <li key={index}>
                          <div className="flex">
                            <div className="">
                              <button
                                onClick={e => {
                                  e.preventDefault();
                                  removeWallet(wallet);
                                }}
                              >
                                <TrashIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>{' '}
                            <div className="">{wallet}</div>
                          </div>{' '}
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="flex">
                    <div className="flex-grow">
                      {' '}
                      <input
                        type="text"
                        name="wallet"
                        id="wallet"
                        onChange={e => {
                          setWallet(e.target.value);
                        }}
                        value={wallet}
                        className="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 dark:border-zinc-600 dark:bg-zinc-800 dark:text-white dark:placeholder-zinc-300"
                      />
                    </div>
                    <div className="">
                      <button
                        onClick={addWallet}
                        type="button"
                        className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="photo"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-100"
                >
                  Photo
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="flex items-center">
                    {image ? (
                      <img
                        className="h-40 w-40 rounded-full"
                        src={image}
                        alt=""
                      />
                    ) : (
                      <span className="h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                        <svg
                          className="h-full w-full text-gray-300"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                      </span>
                    )}

                    <button
                      onClick={() => {
                        setImageModal(true);
                      }}
                      type="button"
                      className="ml-8 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Change Photo
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-5">
            <div className="flex justify-end"></div>
          </div>
        </div>
      </form>
    </>
  );
}

export default function PaginationButton({ page, active, click }: any) {
  if (active) {
    return (
      <button
        onClick={() => {
          click(page);
        }}
        aria-current="page"
        className="z-10 bg-blue-50 border-blue-500 text-blue-600 dark:bg-zinc-800 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
      >
        {page}
      </button>
    );
  } else {
    return (
      <button
        onClick={() => {
          click(page);
        }}
        className="bg-white border-gray-300 dark:border-zinc-600 text-gray-500 hover:bg-gray-50 dark:bg-zinc-800 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium"
      >
        {page}
      </button>
    );
  }
}

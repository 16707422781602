import { Contract, ethers, providers } from 'ethers';
import { isAddress } from 'ethers/lib/utils';
import { useCallback, useEffect, useState } from 'react';
import CRC721ABI from '../../../../web3/ABI/crc721.abi.json';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ApiClient, { Token } from '../../../../utils/ApiClient';
import MoonflowApiClient from '../../../../utils/MoonflowApiClient';

export type MetadataNFT = {
  token: bigint;
  name: string;
  image: string;
  selected?: boolean;
};
type TokenSelectType = {
  tokens: Array<bigint>;
  selectedTokens: Array<any>;
  collection: string;
  setSelectedTokens: any;
};

export function TokenSelect({
  tokens,
  collection,
  selectedTokens,
  setSelectedTokens,
}: TokenSelectType) {
  const [metadata, setMetadata] = useState<MetadataNFT[]>([]);
  const [page, setPage] = useState(1);
  const pageSize = 15;
  const totalPages = Math.ceil(tokens ? tokens.length / pageSize : 0);
  useEffect(() => {
    const fetchImages = async () => {
      if (!isAddress(collection) || !tokens) {
        return;
      }

      setMetadata([]);
      const provider = new providers.Web3Provider(window.ethereum);
      const contract = new Contract(collection, CRC721ABI, provider);
      const startIndex = (page - 1) * pageSize;
      const endIndex = Math.min(page * pageSize, tokens.length);
      const shownTokens = tokens.slice(startIndex, endIndex);

      const metaPromises = shownTokens.map(async token => {
        const metadataUri = await contract.tokenURI(token);
        const tokenUri = metadataUri.replace(
          'ipfs://',
          'https://cdn.ltsglxy.network/ipfs/',
        );
        const metadata = (await ethers.utils.fetchJson(
          tokenUri,
        )) as MetadataNFT;
        const image = metadata.image.startsWith('ipfs://')
          ? metadata.image.replace(
              'ipfs://',
              'https://cdn.ltsglxy.network/ipfs/',
            )
          : metadata.image;
        return {
          token,
          name: metadata.name,
          image,
          selected: selectedTokens.some(t => t === token),
        };
      });

      const meta = await Promise.all(metaPromises);
      setMetadata(meta);
    };

    fetchImages().catch(console.error);
  }, [page, tokens, collection]);

  useEffect(() => {
    for (let i = 0; i < metadata.length; i++) {
      setMetadata((old: any) => {
        old = old.map((o: MetadataNFT) => {
          return {
            ...o,
            selected: selectedTokens.some(t => t === o.token),
          };
        });
        return old;
      });
    }
  }, [selectedTokens]);

  const handleSelect = useCallback(
    (token: bigint) => {
      setSelectedTokens((old: any) => {
        const isAlreadySelected = old.includes(token);
        return isAlreadySelected
          ? old.filter((t: any) => t !== token)
          : [...old, token];
      });
    },
    [setSelectedTokens],
  );

  return (
    <div className="flex flex-col">
      <div className="grid grid-cols-5 gap-x-2 gap-y-2">
        {metadata &&
          metadata.map((token, i) => {
            return (
              <div
                key={token.token + '-' + i}
                className="relative cursor-pointer"
                onClick={() => handleSelect(token.token)}
              >
                <LazyLoadImage
                  src={token.image}
                  alt={token.name}
                  className={`w-[75px] rounded-md ${
                    token.selected ? 'opacity-50' : 'hover:opacity-75'
                  }`}
                />
                {token.selected && (
                  <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 16.17L4.83 12L3.41 13.41L9 19L21 7L19.59 5.59L9 16.17Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                )}
              </div>
            );
          })}
      </div>
      <div className="mt-4 mb-2 flex flex-row gap-x-2 justify-between">
        <div className="flex mt-2 flex-row gap-x-2 justify-start align-start items-start">
          <p className="text-sm font-sm">
            Page {page}/{totalPages}
          </p>
          {selectedTokens.length > 0 && (
            <p className="text-sm  font-sm">
              •<span className="pl-2">Selected: {selectedTokens.length}</span>
            </p>
          )}
        </div>
        <div>
          <button
            onClick={() => setPage(old => old - 1)}
            disabled={page <= 1}
            className="cursor-pointer disabled:cursor-default bg-white dark:bg-zinc-800 dark:text-white dark:border-zinc-700 dark:disabled:opacity-50  dark:hover:bg-zinc-700 dark:disabled:hover:bg-zinc-800 disabled:bg-gray-200 py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 disabled:text-gray-400 hover:bg-gray-50 focus:outline-none"
          >
            {'<'}
          </button>
          <button
            onClick={() => setPage(old => old + 1)}
            disabled={page >= totalPages}
            className="cursor-pointer disabled:cursor-default bg-white dark:bg-zinc-800 dark:text-white dark:border-zinc-700 dark:disabled:opacity-50  dark:hover:bg-zinc-700 dark:disabled:hover:bg-zinc-800 disabled:bg-gray-200 py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 disabled:text-gray-400 hover:bg-gray-50 focus:outline-none"
          >
            {'>'}
          </button>
        </div>
      </div>
    </div>
  );
}

type TokenSelectNativeType = {
  tokens: Array<Token>;
  selectedTokens: Array<Token>;
  setSelectedTokens: any;
  max: number;
  getImages?: 5;
};

export type MetadataNFTNative = {
  token: Token;
  name: string;
  image: string;
  selected?: boolean;
};

export function TokenSelectNative({
  tokens,
  selectedTokens,
  setSelectedTokens,
  max,
  getImages,
}: TokenSelectNativeType) {
  const [metadata, setMetadata] = useState<MetadataNFTNative[]>([]);
  const [page, setPage] = useState(1);
  const pageSize = 15;
  const totalPages = Math.ceil(tokens ? tokens.length / pageSize : 0);
  useEffect(() => {
    const fetchImages = async () => {
      setMetadata([]);
      const startIndex = (page - 1) * pageSize;
      const endIndex = Math.min(page * pageSize, tokens.length);
      const shownTokens: any = tokens.slice(startIndex, endIndex);
      if (
        getImages &&
        shownTokens[0] &&
        shownTokens[0].image !== null &&
        !shownTokens[0].image?.startsWith('http')
      ) {
        const images =
          (await ApiClient.getCollectionTokenImages(
            shownTokens[0].collectionAddress,
            shownTokens.map((t: any) => t.tokenId.toString()),
          ).catch(e => e)) || [];

        for (let i = 0; i < shownTokens.length; i += 1) {
          const foundImage = images.find(
            (img: any) =>
              img.tokenId.toString() === shownTokens[i].tokenId.toString(),
          );
          if (foundImage) {
            shownTokens[i].image = foundImage.image.replace(
              'https://ipfs.io/ipfs/',
              'https://cdn.ltsglxy.network/ipfs/',
            );
          } else {
            shownTokens[i].image = null;
          }
        }
      }

      const metaPromises = shownTokens.map((token: any) => {
        return {
          token,
          name: token.name,
          image: token.image,
          selected: selectedTokens.some(t => t === token),
        };
      });

      const meta = await Promise.all(metaPromises);
      setMetadata(meta);
    };

    fetchImages().catch(console.error);
  }, [page, tokens]);

  useEffect(() => {
    for (let i = 0; i < metadata.length; i++) {
      setMetadata((old: any) => {
        old = old.map((o: MetadataNFTNative) => {
          return {
            ...o,
            selected: selectedTokens.some(t => t === o.token),
          };
        });
        return old;
      });
    }
  }, [selectedTokens]);

  const handleSelect = useCallback(
    (token: Token) => {
      setSelectedTokens((old: any) => {
        return [token];
      });
    },
    [setSelectedTokens],
  );

  return (
    <div className="flex flex-col">
      <div className="grid grid-cols-5 gap-x-2 gap-y-2">
        {metadata &&
          metadata.map((token, i) => {
            return (
              <div
                key={token.name + '-' + i}
                className="relative cursor-pointer"
                onClick={() => handleSelect(token.token)}
              >
                <LazyLoadImage
                  src={token.image?.replace(
                    'https://ipfs.io/ipfs/',
                    'https://cdn.ltsglxy.network/ipfs/',
                  )}
                  alt={token.name}
                  className={`w-[75px] rounded-md ${
                    token.selected ? 'opacity-50' : 'hover:opacity-75'
                  }`}
                />
                {token.selected && (
                  <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 16.17L4.83 12L3.41 13.41L9 19L21 7L19.59 5.59L9 16.17Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                )}
              </div>
            );
          })}
      </div>
      <div className="mt-4 mb-2 flex flex-row gap-x-2 justify-between">
        <div className="flex mt-2 flex-row gap-x-2 justify-start align-start items-start">
          <p className="text-sm font-sm">
            Page {page}/{totalPages}
          </p>
          {selectedTokens.length > 0 && (
            <p className="text-sm  font-sm">
              •<span className="pl-2">Selected: {selectedTokens.length}</span>
            </p>
          )}
        </div>
        <div>
          <button
            onClick={() => setPage(old => old - 1)}
            disabled={page <= 1}
            className="cursor-pointer disabled:cursor-default bg-white dark:bg-zinc-800 dark:text-white dark:border-zinc-700 dark:disabled:opacity-50  dark:hover:bg-zinc-700 dark:disabled:hover:bg-zinc-800 disabled:bg-gray-200 py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 disabled:text-gray-400 hover:bg-gray-50 focus:outline-none"
          >
            {'<'}
          </button>
          <button
            onClick={() => setPage(old => old + 1)}
            disabled={page >= totalPages}
            className="cursor-pointer disabled:cursor-default bg-white dark:bg-zinc-800 dark:text-white dark:border-zinc-700 dark:disabled:opacity-50  dark:hover:bg-zinc-700 dark:disabled:hover:bg-zinc-800 disabled:bg-gray-200 py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 disabled:text-gray-400 hover:bg-gray-50 focus:outline-none"
          >
            {'>'}
          </button>
        </div>
      </div>
    </div>
  );
}

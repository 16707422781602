import PercInput from '../components/inputs/PercInput';
import UsdInput from '../components/inputs/UsdInput';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ApiClient, { Collection } from '../../../utils/ApiClient';
import { getCurrencySymbol } from '../../../utils/parser';
import CurrencyInput from '../components/inputs/CurrencyInput';

export default function CreateAlert() {
  const [value, setValue] = useState(0);
  const [croValue, setCroValue] = useState(0);
  const [usdValue, setUsdValue] = useState(0);
  const { collectionAddress } = useParams();

  const [collection, setCollection] = useState<Collection>();

  useEffect(() => {
    if (!collectionAddress) return;
    ApiClient.getCollection(collectionAddress).then(data => {
      setCollection(data.data.data);
    });
  }, []);

  const percChange = (event: any) => {
    setCroValue(0);
    setUsdValue(0);
    setValue(parseInt(event.target.value));
  };
  const croChange = (event: any) => {
    if (collection == undefined) return;
    setCroValue(event.target.value);
    setUsdValue(
      Math.round(
        (collection.pFloorUsd / collection.pFloor) *
          Number(event.target.value) *
          100,
      ) / 100,
    );
  };
  const usdChange = (event: any) => {
    if (collection == undefined) return;
    setUsdValue(event.target.value);
    setCroValue(
      Number(event.target.value) / (collection.pFloorUsd / collection.pFloor),
    );
  };
  const navigate = useNavigate();
  const setAlert = (a: any) => {
    if (collection === undefined) return;
    ApiClient.addAlert(
      collection.address,
      Math.round(
        croValue
          ? Number(croValue)
          : Number((collection.pFloor / 100) * value) +
              Number(collection.pFloor),
      ),
      collection.pFloor,
    )
      .then(() => {
        navigate('/app/smart-alerts');
      })
      .catch((err: any) => {
        console.error(err);
      });
  };
  if (collection === undefined) return <div></div>;
  return (
    <>
      <div className="bg-white dark:bg-zinc-800 shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
            Create Alert
          </h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500 dark:text-gray-300">
            <p className="dark:text-gray-300">
              Set Floor Price Alert for {collection.name}.
            </p>
          </div>
          <div className="dark:text-gray-400">
            Current floor: {collection.pFloor}{' '}
            {getCurrencySymbol(collection.network)} -{' '}
            {collection.pFloorUsd.toFixed(2)} USD
          </div>
          <form className="mt-5 sm:flex sm:items-center ">
            <div className="w-full sm:max-w-xs">
              <label htmlFor="email" className="sr-only ">
                Price
              </label>

              <PercInput onChange={percChange} value={value}></PercInput>
              <CurrencyInput
                onChange={croChange}
                value={
                  croValue
                    ? Number(croValue)
                    : Number((collection.pFloorUsd / 100) * value) +
                      Number(collection.pFloorUsd)
                }
                currency={collection.network}
              ></CurrencyInput>
              <UsdInput
                onChange={usdChange}
                value={
                  usdValue
                    ? Number(usdValue)
                    : (
                        collection.pFloorUsd +
                        (collection.pFloorUsd / 100) * value
                      ).toFixed(2)
                }
              ></UsdInput>
            </div>
          </form>
          <button
            type="button"
            onClick={e => {
              setAlert(e);
            }}
            className="my-6 px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Set Alert
          </button>
        </div>
      </div>
    </>
  );
}

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import '../../styles/TimeWars.css';
import timeWarsClient from '../../../utils/TimeWarsClient';
import ShipYardDashboard from '../components/timewars/Shipyard';

export default function TimeWars() {
  const { user, activeWallet, loadingActiveWallet } = useAuth();

  return (
    <div className="h-full">
      <h1 className="text-2xl font-semibold text-gray-900 dark:text-white mb-2">
        Time Wars Console
      </h1>
      <GameUI>
        <ShipYardDashboard />
      </GameUI>
    </div>
  );
}

const GameUI = ({ children }: any) => {
  return (
    <div className="w-full h-full bg-black flex justify-center items-center">
      <div className="w-full max-w-screen-lg aspect-w-16 aspect-h-9 bg-black rounded-sm overflow-hidden relative">
        <div className="flex justify-center items-center h-full text-white">
          {children}
        </div>
      </div>
    </div>
  );
};

import { useEffect, useState } from 'react';
import { DropCard } from './DropCard';
import { BaseSvg, CronosSvg, PolygonSvg } from '../../../../utils/parser';
import axios from 'axios';

export function DropsList() {
  const [chain, setChain] = useState('cronos');
  const [drops, setDrops] = useState<any[]>([]);
  useEffect(() => {
    axios
      .get(
        'https://dynamicdropstatas-production.up.railway.app/partners/time-launcher/drops',
      )
      .then(r => r.data)
      .then(data => setDrops(data.drops));
  }, []);

  const showingDrops = drops.filter(d => d.chain === chain);

  const list = showingDrops.map((drop: any, i) => {
    return (
      <DropCard
        key={drop.title + `${i}_drop`}
        name={drop.title}
        slug={drop.slug}
        gifImage={drop.gifImage}
        bannerImage={drop.bannerImage}
      ></DropCard>
    );
  });
  return (
    <div className="flex flex-col justify-center items-center gap-y-1">
      <div className="flex flex-row gap-x-2 items-center mb-2">
        <div
          onClick={() => {
            setChain('cronos');
          }}
          className={`cursor-pointer rounded-md p-2 bg-white ${
            chain === 'cronos' ? 'bg-gray-100' : ''
          } border border-b-gray-300 hover:bg-gray-100`}
        >
          <CronosSvg className="w-6 h-6" />
        </div>
        <div
          onClick={() => {
            setChain('polygon');
          }}
          className={`cursor-pointer rounded-md p-2 bg-white ${
            chain === 'polygon' ? 'bg-gray-100' : ''
          } border border-b-gray-300 hover:bg-gray-100`}
        >
          <PolygonSvg className="w-6 h-6" />
        </div>
        <div
          onClick={() => {
            setChain('base');
          }}
          className={`cursor-pointer rounded-md p-2 bg-white ${
            chain === 'base' ? 'bg-gray-100' : ''
          } border border-b-gray-300 hover:bg-gray-100`}
        >
          <BaseSvg className="w-6 h-6" />
        </div>
      </div>
      <div className="w-full grid gap-4 grid-cols-1 md:grid-cols-2">{list}</div>
    </div>
  );
}

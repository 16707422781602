import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ApiClient, {
  Collection,
  Token,
  TokenStat,
} from '../../../utils/ApiClient';
import TokenCard from '../components/token/TokenCard';
import TraitList from '../components/token/TraitList';
import { ShoppingCartIcon } from '@heroicons/react/24/solid';
import { parsePlatform } from '../../../web3/config';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useAuth } from '../../../hooks/useAuth';
import MoonflowCronosABI from '../../../web3/ABI/moonflow/cronos.market.abi.json';
import MoonflowPolygonABI from '../../../web3/ABI/moonflow/polygon.market.abi.json';
import OpenseaABI from '../../../web3/ABI/seaport.abi.json';
import { BigNumber, Contract, ethers } from 'ethers';
import { ensureCronos, ensurePolygon, txWait } from '../../../web3/utils';
import InfoAlert from '../components/InfoAlert';
import EbisusABI from '../../../web3/ABI/contracts/ebisus-ship';
import ERC20ABI from '../../../web3/ABI/erc20.cro.abi.json';
import axios from 'axios';

function convertLargeNumberToString(num: number) {
  // Convert to scientific notation
  const scientificNotation = num.toString();

  // Split into base and exponent
  const [base, exponentPart] = scientificNotation.split('e+');
  const exponent = parseInt(exponentPart);

  // Calculate the number of zeros to add
  const zerosNeeded = exponent - (base.length - 1); // minus the length of the base

  // Construct the full string
  return base.replace('.', '') + '0'.repeat(zerosNeeded);
}

export default function TokenPage() {
  const { activeWallet, forceUserUpdate } = useAuth();
  const [token, setToken] = useState<Token>();
  const [collection, setCollection] = useState<Collection>();
  const { address, tokenId } = useParams();
  const [tokenBuyMessage, setTokenBuyMessage] = useState<{
    message: string;
    title: string;
    type: 'success' | 'error';
  }>({
    message: '',
    title: '',
    type: 'error',
  });

  // ebisus
  async function getTokenAllowance(
    address: string,
  ): Promise<BigNumber | undefined> {
    await ensureCronos();
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = await provider.getSigner();
    const poolContract = new ethers.Contract(address, ERC20ABI, signer);
    const gotTokenAllowance = await poolContract.allowance(
      activeWallet,
      '0x523d6f30c4aaca133daad97ee2a0c48235bff137',
    );

    if (gotTokenAllowance) {
      return gotTokenAllowance;
    }
  }

  async function approveAllowance(
    address: string,
    value: BigNumber,
  ): Promise<boolean> {
    await ensureCronos();
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = await provider.getSigner();
    const poolContract = new ethers.Contract(address, ERC20ABI, signer);
    const approve = await txWait(
      poolContract.approve('0x523d6f30c4aaca133daad97ee2a0c48235bff137', value),
    );

    return approve ? true : false;
  }

  useEffect(() => {
    if (address === undefined || tokenId === undefined) return;
    ApiClient.getToken(address, tokenId).then(data => {
      setToken(data.token);
      setCollection(data.collection);
    });
  }, []);

  const [loadingPurchase, setLoadingPurchase] = useState('');
  async function onPurchase(stats: TokenStat) {
    if (!token || !collection || !activeWallet) return;
    setLoadingPurchase(stats.source);
    const data = await ApiClient.purchaseToken(
      stats.collectionAddress,
      token?.tokenId,
      stats.source,
      activeWallet,
    );

    try {
      if (data.network === 'POLYGON') {
        await ensurePolygon();
      } else if (data.network === 'CRONOS') {
        await ensureCronos();
      }
    } catch {
      setLoadingPurchase('');
      return;
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum);

    // Get the signer from the provider
    const signer = provider.getSigner();

    console.log({ data });
    try {
      if (data) {
        switch (data.network) {
          case 'POLYGON':
            // MoonflowPolygonABI;

            if (stats.source === 'MOONFLOW') {
              const polygonContract = new Contract(
                data.contract,
                MoonflowPolygonABI,
                signer,
              );

              if (data.data.error && data.data.message) {
                throw new Error(data.data.message);
              }

              // Estimate gas
              const estimatedGas =
                await polygonContract.estimateGas.purchaseItem(
                  data.data.listing,
                  data.data.transfer,
                  {
                    value: data.data.listing.price,
                  },
                );

              const polygonReceipt = await txWait(
                polygonContract.purchaseItem(
                  data.data.listing,
                  data.data.transfer,
                  {
                    value: data.data.listing.price,
                    gasLimit: estimatedGas,
                  },
                ),
              );

              if (polygonReceipt) {
                setTokenBuyMessage({
                  type: 'success',
                  title: 'Success',
                  message: `You have purchased ${token.name}!`,
                });
              }

              setTimeout(() => {
                forceUserUpdate();

                ApiClient.getToken(address!, tokenId!).then(data => {
                  setToken(data.token);
                  setCollection(data.collection);
                });
              }, 3000);
            } else if (stats.source === 'OPENSEA') {
              const transactionData = data.data.transaction;
              const inputData = transactionData.input_data.parameters;

              const openseaContract = new Contract(
                data.contract,
                OpenseaABI,
                signer,
              );

              const contractFunctionArgs = [
                inputData.considerationToken,
                inputData.considerationIdentifier,
                inputData.considerationAmount,
                inputData.offerer, // Ensure this is a payable address
                inputData.zone,
                inputData.offerToken,
                inputData.offerIdentifier,
                inputData.offerAmount,
                inputData.basicOrderType, // Make sure this matches the enum representation
                inputData.startTime,
                inputData.endTime,
                inputData.zoneHash,
                inputData.salt,
                inputData.offererConduitKey,
                inputData.fulfillerConduitKey,
                inputData.totalOriginalAdditionalRecipients, // This should match the length of inputData.additionalRecipients
                inputData.additionalRecipients.map((recipient: any) => [
                  recipient.amount,
                  recipient.recipient,
                ]),
                inputData.signature,
              ];

              const functionName = transactionData.function; //'fulfillBasicOrder';

              const valueBigNumber = ethers.utils.parseUnits(
                convertLargeNumberToString(transactionData.value),
                'wei',
              );

              // const gasEstimate = await provider.estimateGas({
              //   to: data.contract,
              //   from: await signer.getAddress(),
              //   data: openseaContract.interface.encodeFunctionData(
              //     functionName,
              //     contractFunctionArgs,
              //   ),
              //   value: valueBigNumber,
              // });

              const tx = await txWait(
                openseaContract.functions[functionName](contractFunctionArgs, {
                  value: valueBigNumber,
                  // gasLimit: gasEstimate,
                }),
              );

              if (tx) {
                setTokenBuyMessage({
                  type: 'success',
                  title: 'Success',
                  message: `You have purchased ${token.name}!`,
                });

                setTimeout(() => {
                  ApiClient.getToken(address!, tokenId!).then(data => {
                    setToken(data.token);
                    setCollection(data.collection);
                  });

                  forceUserUpdate();
                }, 3000);
              }
            }
            break;
          case 'CRONOS':
            await ensureCronos();

            if (stats.source === 'MOONFLOW') {
              const cronosContract = new Contract(
                data.contract,
                MoonflowCronosABI,
                signer,
              );

              const cronosReceipt = await txWait(
                cronosContract.purchaseItemV2(
                  data.data.listing,
                  data.data.transfer,
                  {
                    value: data.data.listing.price,
                  },
                ),
              );

              console.log(cronosReceipt);
              setTokenBuyMessage({
                type: 'success',
                title: 'Success',
                message: `You have purchased ${token.name}!`,
              });
              forceUserUpdate();
              setTimeout(() => {
                ApiClient.getToken(address!, tokenId!).then(data => {
                  setToken(data.token);
                  setCollection(data.collection);
                });
              }, 3000);
            } else if (stats.source === 'EBISUS') {
              const cartPrice = parseInt(data.data.price, 10);
              const listingIds = [data.data.listingId];
              const price = ethers.utils.parseEther(`${cartPrice}`);
              console.log(data.data);
              if (
                data.data.currency !==
                '0x0000000000000000000000000000000000000000'
              ) {
                const allowance = await getTokenAllowance(data.data.currency);
                if (allowance === undefined) {
                  setTokenBuyMessage({
                    type: 'error',
                    title: 'Error',
                    message: `Failed to retrieve your allowance.`,
                  });
                  return;
                }

                if (
                  data.data.currency !==
                  '0x0000000000000000000000000000000000000000'
                ) {
                  if (allowance.lt(price)) {
                    await approveAllowance(
                      data.data.currency,
                      price.sub(allowance),
                    );
                  }
                }
              }

              const buyContract = new Contract(
                '0x523d6f30c4aaca133daad97ee2a0c48235bff137',
                EbisusABI,
                signer,
              );

              const { data: serverSig } = await axios.get(
                'https://cms.ebisusbay.com/api/gasless-listing/validator',
                {
                  params: {
                    address: activeWallet.toLowerCase(),
                    listingIds,
                  },
                },
              );

              const { signature, orderData, ...sigData } = serverSig.data;
              const total = price.add(sigData.feeAmount);

              const tx = await txWait(
                buyContract.fillOrders(orderData, sigData, signature, {
                  value: total,
                }),
              );

              console.log('tx:');
              console.log(tx);

              if (tx) {
                setTokenBuyMessage({
                  type: 'success',
                  title: 'Success',
                  message: `You have purchased ${token.name}!`,
                });

                setTimeout(() => {
                  forceUserUpdate();
                  ApiClient.getToken(address!, tokenId!).then(data => {
                    setToken(data.token);
                    setCollection(data.collection);
                  });
                }, 3000);
              }
            }
            break;
        }
      }
      setLoadingPurchase('');
    } catch (err: any) {
      setLoadingPurchase('');
      if (err.data?.message) {
        setTokenBuyMessage({
          type: 'error',
          title: 'Something went wrong',
          message: err.data.message,
        });
      } else {
        console.log(err);
        setTokenBuyMessage({
          type: 'error',
          title: 'Something went wrong',
          message:
            err.message || 'Something went wrong, please try again later.',
        });
      }
    }
  }
  if (token === undefined) return <div></div>;
  return (
    <div>
      <TokenCard token={token} collection={collection}></TokenCard>
      <div>
        <div className="bg-white dark:bg-zinc-900 px-8 mt-8">
          <InfoAlert data={tokenBuyMessage} />
          <div className="flow-root overflow-hidden">
            <div className=" max-w-12xl">
              <table className="w-full text-left">
                <thead className="bg-white dark:bg-zinc-900">
                  <tr>
                    <th
                      scope="col"
                      className="relative  isolate text-xl py-3.5 pr-3 text-left font-semibold w-[200px] text-gray-900 dark:text-white"
                    >
                      Marketplaces
                    </th>
                    <th
                      scope="col"
                      className="relative  isolate text-xl py-3.5 pr-3 text-left font-semibold w-[200px] text-gray-900 dark:text-white"
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {token.TokenStats.sort(
                    (a, b) => a.listPrice - b.listPrice,
                  ).map((stats, index) => (
                    <tr
                      key={stats.source}
                      className={`bg-white dark:bg-zinc-900 ${
                        index % 2 === 0 ? 'bg-gray-100 dark:bg-zinc-800' : ''
                      }`}
                    >
                      <td className="relative py-4 pr-3 text-sm font-medium text-gray-900">
                        <div className="flex justify-center items-center">
                          <a
                            target="_blank"
                            href={
                              parsePlatform(stats.source)?.tokenPrefix[
                                collection?.network || 'CRONOS'
                              ]?.(token.collectionAddress, token.tokenId) || '#'
                            }
                            rel="noreferrer"
                          >
                            <LazyLoadImage
                              src={parsePlatform(stats.source).icon}
                              alt={parsePlatform(stats.source).name}
                              className="h-9"
                            />
                          </a>
                        </div>
                      </td>
                      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                        <div className="flex justify-center items-center">
                          {stats.listed ? (
                            <button
                              onClick={() => {
                                onPurchase(stats);
                              }}
                              disabled={loadingPurchase === stats.source}
                              className={`relative bg-purple-600 disabled:opacity-50 hover:bg-purple-500 text-gray-100 inline-flex items-center px-4 py-2 rounded-md text-sm font-medium focus:z-10 focus:outline-none`}
                            >
                              {loadingPurchase === stats.source ? (
                                <>Working on it...</>
                              ) : (
                                <>
                                  {' '}
                                  <ShoppingCartIcon className="w-4 h-4 mr-2" />{' '}
                                  {Number(stats.listPrice).toFixed(1)}{' '}
                                  {stats.originalCurrency
                                    ? stats.originalCurrency
                                    : collection?.network === 'POLYGON'
                                    ? 'MATIC'
                                    : collection?.network === 'CRONOS'
                                    ? 'CRO'
                                    : stats.originalCurrency}
                                </>
                              )}
                            </button>
                          ) : (
                            <a
                              target="_blank"
                              href={parsePlatform(stats.source)?.tokenPrefix?.[
                                collection?.network || 'CRONOS'
                              ]?.(collection?.address || '', token.tokenId)}
                              rel="noreferrer"
                            >
                              <button
                                className={`relative bg-blue-600 hover:bg-blue-500 text-gray-100 inline-flex items-center px-4 py-2 rounded-md text-sm font-medium focus:z-10 focus:outline-none`}
                              >
                                Visit on {parsePlatform(stats.source).name}
                              </button>
                            </a>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {token.traits && token.traits.length > 0 ? (
        <div className="mt-20 bg-white dark:bg-zinc-900 pl-8 pr-8">
          <h2 className="relative  isolate text-xl py-3.5 pr-3 text-left font-semibold w-[200px] text-gray-900 dark:text-white">
            Traits
          </h2>
          <TraitList traits={token.traits}></TraitList>
        </div>
      ) : null}
    </div>
  );
}

// import CollectionNav from "../CollectionNav";
import { Key, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ApiClient, {
  PaginatedResponse,
  Token,
} from '../../../../utils/ApiClient';
import Pagination from '../Pagination';
import SearchButtons from '../SearchButtons';
import { MyTokenCard } from './MyTokenCard';

export function InventoryTokenList({ collection }: any) {
  let [searchParams, setSearchParams] = useSearchParams();
  const [pages, setPages] = useState(0);
  const [pageSize, setPageSize] = useState(12);
  const [page, setPage] = useState(
    parseInt(searchParams.get('page') || '1', 10),
  );
  const [total, setTotal] = useState(0);
  const [order, setOrder] = useState('listPriceNative');
  const [direction, setDirection] = useState('asc');
  const [search, setSearch] = useState('');
  const [listed, setListed] = useState('both');
  const [tokens, setTokens] = useState<Token[]>([]);
  const [source, setSource] = useState('all');

  const goto = (page: number) => {
    setSearchParams({ page: page.toString() });
    setPage(page);
  };

  useEffect(() => {
    const p = parseInt(searchParams.get('page') || '1', 10);
    ApiClient.getCollectionTokens(
      collection,
      p,
      pageSize,
      order,
      direction,
      search,
      listed,
      source,
    )
      .then((res: PaginatedResponse<Token>) => {
        setTokens(res.data);
        setTotal(res.total);
        setPages(res.totalPages);
        setDirection(res.direction);
        setOrder(res.order);
        setPageSize(res.pageSize);
      })
      .catch((e: any) => console.log(e));
  }, [
    page,
    search,
    order,
    direction,
    listed,
    searchParams,
    collection,
    pageSize,
    source,
  ]);

  console.log(tokens);
  const list = tokens.map((token: any) => (
    <MyTokenCard key={token.id} token={token}></MyTokenCard>
  ));
  const myRef = useRef(null);
  const executeScroll = () => {
    if (myRef.current) {
      (myRef.current as any).scrollIntoView();
    }
  };

  return (
    <div
      ref={myRef}
      className="bg-white dark:bg-zinc-900 my-6 shadow border border-gray-100 dark:border-zinc-700 p-6 rounded-lg"
    >
      <SearchButtons
        direction={direction}
        setOrder={setOrder}
        order={order}
        setListed={setListed}
        setDirection={setDirection}
        search={setSearch}
        listed={listed}
        source={source}
        setSource={setSource}
        title=""
        floor={false}
      ></SearchButtons>
      {/* <div className="grid grid-cols-1 sm:grid-cols-4 mb-6">
        <div className="">
          <CollectionNav nav={nav}></CollectionNav>
        </div> */}
      <div className="col-span-3">
        <div className="grid grid-cols-2 gap-4 sm:grid-cols-4">{list}</div>
      </div>
      {/* </div> */}
      <Pagination
        total={total}
        page={page}
        goto={goto}
        pageSize={pageSize}
        pages={pages}
      ></Pagination>
    </div>
  );
}

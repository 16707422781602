import { useEffect, useRef, useState } from 'react';
import ApiClient from '../../../../utils/ApiClient';
import AdminTabs from '../../components/admin/AdminTabs';
import { ethers } from 'ethers';
import _ from 'lodash';

export default function Users() {
  const [userStats, setUserStats] = useState<any>();
  const [search, setSearch] = useState('');
  const [foundUser, setFoundUser] = useState<any>();
  const [error, setError] = useState('');
  const [searching, setSearching] = useState(false);
  const xpFile = useRef<any>();
  const [uploading, setUploading] = useState(false);
  const [generatingCSV, setGeneratingCSV] = useState(false);
  useEffect(() => {
    ApiClient.admin_getUserStats().then(data => {
      setUserStats(data);
    });
  }, []);

  function onUploadTroopsXP() {
    setUploading(true);
    ApiClient.admin_uploadXpTroops(xpFile.current.files[0])
      .then(r => {
        console.log(r);
        setUploading(false);

        if (r.success) {
          xpFile.current.value = '';
          alert(`Successfully distributed XP to ${r.count} users.`);
        } else {
          alert(r.message || 'Something went wrong.');
        }
      })
      .catch(e => {
        console.log(e);
        setUploading(false);

        alert(
          e.message || 'Something went wrong, XP was most likely not given.',
        );
      });
  }
  function onSearchUser() {
    setSearching(true);
    setError('');
    setFoundUser(undefined);
    ApiClient.admin_searchUser(search)
      .then(data => {
        console.log(data);
        if (data.account) {
          setFoundUser(data.account);
        } else {
          setError('Failed to find account.');
        }
        setSearching(false);
      })
      .catch(e => {
        console.log(e);
      });
  }

  return (
    <>
      <AdminTabs />
      <h2 className="mt-6 mb-2 text-xl font-semibold text-gray-900 dark:text-white">
        User Statistics
      </h2>
      <div>
        <p className="dark:text-white">
          Total Users: {userStats?.totalUsers || 'Loading...'}
        </p>
        <p className="dark:text-white">
          Active Paid Subscriptions: {userStats?.activeSubscriptions}
        </p>
      </div>
      <div className="mt-4">
        <h3 className="mt-12 mb-2 text-xl font-semibold text-gray-900 dark:text-white">
          User Lookup
        </h3>
        <div>
          <input
            placeholder="User address"
            className="px-4 block py-2 min-w-0 rounded-md min-w-full md:min-w-[400px] mb-4 sm:text-sm border-gray-300 dark:border-zinc-600 dark:bg-zinc-800 dark:text-white dark:placeholder-zinc-300"
            onChange={e => {
              setSearch(e.target.value);
            }}
          />
          <button
            onClick={onSearchUser}
            className="cursor-pointer mb-4 disabled:cursor-default bg-white dark:bg-zinc-800 dark:text-white dark:border-zinc-700 dark:disabled:opacity-50 dark:hover:bg-zinc-700 dark:disabled:hover:bg-zinc-800 disabled:bg-gray-200 py-2 px-6 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 disabled:text-gray-400 hover:bg-gray-50 focus:outline-none"
            disabled={searching}
          >
            Search
          </button>
        </div>
        {error !== '' && <p className="text-[red]">{error}</p>}
        {foundUser && (
          <div>
            <p className="dark:text-white">
              <b>ID:</b> {foundUser.id}
            </p>

            <p className="dark:text-white">
              <b>Address:</b> {foundUser.address}
            </p>
            <p className="dark:text-white">
              <b>Name:</b> {foundUser.name}
            </p>
            <p className="dark:text-white">
              <b>Role:</b> {foundUser.role}
            </p>
            <p className="dark:text-white">
              <b>Created At:</b> {foundUser.createdAt}
            </p>
            <p className="dark:text-white">
              <b>Level/XP:</b> {foundUser.levelXp}
            </p>
            <br />
            <p className="dark:text-white">
              <b>Extra Wallets</b> ({foundUser.wallets.length}):{' '}
              {foundUser.wallets.length === 0
                ? 'None'
                : foundUser.wallets.join(', ')}
            </p>
            <p className="dark:text-white">
              <b>Active Subscriptions:</b>{' '}
              {foundUser._count.accountSubscription}
            </p>
            <p className="dark:text-white">
              <b>Tokens:</b> {foundUser._count.tokens}
            </p>
            <p className="dark:text-white">
              <b>Pending Payments:</b> {foundUser._count.pendingPayment}
            </p>
          </div>
        )}
      </div>
      <div className="mt-4">
        <h3 className="mt-12 mb-2 text-xl font-semibold text-gray-900 dark:text-white">
          Upload Troops CSV
        </h3>
        <div>
          <input
            placeholder="User address"
            type="file"
            className="px-4 block py-2 min-w-0 rounded-md min-w-full md:min-w-[400px] mb-4 sm:text-sm border-gray-300 dark:border-zinc-600 dark:bg-zinc-800 dark:text-white dark:placeholder-zinc-300"
            ref={xpFile}
          />
          <button
            onClick={onUploadTroopsXP}
            className="cursor-pointer mb-4 disabled:cursor-default bg-white dark:bg-zinc-800 dark:text-white dark:border-zinc-700 dark:disabled:opacity-50 dark:hover:bg-zinc-700 dark:disabled:hover:bg-zinc-800 disabled:bg-gray-200 py-2 px-6 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 disabled:text-gray-400 hover:bg-gray-50 focus:outline-none"
            disabled={uploading}
          >
            Upload & Give XP
          </button>
        </div>
      </div>
      <div className="mt-4">
        <h3 className="mt-12 mb-2 text-xl font-semibold text-gray-900 dark:text-white">
          $TIME Vaults
        </h3>
        <div className="mb-4"></div>
        <button
          onClick={() => {
            setGeneratingCSV(true);
            ApiClient.admin_getVaultsCSV()
              .then(r => {
                const blob = new Blob([r], { type: 'text/csv;charset=utf-8;' });

                // Create a URL for the Blob
                const url = URL.createObjectURL(blob);

                // Create a temporary link element
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'vaults_report.csv'); // Set the file name for download

                // Append the link to the document and trigger the download
                document.body.appendChild(link); // Required for Firefox
                link.click();

                // Clean up by removing the link and revoking the Blob URL
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
                setGeneratingCSV(false);
              })
              .catch(e => {
                console.log(e);
                setGeneratingCSV(false);
              });
          }}
          className="cursor-pointer mb-4 disabled:cursor-default bg-white dark:bg-zinc-800 dark:text-white dark:border-zinc-700 dark:disabled:opacity-50 dark:hover:bg-zinc-700 dark:disabled:hover:bg-zinc-800 disabled:bg-gray-200 py-2 px-6 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 disabled:text-gray-400 hover:bg-gray-50 focus:outline-none"
          disabled={generatingCSV}
        >
          Generate CSV
        </button>
      </div>
    </>
  );
}

// wallet-defiwallet.ts
import { ethers } from 'ethers'; // npm install ethers

// This is the SDK provided by Crypto.com DeFi Wallet
import { DeFiWeb3Connector } from '@deficonnect/web3-connector';

import * as config from '../config';
import { IWallet } from '../../contexts/AuthContext';
import { defaultWallet } from '../utils';
import { Connector } from './Connector';

class DefiConnectConnector implements Connector {
  disconnect(): void {
    // Nothing to do
  }
  async connect(): Promise<IWallet> {
    try {
      const connector = new DeFiWeb3Connector({
        appName: 'Time Sniper',
        chainId: '25',
        chainType: 'Cronos',
        rpcUrls: { 25: 'https://evm.cronos.org/' },
      });
      await connector.activate();

      const provider = await connector.getProvider();
      const web3Provider = new ethers.providers.Web3Provider(provider);

      // if (
      //   !(parseInt(provider.chainId) === config.configVars.rpcNetwork.chainId)
      // ) {
      //   window.alert(
      //     'Switch your Wallet to blockchain network ' +
      //       config.configVars.rpcNetwork.chainName,
      //   );
      //   return defaultWallet;
      // }
      // Subscribe to events that reload the app
      // connector.on('session_update', utils.reloadApp);
      // connector.on('Web3ReactDeactivate', utils.reloadApp);
      // connector.on('Web3ReactUpdate', utils.reloadApp);

      return {
        ...defaultWallet,
        walletProviderName: 'defiwallet',
        address: (await web3Provider.listAccounts())[0],
        browserWeb3Provider: web3Provider,
        // wcProvider: provider,
        // wcConnector: connector,
        connected: true,
        chainId: provider.chainId,
      };
    } catch (e: any) {
      if (e.code) {
        switch (e.code) {
          case 4001:
            throw new Error('Connect request canceled.');
          case -32002:
            throw new Error(
              'Connect request pending, open Metamask to connect or cancel',
            );
          case 4100:
            throw new Error('Please use the Defi App Browser.');
          default:
            console.log(e);
        }
      }
      throw e;
    }
  }

  switchNetwork(networkId: number): void {
    // Nothing to do
  }
}
// Main login flow for Crypto.com DeFi Wallet with Wallet Extension
// The connector must be activated, then it exposes a provider
// that is used by the ethers Web3Provider constructor.
export default new DefiConnectConnector();

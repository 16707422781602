/* This example requires Tailwind CSS v2.0+ */
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';
export interface InfoAlertData {
  message: string;
  type: 'error' | 'success';
  title: string;
}
interface Props {
  data: InfoAlertData;
}
export default function InfoAlert({ data }: Props) {
  const [message, setMessage] = useState('');
  useEffect(() => {
    setMessage(data.message);
  }, [data]);
  let color = data.type === 'error' ? 'yellow' : 'green';
  if (message === '') {
    return <></>;
  }
  return (
    <div className={`bg-${color}-50 rounded-md p-4`}>
      <div className="flex">
        <div className="flex-shrink-0">
          {data.type === 'error' ? (
            <ExclamationCircleIcon
              className={`h-5 w-5 text-${color}-400`}
              aria-hidden="true"
            />
          ) : (
            <CheckCircleIcon
              className={`h-5 w-5 text-${color}-400`}
              aria-hidden="true"
            />
          )}
        </div>
        <div className="ml-3">
          <h3 className={`text-sm font-medium text-${color}-800`}>
            {data.title}
          </h3>
          <p className={`text-sm font-medium text-${color}-800`}>
            {data.message}
          </p>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              onClick={() => setMessage('')}
              className={`inline-flex bg-${color}-50 rounded-md p-1.5 text-${color}-500 hover:bg-${color}-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-${color}-50 focus:ring-${color}-600`}
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

import { PolygonSvg } from '../../../../utils/parser';

export default function CurrencyInput({
  value,
  onChange,
  currency,
}: {
  value: number;
  onChange: (e: any) => void;
  currency: 'POLYGON' | 'CRONOS';
}) {
  return (
    <div>
      {/* <label htmlFor="price" className="block text-sm font-medium text-gray-700">
          Price
        </label> */}
      <div className="mt-1 relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span className="text-gray-500 sm:text-sm dark:border-zinc-600 dark:bg-zinc-900 dark:text-gray-300">
            {currency === 'POLYGON' ? (
              <PolygonSvg className="inline-block h-3 w-3 mb-1" />
            ) : (
              'C'
            )}
          </span>
        </div>
        <input
          type="number"
          name="price"
          id="price"
          value={value.toFixed(0)}
          onChange={onChange}
          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 dark:border-zinc-600 dark:bg-zinc-900 dark:text-gray-300 rounded-md"
          placeholder="0.00"
          aria-describedby="price-currency"
        />
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <span
            className="text-gray-500 sm:text-sm dark:bg-zinc-900 dark:text-gray-300"
            id="price-currency"
          >
            {currency === 'POLYGON' ? 'MATIC' : 'CRO'}
          </span>
        </div>
      </div>
    </div>
  );
}
